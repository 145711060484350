.mtx-knowledge-base-releases-page {
  .mtx-expandable-search-form {
    min-height: @form-height;
  }

  .releases-list > li {
    margin-bottom: @grid-gutter-horizontal;
  }

  .release-title > a:before {
    .position-cover();
    content: '';
    border-radius: @panel-space-border-radius;
  }

  .release-description {
    .text-ellipsis-box(3);
    min-height: unit(@global-line-height * 3, em);
  }
}
