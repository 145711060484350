//
// Component: Flex
//
// ========================================================================


// Variables
// ========================================================================

// Custom variables.
@flex-form-fields-margin-bottom:                  @global-margin;
@flex-form-fields-margin-horizontal:              @global-margin-medium * 2;

// Miscellaneous
// ========================================================================

.hook-flex-misc() {
  // Custom classes.
  .uk-flex-brake {
    flex-basis: 100%;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .uk-flex-form-fields {
    & > * {
      &,
      &.form-item {
        margin-top: 0;
        margin-bottom: @flex-form-fields-margin-bottom;
      }

      .form-item {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-inline-end: @flex-form-fields-margin-horizontal;
      }
    }

    .uk-flex-brake + .description {
      margin: (@field-description-margin-top - @flex-form-fields-margin-bottom) 0 @flex-form-fields-margin-bottom;
    }
  }

  .uk-flex-push-right {
    margin-inline-end: auto !important;
  }

  .uk-flex-push-left {
    margin-inline-start: auto !important;
  }
}
