/*!
 * Bootstrap Colorpicker v2.3.3
 * http://mjolnic.github.io/bootstrap-colorpicker/
 *
 * Originally written by (c) 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0.txt
 *
 */

.bgImg(@imgFilename) {
  background-image: url('@{global-images-path}nei/bootstrap-colorpicker/@{imgFilename}');
}

.borderRadius(@size) {
  -webkit-border-radius: @size;
  -moz-border-radius: @size;
  border-radius: @size;
}

.colorpicker-saturation {
  width: 100px;
  height: 100px;
  .bgImg('saturation.png');
  cursor: crosshair;
  float: left;

  i {
    display: block;
    height: 5px;
    width: 5px;
    border: 1px solid #000000;
    .borderRadius(5px);
    position: absolute;
    top: 0;
    left: 0;
    margin: -4px 0 0 -4px;

    b {
      display: block;
      height: 5px;
      width: 5px;
      border: 1px solid #ffffff;
      .borderRadius(5px);
    }
  }
}

.colorpicker-hue,
.colorpicker-alpha {
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px;
}

.colorpicker-hue i,
.colorpicker-alpha i {
  display: block;
  height: 1px;
  background: #000000;
  border-top: 1px solid #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px;
}

.colorpicker-hue {
  .bgImg('hue.png');
}

.colorpicker-alpha {
  .bgImg('alpha.png');
  display: none;
}

.colorpicker-saturation,
.colorpicker-hue,
.colorpicker-alpha {
  background-size: contain;
}

.colorpicker {
  padding: 0;
  min-width: 130px;
  margin-top: 1px;
  border-radius: 4px;
  z-index: 2;
}

.colorpicker:before,
.colorpicker:after {
  display: table;
  content: "";
  line-height: 0;
}

.colorpicker:after {
  content: '';
  clear: both;
}

.colorpicker div {
  position: relative;
}

.colorpicker.colorpicker-with-alpha {
  min-width: 140px;
}

.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
  display: block;
}

.colorpicker-color {
  height: 10px;
  margin-top: 5px;
  clear: both;
  .bgImg('alpha.png');
  background-position: 0 100%;
}

.colorpicker-color div {
  height: 10px;
}

.colorpicker-selectors {
  display: none;
  height: 10px;
  margin-top: 5px;
  clear: both;
}

.colorpicker-selectors i {
  cursor: pointer;
  float: left;
  height: 10px;
  width: 10px;
}

.colorpicker-selectors i + i {
  margin-left: 3px;
}

.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  vertical-align: text-top;
  width: 16px;
}

.colorpicker.colorpicker-inline {
  position: relative;
  display: inline-block;
  float: none;
  z-index: auto;
}

.colorpicker.colorpicker-horizontal {
  width: 110px;
  min-width: 110px;
  height: auto;
}

.colorpicker.colorpicker-horizontal .colorpicker-saturation {
  margin-bottom: 4px;
}

.colorpicker.colorpicker-horizontal .colorpicker-color {
  width: 100px;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  width: 100px;
  height: 15px;
  float: left;
  cursor: col-resize;
  margin-left: 0;
  margin-bottom: 4px;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
  display: block;
  height: 15px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  border: none;
  margin-top: 0;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue {
  .bgImg('hue-horizontal.png');
}

.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  .bgImg('alpha-horizontal.png');
}

.colorpicker.colorpicker-hidden {
  display: none;
}

.colorpicker.colorpicker-visible {
  display: block;
}

.colorpicker-inline.colorpicker-visible {
  display: inline-block;
}

.colorpicker-right:before {
  left: auto;
  right: 6px;
}

.colorpicker-right:after {
  left: auto;
  right: 7px;
}

.colorpicker-no-arrow:before {
  border-right: 0;
  border-left: 0;
}

.colorpicker-no-arrow:after {
  border-right: 0;
  border-left: 0;
}

// Custom size for color picker
.colorpicker-custom {
  @colorpicker-size: 208px;
  @colorpicker-slider-width: 12px;
  @colorpicker-slider-margins: 16px;
  @colorpicker-marker-size: 16px;
  @colorpicker-marker-offset: (@colorpicker-marker-size - @colorpicker-slider-width) / 2;

  width: 100%;

  .colorpicker-saturation,
  .colorpicker-alpha,
  .colorpicker-hue {
    cursor: default;
    border-radius: @colorpicker-marker-size / 2;
    width: @colorpicker-size;
    margin-bottom: @colorpicker-slider-margins;
    background-size: 100% 100%;

    i {
      cursor: pointer;
      background-color: transparent;
      border: 3px solid @_white_color;
      box-shadow: 0 0 2px fade(@_black_color, 23), inset 0 0 2px fade(@_black_color, 23);
      .circle(@colorpicker-marker-size);
    }
  }

  .colorpicker-saturation {
    height: @colorpicker-size;

    i {
      margin: -@colorpicker-marker-size / 4;

      b {
        display: none;
      }
    }
  }

  .colorpicker-alpha,
  .colorpicker-hue {
    width: @colorpicker-slider-width;
    height: @colorpicker-size;
    margin-left: @colorpicker-slider-margins + @colorpicker-marker-offset;

    i  {
      margin-top: -@colorpicker-marker-size / 4;
      margin-left: -@colorpicker-marker-offset;
    }
  }

  .colorpicker-alpha {
    background-image: linear-gradient(90%, #c0c0c0 0, transparent 5px);
  }

  .colorpicker-color,
  .colorpicker-color div {
    display: none; // Hide color bar for now.
    height: @colorpicker-slider-width;
  }
}
