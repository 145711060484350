//
// Component: Autocomplete
//
// ========================================================================


// Variables
// ========================================================================

@nav-autocomplete-color:                              @global-text-color;
@nav-autocomplete-active-background:                  @global-primary-background;
@nav-autocomplete-active-color:                       @global-contrast-color;
@nav-autocomplete-header-color:                       @global-muted-color;
@nav-autocomplete-divider-border:                     @global-border;

@user-autocomplete-avatar-size:                       @global-size-medium;


// Component
// ========================================================================

.hook-nav-autocomplete() {
  color: @nav-autocomplete-color;

  & > div {
    font-size: @dropdown-font-size;
    line-height: @global-line-height;
  }

  .uk-form-search-field & {
    padding: @nav-dropdown-padding-vertical @nav-dropdown-padding-horizontal;
  }
}

.hook-nav-autocomplete-active() {}

.hook-nav-autocomplete-header() {}

.hook-nav-autocomplete-divider() {}


// Miscellaneous
// ========================================================================

.hook-autocomplete-misc() {
  .uk-autocomplete {
    .uk-dropdown {
      width: 100%;
      max-height: (@global-line-height + unit(@nav-dropdown-padding-vertical / 16, rem) * 2) * 5 + unit(@dropdown-padding / 16, rem);
      margin-top: @dropdown-margin-top;
      overflow-y: auto;
    }

    & > .form-item {
      margin-bottom: 0;
    }
  }

  // Add: `uk-user-autocomplete`
  .uk-user-autocomplete {
    @item-padding: @global-margin-small;

    display: block;

    .uk-dropdown {
      max-height: (@user-autocomplete-avatar-size + @item-padding * 2) * 5 + unit(@dropdown-padding / 16, rem);
    }

    .uk-nav li > a {
      display: flex;
      align-items: center;
      padding: @item-padding;

      & > div {
        overflow: hidden;
      }
    }

    img,
    canvas {
      height: @user-autocomplete-avatar-size !important;
      width: @user-autocomplete-avatar-size !important;
      min-width: @user-autocomplete-avatar-size !important;
      border-radius: 50% !important;
      background-color: darken(@dropdown-background, 15%);
      margin: 0;
      margin-inline-end: @global-margin;
    }
  }
}
