@api-modal-height: 400px;
@video-item-padding: 56.25%; // (9 / 16) * 100
@image-item-padding: 100%;
@api-item-empty-bg: @overlay-color-dark;
@api-item-font-size: @global-font-size-small;

.api-dialog {
  .uk-modal-content {
    min-height: @api-modal-height;
  }

  .filters {
    margin: @global-margin auto;
    .clearfix;

    .folder-breadcrumbs {
      display: inline-block;
      .user-select();

      ul {
        margin: 0;
      }

      li {
        line-height: @form-height;
        font-size: @global-font-size-xsmall;
      }

      &,
      [dir=rtl] [dir=ltr] & {
        margin: 0 @global-margin-large 0 0;
      }

      [dir=rtl] & {
        margin: 0 0 0 @global-margin-large;
      }
    }

    .uk-form-controls {
      label,
      select {
        display: inline-block;
        font-weight: @global-font-weight-normal;
      }

      select {
        width: auto;
      }

      @media all and (max-width: @breakpoint-mini-max) {
        & > select {
          min-width: 16em;
          margin-bottom: @global-margin;
        }
      }
    }
  }

  .api-item {
    display: block;
    font-size: @api-item-font-size;
    margin-top: @focus-state-box-shadow-size; // Fixes box-shadow overflow
    margin-bottom: @global-margin-large;
    text-decoration: none;

    .title {
      display: block;
      margin-top: @global-margin-small;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }

    &.uk-disabled {
      cursor: default;
    }

    .hook-api-item();
  }

  .not-found {
    color: @global-muted-color;
    display: block;
    padding: 5% 0;
    text-align: center;
    width: 100%;

    & > i {
      font-size: 4rem;
    }

    & > .empty-message {
      margin: @global-margin-medium 0 0;
      font-size: @global-font-size-small;
      font-weight: @global-font-weight-semibold;
    }
  }

  &.uk-modal-dialog-large {
    .not-found {
      margin: @api-modal-height / 5 0;
    }
  }

  .hook-api-dialog();
}
