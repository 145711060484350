@mp-overlay-bg: fade(@_black_color, 15%);
@mp-overlay-hover-bg: fade(#aaaaaa, 40%);
@mp-overlay-active-bg: fade(#707070, 90%);
@mp-overlay-gap: 24px;
@mp-overlay-thumb-width: 100px;

.mp-overlay-menu {
  position: absolute;
  inset: 0;
  width: 100%;
  background-color: @mp-bg-alpha-color;
  color: @mp-accent-color;
  z-index: 1;

  .mp-playlist,
  .mp-cue-points {
    display: flex;
    flex-direction: column;
    gap: @mp-overlay-gap;
    padding: @mp-overlay-gap 0 0;
    height: 100%;
  }

  &-title {
    color: inherit !important;
  }

  &-back-link {
    display: flex;
    gap: @mp-grid / 2;
    align-items: center;
    font-size: .875em;
    color: @mp-accent-color;

    &:focus,
    &:hover {
      color: @mp-text-alpha-color;
    }

    &.active {
      color: @mp-accent-color;
    }
  }

  &-title,
  &-back-link {
    margin: 0 2 * @mp-grid;
  }

  &-items {
    .reset-list;
  }

  &-item {
    display: flex;
    gap: @mp-grid;
    align-items: center;
    font-size: 1em;
    line-height: 1em;
    padding: @mp-grid / 2 @mp-grid * 2;
    color: @mp-text-color;
    text-decoration: none;

    &:hover {
      background-color: @mp-overlay-hover-bg;
    }

    &:hover,
    &:focus {
      color: @mp-text-alpha-color;
    }

    &.active {
      background-color: @mp-overlay-active-bg;
      color: @mp-accent-color;
    }

    &.active,
    &.expired {
      cursor: default;
    }

    &.expired {
      background-color: fade(@global-muted-color, 30%);

      .thumb {
        position: relative;
        background-color: @_white_color;
      }

      .mtx-expiration-thumb {
        border-radius: 0;
      }

      &,
      &:hover {
        color: fade(@mp-text-color, 30%);
      }
    }

    .image {
      width: @mp-overlay-thumb-width;
      height: calc(@mp-overlay-thumb-width * .5625);
      background-color: @_white_color;
      border-radius: calc(@thumb-border-radius * .5);
    }

    .info {
      overflow: hidden;
    }

    .title {
      color: inherit;
      font-weight: @global-font-weight-semibold;
      margin-bottom: .75em;
      .text-ellipsis();
    }

    .meta {
      font-size: .875em;
    }
  }

  .mtx-custom-scrollbar {
    flex: 1;

    &.os-host-scrollbar-vertical-hidden {
      margin-inline-start: 0;
    }
  }

  .os-scrollbar {
    &-track {
      background-color: @mp-bg-alpha-color !important;
      width: 6px;
    }

    &-handle {
      padding: 0;
      background-color: @mp-text-alpha-color !important;
      width: 4px;
      left: 1px;
      border-radius: @global-border-radius;
    }
  }
}
