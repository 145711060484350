@mtx-api-item-padding-x: @global-margin-medium;
@mtx-api-item-padding-y: 1.25rem;
@mtx-api-item-grip-padding: @global-margin-medium;
@mtx-api-group-item-margin: 2rem;
@mtx-api-grouped-items-wrapper-margin: @mtx-api-group-item-margin - @global-margin;

.mtx-api-list {
  ul.uk-grid {
    margin-bottom: -@global-margin;
  }

  ul.uk-sortable-empty {
    min-height: 0;
  }

  .uk-sortable-empty-placeholder {
    border: 1px dashed currentColor;
    border-radius: @thumb-border-radius;
    font-size: @global-font-size-small;
    padding: @mtx-api-item-padding-y @mtx-api-item-padding-x;
    text-align: center;
  }

  @media all and (max-width: @breakpoint-small-max) {
    & + .mtx-api-list {
      margin-top: @global-margin;
    }
  }
}

// It should be standalone because of styles issues on items sorting.
.mtx-api-item {
  @icon-width: @md-icon-size;

  margin-bottom: @global-margin;

  .wrapper {
    height: 100%;
    border-radius: @thumb-border-radius;
    padding: @mtx-api-item-padding-y @mtx-api-item-padding-x;
    background-color: @_grey_lighten_color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  &.bordered {
    .wrapper {
      background-color: @page_content_background_color;
      border: 2px solid currentColor;
      padding: calc(@mtx-api-item-padding-y - 2px) calc(@mtx-api-item-padding-x - 2px);

      .mtx-dark-theme & {
        background-color: @overlay-color-lighten;
      }
    }
  }

  &.no-background {
    .wrapper {
      background-color: transparent;
    }
  }

  &.parent {
    .icon {
      font-size: 2em;
    }

    .text {
      padding: 0 @icon-width;
      padding-inline-start: unit(@icon-width + @global-margin-large, rem); // Prevents text flowing around the icon
    }

    .title {
      margin: 0 0 @global-margin-small 0;
      line-height: 1.25;
    }
  }

  &.child {
    .text {
      padding: 0 @icon-width;
      padding-inline-start: unit(@icon-width + @global-margin-large, rem);
    }
  }

  &.inactive {
    svg,
    .grip-icon,
    .content * {
      color: lighten(@global-muted-color, 10%) !important;
    }

    &.bordered .wrapper {
      border-color: lighten(@global-muted-color, 10%) !important;
    }
  }

  .mark {
    padding: 0 @global-margin-medium;
    color: @global-muted-color;
    line-height: 2;
    font-size: .6875rem;
    text-transform: uppercase;
  }

  .title {
    margin: 0;
    line-height: @global-line-height;
    color: inherit;
    font-size: @global-font-size;
  }

  .description {
    margin: 0;
    font-size: @global-font-size-small;
    line-height: 1.25;
    color: @global-text-color;

    .mtx-dark-theme & {
      color: @_grey_light_color;
    }
  }

  .content {
    display: block;
    color: inherit;
    margin: -@global-margin-medium;
    padding: @global-margin-medium;

    & > .md-icon,
    & > .custom-icon {
      color: lighten(@global-text-color, 12%);

      .mtx-dark-theme & {
        opacity: .85;
        color: inherit;
      }
    }
  }

  a.content {
    text-decoration: none;

    .title {
      text-decoration: @base-link-text-decoration;
    }

    &:hover,
    &:focus {
      .title {
        text-decoration: @base-link-hover-text-decoration;
      }
    }
  }

  .content,
  .grip-icon,
  .mtx-actions .mtx-handler {
    & > .md-icon {
      vertical-align: top;
    }
  }

  .mtx-actions + .content {
    padding-inline-end: 0;
    margin-inline-end: @md-icon-size;
  }

  .grip-icon {
    padding-inline: @global-margin;
    margin-inline-start: -@global-margin-medium;
    position: relative;

    .md-icon {
      vertical-align: top;
    }

    // Fixes sortable handler.
    &:before {
      .position-cover();
      content: '';
      z-index: 1;
    }

    & ~ .content {
      padding-inline-start: @md-icon-size + 2 * @global-margin;
    }
  }

  &.uk-sortable-dragged {
    .break-word();
    min-height: unit(@global-margin-medium * 2 + @global-line-height, rem);
    height: auto !important;
  }

  &.no-background.has-offset {
    .wrapper {
      margin-inline-start: -@mtx-api-item-padding-x;
    }
  }

  &:not(.mtx-api-group-item) {
    @media all and (min-width: @breakpoint-medium) {
      width: 50%;
    }
  }

  [dir=rtl] & {
    .icon {
      transform: scale(-1, 1);
      filter: progid:DXImageTransform.Microsoft.BasicImage(mirror=1);
    }
  }
}

// It should be standalone because of styles issues on items sorting.
.mtx-api-group-item {
  &.mtx-api-grouped-items-wrapper {
    background: none;
    border-radius: 0;
    padding: 0;
    margin-inline-start: @mtx-api-grouped-items-wrapper-margin;

    .mtx-api-item {
      margin: @global-margin !important;
    }

    & > .content {
      position: relative;
      border: 1px solid #e5e8e8;
      border-radius: calc(@thumb-border-radius + @global-margin - 1px);
      margin: 0 -1px;
      padding: 0;
      background-color: @content-section-color;

      .mtx-dark-theme & {
        border-color: @overlay-color-light;
        background-color: @overlay-color-darken;
      }

      & > .grip-icon {
        position: absolute;
        inset-inline-end: 100%;
        top: 50%;
        transform: translate(0, -50%);
        inset-inline-start: -@mtx-api-grouped-items-wrapper-margin;
        padding: 0 (@mtx-api-group-item-margin - @md-icon-size);
      }
    }

    &.bordered > .content {
      border: 2px solid currentColor;
      border-radius: calc(@thumb-border-radius + @global-margin - 2px);
      margin: 0;
    }
  }

  [dir=ltr] & {
    margin: 0 @global-margin @global-margin @mtx-api-group-item-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }

  [dir=rtl] & {
    margin: 0 @global-margin @global-margin @mtx-api-group-item-margin;
  }
}

// It should be standalone because of styles issues on items sorting.
.mtx-api-no-grouped-items {
  .mtx-api-group-item {
    margin: 0 0 @global-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
