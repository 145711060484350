.mtx-upcoming-events-section {
  padding-bottom: @mtx-section-padding - @list-item-padding-y;

  .event-item {
    &.mtx-list-item {
      .mtx-date-widget-icon a {
        text-decoration: none;
      }
    }
  }
}
