.mp-button() {
  appearance: none;
  background: transparent;
  border: 0 none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: fade(@mp-text-color, 65%);
  display: inline-block;
  font-family: inherit;
  font-size: @mp-grid;
  line-height: @mp-control-size;
  min-width: @mp-control-size;
  text-align: center;
  transition: all @mp-transition;

  &:hover,
  &.active {
    color: @mp-text-color;
  }

  &:disabled {
    color: fade(@mp-text-color, 25%);
    cursor: default;
  }
}
