.hook-api-item() {
  &.video {
    .thumb {
      display: block;
      border-radius: @thumb-border-radius;
      overflow: hidden;
      padding-top: @video-item-padding;
      position: relative;
      z-index: 1;

      &-image {
        background-color: @api-item-empty-bg;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: 100%;
        left: 0;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: @thumb-border-radius;

        .mtx-dark-theme & {
          background-color: @overlay-color-darken;
        }
      }
    }
  }
}

.hook-api-dialog() {
  &.video-dialog {
    #video-upload-button {
      display: inline-block;
    }

    [class*='qq-file-id'] .api-item.video {
      .thumb {
        background-color: @api-item-empty-bg;

        .mtx-dark-theme & {
          background-color: @overlay-color-darken;
        }
      }

      .progress {
        width: 0;
        height: @videos-progressbar-height;
      }

      .progress-wrapper {
        top: calc(100% - @videos-progressbar-height);
      }
    }
  }
}

// @Todo: Add styles for input
