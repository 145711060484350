//
// Component: Close
//
// ========================================================================

@close-size: @md-icon-size;

// Component
// ========================================================================

.hook-close() {}


// Modifier: `uk-close-alt`
// ========================================================================

.hook-close-alt() {
  padding: 0;

  &:after {
    vertical-align: top;
  }

  .mtx-dark-theme & {
    background-color: fade(@_black_color, 50%);
  }
}


// Miscellaneous
// ========================================================================

.hook-close-misc() {
  .uk-close {
    &:after {
      .material-icon();
      content: '\e5cd'; // md-close
    }

    &:focus-visible {
      opacity: 1;
    }

    &.uk-disabled {
      cursor: default;
    }

    &.no-pointer-events,
    .no-pointer-events & {
      pointer-events: none;
    }
  }
}
