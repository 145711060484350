// @Todo: Move to site settings folder once it merged into master
.mtx-trial-period-form {
  .filter-label {
    line-height: @form-height;
    word-break: break-word;

    [dir=ltr] & {
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      margin-left: @global-margin;
    }
  }

  & + .divider {
    height: @global-margin;
    margin: 0 -@global-margin;
    background-color: @page_content_background_color;
  }

  .widget-fields {
    .form-wrapper + .form-wrapper {
      @media all and (min-width: @breakpoint-small) {
        margin-top: 0;
      }

      @media all and (max-width: @breakpoint-mini-max) {
        margin-top: @global-margin;
      }
    }
  }
}
