/**
 * @file
 * Styles for menus and navigation markup.
 */

ul {
  // Markup generated by theme_menu_tree().
  &.menu {
    border: none;
    list-style: none;
    text-align: left; /* LTR */

    li {
      margin: 0 0 0 0.5em; /* LTR */
    }
  }

  // Markup generated by theme_links().
  &.inline,
  &.links.inline {
    display: inline;
    padding-inline-start: 0;
  }

  &.inline li {
    display: inline;
    list-style-type: none;
    padding: 0 0.5em;
  }

  // Markup generated by theme_menu_local_tasks().
  &.primary {
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 5px;
    padding: 0 0 0 1em; /* LTR */
    white-space: nowrap;

    li {
      display: inline;

      & a {
        height: auto;
        margin-right: 0.5em; /* LTR */
        padding: 0 1em;
        text-decoration: none;
      }

      &.active a {
        background-color: #fff;
        border: 1px solid #bbb;
        border-bottom: 1px solid #fff;
      }

      & a:hover {
        background-color: #eee;
        border-color: #ccc;
        border-bottom-color: #eee;
      }
    }
  }

  &.secondary {
    border-bottom: 1px solid #bbb;
    padding: 0.5em 1em;
    margin: 5px;

    li {
      border-right: 1px solid #ccc; /* LTR */
      display: inline;
      padding: 0 1em;
    }

    a {
      padding: 0;
      text-decoration: none;

      &.active {
        border-bottom: 4px solid #999;
      }
    }
  }

  li {
    &.expanded {
      list-style-image: url(/misc/menu-expanded.png);
      list-style-type: circle;
    }

    &.collapsed {
      list-style-image: url(/misc/menu-collapsed.png); /* LTR */
      list-style-type: disc;
    }

    &.leaf {
      list-style-image: url(/misc/menu-leaf.png);
      list-style-type: square;
    }
  }
}
