/** Spinner */

// Variables
// ========================================================================
@spinner-size: 48px;
@spinner-stroke-width: 2;
@spinner-radius: floor((@spinner-size - @spinner-stroke-width) / 2); // Minus stroke width to prevent overflow clipping
@spinner-circumference: round(2 * 3.141 * @spinner-radius);
@spinner-duration: 1.4s;

.spinner {
  display: inline-block;
  width: @spinner-size;
  height: @spinner-size;
  max-width: 100%;
  max-height: 100%;

  //** SVG */
  svg {
    animation: spinner-rotate @spinner-duration linear infinite;
  }

  //** Circle */
  circle {
    stroke-dasharray: @spinner-circumference;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: spinner-dash @spinner-duration ease-in-out infinite;
    stroke-width: @spinner-stroke-width;
    stroke-linecap: round;
  }
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dashoffset: @spinner-circumference;
  }
  50% {
    stroke-dashoffset: @spinner-circumference / 4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: @spinner-circumference;
    transform: rotate(450deg);
  }
}

.views_infinite_scroll-ajax-loader {
  padding: @global-margin-small 0;
  text-align: center;
}

.ajax-progress {
  text-align: center;

  .uk-modal &,
  &-spinner {
    display: block;
    width: 100%;
    height: 100%;
    background: fade(@_grey_color, .3); // @todo: check if we need overlay bg.
    z-index: 9998;
    .position-cover(fixed);

    .spinner {
      .position-center;
    }
  }

  .uk-dropdown &.ajax-progress-throbber {
    display: none;
  }
}
