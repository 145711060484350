.price-manager {
  @banner-cell-large-width: 5rem;
  @banner-cell-size: 3.25rem;
  @price-field-width: 7.5rem;
  @placeholder-bg-color: @_grey_lighten_color;
  @placeholder-dark-theme-bg-color: @overlay-color-light;

  .views-exposed-widgets {
    &,
    & .form-item {
      margin-bottom: 0;
    }
  }

  .view-empty {
    padding-top: @global-margin;
  }

  .cell {
    padding: @global-margin-small @global-margin 0;

    &.banner {
      width: @banner-cell-large-width;
      min-height: @banner-cell-size;
      padding: @global-margin-small 0 0;
      text-align: center;

      .banner-wrapper {
        display: inline-block;
        min-height: @banner-cell-size; // Safari
        border-radius: @global-border-radius;
        background-color: @placeholder-bg-color;

        .mtx-dark-theme & {
          background-color: @placeholder-dark-theme-bg-color;
        }

        &.course-banner-wrapper {
          width: @banner-cell-large-width;
        }

        &.product-banner-wrapper {
          width: @banner-cell-size;
        }

        img {
          object-fit: cover;
          height: @banner-cell-size;
          width: 100%;
        }
      }

      img {
        display: inline-block;
        border-radius: @global-border-radius;
      }

      .placeholder {
        display: inline-block;
        width: @banner-cell-size;
        line-height: @banner-cell-size;
        color: @_grey_color;
        background-color: @placeholder-bg-color;
        border-radius: @global-border-radius;
        text-align: center;

        .mtx-dark-theme & {
          background-color: @placeholder-dark-theme-bg-color;
        }
      }
    }

    &.info {
      .title {
        font-size: @global-font-size-small;
        font-weight: @global-font-weight-normal;
      }

      .type {
        font-size: @global-font-size-xsmall;
        color: @global-muted-color;
      }
    }

    &.prices {
      width: 4 * @price-field-width;
    }
  }

  .commerce-entity-price {
    width: @price-field-width;
    padding: @global-margin-small @global-margin;

    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .row {
      display: table;
      width: 100%;
    }

    .cell {
      display: table-cell;
      vertical-align: top;

      &.prices {
        width: 4 * @price-field-width;
        padding: @global-margin-small 0;
      }
    }

    .commerce-entity {
      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }
  }

  .cell.prices {
    @media all and (max-width: @breakpoint-xlarge) {
      width: 3 * @price-field-width;
    }

    @media all and (max-width: @breakpoint-large) {
      width: 2 * @price-field-width;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .cell {
      &.prices {
        width: 100%;
      }

      [dir=ltr] & {
        &.banner {
          float: left;
        }

        &.info {
          padding-left: @banner-cell-large-width + @global-margin;
        }

        &.prices {
          padding-left: @banner-cell-large-width;
        }
      }

      [dir=rtl] & {
        &.banner {
          float: right;
        }

        &.info {
          padding-right: @banner-cell-large-width + @global-margin;
        }

        &.prices {
          padding-right: @banner-cell-large-width;
        }
      }
    }
  }

  input.commerce-entity-price-edit {
    background-color: @_grey_lighten_color;
    width: @price-field-width - @global-margin-medium;
    display: inline-block;

    &:focus {
      background-color: @form-focus-background;
    }

    .mtx-dark-theme & {
      background-color: transparent;
    }
  }

  .commerce-entity-currency-title {
    vertical-align: middle;
  }
}
