@course-label-max-width: 7rem;

.course-label {
  display: inline-block;
  min-width: @badge-line-height;
  max-width: @course-label-max-width; // 112px
  padding: @global-margin-small / 2 @global-margin-small;
  line-height: unit(@global-line-height, rem);
  font-size: @global-font-size-small;
  font-weight: @global-font-weight-semibold;
  background-color: fade(@_white_color, 80%);
  color: @global-text-color;
  border-radius: calc(unit(@global-line-height + @global-margin-small, rem) / @button-border-radius-ratio);
  margin-bottom: @global-margin-mini;

  & > * {
    vertical-align: bottom;
  }

  .md-icon {
    font-size: 1.125rem;
    line-height: inherit;
  }
}
