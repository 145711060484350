.mp-slides {
  position: relative;
  padding: .25em;

  .mp-slide-thumb {
    display: block;
    background-color: transparent;

    .image {
      display: block;
      padding-top: 56.25%;
      background: transparent no-repeat center center;
      background-size: contain;
      border-radius: @thumb-border-radius;
    }
  }

  .mp-slide {
    display: block;
    padding: .25em;
    background-color: fade(@mp-text-color, 0%);
    transition: all @mp-transition;
    border-radius: calc(@thumb-border-radius + .25em);

    &.active {
      background-color: fade(@mp-text-color, 15%);
    }
  }

  .owl-next,
  .owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    width: 1.5em;
    text-align: center;
    line-height: 3;
    background-color: @mp-bg-alpha-color;
    color: @mp-text-alpha-color;
    opacity: .5;
    transition: all @mp-transition;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: .25;
    }
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    .owl-next {
      right: 0;
      left: auto;
      border-radius: @global-border-radius 0 0 @global-border-radius;
    }

    .owl-prev {
      left: 0;
      right: auto;
      border-radius: 0 @global-border-radius @global-border-radius 0;
    }
  }

  [dir=rtl] & {
    .owl-next {
      left: 0;
      right: auto;
      border-radius: 0 @global-border-radius @global-border-radius 0;
    }

    .owl-prev {
      left: auto;
      right: 0;
      border-radius: @global-border-radius 0 0 @global-border-radius;
    }
  }
}
