.page-breadcrumbs {
  padding: 5px 0;
  z-index: 1;

  ul {
    float: left;
    margin: 0;

    li {
      font-size: @global-font-size-small;
    }
  }

  .course-code {
    display: block;
    float: right;
    font-size: @global-font-size-small;
  }

  [dir=rtl] & {
    ul {
      float: right;
    }

    .course-code {
      float: left;
    }
  }
}
