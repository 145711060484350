.mtx-site-settings-form {
  @block-margin: 2.5rem;

  .form-wrapper + .form-wrapper {
    margin-top: @block-margin;
  }

  .group .form-wrapper + .form-wrapper {
    margin-top: 0;
  }

  h3 {
    .form-required {
      font-size: unit(@global-font-size-small, rem);
    }
  }

  .group-item {
    & + .group-item {
      margin-top: @global-margin-large;
    }
  }

  table {
    margin-top: 1.875rem;
    .break-word();

    caption {
      font-weight: @global-font-weight-semibold;
      margin-bottom: @global-margin-small;
    }

    th {
      min-width: 4rem;
      vertical-align: top;
      font-weight: @global-font-weight-normal;
    }

    th,
    td:not(:first-child) {
      text-align: center;
    }

    tr {
      background: none !important;
      border: none;
    }

    td:first-child {
      [dir=ltr] & {
        padding-left: 0;
      }

      [dir=ltr] & {
        padding-right: 0;
      }
    }
  }

  @media all and (min-width: @breakpoint-large) {
    .form-type-select {
      width: 50%;
    }

    .uk-grid-width-small-1-2,
    &.uk-width-large-1-2,
    &.mtx-onboarding-rule-form {
      .form-type-select {
        width: 100%;
      }
    }

    &.mtx-onboarding-rule-form {
      .form-item + .form-type-select {
        .form-type-select-max-width(@field-type-checkbox-radio-siblings-margin);
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .block + .block {
      margin-top: @block-margin;
    }
  }
}
