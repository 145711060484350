//**
// Skip link.
#skip-link {
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 0;
  position: absolute;
  width: 14em;
  max-width: 14em;
  z-index: 50;
  text-align: center;

  a,
  a:link,
  a:visited {
    background: #444444;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    display: inline-block;
    line-height: @global-line-height;
    padding: @global-margin-small @global-margin;
    text-decoration: none;
    border-radius: 0 0 @button-border-radius @button-border-radius;
  }

  a:hover,
  a:active,
  a:focus {
    outline: 0;
  }
}
