@edit-icon-size: 1.5em;
@edit-icon-position: -.25em;

.access-restriction-message {
  margin: @global-grid-gutter * 2 0;

  .avatar-wrapper {
    position: relative;
    border: @global-border-radius solid @global-link-color;
    border-radius: @global-border-radius-circle;
    margin: 0 auto @global-margin-large;
    display: inline-block;
    width: 5.5em;

    &:after {
      .material-icon(@edit-icon-size);
      content: '\f097'; // md-edit
      display: block;
      position: absolute;
      bottom: @edit-icon-position;
      line-height: 1.5;
      width: @edit-icon-size;
      height: @edit-icon-size;
      box-sizing: content-box;
      text-align: center;
      background: @global-base-color;
      color: @global-link-color;
      border: 1px solid @global-link-color;
      box-shadow: 0 0 0 1px @global-base-color, 0 0 0 2px @global-link-color;
      border-radius: @global-border-radius-circle;


      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        right: @edit-icon-position;
        left: auto;
      }

      [dir=rtl] & {
        right: auto;
        left: @edit-icon-position;
      }
    }
  }

  h3 {
    font-size: @global-font-size;
  }
}
