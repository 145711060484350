@date-widget-min-width: @global-size-medium;
@date-widget-date-font-size: 2rem;
@date-widget-month-font-size: @global-font-size-xsmall;

.mtx-date-widget {
  min-width: @date-widget-min-width;
  text-align: center;
  overflow: hidden;
  color: inherit;

  &-icon {
    color: inherit;

    a {
      color: inherit !important;
    }
  }

  &-body {
    padding: (@global-margin-mini / 2) 0;
  }

  &-date {
    font-size: @date-widget-date-font-size;
    font-weight: @global-font-weight-bold;
    line-height: 1;
  }

  &-month {
    font-size: @date-widget-month-font-size;
    font-weight: @global-font-weight-semibold;
    line-height: 1.25;
  }
}
