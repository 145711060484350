@widget-input-active-z-index: @global-z-index + 40;

.widget-input {
  position: absolute;
  top: calc(100% - @nei-controls-size - 2 * @nei-controls-padding - @nei-controls-offset);
  left: @nei-controls-offset;
  width: @nei-controls-size;
  overflow: hidden;
  margin: @nei-controls-padding;
  background: @nei-controls-bg;
  border-radius: @global-border-radius-circle;
  transform: translateY(-@nei-controls-offset);
  transition: all .15s linear;
  z-index: @widget-overlay-z-index;
  opacity: 0;
  display: flex;
  align-items: center;

  & > a {
    display: block;
  }

  .widget-input-action {
    width: @nei-controls-size;
    height: @nei-controls-size;
    line-height: @nei-controls-size;
    text-align: center;
    background-color: @_white_color;
    color: @global-text-color;
    border-radius: @global-border-radius-circle;
    transition: background-color .2s linear;
    display: none;
    opacity: 0;

    &:hover,
    &:focus,
    &:active {
      background-color: @_grey_lighten_color;
    }

    .md-icon {
      font-size: @nei-controls-icon-font-size;
      line-height: @nei-controls-size;
      vertical-align: baseline;
    }

    &.widget-input-toggle {
      display: block;
      opacity: 1;
    }
  }

  .widget-input-text-field {
    display: none;
    background: transparent;
    color: inherit;
    font-size: @global-font-size;
    height: 100%;
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    padding: 0 @nei-controls-padding;

    .placeholder({
      color: @_grey_light_color;
    });
  }

  &.open {
    width: auto;
    right: @nei-controls-offset;
    opacity: 1;
    z-index: @widget-input-active-z-index;

    .widget-input-text-field,
    .widget-input-action {
      display: block;
      opacity: 1;
    }
  }

  .nei-widget:hover & {
    opacity: 1;
  }
}

.widget-input-editing-overlay {
  background: fade(@global-base-color, 20%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: @widget-input-active-z-index;
}
