.form-simple {
  &-block {
    margin-top: 2.25rem;

    .uk-width-medium-1-2 > .form-item {
      margin-bottom: @global-margin;
    }

    .mtx-date-field {
      margin-bottom: 0;
    }

    .mtx-timezone-field {
      max-width: 100%;
      margin-bottom: 0;

      & > div {
        margin-top: @global-margin-small;
      }

      .form-item {
        margin-bottom: 0;
      }

      .chosen-container {
        max-width: initial;
      }
    }
  }

  &-features-block {
    .uk-grid {
      margin-top: -@global-margin;
      margin-bottom: -@global-margin;

      .form-item:first-child {
        margin-top: @global-margin;
      }
    }
  }

  .chosen-container {
    // Prevents container to be overflowed with long string.
    max-width: 0;
  }

  input[type='number'],
  input.price-setting {
    width: @field-type-number-width;

    & + .field-suffix {
      vertical-align: middle;
    }
  }
}
