.mtx-team-members-requests-table,
.mtx-team-members-table {
  .views-field {
    &-field-full-name {
      min-width: 15em;
      max-width: 25em;
      word-break: break-word;

      .avatar {
        min-width: @global-size-medium;

        a,
        img {
          .circle(@mtx-users-avatar-size);
        }
      }

      .text > div + div {
        margin-top: @global-margin-mini;
      }
    }

    &-views-bulk-operations {
      padding-left: @global-margin;

      [dir=rtl] & {
        padding-left: 5px; // tables.less
        padding-right: @global-margin;
      }
    }

    &-mail,
    &-rid {
      min-width: 8em;
      max-width: 15em;
    }
  }

  .not-approved {
    .views-field-operations {
      white-space: nowrap; // Prevents buttons jumping
      text-align: right;

      [dir=rtl] & {
        text-align: left;
      }
    }
  }
}

.view-team-members {
  .view-content + .item-list {
    margin-top: @global-margin-medium;
  }
}
