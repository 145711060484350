//
// Component: Panel
//
// ========================================================================

// Variables
// ========================================================================

@panel-title-margin-bottom: @global-margin;
@panel-title-font-size: @base-h3-font-size;
@panel-title-font-weight: @base-heading-font-weight;
@panel-title-line-height: @base-h3-line-height;
@panel-title-color: @global-color;

@panel-box-padding: @global-margin;
@panel-box-color: inherit;
@panel-box-title-color: inherit;
@panel-box-border-radius: @global-border-radius;
@panel-box-background: @mtx-section-even-bg-color;

@panel-hover-padding: @global-margin;
@panel-hover-color: @global-color;
@panel-hover-hover-color: @global-color;

@panel-header-title-border: @global-border;
@panel-header-title-color: @global-color;

@panel-space-padding: 2rem;
@panel-space-border-radius: 2rem;
@panel-space-icon-size: @global-size-small;

@panel-divider-gutter: @global-grid-gutter;
@panel-divider-gutter-large: @global-grid-gutter-large;
@panel-divider-border: @global-border;

// Sub-object: `uk-panel-title`
// ========================================================================

.hook-panel-title() {
}

// Sub-object: `uk-panel-badge`
// ========================================================================

.hook-panel-badge() {}

// Modifier: `uk-panel-box`
// ========================================================================

.hook-panel-box() {
  border-radius: @panel-box-border-radius;

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  // Normalize font sizes.
  .uk-form {
    font-size: @global-font-size;
  }
}

.hook-panel-box-hover() {}

//
// Sub-modifier: `uk-panel-box-primary`
//

.hook-panel-box-primary() {}

.hook-panel-box-primary-hover() {}

//
// Sub-modifier: `uk-panel-box-secondary`
//

.hook-panel-box-secondary() {}

.hook-panel-box-secondary-hover() {}

// Modifier: `uk-panel-hover`
// ========================================================================

.hook-panel-hover() {}

.hook-panel-hover-hover() {}

// Modifier: `uk-panel-header`
// ========================================================================

.hook-panel-header() {}

// Miscellaneous
// ========================================================================

.hook-panel-misc() {
  // @TODO: Rework when HELP will be removed.
  .uk-panel-body {
    padding: 0;
    word-wrap: break-word;
  }

  &.uk-panel-space {
    border-radius: @panel-space-border-radius;
  }

  // Add: `uk-panel-space-icon`
  .uk-panel-space-icon {
    width: @panel-space-icon-size;
    height: @panel-space-icon-size;

    .md-icon {
      font-size: @panel-space-icon-size;
    }
  }
}
