.ui-sortable-handle-custom-icon {
  cursor: move !important;
}

.ui-sortable-handle-custom-icon-offset {
  margin-inline-start: -@md-icon-size;
}

.ui-sortable-placeholder {
  .uk-form.form-simple & {
    margin-bottom: @global-margin-large;
    background: none;
    border: none !important;
  }
}
