.mtx-statistics-rubrics {
  @rubric-size: 8.25rem;
  @rubric-margin: @global-margin-medium;
  @rubric-number-font-size: 2rem;

  margin-top: -@rubric-margin;

  .item {
    margin: @rubric-margin @rubric-margin 0;

    [dir=ltr] & {
      float: left;
      margin-left: 0;
    }

    [dir=rtl] & {
      float: right;
      margin-right: 0;
    }
  }

  .square-rubric {
    width: @rubric-size;
    max-width: @rubric-size;
    padding: @global-margin;
    text-align: center;

    .header,
    .footer {
      .text-ellipsis-box(2, 1.3);
      font-size: @global-font-size-small;
    }

    .header + .value,
    .value + .footer {
      margin-top: .625rem;
    }

    .value {
      font-weight: @global-font-weight-bold;

      * {
        line-height: 1.2;
      }

      .number {
        font-size: @rubric-number-font-size;
      }

      .sign {
        font-size: @rubric-number-font-size * .75;
      }
    }
  }

  & + .mtx-statistics-actions {
    padding-top: @global-margin-large;
  }
}
