.mtx-catalog-banner {
  background-color: @mtx-section-even-bg-color;

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  .mtx-store-front-preview & {
    transition: all .15s ease-in-out;

    &-text {
      transition: all .3s linear;
    }

    &-button[disabled] {
      cursor: default;
    }
  }
}
