@vmp-widget-actions-padding-top: @global-margin-large;

.nei-widget-marketplace_info {
  padding: @global-margin-small @global-margin;
  border: 1px solid @global-border-color;

  .info-list {
    .reset-list;

    li {
      .clearfix;
      border-bottom: 1px solid @global-border-color;
      padding: @global-margin-mini 0;

      &:last-child {
        border-bottom: 0 none;
      }
    }

    .icon {
      float: left;
      padding-right: @global-margin-mini;

      .fa {
        vertical-align: middle;
      }

      [dir=rtl] & {
        float: right;
        padding-left: @global-margin-mini;
        padding-right: 0;
      }
    }

    .info {
      overflow: hidden;
    }

    .label,
    .value {
      display: inline-block;
      vertical-align: middle;
    }

    .label {
      white-space: nowrap;
      min-width: 40%;
    }

    .value {
      color: lighten(@global-text-color, 20%);
    }
  }

  .view-only .info-list li:last-child {
    border-bottom: none;
  }

  .options-description {
    .text-ellipsis();
    text-align: center;
    margin: @global-margin-large 0 @global-margin-medium;
  }

  .price-options,
  .options-list {
    .reset-list;
  }

  .price-options {
    margin-bottom: @global-margin-small;

    &.single {
      margin-top: @global-margin-large;

      .value {
        margin-top: @global-margin;
      }

      .retail-info {
        text-align: center;
      }
    }

    .options-list {
      border-top: 1px solid @_grey_light_color;
      margin-top: @global-margin;
      padding-top: @global-margin;

      li + li {
        margin-top: @global-margin-small;
      }
    }

    .price-option {
      padding: @global-margin @global-margin-large 0;
      background-color: @_grey_lighten_color;
      border-radius: @global-border-radius;

      & + .price-option {
        margin-top: @global-margin;
      }

      .title,
      .value,
      .option {
        .text-ellipsis();
        text-align: center;
      }

      .title {
        margin: @global-margin 0;
        font-weight: @global-font-weight-semibold;
      }

      .value {
        font-size: unit(@global-font-size-xlarge / @nei-container-font-size, em);
        font-weight: @global-font-weight-bold;
      }

      .option,
      .retail-info {
        font-size: unit(@global-font-size-xsmall / @nei-container-font-size, em);
      }

      .option,
      .retail-info .text {
        color: @_grey_dark_color;
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      .marketplace-actions {
        padding: @vmp-widget-actions-padding-top 0;
      }
    }
  }

  .marketplace-actions {
    padding: @vmp-widget-actions-padding-top 15% @global-margin;

    .uk-button {
      .text-ellipsis();
    }

    &:empty {
      display: none;
    }

    @media all and (max-width: @breakpoint-small-max) {
      padding: 1.5 * @global-margin 0 @global-margin;
    }
  }
}
