.mtx-statistics-filters {
  @filter-height: calc(@activity-filter-items-padding-y * 2 + @activity-filter-image-height);

  margin-bottom: @global-margin-large;

  .label {
    .text-ellipsis();
    margin: 0 0 @global-margin-small;
    line-height: 1.375;
    font-weight: @global-font-weight-semibold;
    font-size: @global-font-size;
  }

  .filter {
    margin-top: @global-margin-large;
  }

  .activity-filter {
    select,
    .chosen-single {
      width: @activity-filter-width;
    }

    select {
      height: auto !important;
      padding-top: @activity-filter-items-padding-y;
      padding-bottom: @activity-filter-items-padding-y;
      line-height: @activity-filter-image-height;
    }

    .chosen-container-single .chosen-single {
      height: auto;
      max-width: 100%;
      padding-top: @activity-filter-items-padding-y;
      padding-bottom: @activity-filter-items-padding-y;
      line-height: @activity-filter-image-height;

      div {
        b:before {
          line-height: @filter-height;
        }

        [dir=ltr] & {
          right: @activity-filter-items-padding-x;
        }

        [dir=rtl] & {
          left: @activity-filter-items-padding-x;
        }
      }
    }
  }
}
