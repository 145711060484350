@comment-replies-padding: unit(@list-icon-margin + @list-icon-size, rem);
@course-comment-replies-padding: unit(@list-icon-margin + @list-icon-size-small, rem);

.comments-list {
  li + p {
    margin: 0;
  }

  .comment-replies-list {
    .comment-reply {
      margin-bottom: @global-margin;

      &.element-invisible {
        margin: 0;
        height: 0;
      }
    }

    & > li {
      margin: 0;
      padding: @global-margin 0 0;
      padding-inline-start: @comment-replies-padding;

      .nei-widget-comments & {
        padding-inline-start: @course-comment-replies-padding;
      }
    }
  }
}

.comments-actions {
  margin: @global-margin-small 0 0;
  padding: 0;
  list-style: none;
  background-image: none;
  font-size: @global-font-size-xsmall;
  line-height: @global-line-height;

  & > * {
    display: inline-block;
    margin-inline-end: @global-margin;
  }

  ul {
    .reset-list;
  }

  a {
    display: inline-block;
  }

  & > li a {
    &,
    i.md-icon {
      transition: all .15s ease-in-out;
    }
  }

  .nei-widget-auto-color & {
    & > li {
      & > span.counter,
      & > i.md-icon {
        color: inherit;
        opacity: @widget-contrast-opacity;
      }

      a {
        opacity: @widget-contrast-opacity;

        &,
        i.md-icon {
          color: inherit;
        }

        &:hover,
        &:focus {
          opacity: @widget-contrast-opacity-hover;

          &,
          i.md-icon {
            color: inherit;
          }
        }

        &.mtx-flag-link.uk-active {
          color: inherit;
          opacity: 1;

          & > i.md-icon {
            color: inherit;
          }
        }
      }
    }
  }
}

.mtx-share-social-links {
  &[data-uk-dropdown] .mtx-handler {
    &,
    & > i.md-icon {
      transition: all .15s ease-in-out;
    }
  }

  .uk-dropdown {
    a[disabled] {
      &,
      &:hover,
      &:focus {
        color: @global-muted-color;
        cursor: default;
      }
    }

    i.fa {
      font-size: 1.25rem;
      line-height: 1.25rem;
      width: 1.25rem;
      transition: all .15s ease-in-out;
      margin-inline-end: @global-margin-small;
    }
  }
}
