//
// Component: Grid
//
// ========================================================================


// Variables
// ========================================================================

@grid-gutter-horizontal:                        @global-grid-gutter;
@grid-gutter-vertical:                          @global-margin-medium;

@grid-gutter-large-horizontal:                  @global-grid-gutter-large;
@grid-gutter-large-vertical:                    @global-grid-gutter;

@grid-gutter-small-horizontal:                  @global-margin-medium;
@grid-gutter-small-vertical:                    @global-margin-medium;

@grid-divider-border:                           @global-border;


// Miscellaneous
// ========================================================================

.hook-grid-misc() {

  /* Sub-object: `uk-push-*` and `uk-pull-*`
 ========================================================================== */

  /*
   * Source ordering
   * Works only with `uk-width-large-*`
   */

  /* Tablet and bigger RTL */
  @media (min-width: @breakpoint-medium) {
    .uk-width-medium-1-7 { width: unit(100 / 7, %); }
    .uk-width-medium-1-8 { width: unit(100 / 8, %); }

    [dir=rtl] {
      //** Push **//

      //* Halves *//
      .uk-push-1-2,
      .uk-push-2-4,
      .uk-push-3-6,
      .uk-push-5-10 { left: -50%; }

      //* Thirds *//
      .uk-push-1-3,
      .uk-push-2-6 { left: -33.333%; }
      .uk-push-2-3,
      .uk-push-4-6 { left: -66.666%; }

      //* Quarters *//
      .uk-push-1-4 { left: -25%; }
      .uk-push-3-4 { left: -75%; }

      //* Fifths *//
      .uk-push-1-5,
      .uk-push-2-10 { left: -20%; }
      .uk-push-2-5,
      .uk-push-4-10 { left: -40%; }
      .uk-push-3-5,
      .uk-push-6-10 { left: -60%; }
      .uk-push-4-5,
      .uk-push-8-10 { left: -80%; }

      //* Sixths *//
      .uk-push-1-6 { left: -16.666%; }
      .uk-push-5-6 { left: -83.333%; }

      //* Tenths *//
      .uk-push-1-10 { left: -10%; }
      .uk-push-3-10 { left: -30%; }
      .uk-push-7-10 { left: -70%; }
      .uk-push-9-10 { left: -90%; }

      //** Pull **//

      //* Halves *//
      .uk-pull-1-2,
      .uk-pull-2-4,
      .uk-pull-3-6,
      .uk-pull-5-10 { left: 50%; }

      //* Thirds *//
      .uk-pull-1-3,
      .uk-pull-2-6 { left: 33.333%; }
      .uk-pull-2-3,
      .uk-pull-4-6 { left: 66.666%; }

      //* Quarters *//
      .uk-pull-1-4 { left: 25%; }
      .uk-pull-3-4 { left: 75%; }

      //* Fifths *//
      .uk-pull-1-5,
      .uk-pull-2-10 { left: 20%; }
      .uk-pull-2-5,
      .uk-pull-4-10 { left: 40%; }
      .uk-pull-3-5,
      .uk-pull-6-10 { left: 60%; }
      .uk-pull-4-5,
      .uk-pull-8-10 { left: 80%; }

      //* Sixths *//
      .uk-pull-1-6 { left: 16.666%; }
      .uk-pull-5-6 { left: 83.333%; }

      //* Tenths *//
      .uk-pull-1-10 { left: 10%; }
      .uk-pull-3-10 { left: 30%; }
      .uk-pull-7-10 { left: 70%; }
      .uk-pull-9-10 { left: 90%; }
    }
  }

  //* Tablet portrait and bigger *//
  @media (min-width: @breakpoint-large) {
    .uk-grid-width-large-1-8 > * { width: unit(100 / 8, %); }

    //** Push **//

    //* Halves *//
    .uk-push-large-1-2,
    .uk-push-large-2-4,
    .uk-push-large-3-6,
    .uk-push-large-5-10 { left: 50%; }

    //* Thirds *//
    .uk-push-large-1-3,
    .uk-push-large-2-6 { left: 33.333%; }
    .uk-push-large-2-3,
    .uk-push-large-4-6 { left: 66.666%; }

    //* Quarters *//
    .uk-push-large-1-4 { left: 25%; }
    .uk-push-large-3-4 { left: 75%; }

    //* Fifths *//
    .uk-push-large-1-5,
    .uk-push-large-2-10 { left: 20%; }
    .uk-push-large-2-5,
    .uk-push-large-4-10 { left: 40%; }
    .uk-push-large-3-5,
    .uk-push-large-6-10 { left: 60%; }
    .uk-push-large-4-5,
    .uk-push-large-8-10 { left: 80%; }

    //* Sixths *//
    .uk-push-large-1-6 { left: 16.666%; }
    .uk-push-large-5-6 { left: 83.333%; }

    //* Tenths *//
    .uk-push-large-1-10 { left: 10%; }
    .uk-push-large-3-10 { left: 30%; }
    .uk-push-large-7-10 { left: 70%; }
    .uk-push-large-9-10 { left: 90%; }

    //** Pull **//

    //* Halves *//
    .uk-pull-large-1-2,
    .uk-pull-large-2-4,
    .uk-pull-large-3-6,
    .uk-pull-large-5-10 { left: -50%; }

    //* Thirds *//
    .uk-pull-large-1-3,
    .uk-pull-large-2-6 { left: -33.333%; }
    .uk-pull-large-2-3,
    .uk-pull-large-4-6 { left: -66.666%; }

    //* Quarters *//
    .uk-pull-large-1-4 { left: -25%; }
    .uk-pull-large-3-4 { left: -75%; }

    //* Fifths *//
    .uk-pull-large-1-5,
    .uk-pull-large-2-10 { left: -20%; }
    .uk-pull-large-2-5,
    .uk-pull-large-4-10 { left: -40%; }
    .uk-pull-large-3-5,
    .uk-pull-large-6-10 { left: -60%; }
    .uk-pull-large-4-5,
    .uk-pull-large-8-10 { left: -80%; }

    //* Sixths *//
    .uk-pull-large-1-6 { left: -16.666%; }
    .uk-pull-large-5-6 { left: -83.333%; }

    //* Tenths *//
    .uk-pull-large-1-10 { left: -10%; }
    .uk-pull-large-3-10 { left: -30%; }
    .uk-pull-large-7-10 { left: -70%; }
    .uk-pull-large-9-10 { left: -90%; }

    //** RTL
    [dir=rtl] {
      //** Push **//

      //* Halves *//
      .uk-push-large-1-2,
      .uk-push-large-2-4,
      .uk-push-large-3-6,
      .uk-push-large-5-10 { left: -50%; }

      //* Thirds *//
      .uk-push-large-1-3,
      .uk-push-large-2-6 { left: -33.333%; }
      .uk-push-large-2-3,
      .uk-push-large-4-6 { left: -66.666%; }

      //* Quarters *//
      .uk-push-large-1-4 { left: -25%; }
      .uk-push-large-3-4 { left: -75%; }

      //* Fifths *//
      .uk-push-large-1-5,
      .uk-push-large-2-10 { left: -20%; }
      .uk-push-large-2-5,
      .uk-push-large-4-10 { left: -40%; }
      .uk-push-large-3-5,
      .uk-push-large-6-10 { left: -60%; }
      .uk-push-large-4-5,
      .uk-push-large-8-10 { left: -80%; }

      //* Sixths *//
      .uk-push-large-1-6 { left: -16.666%; }
      .uk-push-large-5-6 { left: -83.333%; }

      //* Tenths *//
      .uk-push-large-1-10 { left: -10%; }
      .uk-push-large-3-10 { left: -30%; }
      .uk-push-large-7-10 { left: -70%; }
      .uk-push-large-9-10 { left: -90%; }

      //** Pull **//

      //* Halves *//
      .uk-pull-large-1-2,
      .uk-pull-large-2-4,
      .uk-pull-large-3-6,
      .uk-pull-large-5-10 { left: 50%; }

      //* Thirds *//
      .uk-pull-large-1-3,
      .uk-pull-large-2-6 { left: 33.333%; }
      .uk-pull-large-2-3,
      .uk-pull-large-4-6 { left: 66.666%; }

      //* Quarters *//
      .uk-pull-large-1-4 { left: 25%; }
      .uk-pull-large-3-4 { left: 75%; }

      //* Fifths *//
      .uk-pull-large-1-5,
      .uk-pull-large-2-10 { left: 20%; }
      .uk-pull-large-2-5,
      .uk-pull-large-4-10 { left: 40%; }
      .uk-pull-large-3-5,
      .uk-pull-large-6-10 { left: 60%; }
      .uk-pull-large-4-5,
      .uk-pull-large-8-10 { left: 80%; }

      //* Sixths *//
      .uk-pull-large-1-6 { left: 16.666%; }
      .uk-pull-large-5-6 { left: 83.333%; }

      //* Tenths *//
      .uk-pull-large-1-10 { left: 10%; }
      .uk-pull-large-3-10 { left: 30%; }
      .uk-pull-large-7-10 { left: 70%; }
      .uk-pull-large-9-10 { left: 90%; }
    }
  }
}
