.mtx-api-toggler-button {
  margin-bottom: @load-more-margin;

  .uk-button {
    .button-toggler();
  }
}

[data-uk-toggle] {
  .md-icon:after {
    content: '\e5cf'; // md-expand_more
  }

  &.uk-active .md-icon:after {
    content: '\e5ce'; // md-expand_less
  }
}
