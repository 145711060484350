.mtx-account-applications {
  @item-padding: @global-margin-large;

  .mtx-section-title {
    margin-bottom: @global-margin-small;
  }

  .list-wrapper {
    & + .list-wrapper {
      margin-top: 2.5rem;
    }
  }

  .item {
    padding: @global-margin-large 0;
  }

  .item + .item {
    border-top: 1px solid @_grey_light_color;

    .mtx-dark-theme & {
      border-color: @overlay-color-light;
    }
  }

  .icon {
    text-align: center;
    .mtx-social-icon();

    [dir=ltr] & {
      margin-right: @global-margin-medium;
    }

    [dir=rtl] & {
      margin-left: @global-margin-medium;
    }
  }

  .details {
    margin: @item-padding 0 0;
  }

  .button {
    a {
      width: 100%;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .details {
      .uk-grid + .uk-grid {
        margin-top: 0;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .item .text {
      p {
        display: none;
      }

      h5 {
        margin: (@global-margin / 2) 0;
      }
    }
  }
}
