.mtx-api-switcher {
  @switcher-switch-size: .75rem;
  @switcher-switch-offset: .125rem;
  @switcher-height: @switcher-switch-size + 2 * @switcher-switch-offset;
  @switcher-width: 2 * @switcher-switch-size;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-inline-end: @global-margin;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  label {
    word-break: break-word;
    cursor: pointer;
    max-width: calc(100% - @switcher-width - @global-margin);
  }

  [role='switch'] {
    position: relative;
    cursor: pointer;
    height: @switcher-height;
    line-height: @switcher-height;
    width: @switcher-width;
    .button-border-radius(@switcher-height);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      transition: .4s;
      .button-border-radius(@switcher-height);
    }

    &:before {
      top: 0;
      width: @switcher-width;
      height: @switcher-height;
      background-color: @_grey_light_color;
      inset-inline-start: 0;
    }

    &:after {
      top: @switcher-switch-offset;
      width: @switcher-switch-size;
      height: @switcher-switch-size;
      background-color: @_white_color;
      inset-inline-start: @switcher-switch-offset;
    }
  }

  input[type='checkbox'] {
    display: none !important;

    &:checked + [role='switch'] {
      &:before {
        background-color: @secondary_color;
      }

      &:after {
        inset-inline-start: @switcher-width - (@switcher-switch-size + @switcher-switch-offset);
      }
    }

    &:disabled + [role='switch'] {
      cursor: default;
    }
  }

  &.disabled {
    pointer-events: none !important;
    cursor: default !important;
  }
}
