@mtx-account-avatar-size: 6rem;

.mtx-account-personal-information {
  .form-type-select {
    width: 100%;
  }

  .form-wrapper + .form-wrapper {
    margin-top: @global-margin;
  }

  // Avatar.
  .mtx-user-avatar-widget();

  .date-date {
    // @Todo: Platform date format should be reflected in field description? Inputmask may be used.
    input[name*='field_user_birthday'] {
      width: @datepicker-date-input-width;
    }
  }

  @media all and (min-width: @breakpoint-large) {
    .group-container {
      margin-left: -@grid-gutter-large-horizontal;
      .clearfix;

      & > * {
        width: 50%;
        padding-left: @grid-gutter-large-horizontal;

        [dir=ltr] & {
          float: left;
        }

        [dir=rtl] & {
          float: right;
        }
      }
    }
  }
}

// Mixins.
.mtx-user-avatar-widget(@avatar-size: @mtx-account-avatar-size) {
  // Avatar.
  .group-avatar {
    .clearfix;
    margin-bottom: @global-margin-large;
  }

  .default-avatar-wrapper {
    width: @avatar-size;
  }

  .mtx-account-avatar-widget {
    overflow: auto;

    .image-preview {
      padding: 0;
    }

    .avatar-actions {
      margin-top: 1.875rem;

      a {
        display: inline-block;
        margin-top: .625rem;
      }
    }

    .image-widget-data {
      display: none;
    }

    [dir=ltr] & {
      .image-preview {
        float: left;
      }

      .avatar-actions {
        margin-left: @avatar-size;

        a {
          margin-left: @global-margin-medium * 2;
        }
      }
    }

    [dir=rtl] & {
      .image-preview {
        float: right;
      }

      .avatar-actions {
        margin-right: @avatar-size;

        a {
          margin-right: @global-margin-medium * 2;
        }
      }
    }
  }

  .image-preview img {
    .circle(@avatar-size);
  }

  .form-type-managed-file {
    margin: 0;
  }
}
