@spectrum-replacer-size: 1.5rem;
@spectrum-replacer-border-radius: @global-border-radius;
@spectrum-replacer-pos-x: @form-padding-x;
@spectrum-input-width: 8.75rem;
@spectrum-input-padding-x: calc(@spectrum-replacer-size + @spectrum-replacer-pos-x + @global-margin-mini);

.mtx-spectrum {
  .form-item {
    margin-bottom: @global-margin-large;
  }

  // Input.
  .form-type-textfield {
    .clearfix;
    position: relative;

    label {
      display: inline-block;

      &,
      & ~ .description {
        width: 54%;
      }
    }
  }

  .color-field {
    display: block !important;
    width: @spectrum-input-width;
    text-transform: uppercase;
    padding-inline-end: @spectrum-input-padding-x !important;
  }

  .sp-replacer {
    width: @spectrum-replacer-size;
    height: @spectrum-replacer-size;
    position: absolute;
    top: @form-padding-y;
    inset-inline-end: @spectrum-replacer-pos-x;
    border: none;
    padding: 0;
    background: none;

    .sp-preview {
      margin: 0;
      width: 100%;
      height: 100%;
      float: none;
      border: none;
      background: none;

      &-inner {
        background: none;
        border: 1px solid @form-border;
        border-radius: calc(unit(@spectrum-replacer-size * 16, px) / @inputs-border-radius-ratio);
      }
    }

    .sp-dd {
      display: none;
    }
  }

  // Dropdown.
  .sp-container {
    .clearfix;
    border: 0 none;
    border-radius: @spectrum-replacer-border-radius;
    padding: @global-margin-small @global-margin-small 0;
    background-color: @dropdown-background;
    box-shadow: @dropdown-box-shadow;
    overflow: visible;
  }

  .sp-picker-container {
    margin: 0 0 @global-margin-small / 2;
    padding: 0;
  }

  // Palette.
  .sp-palette {
    display: none;
  }

  .sp-thumb {
    display: none;
  }

  // Spectrum.
  .sp {
    &-top {
      margin-bottom: 0;
    }

    &-fill {
      padding-top: 85%;
    }

    &-color {
      inset-inline-start: 0;
      inset-inline-end: 20%;

      .sp-sat {
        border-radius: @spectrum-replacer-border-radius;
      }
    }

    &-hue {
      inset-inline-start: 84%;
      inset-inline-end: 0;
    }

    &-color,
    &-hue {
      border: 0 none;
      border-radius: @spectrum-replacer-border-radius;
    }

    &-dragger {
      @size: 8px;

      .square(@size);
      .button-border-radius(@size / 16);
      border: 1px solid @form-border;
      background-color: @_white_color;
    }

    &-slider {
      @height: 6px;

      left: -(@height / 2);
      right: -(@height / 2);
      height: @height;
      border: 1px solid @form-border;
      .button-border-radius(@height / 16);
      opacity: 1;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .form-type-textfield {
      label {
        &,
        & ~ .description {
          width: 45%;
        }
      }
    }
  }
}

.mtx-spectrum-reset {
  @reset-icon-size: @form-height;

 .color-reset {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    line-height: @reset-icon-size;
    width: @reset-icon-size;
    text-align: center;
    opacity: 1;
    transition: all .15s ease-in-out;

    &.uk-hidden {
      opacity: 0;
    }
  }

  .color-field {
    margin-inline-end: @reset-icon-size;
  }

  .sp-replacer {
    inset-inline-end: calc(@spectrum-replacer-pos-x + @form-border-width + @reset-icon-size);
  }
}
