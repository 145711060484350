#hubspot-app-wizard {
  ol + .form-item[class*="access-token"] {
    padding-inline-start: @base-list-padding-left;
  }

  .mtx-site-settings-form {
    .uk-grid-width-medium-1-2 > .form-wrapper + .form-wrapper {
      margin-top: @form-fields-group-margin-bottom;

      @media all and (min-width: @breakpoint-medium) {
        margin-top: 0;
      }
    }
  }
}
