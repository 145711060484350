.mtx-last-visited-course {
  @progress-bar-height: .75rem;

  .banner {
    .position-cover();
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: padding-box;
  }

  h2.title {
    margin-bottom: 0;
    .text-ellipsis-box(2, 1.25);
  }

  .tags {
    @tag-margin: @global-margin-mini;
    @tag-icon-size: 1.25rem;

    padding-top: @global-margin-medium - @tag-margin;
    font-size: @global-font-size-small;
    line-height: @tag-icon-size;
    word-break: break-word;

    // Similar to .course-tags {}
    & > li {
      margin-top: @global-margin-mini;
      padding: @global-margin-small @global-margin;
      background-color: @_grey_lighten_color;
      .button-border-radius(@global-font-size-xsmall * @global-line-height + 2 * @global-margin-small);

      .md-icon {
        min-width: @tag-icon-size;
        position: relative;
        top: 50%;
        margin-top: -(@tag-icon-size / 2);
        font-size: @tag-icon-size;
      }

      .mtx-dark-theme & {
        background-color: @overlay-color-light;
      }

      [dir=ltr] & {
        margin-right: @tag-margin;

        .md-icon {
          margin-right: @global-margin-small;
        }
      }

      [dir=rtl] & {
        margin-left: @tag-margin;

        .md-icon {
          margin-left: @global-margin-small;
        }
      }
    }
  }

  .description {
    margin: @global-margin-large 0 0;
    line-height: 1.4;

    p {
      margin-bottom: 0;
    }
  }

  .progress {
    position: relative;
    margin-top: @global-margin-large;

    .label {
      margin-bottom: @global-margin;
      font-weight: @global-font-weight-semibold;
    }
  }

  .mtx-progress {
    &-bar {
      height: @progress-bar-height;
      border-radius: calc(@progress-bar-height / @progress-border-radius-ratio);
    }

    &-bar-inner[aria-valuenow='100'] {
      border-radius: 0 !important;
    }

    &-value {
      font-weight: @global-font-weight-semibold;
    }

    &.value-inline .mtx-progress-value {
      bottom: 100%;
      top: auto;
    }

    [dir=ltr] & {
      &-bar-inner {
        border-radius: 0 calc(@progress-bar-height / @progress-border-radius-ratio) calc(@progress-bar-height / @progress-border-radius-ratio) 0;
      }

      &-value {
        padding-left: @global-margin-medium;
      }

      &.value-inline .mtx-progress-value {
        left: auto;
        right: 0;
      }
    }

    [dir=rtl] & {
      &-bar-inner {
        border-radius: calc(@progress-bar-height / @progress-border-radius-ratio) 0 0 calc(@progress-bar-height / @progress-border-radius-ratio);
      }

      &-value {
        padding-right: @global-margin-medium;
      }

      &.value-inline .mtx-progress-value {
        right: auto;
        left: 0;
      }
    }
  }

  .buttons {
    padding-top: @global-margin-large;
  }

  @media (min-width: @breakpoint-medium) {
    h2.title {
      font-size: 2.5rem;
    }

    .progress {
      margin-top: @global-margin-medium * 2;
    }

    .buttons {
      padding-top: @global-margin-medium * 2;
    }
  }

  @media (min-width: @breakpoint-xlarge) {
    h2.title {
      font-size: 3rem;
    }

    .buttons {
      padding-top: 2.5rem;
    }
  }

  @media (max-width: @breakpoint-large-max) {
    .tags,
    .description {
      display: none;
    }
  }

  @media (max-width: @breakpoint-mini-max) {
    h2.title {
      margin-top: @global-margin-medium;
    }

    .content-container {
      order: 1;
    }

    .buttons {
      .uk-button {
        width: 100%;
      }
    }
  }
}

.mtx-last-visited-course-section-banner-type-rounded {
  @banner-ratio: 56.25; // (9 / 16) * 100
  @banner-border-radius: calc(@thumb-border-radius-relative * 15%); // 15% is a maximum value for banner border-radius

  .mtx-last-visited-course {
    .banner-wrapper {
      position: relative;
      padding-top: unit(@banner-ratio, %);
    }

    .banner {
      border-radius: @banner-border-radius ~"/" calc(@banner-border-radius / (@banner-ratio / 100));
      background-color: @api-item-empty-bg;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }
    }

    @media (min-width: @breakpoint-large) {
      .content {
        [dir=ltr] & {
          padding-right: calc(2.5rem - @grid-gutter-horizontal);
        }

        [dir=rtl] & {
          padding-left: calc(2.5rem - @grid-gutter-horizontal);
        }
      }
    }

    @media (min-width: @breakpoint-xlarge) {
      .content {
        [dir=ltr] & {
          padding-right: calc(3rem - @grid-gutter-large-horizontal);
        }

        [dir=rtl] & {
          padding-left: calc(3rem - @grid-gutter-large-horizontal);
        }
      }
    }
  }

  @media (min-width: @breakpoint-medium) {
    &.mtx-section {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  @media (min-width: @breakpoint-xlarge) {
    &.mtx-section {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
  }

  @media (max-width: @breakpoint-mini-max) {
    &.mtx-section {
      padding-top: @global-margin-large;
      padding-bottom: @mtx-section-padding;
    }
  }
}

.mtx-last-visited-course-section-banner-type-gradient {
  .mtx-last-visited-course {
    @min-height: 420px;
    @gradient-z-index: 1;

    position: relative;
    min-height: @min-height;
    background-clip: padding-box;

    .banner {
      &.mtx-video-preview {
        border-radius: unset;
      }
    }

    @media (min-width: @breakpoint-small) {
      min-height: round(@min-height / 1.25);

      .banner-container {
        width: 41%;
        position: absolute;
        top: 0;
        bottom: 0;

        [dir=ltr] & {
          right: 0;
        }

        [dir=rtl] & {
          left: 0;
        }
      }

      .banner {
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          height: 100%;
          width: 37.5%;
          z-index: @gradient-z-index;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }

      .content-container {
        position: relative;
        max-width: 76%;
        padding-top: @global-margin-medium * 3;
        padding-bottom: @global-margin-medium * 4;
        z-index: @gradient-z-index + 1;
      }

      .progress {
        max-width: 22.5rem; // 360px
      }
    }

    @media (min-width: @breakpoint-medium) {
      min-height: round(@min-height / 1.125);

      .content-container {
        max-width: 66%;
      }

      .banner-container {
        width: 51%;
      }
    }

    @media (min-width: @breakpoint-large) {
      min-height: @min-height;
    }

    @media (min-width: @breakpoint-xlarge) {
      .content-container {
        max-width: 50%;
      }

      .banner-container {
        width: 71%;
      }
    }

    @media (max-width: @breakpoint-mini-max) {
      @banner-aspect-ratio: 16 / 9;

      display: flex;
      flex-wrap: wrap;

      .banner-container {
        width: 100%;
      }

      .banner-wrapper {
        margin: 0 -@utility-container-padding-horizontal; // .uk-container {} padding
      }

      &:not(.video-reactive) .banner {
        position: static;
        aspect-ratio: @banner-aspect-ratio;
      }

      &.video-reactive .banner {
        &.mtx-video-preview,
        mtx-video-preview {
          position: static;
          aspect-ratio: @banner-aspect-ratio;
        }
      }
    }
  }

  &.mtx-section {
    padding-top: 0;

    @media (min-width: @breakpoint-small) {
      padding-bottom: 0;
    }
  }

  &.mtx-section:nth-of-type(even) {
    .mtx-last-visited-course {
      background-color: @mtx-section-even-bg-color;

      @media (min-width: @breakpoint-small) {
        .banner:before {
          background: linear-gradient(90deg, @mtx-section-even-bg-color, rgba(255, 255, 255, 0));
        }

        .banner:after {
          background: linear-gradient(270deg, @mtx-section-even-bg-color, rgba(255, 255, 255, 0));
        }
      }
    }
  }
}
