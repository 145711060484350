.mtx-player-video {
  background-color: @mp-bg-color;
  position: relative;
  width: 100%;
  overflow: hidden;

  .mp-video {
    width: 100%;
    height: 100%;
  }

  // Header.
  .mp-header {
    opacity: 0;
    transition: all @mp-transition-delayed;
  }

  .mp-playlist-status {
    display: inline-block;
    font-size: .875em;
    vertical-align: bottom;
    direction: ltr;
    white-space: nowrap;
    word-spacing: -.125em;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      margin: 0 .5em 0 0;
    }

    [dir=rtl] & {
      margin: 0 0 0 .5em;
    }
  }

  .mp-fullscreen {
    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      float: right;
    }

    [dir=rtl] & {
      float: left;
    }

    .fullscreen-exit-icon {
      display: none;
    }
  }

  .mp-play-large,
  .mp-loader {
    display: none;
  }

  .mp-video-overlay {
    .position-cover();
    cursor: pointer;
  }

  .mp-top-shadow {
    margin-top: -@mp-shadow-size;
  }

  .mp-bottom-shadow {
    margin-bottom: -@mp-shadow-size;
  }

  .mp-controls {
    color: @mp-text-color;
    width: 100%;
    opacity: 0.5;
    transition: all @mp-transition-delayed;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @mp-grid / 4 @mp-grid;
    .position-bottom(-4 * @mp-grid);

    svg {
      [fill=white] {
        fill: currentColor;
      }

      [stroke=white] {
        stroke: currentColor;
      }
    }
  }

  .controls-group {
    display: flex;
    align-items: center;
    gap: @mp-grid;
  }

  .mp-current-slide {
    position: absolute;
    bottom: @mp-grid;
    width: 13%;
    opacity: .5;
    background-color: @mp-bg-alpha-color;
    transition: all @mp-transition-delayed;
    z-index: 1;
    border-radius: @thumb-border-radius;
    overflow: hidden;

    .image {
      padding-top: 75%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .mp-next-slide,
    .mp-prev-slide {
      display: none;
      position: absolute;
      top: 50%;
      margin-top: -1.5em;
      width: 1.5em;
      text-align: center;
      line-height: 3;
      background-color: @mp-bg-alpha-color;
      color: @mp-text-alpha-color;
      opacity: .5;
      transition: all @mp-transition;

      &:hover {
        opacity: 1;
      }
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      right: 1%;
      left: auto;

      .mp-next-slide {
        right: 0;
        left: auto;
        border-radius: @global-border-radius 0 0 @global-border-radius;
      }

      .mp-prev-slide {
        left: 0;
        right: auto;
        border-radius: 0 @global-border-radius @global-border-radius 0;
      }
    }

    [dir=rtl] & {
      right: auto;
      left: 1%;

      .mp-next-slide {
        left: 0;
        right: auto;
        border-radius: 0 @global-border-radius @global-border-radius 0;
      }

      .mp-prev-slide {
        left: auto;
        right: 0;
        border-radius: @global-border-radius 0 0 @global-border-radius;
      }
    }

    &:hover {
      width: 48%;
      opacity: 1;
      transition: all @mp-transition-delayed, opacity @mp-transition, width @mp-transition;

      .mp-next-slide,
      .mp-prev-slide {
        display: block;
      }
    }
  }

  .mp-subtitle {
    position: absolute;
    left: 10%;
    bottom: @mp-grid;
    font-size: 1rem;
    width: 80%;
    transition: all @mp-transition-delayed;
    text-align: center;

    p {
      display: inline-block;
      font-size: 1em;
      padding: @global-margin-mini @global-margin;
      line-height: 1.25;
      border-radius: @global-border-radius;
      background-color: @mp-bg-alpha-color;
      margin: 0;
      white-space: nowrap;

      & ~ p {
        margin-top: .25em;
      }
    }

    &.subtitle-fixed-font-size p {
      white-space: normal;
    }

    br:last-child {
      display: none;
    }
  }

  .mp-watermark {
    position: absolute;
    bottom: @mp-grid;
    max-width: 13%;
    opacity: .5;
    transition: all @mp-transition-delayed;
    z-index: 1;

    &:hover,
    &:focus {
      transition: opacity .1s ease-in-out;
      opacity: 1;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      left: 1%;
      right: auto;
    }

    [dir=rtl] & {
      left: auto;
      right: 1%;
    }

    a {
      display: block;
    }

    img {
      max-width: 100%;
      max-height: 3em;
    }
  }

  .mp-play {
    .play-icon {
      display: none;
    }
  }

  .mp-volume {
    display: flex;
    align-items: center;
    gap: @mp-grid / 4;

    .mp-mute {
      width: auto;
    }

    .volume-slider {
      display: inline-block;
      cursor: pointer;
      width: 0;
      transition: width @mp-transition-delayed;

      .volume-bar {
        background-color: fade(@mp-text-color, 25%);
        height: @mp-grid / 4;
      }

      .value {
        height: 100%;
        background-color: @mp-accent-color;
      }

      &:focus {
        transition: width @mp-transition;
        width: 2 * @mp-controls-size;
      }
    }

    &:hover {
      .volume-slider {
        transition: width @mp-transition;
        width: 2 * @mp-controls-size;

        .uk-touch & {
          display: none;
        }
      }
    }
  }

  .mp-mute {
    .volume-off-icon {
      display: none;
    }
  }

  .mp-bit-rate,
  .mp-speed,
  .mp-subtitles {
    display: inline-block;
    position: relative;
  }

  .speed-btn {
    font-size: .75rem;
    font-weight: @global-font-weight-semibold;
    line-height: @mp-control-size;
    text-transform: uppercase;
  }

  &.swapped {
    .mp-video {
      position: absolute;
      bottom: @mp-grid;
      width: 13%;
      height: auto;
      opacity: .5;
      background-color: @mp-bg-alpha-color;
      transition: all @mp-transition-delayed, width 0s linear;
      z-index: 1;

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        right: 1%;
        left: auto;
      }

      [dir=rtl] & {
        right: auto;
        left: 1%;
      }

      .mp-play-large,
      .mp-loader {
        font-size: 1em;
      }

      &:hover {
        width: 48%;
        opacity: 1;
        transition: all @mp-transition-delayed, width @mp-transition;

        .mp-play-large,
        .mp-loader {
          font-size: 4em;
        }
      }
    }

    .mp-current-slide {
      position: relative;
      top: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      width: 100%;
      padding-top: 56.25%;
      opacity: 1;
      transition: none;
      z-index: 0;

      .slide-holder {
        .position-cover();
        width: 100%;
        height: 100%;
      }

      .image {
        padding-top: 56.25%;
      }

      &:hover {
        transition: none;
      }

      .mp-next-slide,
      .mp-prev-slide {
        display: block;
        z-index: 2;
      }
    }
  }

  &.paused,
  &.hover,
  &:focus-within {
    .mp-header,
    .mp-controls,
    .mp-top-shadow,
    .mp-bottom-shadow,
    .mp-subtitle,
    .mp-current-slide,
    .mp-watermark {
      transition: all @mp-transition;
    }

    .mp-header {
      opacity: 1;
    }

    .mp-top-shadow {
      margin-top: 0;
    }

    .mp-bottom-shadow {
      margin-bottom: 0;
    }

    .mp-controls {
      opacity: 1;
      bottom: @mp-grid / 2;
    }

    .mp-current-slide,
    .mp-watermark {
      bottom: @mp-controls-size + 1.75 * @mp-grid;
    }

    &.swapped {
      .mp-current-slide {
        bottom: 0;
        transition: none;
      }

      .mp-video {
        bottom: @mp-controls-size + 1.75 * @mp-grid;
        transition: all @mp-transition;
      }
    }

    .mp-subtitle {
      bottom: @mp-shadow-size - .5 * @mp-grid;
    }
  }

  &.paused {
    .mp-play {
      .play-icon {
        display: inline-block;
      }

      .pause-icon {
        display: none;
      }
    }

    .mp-play-large {
      display: block;
    }
  }

  &.muted {
    .mp-mute {
      .volume-up-icon {
        display: none;
      }

      .volume-off-icon {
        display: inline-block;
      }
    }
  }

  &.loading,
  &.seeking {
    .mp-subtitle,
    .mp-video-overlay {
      display: none;
    }

    .mp-loader {
      display: block;
    }
  }

  &.fullscreen {
    height: 100%;

    mtx-player,
    video {
      height: 100%;
    }

    mtx-player {
      padding-top: 0;
    }

    &.fake {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 99999999;
    }

    .mp-fullscreen {
      .fullscreen-exit-icon {
        display: inline-block;
      }

      .fullscreen-icon {
        display: none;
      }
    }
  }

  // Preview mode.
  .mtx-player-preview & {
    .mp-subtitles,
    .mp-swap-button,
    .mp-current-slide,
    .mp-subtitle {
      display: none !important;
    }
  }

  // Course review.
  .mp-place_pin {
    display: none;

    .page-node-review & {
      display: inline-block;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      margin: 0 .5em 0 0;
    }

    [dir=rtl] & {
      margin: 0 0 0 .5em;
    }
  }

  .mp-pin_info {
    display: inline-block;
    font-weight: @global-font-weight-normal;
    background-color: @mp-bg-alpha-color;
    color: @mp-text-color;
    border-radius: @global-border-radius-circle;
    height: @mp-controls-size;
    padding: 0 @mp-controls-size / 4;

    .md-icon {
      font-size: @md-icon-size-small;
    }
  }
}
