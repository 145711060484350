.courses-list-item {
  @nei-course-thumbnail-width: 10rem;

  & + & {
    margin-top: @global-margin-large;
  }

  .course-thumbnail {
    width: @nei-course-thumbnail-width;
    min-height: @nei-course-thumbnail-width * 9 / 16;
    background-color: @api-item-empty-bg;

    .mtx-dark-theme & {
      background-color: @overlay-color-darken;
    }

    &, img {
      border-radius: @global-border-radius;
    }
  }

  .course-title {
    font-size: @global-font-size;
    margin: 0 0 @global-margin-mini;
  }

  .course-description {
    font-size: @global-font-size-small;
    margin: 0;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    .course-thumbnail {
      float: left;
    }

    .course-info {
      padding-left: @nei-course-thumbnail-width + @global-margin-large;
    }
  }

  [dir=rtl] & {
    .course-thumbnail {
      float: right;
    }

    .course-info {
      padding-right: @nei-course-thumbnail-width + @global-margin-large;
    }
  }
}
