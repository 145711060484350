.coupon-page {
  .coupon-details {
    [dir=ltr] & {
      & > * {
        margin-right: @global-margin-medium * 2;
      }

      .md-icon {
        margin-right: @global-margin-mini;
      }
    }

    [dir=rtl] & {
      & > * {
        margin-left: @global-margin-medium * 2;
      }

      .md-icon {
        margin-left: @global-margin-mini;
      }
    }
  }
}

.coupon-codes-table {
  width: auto;

  &-title {
    margin-bottom: calc(@global-margin-medium * 2 - @table-cell-padding);
  }

  .coupon-code-cell {
    & > div {
      white-space: nowrap;
    }
  }

  .copy-coupon-btn {
    .md-icon {
      font-size: @table-font-size * @global-line-height;
    }
  }

  .coupon-used-by-cell {
    min-width: 10em;
    max-width: 15em;
  }

  .coupon-used-in-cell {
    min-width: 10em;
  }

  .coupon-date-cell {
    min-width: 15em;
  }
}
