/**
 * @file
 * Generic theme-independent base styles.
 */

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete {
  position: absolute;
  width: 100% !important;
  max-height: 22rem;
  font-size: @dropdown-font-size;
  padding: @dropdown-padding 0;
  border-radius: 0 0 @dropdown-border-radius @dropdown-border-radius;
  background-color: @dropdown-background;
  color: @dropdown-color;
  box-shadow: @dropdown-box-shadow;
  overflow-y: auto;
  z-index: 100;

  ul {
    .reset-list();
    width: 100%;
  }

  li {
    width: 100%;
  }

  div {
    cursor: pointer;
    padding: @global-margin @global-margin-medium;
    line-height: 1.3;
    .text-ellipsis();
  }

  &:not(:empty) {
    & + input[type=text] {
      border-radius: @global-border-radius @global-border-radius 0 0;
    }
  }

  [dir=rtl] & {
    left: auto !important; // Override styles added by JS.
    right: 0;
  }
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
fieldset {
  html.js &.collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;

    .fieldset-wrapper {
      display: none;
    }
  }

  &.collapsible {
    position: relative;

    .fieldset-legend {
      display: block;
    }
  }
}

/**
 * Resizable textareas.
 *
 * @see textarea.js
 */
.form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
body.drag {
  cursor: move;
}

a.tabledrag-handle {
  .draggable & {
    cursor: move;
    float: left; /* LTR */
    height: 1.7em;
    margin-left: -1em; /* LTR */
    overflow: hidden;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  .handle {
    background: url(/misc/draggable.png) no-repeat 6px 9px;
    height: 13px;
    margin: -0.4em 0.5em; /* LTR */
    padding: 0.42em 0.5em; /* LTR */
    width: 13px;
  }

  &-hover .handle {
    background-position: 6px -11px;
  }
}

div {
  &.indentation {
    float: left; /* LTR */
    height: 1.7em;
    margin: -0.4em 0.2em -0.4em -0.4em; /* LTR */
    padding: 0.42em 0 0.42em 0.6em; /* LTR */
    width: 20px;
  }

  &.tree-child {
    background: url(/misc/tree.png) no-repeat 11px center; /* LTR */
  }

  &.tree-child-last {
    background: url(/misc/tree-bottom.png) no-repeat 11px center; /* LTR */
  }

  &.tree-child-horizontal {
    background: url(/misc/tree.png) no-repeat -11px center;
  }
}

.tabledrag-toggle-weight-wrapper {
  text-align: right; /* LTR */
}

/**
 * TableHeader behavior.
 *
 * @see tableheader.js
 */
table.sticky-header {
  background-color: #ffffff;
  margin-top: 0;
}

/**
 * Progress behavior.
 *
 * @see progress.js
 */
/* Bar */
#updateprogress {
  .clearfix();
  border-radius: @global-border-radius;
  padding: @content-section-padding * 2;
  background-color: @content-section-color;
  font-size: @global-font-size-small;


  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }

  .bar {
    border-radius: @global-border-radius;
    background-color: @page_content_background_color;
  }

  .filled {
    height: 1.5em;
    width: 5px;
    border-radius: @global-border-radius;
  }

  .message {
    margin-top: @global-margin;
    word-break: break-word;
    word-wrap: break-word;

    br {
      display: none;
    }
  }

  .percentage {
    margin-top: @global-margin;
  }

  [dir=ltr] & {
    .filled:not([style*="100%"]) {
      border-radius: @global-border-radius 0 0 @global-border-radius;
    }

    .percentage {
      float: right;
      padding-left: 2.5rem;
    }
  }

  [dir=rtl] & {
    .filled:not([style*="100%"]) {
      border-radius: 0 @global-border-radius @global-border-radius 0;
    }

    .percentage {
      float: left;
      padding-right: 2.5rem;
    }
  }
}

.ajax-progress-bar {
  width: 16em;
}

/**
 * Inline items.
 */
.container-inline {
  div,
  label {
    display: inline;
  }

  /* Fieldset contents always need to be rendered as block. */

  .fieldset-wrapper {
    display: block;
  }
}

/**
 * Prevent text wrapping.
 */
.nowrap {
  white-space: nowrap;
}

/**
 * For anything you want to hide on page load when JS is enabled, so
 * that you can use the JS to control visibility and avoid flicker.
 */
html.js .js-hide {
  display: none;
}

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be a collapsible fieldset that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
.element-hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  display: none;
  height: 1px;
  overflow: hidden;
  position: absolute !important;

  /**
   * The .element-focusable class extends the .element-invisible class to allow
   * the element to be focusable when navigated to via the keyboard.
   */
  &.element-focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      overflow: visible;
      position: static !important;
    }
  }
}

//* Micro ClearFix Mixin */
.clearfix {
  &:before,
  &:after {
    content: '';
  }

  &:before {
    display: table-cell;
  }

  &:after {
    display: table;
    clear: both;
  }
}
