.mtx-leaderboard {
  .mtx-list-item {
    padding: @global-margin-large @global-margin-large-2;
    border-radius: @global-border-radius-xlarge;
    background-color: @_white_color;

    & + .mtx-list-item {
      margin-top: @global-margin-medium;
    }

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .users-list {
    .mtx-progress-bar {
      height: @global-size-mini;
      border-radius: calc(@global-size-mini / @progress-border-radius-ratio);

      &-inner {
        background-color: #00ff00;
      }

      .mtx-dark-theme & {
        background-color: @overlay-color-light;
      }
    }
  }
}

.mtx-leaderboard-badge {
  .circle(var(--leaderboard-badge-size));
  min-width: var(--leaderboard-badge-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--leaderboard-badge-background-color);
  color: var(--leaderboard-badge-color);
  border: 1px solid currentColor;

  &.first-place {
    --leaderboard-badge-size: @global-size-small;
    --leaderboard-badge-color: #c59c07;
    --leaderboard-badge-background-color: #f7d33c;
  }

  &.second-place {
    --leaderboard-badge-size: 1.75rem;
    --leaderboard-badge-color: #708194;
    --leaderboard-badge-background-color: #c7d8eb;
  }

  &.third-place {
    --leaderboard-badge-size: @global-size-mini;
    --leaderboard-badge-color: #884f21;
    --leaderboard-badge-background-color: #ffac67;
  }
}
