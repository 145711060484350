.mtx-page-top-bar {
  padding: @global-margin 0;
  line-height: 1.42857143; // 20/14
  .break-word();

  & + & {
    margin-top: 1px;
  }

  .text,
  .uk-close {
    font-size: @global-font-size-small;
  }

  .text {
    margin-bottom: 0;

    & > a {
      text-decoration: underline;
    }
  }

  .uk-close {
    @size: 2rem;
    @margin: calc((@close-size - @size - @global-margin-mini) / 2);

    width: @size;
    height: @size;
    .button-border-radius(@size);

    &:after {
      width: @size;
      line-height: @size;
    }

    &:focus-visible {
      opacity: 1;
      .focus-state-box-shadow(@inverted: true);
    }

    [dir=ltr] & {
      margin: @margin @margin 0 auto;
    }

    [dir=rtl] & {
      margin: @margin auto 0 @margin;
    }
  }
}
