@service-page-width: 30rem; // 480px
@service-page-large-width: 50rem; // 800px
@service-page-content-padding: @global-margin-medium * 2;

body {
  &.not-logged-in,
  &.maintenance-page {
    background-color: transparent;
  }
}

.service-page {
  .page-content {
    padding: 4% @global-margin-medium;
  }

  .page-content-layout {
    background-color: @_white_color;
    max-width: @service-page-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: @global-border-radius-xlarge;
    padding: @service-page-content-padding;
  }

  &.service-page-large {
    .page-content-layout {
      max-width: @service-page-large-width;
    }
  }

  &.mtx-dark-theme,
  .mtx-dark-theme &.service-page-openid {
    .page-content-layout {
      background-color: @overlay-color-darken;
    }
  }

  .page-title {
    font-size: @base-h2-font-size;
    margin-bottom: @global-margin-medium * 2;
  }

  // Form.
  .form-block {
    padding-bottom: 0;

    & + .form-block {
      margin-top: @form-block-item-margin;
    }
  }

  .external-login {
    &-block {
      & > div + div {
        margin-top: @global-margin-large;
      }
    }
  }

  .social-login-block {
    .uk-button {
      color: inherit;

      & + .uk-button {
        margin: @global-margin 0 0;
      }

      .fa {
        font-size: @md-icon-size;
        line-height: @md-icon-size;
        width: @md-icon-size;
        height: @md-icon-size;
        text-align: center;
        vertical-align: text-bottom;

        [dir=ltr] & {
          margin-right: @global-margin;
        }

        [dir=rtl] & {
          margin-left: @global-margin;
        }
      }

      &.facebook .fa {
        color: #4c6e9b;
      }

      &.twitter .fa {
        color: #44c1eb;
      }

      &.linkedin .fa {
        color: #187cb3;
      }

      &.msgraph .fa:before {
        content: '';
        .square(@md-icon-size);
        display: inline-block;
        background: @windows-active-icon center center no-repeat;
        background-size: @md-icon-size * .8;
      }
    }

    & + .regular-login-block {
      margin-top: @global-margin-xlarge;
    }
  }

  .other-login-block {
    & + .regular-login-block {
      margin-top: @global-margin-xlarge;
    }
  }

  .password-message {
    word-break: break-word;
  }

  .fields-one-column {
    max-width: @service-page-width - @service-page-content-padding * 2;
  }

  // Maintenance page, OpenID pages.
  &.service-page-openid {
    .status-number {
      font-size: 4.5rem;
    }
  }

  &.maintenance-page {
    word-break: break-word;
  }

  &.maintenance-page,
  &.service-page-openid {
    .page-logo {
      margin: 5vh 0 @global-margin-medium;
      padding: @global-margin-medium;
      text-align: center;

      img {
        max-height: 3.125rem;
      }
    }

    .page-content {
      padding: 1rem;
    }

    .page-content-layout {
      margin: 0 auto;
    }
  }
}

.page-user-login {
  .load {
    .loading(48px);
  }
}

// Login form settings
.mtx-user-forms-settings {
  &-primary {
    .status-button {
      display: none !important;
    }
  }
}

.mtx-user-forms-settings-preview {
  & > h2 {
    max-width: @service-page-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media all and (max-width: @breakpoint-small-max) {
    margin-top: @global-margin-xlarge;

    & > h2,
    .page-content-layout {
      max-width: none;
    }
  }
}
