.mtx-knowledge-base-article-faq-section {
  .uk-accordion li {
    border-bottom: @form-border-width solid @form-border;

    &:last-child {
      border-bottom: 0 none;

      .uk-accordion-content {
        padding-bottom: 0;
      }
    }
  }

  .uk-accordion-title {
    background-color: transparent;
    padding: 1.25rem 0;
  }

  .uk-accordion-content {
    padding: @global-margin-medium 0 2.5rem;
  }

  @media all and (min-width: @breakpoint-medium) {
    .uk-accordion {
      width: 75%;
    }
  }

  @media all and (min-width: @breakpoint-xlarge) {
    .uk-accordion {
      width: 60%;
    }
  }
}
