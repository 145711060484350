.mtx-api-form-select {
  .chosen-choices li.search-field {
    display: inline-block;
    width: auto;
  }

  .chosen-results li.group-result {
    border-bottom: 1px solid @_grey_lighten_color;
    padding: 0;
    margin: @global-margin-small 0;

    &:first-child {
      display: none;
    }
  }
}
