.hook-api-item() {
  // Proper support of dark theme for all api items
  color: inherit !important;
  text-decoration: none;

  .thumb {
    .mtx-dark-theme & {
      background-color: @overlay-color-darken;
    }
  }
}
