.text-widget-content {
  outline: none;
  background-color: transparent;
  word-wrap: break-word;
  white-space: pre-wrap;

  p:only-child {
    min-height: 1.5em;
    margin-bottom: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  .text-widget-unset-headings-font-weight & {
    h1, h2, h3, h4, h5, h6 {
      font-weight: unset;
    }
  }
}
