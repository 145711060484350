@mtx-section-padding: @global-margin-medium * 2;
@mtx-section-even-bg-color: @_white_color;
@mtx-section-even-bg-color-dark: fade(@_black_color, 40%);
@mtx-section-title-margin-bottom: @global-margin-large;

.mtx-section {
  padding-top: @mtx-section-padding;
  padding-bottom: @mtx-section-padding;
  background-color: transparent;
  word-wrap: break-word;

  div > &:nth-of-type(even) {
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  &.uk-hidden {
    .mtx-section-odd-change-following-sections-bg();
    .mtx-section-even-change-following-sections-bg();
  }

  &.no-padding {
    padding: 0;

    &-top {
      padding-top: 0;
    }

    &-bottom {
      padding-bottom: 0;
    }
  }

  &.no-background {
    background-color: transparent !important;
  }
}

.mtx-section-form-section-errors {
  border-top: 2px solid @global-danger-background;
  border-bottom: 2px solid @global-danger-background;
}

.mtx-section-title {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 @mtx-section-title-margin-bottom;
  overflow: hidden;

  h3& {
    font-size: 1.25rem;
    margin-bottom: @global-margin;
  }

  & > span {
    display: inline-block;

    [dir=ltr] & {
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      margin-left: @global-margin;
    }
  }
}

.mtx-section-subtitle {
  display: inline-block;
  font-size: 1rem;
  line-height: inherit;
  font-weight: @global-font-weight-normal;

  .md-icon {
    margin-top: (1rem - @md-icon-size) / 2;
  }
}

.mtx-dashboard-section-filter-form {
  padding: @focus-state-box-shadow-size;
  margin: -@focus-state-box-shadow-size;
  overflow-y: auto;

  & > div {
    display: flex;
    align-items: start;
    justify-content: flex-end;
  }

  .mtx-api-explore-trainings-anchor {
    white-space: nowrap;
  }

  .mtx-api-filter {
    margin: 0 !important;
  }

  .form-item-search {
    margin: 0 @global-margin-mini;
  }

  @media all and (max-width: @breakpoint-medium-max) {
    margin-bottom: @global-margin-small;

    & > div {
      flex-wrap: wrap;

      .mtx-api-explore-trainings-anchor {
        margin-bottom: @global-margin;
        width: 100%;
        .text-ellipsis();
      }
    }
  }
}

// Mixins.
.mtx-section-odd-change-following-sections-bg() {
  &:nth-of-type(odd) {
    & ~ .mtx-section:nth-of-type(odd) {
      background-color: @mtx-section-even-bg-color;

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark;
      }
    }

    & ~ .mtx-section:nth-of-type(even) {
      background-color: transparent;

      .mtx-dark-theme & {
        background-color: transparent;
      }
    }
  }
}

.mtx-section-even-change-following-sections-bg() {
  &:nth-of-type(even) {
    & ~ .mtx-section:nth-of-type(even) {
      background-color: transparent;

      .mtx-dark-theme & {
        background-color: transparent;
      }
    }

    & ~ .mtx-section:nth-of-type(odd) {
      background-color: @mtx-section-even-bg-color;

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark;
      }
    }
  }
}
