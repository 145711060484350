.api-tabs {
  margin: 0 auto @global-margin;

  ul {
    .reset-list;
    white-space: nowrap;
    overflow: auto;
    font-size: @global-font-size-small;
    border-bottom: 1px solid @_grey_light_color;

    .mtx-dark-theme & {
      border-bottom-color: @_grey_dark_color;
    }
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    position: relative;
    padding: @global-margin 1.375rem;

    &,
    &:hover,
    &:focus {
      color: inherit;
    }

    &.active:after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 2px solid @secondary_color;
    }

    .folders-dialog & {
      &[data-type='archive'],
      &[data-type='gdrive'],
      &[data-type='onedrive'],
      &[data-type='dropbox'] {
        height: 3rem;
        width: 4.5rem;
        line-height: 3rem;
        text-align: center;
        font-size: 0;
        background-position: center center;
        background-size: auto 2rem;
        background-repeat: no-repeat;
      }

      &[data-type='archive'] {
        background-image: url('@{global-images-path}/tabs/eurekos.svg');
      }

      &[data-type='gdrive'] {
        background-image: url('@{global-images-path}/tabs/gdrive.svg');
      }

      &[data-type='onedrive'] {
        background-image: url('@{global-images-path}/tabs/onedrive.svg');
      }

      &[data-type='dropbox'] {
        background-image: url('@{global-images-path}/tabs/dropbox.svg');
      }

      &.disabled {
        filter: grayscale(1);
      }
    }
  }
}
