.mtx-suborganizations-list {
  // @Todo: Make it universal, move to list.less
  // See .team-files-list {}
  .icon img {
    background-color: @_grey_lighten_color;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }
  }

  .title {
    &, & > a {
      .text-ellipsis-box(3);
    }
  }
}

.mtx-organization-hierarchical-children,
.mtx-hierarchical-children {
  &.chosen-container {
    &.chosen-with-drop {
      .chosen-drop {
        display: none;
      }
    }
  }

  &.chosen-container-multi {
    .chosen-choices {
      // See .chosen-container-multi .chosen-choices li.search-field input[type="text"] {}.
      min-height: unit(@form-height - (@form-border-width / 16) * 2, rem);
      cursor: pointer;

      li.search-field {
        display: none;
      }
    }

    &.chosen-with-drop {
      .chosen-choices {
        border-bottom-right-radius: @global-border-radius;
        border-bottom-left-radius: @global-border-radius;
      }
    }
  }
}

.multilevel-accordion {
  @item-padding-x: @global-margin-medium;
  @item-padding-y: @global-margin;
  @toggler-padding: @global-margin;
  @toggler-width: @md-icon-size + (@toggler-padding * 2);
  @item-height: calc(@global-font-size * @global-line-height + @item-padding-y * 2);
  @item-border-radius: calc(@item-height / @button-border-radius-ratio);

  &-item {
    display: block;
    padding: @item-padding-y @item-padding-x;
    border-radius: @item-border-radius;
    text-decoration: none;

    .text {
      vertical-align: middle;
    }

    .count {
      @size: 1.5rem;

      .circle(@size);
      display: inline-block;
      margin: 0 @global-margin-small;
      line-height: @size;
      font-size: @global-font-size-small;
      text-align: center;
      transition: opacity .15s ease-in-out;
      vertical-align: middle;

      &:empty {
        opacity: 0;
      }
    }
  }

  &-item-wrapper {
    position: relative;
    margin-bottom: @global-margin-mini;
    word-break: break-word;

    &:hover,
    &:focus-within {
      .multilevel-accordion-item {
        background-color: @_grey_lighten_color;
        color: @global-text-color;
      }

      .mtx-dark-theme & {
        .multilevel-accordion-item {
          background-color: fade(@_black_color, 40%);
          color: @_white_color;
        }
      }
    }

    &.expandable {
      .multilevel-accordion-item {
        [dir=ltr] & {
          padding: @item-padding-y @toggler-width @item-padding-y @item-padding-x;
        }

        [dir=rtl] & {
          padding: @item-padding-y @item-padding-x @item-padding-y @toggler-width;
        }
      }
    }
  }

  &-item-wrapper.selectable {
    &:hover,
    &:focus-within {
      .multilevel-accordion-item-toggler {
        background-color: @_grey_light_color;
        color: @_grey_dark_color;
      }

      .mtx-dark-theme & {
        .multilevel-accordion-item-toggler {
          background-color: fade(@_black_color, 60%);
          color: @_white_color;
        }
      }
    }
  }

  &-item-toggler {
    position: absolute;
    display: block;
    width: @toggler-width;
    padding: @toggler-padding;
    top: 0;
    bottom: 0;
    color: @_grey_dark_color;
    cursor: pointer; // In case item works as toggler itself.

    .md-icon {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    [dir=ltr] & {
      right: 0;
      border-radius: 0 @item-border-radius @item-border-radius 0;
    }

    [dir=rtl] & {
      left: 0;
      border-radius: @item-border-radius 0 0 @item-border-radius;
    }
  }

  &-item-toggler.uk-active,
  .uk-active + &-item-toggler {
    .md-icon:after {
      content: '\e5ce'; // md-expand_less
    }
  }

  &.is-expanded {
    margin-bottom: 1.75rem;

    & > .multilevel-accordion-item-wrapper:not(.selected) {
      .multilevel-accordion-item {
        background-color: @_grey_lighten_color;
        color: @global-text-color;

        .mtx-dark-theme & {
          background-color: fade(@_black_color, 40%);
          color: @_white_color;
        }
      }

      &.selectable .multilevel-accordion-item-toggler {
        background-color: @_grey_light_color;
        color: @_grey_dark_color;

        .mtx-dark-theme & {
          background-color: fade(@_black_color, 60%);
          color: @_white_color;
        }
      }
    }
  }

  &.level-1 > &-item-wrapper,
  &-item-wrapper.level-1 {
    .multilevel-accordion-item .text {
      font-weight: @global-font-weight-semibold;
    }
  }
}
