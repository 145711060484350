@back-link-margin-bottom: @global-margin-medium;

.back-link {
  margin: 0 0 @back-link-margin-bottom;
  line-height: 1.3;
  font-size: @global-font-size-small;
  transition: opacity .2s linear;

  &,
  &.active {
    // Class 'active' is added by l()/mtx_api_l() fns.
    color: @global-text-color;

    &:hover,
    &:focus {
      color: lighten(@global-text-color, 15%); // .hover-color() in colors.less
    }
  }

  &:before {
    .material-icon();
    font-size: 1.125rem;
    vertical-align: text-bottom;
  }

  [dir=ltr] & {
    float: left;

    &:before {
      content: '\e5c4'; // md-arrow_back
      margin-right: @global-margin-small;
    }
  }

  [dir=rtl] & {
    float: right;

    &:before {
      content: '\e5c8'; // md-arrow_forward
      margin-left: @global-margin-small;
    }
  }
}

.back-link-wrapper {
  &.outstretched {
    .back-link {
      margin-top: @global-margin-large;
    }
  }

  .course-content-background-inherited & + .mtx-section {
    padding-top: 0;
  }

  .course-content-background-not-inherited & + .mtx-section {
    margin-top: @global-margin-large - @back-link-margin-bottom;
  }
}
