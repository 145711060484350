.hook-api-item() {
  &.image {
    .thumb {
      display: block;
      position: relative;
      padding-top: @image-item-padding;
      z-index: 1;
      border-radius: @thumb-border-radius;
      overflow: hidden;
      background-color: @api-item-empty-bg;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }

      img,
      &-image-hover {
        .position-cover();
        display: block;
        margin: 0 auto;
        border-radius: @thumb-border-radius;
      }
    }
  }
}
