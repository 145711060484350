//** See mtx_api_activity_filter theme.

@activity-filter-width: 100%;
@activity-filter-items-padding-x: @global-margin-medium;
@activity-filter-items-padding-y: @global-margin;
@activity-filter-image-height: 3rem;
@activity-filter-image-width: 5rem;

.mtx-api-activity-filter {
  // Dropdown button
  .uk-button-dropdown {
    max-width: 100%;
    width: @activity-filter-width;

    &.uk-open {
      .activity-current-item {
        border-color: @form-focus-border;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .md-icon:after {
          content: '\e5ce'; // md-expand_less
        }
      }

      .uk-dropdown {
        border-radius: 0 0 @inputs-border-radius @inputs-border-radius;
      }
    }
  }

  .uk-dropdown {
    width: 100%;
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0 @activity-filter-items-padding-x;
    overflow: hidden;
  }

  .uk-nav-dropdown > li > a {
    padding: @activity-filter-items-padding-y @activity-filter-items-padding-x;
  }

  .activity-search-input {
    margin: @global-margin-medium 0;

    .input-search-icon;

    .search-input {
      width: 100%;
      font-size: @dropdown-font-size;
    }
  }

  .mtx-custom-scrollbar {
    max-height: 300px;
    margin-inline-start: -@activity-filter-items-padding-x;
  }

  .uk-dropdown .mtx-custom-scrollbar {
    &.os-host-scrollbar-vertical-hidden {
      margin-inline-end: -@activity-filter-items-padding-x;
    }
  }

  // Course item
  .activity-banner {
    @activity-filter-height: @activity-filter-items-padding-y * 2 + @activity-filter-image-height;
    @activity-filter-image-radius: calc(unit(@activity-filter-image-height / @activity-filter-height) * @inputs-border-radius);

    display: block;
    position: relative;
    height: @activity-filter-image-height;
    width: @activity-filter-image-width;
    background-color: @api-item-empty-bg;
    border-radius: @activity-filter-image-radius;
    margin-inline-end: @global-margin-medium;

    .mtx-dark-theme & {
      background-color: @overlay-color-darken;
    }

    img {
      height: 100%;
      width: auto;
      border-radius: @activity-filter-image-radius;
    }

    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
    }
  }

  .activity-details {
    line-height: @global-line-height;

    & > * {
      .text-ellipsis;
      width: auto;
      line-height: inherit;
    }
  }

  .empty-message,
  .activity-current-item,
  .activity-item {
    width: 100%;
    display: block;
  }

  .activity-item.uk-active {
    cursor: default;
  }

  .activity-current-item {
    position: relative;
    width: 100%;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    padding: @activity-filter-items-padding-y @activity-filter-items-padding-x;
    padding-inline-end: calc(@md-icon-size + @activity-filter-items-padding-x * 2);
    color: @form-color;
    transition: @form-border-transition;
    outline-offset: initial;
    text-decoration: none;

    .md-icon,
    .ajax-progress {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .md-icon {
      color: @form-icon-color;
    }

    .ajax-progress .spinner circle {
      stroke: @form-icon-color;
    }

    &:focus,
    &:active {
      border-color: @form-focus-border;
    }

    [dir=ltr] & {
      .md-icon,
      .ajax-progress {
        right: @activity-filter-items-padding-x;
      }
    }

    [dir=rtl] & {
      .md-icon,
      .ajax-progress {
        left: @activity-filter-items-padding-x;
      }
    }
  }

  .empty-message {
    padding: @global-margin-large;
    text-align: center;
    color: @global-muted-color;
  }

  .ajax-progress .spinner {
    width: @md-icon-size;
    height: @md-icon-size;
    margin-bottom: @global-margin-medium;
  }
}
