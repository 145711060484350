@page-title-margin-bottom: @global-margin-large;

.mtx-content-section h1,
.page-title {
  font-size: 2rem;
  line-height: 1;
  margin: 0 0 @page-title-margin-bottom;
}

.page-subtitle {
  margin: 0 0 @global-margin-medium;
  font-size: @global-font-size-small;

  .mtx-content-section h1 + &,
  .page-title + & {
    margin-top: (@global-margin-small - @page-title-margin-bottom);
  }

  .slash {
    margin: 0 @global-margin-small;
  }
}
