//
// Component: Dropdown
//
// ========================================================================

// Variables
// ========================================================================

@dropdown-z-index:                              @global-z-index + 20;
@dropdown-width:                                12.5rem;
@dropdown-margin-top:                           @global-margin-mini;
@dropdown-padding:                              12px;
@dropdown-background:                           #ffffff;
@dropdown-color:                                @global-text-color;
@dropdown-font-size:                            @global-font-size-small;

@dropdown-divider-border:                       @global-border;

@dropdown-small-padding:                        8px;

@dropdown-navbar-margin:                        @global-margin-mini;
@dropdown-navbar-background:                    @dropdown-background;
@dropdown-navbar-color:                         @global-color;

@dropdown-border-radius:                        @thumb-border-radius;
@dropdown-box-shadow:                           0 4px 12px fade(@_black_color, 29%);
@dropdown-line-height:                          @global-line-height;

// Component
// ========================================================================

.hook-dropdown() {
  border-radius: @dropdown-border-radius;
  box-shadow: @dropdown-box-shadow;
  line-height: @dropdown-line-height; // Prevents default line-height overriding by parent's container. E.g. courses list.

  &-top.uk-dropdown-navbar {
    margin-top: -@dropdown-margin-top;
  }

  &.uk-dropdown-small {
    min-width: 5.625rem;
  }

  &.uk-dropdown-large {
    min-width: 12.625rem;
  }

  li.uk-parent {
    & > a {
      @icon-margin-x: @global-margin-small;

      position: relative;
      padding-inline-end: calc(@nav-dropdown-padding-horizontal + @icon-margin-x * 2);

      .md-icon {
        position: absolute;
        top: 50%;
        margin: -(@md-icon-size / 2) @icon-margin-x 0;
        inset-inline-end: 0;
      }
    }
  }

  .mtx-date-widget {
    &-date {
      font-size: unit(@date-widget-date-font-size, em);
    }

    &-month {
      font-size: unit(@date-widget-month-font-size, em);
    }
  }

  @media (min-width: @breakpoint-medium) {
    /*
     * Width multiplier for dropdown columns
     */
    &:not(.uk-dropdown-stack) {
      &.uk-dropdown-width-3 {
        width: (@dropdown-width * 4);
      }

      &.uk-dropdown-width-6 {
        width: (@dropdown-width * 6);
        max-width: @breakpoint-medium;
      }

      &.uk-dropdown-width-7 {
        width: (@dropdown-width * 7);
        max-width: @breakpoint-medium;
      }

      &.uk-dropdown-width-8 {
        width: (@dropdown-width * 8);
        max-width: @breakpoint-medium;
      }

      [dir=rtl] & > .uk-dropdown-grid > [class*='uk-width-'] {
        &:nth-child(n+2) {
          border-left: none;
          border-right: @dropdown-divider-border-width solid @dropdown-divider-border;
        }

        &:first-child {
          border-left: @dropdown-divider-border-width solid @dropdown-divider-border;
        }

        &:nth-last-child(-2n+2) {
          border-left: none;
        }
      }
    }
  }
}

// Modifier: `uk-dropdown-navbar`
// ========================================================================

.hook-dropdown-navbar() {
  li.uk-parent.uk-open & {
    margin-top: 0;

    [data-uk-dropdown] & {
      // Override rules of .hook-dropdown-misc() {}
      margin-inline: 0;
    }
  }
}

// Sub-object: `uk-dropdown-overlay`
// ========================================================================

.hook-dropdown-overlay() {
}

// Miscellaneous
// ========================================================================

.hook-dropdown-misc() {
  [data-uk-dropdown] {
    position: relative;

    & > i,
    .mtx-handler > i.md-icon {
      color: @_grey_dark_color;
      cursor: pointer;
      transition: all .15s ease-in-out;

      .bulk-operations-hidden-actions &,
      .vbo-hidden-actions & {
        transition: all .15s ease-in-out, visibility 0s;
      }

      .uk-open&,
      &:hover {
        color: @_grey_darken_color;
      }
    }

    .mtx-handler:focus > i.md-icon {
      color: @_grey_darken_color;
    }

    &:not(.mtx-actions) > .uk-button {
      .button-toggler();
    }

    &.combobox {
      .uk-dropdown-toggler {
        cursor: pointer;
        .button-toggler();
      }

      &.uk-open {
        .uk-dropdown-toggler:after {
          content: '\e5ce'; // md-expand_less
        }
      }
    }

    .mtx-handler.uk-button-circle {
      &,
      &:hover,
      &:focus {
        & > i.md-icon {
          color: inherit;
        }
      }
    }

    .nei-widget-auto-color & {
      .mtx-handler {
        opacity: @widget-contrast-opacity;

        &,
        & > i.md-icon {
          color: inherit !important;
        }

        &:hover,
        &:focus {
          opacity: @widget-contrast-opacity-hover;
        }
      }
    }
  }

  @media screen and (min-width: @breakpoint-small-max) {
    [data-uk-dropdown*="bottom-right"] {
      .mtx-handler + .uk-dropdown {
        margin-inline: @action-icon-pos-x -@action-icon-pos-x;
      }

      // Courses
      .mtx-courses & {
        .mtx-handler + .uk-dropdown {
          margin-inline: @mtx-course-el-actions-pos-x -@mtx-course-el-actions-pos-x;
        }
      }
    }

    [data-uk-dropdown*="bottom-left"] {
      .mtx-handler + .uk-dropdown {
        margin-inline: -@action-icon-pos-x @action-icon-pos-x;
      }

      // Courses
      .mtx-courses & {
        .mtx-handler + .uk-dropdown {
          margin-inline: -@mtx-course-el-actions-pos-x @mtx-course-el-actions-pos-x;
        }
      }
    }

    [data-uk-dropdown] {
      &.uk-button-circle .mtx-handler,
      .mtx-handler.uk-button-circle {
        & + .uk-dropdown {
          margin-inline: 0;
        }
      }
    }
  }

  // Add: `uk-select-button-dropdown`
  .uk-select-button-dropdown.uk-button {
    background-color: @form-background !important;
    border: @form-border-width solid @form-border;
    padding: @form-padding-y @form-padding-x;

    &:hover,
    &:focus {
      border-color: @form-focus-border;
    }
  }
}
