@mtx-widget-padding: @global-margin-medium * 2;
@mtx-widget-action-pos-x: @global-margin-medium;
@mtx-widget-action-pos-y: @global-margin-medium;

.mtx-widget {
  margin-bottom: @global-margin;
  border-radius: @global-border-radius-large;

  &.course-info-block {
    background-color: transparent;
  }

  &-banner {
    border-radius: @global-border-radius-large @global-border-radius-large 0 0;
    position: relative;
    overflow: hidden;

    figure {
      margin: 0;
    }

    &-label {
      position: absolute;
      top: @mtx-widget-action-pos-y;

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        left: @mtx-widget-action-pos-x;
        right: auto;
      }

      [dir=rtl] & {
        right: @mtx-widget-action-pos-x;
        left: auto;
      }
    }
  }

  &-body {
    padding: @mtx-widget-padding;

    & > div + div {
      margin-top: @global-margin-large;
    }

    div.mtx-widget-action {
      margin-top: @global-margin-medium * 2;
    }
  }

  &-message {
    border: 1px solid @_grey_color;
    border-radius: @global-border-radius;
    padding: @mtx-widget-padding;

    .icon {
      display: block;
      margin: 0 auto @global-margin-medium;
      font-size: 2rem;
    }

    p.text {
      margin: 0;
    }
  }

  // Choose banner action
  .choose-banner {
    position: absolute;
    top: @mtx-widget-action-pos-y;
    display: inline-block;
    width: @nei-controls-size;
    height: @nei-controls-size;
    text-align: center;
    background-color: @nei-controls-bg;
    box-shadow: @nei-controls-box-shadow;
    color: @global-text-color;
    .button-border-radius(@nei-controls-size / 16);
    transition: background-color .2s linear;

    &:hover,
    &:focus,
    &.active {
      background-color: @nei-controls-bg-hover;
    }

    &:focus-visible {
      .focus-state-box-shadow();
    }

    .md-icon {
      font-size: @nei-controls-icon-font-size;
      line-height: @nei-controls-size;
      vertical-align: baseline;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      right: @mtx-widget-action-pos-x;
      left: auto;
    }

    [dir=rtl] & {
      left: @mtx-widget-action-pos-x;
      right: auto;
    }
  }

  // Dropdown
  [data-uk-dropdown] {
    &.uk-open .uk-dropdown-toggler {
      border-color: @form-focus-border;
    }
  }

  .uk-dropdown-toggler {
    display: block;
    width: 100%;
    border: @form-border-width solid @form-border;
    border-radius: @inputs-border-radius;
    padding: 1.25rem @global-margin-large;
    line-height: 1.4;
    color: @form-color;
    transition: @form-border-transition;

    &:focus,
    &:active {
      border-color: @form-focus-border;
    }

    &.disabled {
      border: @form-border-width solid @form-border;
      cursor: default;
    }
  }

  .uk-nav-dropdown > li > a {
    padding: @global-margin-medium @global-margin-large;
    line-height: 1.3;
  }

  .uk-dropdown {
    width: 100%;
  }
}
