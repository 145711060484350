.mtx-api-requirements {
  &-group {
    ul {
      margin: 0;
    }

    li + li {
      margin-top: @global-margin;
    }
  }

  &-grouped &-group {
    @group-padding: @global-margin-medium;

    border-radius: @global-border-radius;
    padding: @group-padding;
    background-color: @global-page-background-color;

    .mtx-dark-theme & {
      background-color: @overlay-color-lighten;
    }

    ul {
      margin-inline-start: calc(@group-padding - @base-list-padding-left);
    }
  }

  &:not(&-grouped) &-group {
    ul {
      @half-marker-width: .4375rem / 2;

      margin-inline-start: calc(-(@base-list-padding-left / 2) + @half-marker-width);
    }
  }
}
