.hook-api-item() {
  &.playlist {
    position: relative;

    .thumb {
      display: block;
      border-radius: @thumb-border-radius;
      overflow: hidden;
      padding-top: @video-item-padding;
      position: relative;
      z-index: 1;

      &-image {
        background-color: @api-item-empty-bg;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: 100%;
        left: 0;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: @thumb-border-radius;

        .mtx-dark-theme & {
          background-color: @overlay-color-darken;
        }
      }

      .playlist-video-counter {
        background: fade(@global-text-color, 70%);
        bottom: 0;
        color: fade(@global-base-color, 70%);
        font-size: @global-font-size;
        height: 100%;
        line-height: 1.25em;
        min-width: 80px;
        padding: 12% 0 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 5;
        border-radius: 0 @global-border-radius @global-border-radius 0;

        .counter-label {
          font-size: @global-font-size-xsmall;
          text-transform: uppercase;
        }

        span {
          display: block;
        }
      }

      .mtx-expiration-thumb {
        z-index: 5;
      }
    }
  }
}
