.mtx-certificate-verify-page {
  max-width: 50rem;
  border-radius: @global-border-radius;
  margin: @global-margin-large auto 0;
  padding: 4rem 2rem 11.875rem;
  background-color: @mtx-section-even-bg-color;

  .md-icon {
    font-size: 3rem;
    margin-bottom: @global-margin-medium * 2;
    color: @secondary_color;
  }

  .page-title {
    margin-bottom: @global-margin-medium * 2;
  }

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  &.mtx-certificate-verify-failed {
    .md-icon {
      color: @text-danger-color;
    }
  }
}
