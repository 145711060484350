.ms-connect-banner {
  @icon-width: 3rem;

  border-radius: @global-border-radius;
  margin-bottom: @global-margin-large;
  padding: @global-margin-medium;
  background-color: @_white_color;

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  .icon {
    .mtx-social-icon(@icon-width, 87.5%);
  }

  .title {
    margin-bottom: @global-margin-small / 2;
  }

  .uk-close {
    padding: @global-margin-small;
  }

  [dir=ltr] & {
    .icon {
      float: left;
      margin-right: @global-margin-medium;
    }

    .item-content {
      margin: 0 @global-margin-large 0 (@icon-width + @global-margin);
    }

    .uk-close {
      margin: -@global-margin -@global-margin -@global-margin-small -@global-margin-small;
    }
  }

  [dir=rtl] & {
    .icon {
      float: right;
      margin-left: @global-margin-medium;
    }

    .item-content {
      margin: 0 (@icon-width + @global-margin) 0 @global-margin-large;
    }

    .uk-close {
      margin: -@global-margin -@global-margin-small -@global-margin-small -@global-margin;
    }
  }
}
