.mtx-marketplace {
  @info-block-padding: @global-margin-small;
  @title-font-size-mobile: 1.2em;
  @title-line-height: 1.2;

  clear: both;

  .filters {
    .search {
      .input-search-icon();

      input {
        width: 100%;
      }

      @media all and (max-width: @breakpoint-small-max) {
        float: none;
        overflow: hidden;
      }
    }
  }

  .courses {
    position: relative;

    & > * {
      margin-bottom: @global-margin;
    }

    .price-options {
      margin-bottom: @global-margin;
      .clearfix();

      &.multiple {
        .price-option {
          float: left;
          width: 50%;

          &:last-child {
            border-left: 1px solid @global-border-color;
          }
        }
      }

      .price-option {
        text-align: center;

        .value {
          font-size: @global-font-size-large;
          font-weight: @global-font-weight-bold;
        }

        .option {
          font-size: @global-font-size-xxsmall;
          color: @global-muted-color;
        }
      }
    }
  }

  .pager {
    text-align: center;
    line-height: 2;

    .fa {
      color: @global-muted-color !important;
    }
  }

  .mtx-empty-message {
    padding: 7% 25%;
  }

  .course-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid @global-border-color;
    border-radius: unit(@global-border-radius + 1, px);

    .thumbnail {
      display: block;
      padding-top: 56.25%; // 281/500 %
      position: relative;
      border-radius: @global-border-radius @global-border-radius 0 0;
      background-color: @api-item-empty-bg;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }
    }

    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: @global-border-radius @global-border-radius 0 0;

      // styles for alternative text.
      font-size: @global-font-size-xsmall;
      color: @global-muted-color;
    }

    .course-labels {
      position: absolute;
      top: 0;
    }

    .course-info {
      position: relative;
      padding: @info-block-padding @info-block-padding 0;
    }

    .course-info-footer {
      margin-top: auto;
      padding: @global-margin @info-block-padding @info-block-padding;
    }

    // @Todo: Remove icon and show description for 3 lines
    .info-icon {
      float: right;
      line-height: @global-line-height;
      text-align: center;
    }

    .title {
      height: unit(@title-line-height * 2, em);
      line-height: @title-line-height;
      font-size: @global-font-size;
      margin: 0 0 @global-margin-small;

      .info-link {
        margin: 0;
        .text-ellipsis-box(2, @title-line-height);
      }
    }

    .contact,
    .updated-date,
    .mtx-copyright-sign {
      color: @global-muted-color;
      .text-ellipsis;
    }

    .language,
    .version,
    .contact,
    .updated-date,
    .mtx-copyright-sign {
      font-size: @global-font-size-xsmall;
      line-height: @global-line-height;
    }

    .language,
    .version {
      max-width: 50%;
      position: absolute;
      bottom: @global-margin-small;
      padding: .1875rem @global-margin;
      color: @mtx-course-el-info-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-radius: @global-border-radius;
      background-color: rgba(0, 0, 0, .3);
    }

    .language {
      [dir=ltr] & {
        left: @global-margin-small;
      }

      [dir=rtl] & {
        right: @global-margin-small;
      }
    }

    .version {
      [dir=ltr] & {
        right: @global-margin-small;
      }

      [dir=rtl] & {
        left: @global-margin-small;
      }
    }

    .price {
      text-align: center;
      margin-bottom: @global-margin;
      font-size: @global-font-size-large;
      font-weight: @global-font-weight-bold;

      span {
        font-size: @global-font-size-xsmall;
        font-weight: @global-font-weight-normal;
      }
    }

    .download-link,
    .price {
      .text-ellipsis();
    }

    [dir=rtl] & {
      .info-icon {
        float: left;
      }
    }

    @media all and (max-width: @breakpoint-mini-max) {
      .title {
        font-size: @title-font-size-mobile;
      }

      .language,
      .version,
      .contact,
      .updated-date,
      .mtx-copyright-sign {
        font-size: @global-font-size-small;
      }
    }
  }
}

.certification-stamp {
  position: absolute;
  right: @global-margin-small;
  bottom: @global-margin-small;
  width: 9em;
  max-width: 60%;
  padding: @global-border-radius;
  border-radius: @global-border-radius;
  background-color: fade(@_white_color, 85%);

  [dir=rtl] & {
    left: @global-margin-small;
    right: auto;
  }
}
