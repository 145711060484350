.mtx-api-form-radios-horizontal {
  @value-pos-top: -@md-icon-size - @global-margin-mini / 2;

  .form-radios-wrapper {
    margin-top: calc(@global-font-size * @global-line-height + @global-margin-small);

    label {
      cursor: pointer;
    }
  }

  .form-radios {
    display: flex;
    gap: @global-margin;
  }

  .form-radios > .form-item {
    position: relative;
    margin: 0;

    &:before {
      content: attr(data-value);
      position: absolute;
      top: @value-pos-top;
      left: 50%;
      transform: translateX(-50%);
    }

    label {
      position: absolute;
      top: @value-pos-top;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      overflow: hidden;
      opacity: 0;
    }
  }
}
