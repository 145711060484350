@mtx-search-field-expanded-width: 13.75rem;

#mtx-search-header-search-form {
  @input-padding: @form-padding-x + @global-margin-small; // @form-icon-width

  z-index: 1;

  .form-item-s {
    margin: 0;
    position: relative;

    input {
      position: absolute;
      width: 0;
      min-width: 0;
      top: -((@form-height - @page-header-button-icon-size) / 2);
      padding-left: 0;
      padding-right: 0;
      border: 0 none;
      border-radius: 0;

      .placeholder({
        color: inherit;
      });

      &.active {
        min-width: @mtx-search-field-expanded-width;
        width: @mtx-search-field-expanded-width;
        transition: all .2s ease-out;
        inset-inline-end: -(@input-padding / 2);
        padding-inline-start: @form-padding-x;
        padding-inline-end: @page-header-button-icon-size + @input-padding;
      }
    }
  }

  a.search-button {
    .page-header-button();
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .form-item-s input {
      top: -((@form-height - @page-header-button-icon-large-size) / 2);

      &.active {
        inset-inline-end: 0;
        padding-inline-end: @page-header-button-icon-large-size;
      }
    }
  }
}

// Former #mtx-search-page-search-form
.mtx-search-form {
  .form-item-s {
    input {
      &:extend(.page-title);
      font-weight: @global-font-weight-bold;
      border-width: 0 0 1px 0;
      border-radius: 0;
      height: auto;
      margin-bottom: @global-margin;
      padding: 0 0 @global-margin 0;

      // Implement placeholder styles
      .placeholder({
        font-size: inherit;
      });
    }
  }
}

.mtx-search-results {
  .mtx-list-item {
    .meta .md-icon {
      font-size: 1rem;
      line-height: unit(@list-meta-font-size * @global-line-height, em);
      vertical-align: bottom;

      [dir=ltr] & {
        margin-right: @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @global-margin-small;
      }
    }
  }

  .mtx-search-keywords {
    display: flex;
    flex-wrap: wrap;

    &-item {
      border: 1px solid @_grey_dark_color;
      font-size: @global-font-size-small;
      padding: 0 @global-margin-small;
      margin-top: @global-margin-small;
      line-height: calc(2em - 2px); // 28px

      [dir=ltr] & {
        margin-right: @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @global-margin-small;
      }
    }
  }

  .pager {
    margin-top: @global-margin-large;
  }
}

.mtx-search-resource {
  margin-top: @global-margin;

  .title {
    line-height: @list-icon-size-small;
    font-weight: @global-font-weight-normal;
    .text-ellipsis();
  }

  .icon.small {
    margin: 0;
    margin-inline-end: @global-margin;

    & + .item-content {
      margin-inline-start: @list-icon-size-small + @global-margin;
    }
  }
}
