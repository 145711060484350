.expired {
  &-default-view {
    .overlay {
      padding: @global-margin-large 0;
      color: @mtx-course-el-info-color;
      background-color: fade(#000000, 50%);
      text-align: center;
      z-index: 1;
    }

    .icon {
      height: 85px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      &.external {
        background-image: url('@{global-images-path}nei/icons/external.svg');
      }

      &.html {
        background-image: url('@{global-images-path}nei/icons/html.svg');
      }

      &.text {
        background-image: url('@{global-images-path}nei/icons/text.svg');
      }

      &.math {
        background-image: url('@{global-images-path}nei/icons/math.svg');
      }

      &.code_snippet {
        background-image: url('@{global-images-path}nei/icons/code-snippet.svg');
      }

      &.folder {
        background-image: url('@{global-images-path}nei/icons/dynamic.svg');
      }

      &.gfolder {
        background-image: url('@{global-images-path}nei/icons/drive.svg');
      }

      &.h5p {
        background-image: url('@{global-images-path}nei/icons/h5p.svg');
      }

      &.button {
        background-image: url('@{global-images-path}nei/icons/button.svg');
      }
    }

    .expiration-text {
      padding: 0 @global-margin-small;
      margin-bottom: 0;
    }
  }

  &-image-view {
    position: relative;

    .overlay,
    .expiration-text {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .overlay {
      top: 0;
      color: @mtx-course-el-info-color;
      background-color: fade(#000000, 50%);
      text-align: center;
      z-index: 1;
    }

    .expiration-text {
      top: 50%;
      margin: -1.5em 0 0;
      padding: 0 @global-margin-small;
      .text-ellipsis-box(2);
    }

    .mtx-player-placeholder,
    .expired-image {
      overflow: hidden;
    }

    .mp-thumbnail,
    .expired-image img {
      .blur();
    }

    figure {
      margin: 0;
    }
  }

  &-link {
    color: @global-muted-color;

    &:hover,
    &:focus {
      color: @global-muted-color;
      cursor: default;
    }
  }
}
