@text-editor-resource-size: @global-size-mini * 1.5;
@text-editor-margin-bottom: @global-margin;

.mtx-text-resources {
  margin-top: @global-margin;
  font-size: 0;

  .resource-item {
    display: inline-block;
    vertical-align: top;
    font-size: @global-font-size;
    width: 15rem;
    max-width: 100%;
    position: relative;
    border-radius: @thumb-border-radius;
    margin-bottom: @text-editor-margin-bottom;
    margin-inline-end: @global-margin;

    &.file {
      a {
        display: block;
        background-color: @_grey_lighten_color;
        border-radius: @thumb-border-radius;
        color: inherit;
        padding: @global-margin @global-margin-small;
        text-decoration: none;

        .title {
          text-decoration: @base-link-text-decoration;
        }

        &:hover,
        &:focus {
          background-color: @_grey_light_color;

          .title {
            text-decoration: @base-link-hover-text-decoration;
          }
        }

        .mtx-dark-theme & {
          background-color: @overlay-color-light;

          &:hover {
            background-color: @overlay-color-lighten;
          }
        }
      }

      .mtx-text-resources-file();

      .thumb {
        img {
          width: auto;
        }

        &.folder-icon {
          margin: 0 .25rem;

          path {
            fill: @_grey_color;
          }

          & + .info {
            padding-inline-start: @text-editor-resource-size + .5rem;
          }
        }
      }

      .title {
        font-weight: @global-font-weight-normal;
        line-height: @global-line-height;
        margin: 0;
      }

      .folder-icon + .info .title {
        line-height: 3;
      }
    }

    &.video,
    &.external-video {
      .thumb {
        position: relative;

        &::after {
          .fa-icon;
          content: '\f04b';
          color: fade(#ffffff, 70%);
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 3rem;
          line-height: 1;
          width: 2em;
          margin: -.5em -1em 0;
          text-align: center;
        }

        &:hover,
        &:focus {
          &::after {
            color: fade(#ffffff, 90%);
          }
        }
      }

      &.youtube .thumb:after {
        content: '\f167';
      }

      &.vimeo .thumb:after {
        content: '\f194';
      }
    }

    .uk-modal .uk-modal-dialog:not(.uk-modal-dialog-fullscreen) & {
      // E.g. biography modal is in fullscreen dialog.
      width: 10rem;
    }
  }
}

.mtx-text-resources-text-editor-view {
  .resource-item {
    background-color: @_grey_lighten_color;
    color: @global-text-color;
    padding: @global-margin @global-margin-small;

    .mtx-text-resources-file();

    &:not(.file) {
      .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: calc(@thumb-border-radius * .5);
      }

      .thumb + .info {
        padding-inline-start: @text-editor-resource-size + .5rem;
      }
    }

    &.video,
    &.external-video {
      .thumb::after {
        font-size: 1rem;
        width: 1em;
        margin: -.5em -.5em 0;
      }
    }

    a.delete {
      opacity: 0;
      position: absolute;
      top: @global-margin-small;
      padding: 0;
      text-align: center;
      color: #6c757d;
      background-color: fade(@_white_color, 80%);
      border-radius: @global-border-radius;
      transition: all .12s ease-in;
      width: @md-icon-size;
      height: @md-icon-size;
      text-decoration: none;

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        right: @global-margin-small;
        left: auto;
      }

      [dir=rtl] & {
        left: @global-margin-small;
      }

      .md-icon {
        font-size: 1.125rem;
        line-height: @md-icon-size;
      }

      &:hover,
      &:focus {
        opacity: 1;
        background-color: fade(@_white_color, 90%);
        color: darken(#6c757d, 10%);
      }
    }

    &:hover {
      a.delete {
        opacity: 1;
      }
    }
  }
}

.mtx-text-resources-text-editor-preview {
  @thumb-size: 3rem;

  // Increased selector specificity helps to override .mtx-text-resources .resource-item.file {}
  &.mtx-text-resources .resource-item {
    background-color: transparent;
    width: 100% !important;

    .mtx-text-resources-file(@thumb-size, @global-margin, @global-font-size, @global-font-size-small);

    .thumb {
      border-radius: calc(@thumb-border-radius * .5);
      overflow: hidden;
      background-color: @_grey_lighten_color;

      .mtx-dark-theme & {
        background-color: @overlay-color-lighten;
      }
    }

    &:not(.file) {
      .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title + .size {
      margin-top: @global-margin-mini / 2;
    }
  }

  &.show-more-enable {
    overflow: hidden;
    max-height: calc((@thumb-size + @text-editor-margin-bottom) * 5);
  }
}

.mtx-text-resources-text-editor-content-view {
  .resource-item {
    &.h5p {
      width: 100%;
    }

    &.image,
    &.video,
    &.external-video {
      .thumb {
        padding-top: 56%; // 140x250
        position: relative;
        overflow: hidden;
        border-radius: @thumb-border-radius;
        background-color: @api-item-empty-bg;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: inherit;
        }

        .mtx-dark-theme & {
          background-color: @overlay-color-darken;
        }
      }
    }
  }
}

.mtx-text-resources-file(@thumb-size: @text-editor-resource-size, @thumb-margin: 0, @title-fs: .75em, @size-fs: .625em) {
  .thumb {
    width: @thumb-size;
    height: @thumb-size;
    text-align: center;

    img {
      display: inline-block;
      max-height: 100%;
      vertical-align: bottom;
    }
  }

  .info {
    line-height: 1.5;
  }

  .title {
    font-size: @title-fs;
    font-weight: @global-font-weight-semibold;
    .text-ellipsis();
  }

  .size {
    font-size: @size-fs;
    color: @global-muted-color;
  }

  .thumb + .info {
    padding-inline-start: @thumb-size + @thumb-margin;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    .thumb {
      float: left;
    }
  }

  [dir=rtl] & {
    .thumb {
      float: right;
    }
  }
}
