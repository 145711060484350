.nei-widget-video,
.nei-widget-playlist,
.nei-widget-banner {
  .mtx-player {
    overflow: hidden;

    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }
  }
}
