//
// Component: List
//
// ========================================================================


// Variables
// ========================================================================

@list-line-border:                              @global-border;


// Modifier: `list-line`
// ========================================================================

.hook-list-line() {}


// Modifier: `list-striped`
// ========================================================================

.hook-list-striped() {}


// Miscellaneous
// ========================================================================

.hook-list-misc() {
  .uk-list {
    // Fixes platform styles conflicts
    ul&,
    ol& {
      margin-left: 0;
    }

    &-inline {
      > * {
        display: inline-block;
        margin-right: 1em;

        .uk-align-right & {
          margin-right: 0;
          margin-left: .375em;

          [dir=rtl] & {
            margin-left: 0;
            margin-right: .375em;
          }
        }

        [dir=rtl] & {
          margin-left: 1em;
          margin-right: 0;
        }
      }
    }

    .uk-nav {
      padding-left: 0;
    }

    ul {
      padding-left: 0; // rtl
      padding-inline-start: @list-nested-padding-left;
    }
  }
}
