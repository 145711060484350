@card-title-line-height: 1.25;
@card-item-margin: 2rem;

.mtx-card {
  display: block;
  position: relative;

  .mtx-cards-list & {
    margin-bottom: @card-item-margin;
  }

  .mtx-thumb {
    &,
    &-image,
    &-image > img,
    &-overlay {
      border-radius: @thumb-border-radius;
    }

    &-icon {
      font-size: 4em;
    }
  }

  &-icon-item {
    .mtx-thumb-icon {
      font-size: 4.5rem;
      opacity: .8;
      transition: all .12s linear;
    }

    &:hover,
    &:focus {
      .mtx-thumb-icon {
        opacity: 1;
        font-size: 5.25rem;
      }
    }
  }

  &-link {
    display: block;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;

      .mtx-thumb-overlay {
        opacity: 1;
      }
    }
  }

  &-title {
    font-size: @global-font-size;
    margin: @global-margin 0 @global-margin-small;
    .text-ellipsis-box(2, @card-title-line-height);
  }

  &-body {
    margin: @global-margin-small 0;
    font-size: @global-font-size-small;
  }
}
