.view-organization-users {
  .organization-actions {
    .mtx-button-group {
      .uk-button + .uk-dropdown {
        min-width: 100%;
      }
    }
  }

  .views-widget-filter-field_job_function_tid,
  .views-widget-filter-field_user_organization_tid,
  .views-widget-filter-field_user_country_value {
    display: none;
  }

  .views-field {
    &-name {
      min-width: 15rem;
      max-width: 18rem;
      word-break: break-word;

      .avatar {
        min-width: @mtx-users-avatar-size;

        &,
        img {
          .circle(@mtx-users-avatar-size);
        }
      }

      .text > div + div {
        margin-top: @global-margin-mini;
      }
    }

    &-created,
    &-field-user-organization {
      min-width: 10em;
    }

    &-field-user-organization {
      [data-show-more] {
        max-height: @table-font-size * @table-line-height * 3;
      }
    }
  }

  .item-list .pager {
    margin-top: 2rem;
  }
}
