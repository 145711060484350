.attendees-list-filters {
  & > div {
    width: @exposed-input-width;

    &.search {
      .input-search-icon;
    }
  }
}

.attendees-list {
  .mtx-list-item {
    .info a.meta {
      display: inline-block;
    }

    @media all and (max-width: @breakpoint-mini-max) {
      .status {
        margin: @global-margin 0;
      }
    }
  }
}
