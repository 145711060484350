// Tests block.
.mtx-statistics-details.tests {
  .mtx-api-spider-chart {
    width: 42%;

    @media all and (max-width: @breakpoint-large-max) {
      display: none;
    }
  }
}
