//** Teams pages global styles
.page-content-wrapper {
  ul.pager--infinite-scroll {
    margin: 0;
    list-style: none;
  }

  .uk-text-bold a {
    color: @global-text-color;
    word-wrap: break-word;

    &:hover {
      color: @global-link-hover-color;
    }
  }

  .item-content > .uk-form {
    margin-bottom: @global-margin;
  }
}

// Team stream follow star
.node-type-team {
  .mtx-flag-wrapper {
    display: block;
    margin-bottom: @global-margin-small;
    .clearfix;

    a.mtx-flag-link {
      float: right;

      [dir=rtl] & {
        float: left;
      }
    }
  }
}

// Post actions
.post-actions {
  .uk-list-inline > *:last-child {
    margin-right: 0;
    margin-left: 0;
  }
}

// Recent updates search
.team-recent-updates-filters {
  & > div {
    width: @exposed-input-width;

    &.search {
      .input-search-icon;
    }
  }
}
