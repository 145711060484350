.mtx-file-kb-settings-form {
  &.mtx-site-settings-form .form-type-select {
    width: 100%;
  }

  .form-item-mtx-file-kb-outdated-period {
    input[type='number'] {
      width: @field-type-number-width;
    }
  }

  .form-item-mtx-file-kb-outdated-notification-enabled {
    & ~ [class*="form-item-mtx-file-kb-outdated"] {
      display: none;
    }
  }
}
