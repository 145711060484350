//
// Component: Nav
//
// ========================================================================


// Variables
// ========================================================================

@nav-header-padding-vertical:                   @nav-padding-vertical;
@nav-header-padding-horizontal:                 @nav-padding-horizontal;
@nav-header-font-size:                          round((@global-font-size * 0.85)); // 12px / 14px
@nav-header-margin-top:                         @global-margin;

@nav-divider-margin-vertical:                   @global-margin-small;
@nav-divider-margin-horizontal:                 0;

@nav-subtitle-font-size:                        round((@global-font-size * 0.85)); // 12px / 14px
@nav-subtitle-line-height:                      round((@nav-subtitle-font-size * 1.46)); // 18px / 20px

@nav-sub-padding-left:                          @nav-padding-horizontal;

@nav-parent-icon-width:                         @global-line-height;

@nav-side-color:                                @global-color;
@nav-side-hover-color:                          @global-color;
@nav-side-active-background:                    @global-primary-background;
@nav-side-active-color:                         @global-contrast-color;
@nav-side-header-color:                         @global-color;
@nav-side-divider-border:                       @global-border;
@nav-side-nested-color:                         @global-link-color;
@nav-side-nested-hover-color:                   @global-link-hover-color;

@nav-dropdown-color:                            inherit;
@nav-dropdown-hover-background:                 @global-primary-background;
@nav-dropdown-hover-color:                      @global-contrast-color;
@nav-dropdown-header-color:                     @global-muted-color;
@nav-dropdown-divider-border:                   @global-border;
@nav-dropdown-nested-color:                     @global-text-color;
@nav-dropdown-nested-hover-color:               @global-link-hover-color;
@nav-dropdown-padding-vertical:                 12px;
@nav-dropdown-padding-horizontal:               24px;

@nav-navbar-color:                              @global-color;
@nav-navbar-hover-background:                   @global-primary-background;
@nav-navbar-hover-color:                        @global-contrast-color;
@nav-navbar-header-color:                       @global-muted-color;
@nav-navbar-divider-border:                     @global-border;
@nav-navbar-nested-color:                       @global-link-color;
@nav-navbar-nested-hover-color:                 @global-link-hover-color;

@nav-offcanvas-color:                           @offcanvas-panel-link-color;
@nav-offcanvas-hover-background:                lighten(@offcanvas-bar-background, 5%);
@nav-offcanvas-hover-color:                     @offcanvas-panel-link-hover-color;
@nav-offcanvas-active-background:               darken(@offcanvas-bar-background, 10%);
@nav-offcanvas-active-color:                    @offcanvas-panel-link-hover-color;
@nav-offcanvas-header-color:                    @offcanvas-panel-color;
@nav-offcanvas-divider-border:                  darken(@offcanvas-bar-background, 10%);
@nav-offcanvas-nested-color:                    @offcanvas-panel-link-color;
@nav-offcanvas-nested-hover-color:              @offcanvas-panel-link-hover-color;


// Sub-object: `uk-nav-header`
// ========================================================================

.hook-nav-header() {}


// Sub-object: `uk-nav-divider`
// ========================================================================

.hook-nav-divider() {}


// Sub-object: `uk-nav-sub`
// ========================================================================

.hook-nav-sub() {}


// Modifier: `uk-nav-parent-icon`
// ========================================================================

.hook-nav-parent-icon() {}


// Modifier `uk-nav-side`
// ========================================================================

//
// Items
//

.hook-nav-side() {}

// Hover
.hook-nav-side-hover() {}

// Active
.hook-nav-side-active() {}

//
// Sub-object: `uk-nav-header`
//

.hook-nav-side-header() {}

//
// Sub-object: `uk-nav-divider`
//

.hook-nav-side-divider() {}


// Modifier `uk-nav-dropdown`
// ========================================================================

//
// Items
//

.hook-nav-dropdown() {
  padding: @nav-dropdown-padding-vertical @nav-dropdown-padding-horizontal;
  transition: all .1s ease-in-out;
}

// Hover
.hook-nav-dropdown-hover() {}

//
// Sub-object: `uk-nav-header`
//

.hook-nav-dropdown-header() {}

//
// Sub-object: `uk-nav-divider`
//

.hook-nav-dropdown-divider() {}


// Modifier `uk-nav-navbar`
// ========================================================================

//
// Items
//

.hook-nav-navbar() {}

// Hover
.hook-nav-navbar-hover() {}

//
// Sub-object: `uk-nav-header`
//

.hook-nav-navbar-header() {}

//
// Sub-object: `uk-nav-divider`
//

.hook-nav-navbar-divider() {}


// Modifier `uk-nav-offcanvas`
// ========================================================================

.hook-nav-offcanvas-link() {}

.hook-nav-offcanvas-link-hover() {}

//
// Items
//

.hook-nav-offcanvas() {}

// Hover
.hook-nav-offcanvas-hover() {}

// Active
.hook-nav-offcanvas-active() {}

//
// Sub-object: `uk-nav-header`
//

.hook-nav-offcanvas-header() {}

//
// Sub-object: `uk-nav-divider`
//

.hook-nav-offcanvas-divider() {}


// Miscellaneous
// ========================================================================

.hook-nav-misc() {
  ul.uk-nav {
    padding: 0;

    li {
      button {
        font-family: inherit;
        font-size: @dropdown-font-size;
        line-height: @dropdown-line-height;
        color: @nav-dropdown-color;
        padding: @nav-dropdown-padding-vertical @nav-dropdown-padding-horizontal;
        background: none;
        border: none;
        display: block;
        text-align: start;
        width: 100%;
        appearance: none;
        .hook-nav-dropdown;
      }
    }
  }

  .uk-nav-dropdown,
  .uk-modal .uk-nav {
    ul a {
      color: @nav-dropdown-color;
    }
  }

  .uk-nav li > .filter-item-wrapper > a {
    text-decoration: none;
  }

  // Overrides .views-table a:not(.uk-button) {} in colors.less
  .views-table .uk-nav-dropdown {
    & ul a,
    & > li > a,
    & > li > button,
    & > li > span > a {
      color: inherit;
    }
  }
}
