.editor-dragover {
  background: #ececec;
}

.editor-image-loading {
  animation: editor-image-loading 1s infinite ease-in-out;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px;
}
