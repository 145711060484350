@exposed-input-width: 190px;
@exposed-widget-margin: .5em;

.views-exposed-form {
  .views-exposed-widget {
    display: block;
    margin: 0;
  }

  .form-item {
    &.form-type-textfield,
    &.form-type-select {
      display: block;
      margin: 0;
      width: 100%;
    }
  }

  .form-type-checkbox {
    padding-top: .33em;
  }

  .ctools-auto-submit-full-form & {
    .form-type-textfield {
      max-width: @exposed-input-width;
      .input-search-icon;

      // Search field on courses page and creating combi-course page
      @media screen and (max-width: @breakpoint-small-max) {
        max-width: 100%;

        .mtx-filter-and-search-wrapper & {
          max-width: none;
          width: auto;
        }
      }
    }

    .form-type-textfield.form-item-field-event-date-value2,
    .mtx-date-field .form-type-textfield {
      width: @datepicker-date-input-width;

      // Removes search icon.
      &:before {
        content: unset;
      }
    }

    & .uk-container-center {
      > .form-type-textfield {
        max-width: none;
        width: 100%;
      }
    }
  }

  label {
    font-weight: @global-font-weight-semibold;
  }

  /* Tablet and bigger */
  @media (min-width: @breakpoint-medium) {
    .views-exposed-widget {
      float: left;
      padding: 0;
      margin: 0 0 0 @exposed-widget-margin;

      &.views-submit-button {
        margin: 0;
      }

      [dir=rtl] & {
        float: right;
        margin: 0 @exposed-widget-margin 0 0;
      }
    }

    .form-item,
    .form-submit {
      margin-top: 0;
      margin-bottom: 0;
    }

    .form-item {
      &.form-type-textfield,
      &.form-type-select {
        width: @exposed-input-width;
      }
    }
  }
}

.views-exposed-widgets {
  margin-bottom: @exposed-widget-margin;
}

/* table style column align */
.views-align {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }
}

/* Remove the border on tbody that system puts in */
.views-view-grid tbody {
  border-top: none;
}

.view .progress-disabled {
  //float: none;
}
