.nei-widget-copyrighted {
  .nei-container.has-overlay & {
    .widget-caption[tabindex] {
      outline: 0 none;
    }

    .caption-text,
    .headline-text,
    .text-widget-content {
      cursor: default;

      a {
        cursor: pointer;
      }
    }
  }

  .nei-widget-controls {
    .mtx-copyright-sign {
      width: @nei-controls-size;
      height: @nei-controls-size;
      line-height: @nei-controls-size;
      text-align: center;
      background: @global-muted-color;
      color: #ffffff;
      border-radius: @global-border-radius-circle;

      &:before {
        font-size: 1.2rem;
      }
    }
  }
}
