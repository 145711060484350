.mtx-activity-promote-invitations-page-header {
  .page-title {
    margin-bottom: calc(2.5rem - @mtx-section-padding);
  }
}

.mtx-activity-promote-invitations-list {
  .mtx-course-el {
    &,
    .course-details {
      height: 100%;
    }

    .course-details {
      display: flex;
      flex-direction: column;
    }

    .activity-info {
      margin-top: auto;
      padding-top: @global-margin;
    }

    &.invite-inactive {
      .activity-info .invite-deadline {
        color: @global-muted-color;
      }
    }
  }
}

.mtx-activity-promote-invitees-section {
  .bulk-operations + & {
    margin-top: @global-margin;
  }

  .bulk-operations[style*="none"] + & {
    margin-top: 0;
  }
}

.mtx-instance-dcf-contacts-form {
  .contacts-search {
    .input-search-icon;
  }

  .contacts-filters-wrapper,
  .contacts-selected-wrapper {
    border-radius: @global-border-radius-xlarge;
    padding: @global-margin-large;
    background-color: @_grey_lighten_color;
  }

  .contacts-results-wrapper {
    word-break: break-word;

    & .mtx-custom-scrollbar {
      min-height: 100px;
      max-height: calc(100vh - 21rem);
    }

    .row {
      display: table;
      width: 100%;
      padding: @global-margin 0;

      & > * {
        vertical-align: middle;
      }

      label {
        cursor: pointer;
      }
    }

    .cell {
      display: table-cell;
      padding: 0 @table-cell-padding;

      &.contact-info-cell {
        width: 100%;
      }

      &:first-child {
        padding-inline-start: 0;
      }

      &:last-child {
        padding-inline-end: 0;
      }

      &.checkbox-cell {
        padding-inline-end: @global-margin-medium - @table-cell-padding;
      }
    }
  }

  .contacts-selected-wrapper {
    word-break: break-word;
    min-height: 50vh;
    height: calc(100vh - 14.5rem);

    & .mtx-custom-scrollbar {
      max-height: calc(100vh - 22rem);
    }
  }
}
