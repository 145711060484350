body.page-courses-scorm {
  width: 100%;
}

.scorm-page {
  .page-content {
    background: @global-base-color;
    margin: 0 auto;

    #sco-object {
      display: block;
    }

    #sco-nav-wrapper {
      padding: 20px 0;
    }

    #sco-iframe {
      width: 100%;
    }
  }
}

// Navigation in course with H5P SCORM/xAPI bubbles
#sco-wrapper #sco-nav-wrapper {
  overflow: hidden;
  display: none;
}

// Course scorm bubble page.
.touch,
.uk-touch {
  .mtx-content-section > #sco-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
}
