.mtx-account-2fa {
  @media all and (min-width: @breakpoint-medium) {
    .qr-code-wrapper {
      .clearfix;
    }

    .qr-code-wrapper .qr-code {
      margin-inline-end: @global-margin-xlarge;

      [dir=ltr] & {
        float: left;
      }

      [dir=rtl] & {
        float: right;
      }
    }
  }
}
