@vertical-tab-list-width: 210px;
@vertical-tab-list-small-width: 140px;
@vertical-tab-border-width: @tab-border-width;
@vertical-tab-active-border: @tab-active-border;
@vertical-tab-hover-background: @tab-hover-background;

.vertical-tabs {
  input {
    width: 100%;
  }

  &-list {
    .reset-list;
    font-size: unit(@global-font-size-small, rem);
    width: @vertical-tab-list-width;
    border: 0 none;

    li {
      margin-top: @global-margin-small;

      a {
        display: block;
        border-radius: @global-border-radius;
        background: none;
        text-decoration: none;
        color: inherit;
        padding: @tab-padding-top @tab-padding-horizontal @tab-padding-bottom @tab-padding-horizontal;

        &:hover,
        &:focus {
          background: @vertical-tab-hover-background;

          .mtx-dark-theme & {
            background: @overlay-color-light;
          }
        }
      }

      &:focus {
        outline: none;
      }

      &.selected a {
        border: 0 none;
        border-radius: @global-border-radius;
        background: @vertical-tab-hover-background;

        .mtx-dark-theme & {
          background: @overlay-color-light;
        }
      }

      .summary {
        display: block;
        line-height: normal;
        margin-bottom: 0;
      }

      strong {
        font-weight: @global-font-weight-normal;
      }

      #active-vertical-tab {
        display: none;
      }
    }

  }

  &-panes {
    label {
      display: inline;
    }

    fieldset {
      background: none;
      margin-bottom: @global-margin;
      padding: 0;
      position: relative;
    }

    & > fieldset > legend {
      html.js & {
        display: none;
      }
    }

    div + .group-title {
      margin-top: @global-margin-xlarge;
    }
  }


  [dir=ltr] & {
    margin: @global-margin-medium 0 @global-margin-medium @vertical-tab-list-width;

    &-list {
      float: left;
      margin-left: -@vertical-tab-list-width;
    }

    &-panes {
      padding: @global-margin 0 @global-margin @global-margin-large * 2;
    }
  }

  [dir=rtl] & {
    margin: @global-margin-medium @vertical-tab-list-width @global-margin-medium 0;

    &-list {
      float: right;
      margin-right: -@vertical-tab-list-width;
    }

    &-panes {
      padding: @global-margin @global-margin-large * 2 @global-margin 0;
    }
  }

  &-pane {
    margin: 0 !important;

    legend {
      display: none;
    }

    .fieldset-wrapper {
      border: none;
      padding: 0;
    }

    fieldset {
      legend {
        display: block;
        background: @global-fieldset-color;
        color: @global-text-color;
        border: @vertical-tab-border-width solid @global-border-color;
        padding: 0;
        border-radius: 0;
        .clearfix();

        &:after {
          border: none;
        }
      }

      .fieldset-wrapper {
        border: @vertical-tab-border-width solid @global-border-color;
        margin-top: -@vertical-tab-border-width !important;
        padding: @global-margin;
      }
    }

    .collapsed {
      height: auto;

      .fieldset-legend .fieldset-title:after {
        [dir=ltr] & {
          content: '\f104';
        }

        [dir=rtl] & {
          content: '\f105';
        }
      }
    }

    .fieldset-legend {
      font-size: @global-font-size;
      font-weight: @global-font-weight-semibold;
      padding: 0 @global-margin;

      .summary {
        display: none;
      }

      .fieldset-title {
        font-size: @global-font-size-small;
        color: @global-link-color;
        display: block;
        padding: 0;

        &:after {
          content: '\f107';
          color: @global-link-color;
          display: inline-block;
          float: right;
          font-family: FontAwesome;
          font-size: @global-font-size-small;
          font-style: normal;

          [dir=rtl] & {
            float: left;
          }
        }
      }

      @media screen and (max-width: @breakpoint-mini-max) {
        line-height: @global-line-height;
      }
    }
  }
}

// Styles for create/edit H5P object/Quiz/Variables page (options block), only for screen less than 479px
@media screen and (max-width: @breakpoint-mini-max) {
  #h5p-content-node-form,
  #variable-realm-edit-variables-form {
    .vertical-tabs {
      margin-left: @vertical-tab-list-small-width;

      &-list {
        width: @vertical-tab-list-small-width;
        margin-left: -@vertical-tab-list-small-width;
      }

      [dir=rtl] & {
        margin-left: 0;
        margin-right: @vertical-tab-list-small-width;

        &-list {
          margin-left: 0;
          margin-right: -@vertical-tab-list-small-width;
        }
      }
    }
  }
}
