#organization-reminder {
  .mtx-list-item {
    border-radius: @global-border-radius;
    padding: @global-margin;
    background-color: @_white_color;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: @global-margin;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }

    &:before,
    &:after {
      content: none;
    }
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .content {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .organization-reminder-close {
    .material-icon(1.125rem);
    width: 1rem;
  }

  @media all and (min-width: @breakpoint-medium) {
    .content {
      grid-template-columns: 1fr auto;
    }
  }
  @media all and (min-width: @breakpoint-large-max) {
    .content-wrapper {
      align-items: center;
    }

    .button-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
