//
// Component: Datepicker
//
// ========================================================================


// Variables
// ========================================================================

@datepicker-nav-height:                         1.25rem;
@datepicker-nav-margin-bottom:                  @global-margin-small;
@datepicker-nav-color:                          @global-color;
@datepicker-nav-hover-color:                    @global-color;

@datepicker-table-color:                        @global-color;

@datepicker-table-hover-color:                  @global-color;
@datepicker-table-onclick-color:                @global-color;

@datepicker-table-active-background:            @global-link-color;
@datepicker-table-active-color:                 @global-contrast-color;

@datepicker-table-muted-color:                  @global-muted-color;

@datepicker-previous-icon:                     '\e5cb'; // md-chevron_left
@datepicker-next-icon:                         '\e5cc'; // md-chevron_right

// Component
// ========================================================================

.hook-datepicker-nav() {}

.hook-datepicker-nav-item() {}

.hook-datepicker-table-item() {
  border-radius: @global-border-radius;

  &.uk-datepicker-date-disabled {
    cursor: default;
  }
}

.hook-datepicker-table-item-hover() {}

.hook-datepicker-table-item-onclick() {}

.hook-datepicker-table-item-active() {}


// Miscellaneous
// ========================================================================

.hook-datepicker-misc() {
  .uk-datepicker-table {
    margin-bottom: 0;
  }

  input[data-uk-datepicker],
  input[data-uk-timepicker] {
    font-family: inherit;
    font-size: @form-font-size;

    // Overwrite styles from .input-readonly().
    &.form-text[readonly] {
      cursor: pointer;

      &:focus,
      &:active {
        border-color: @form-focus-border;
        cursor: pointer;
      }

      &[disabled],
      &.disabled {
        &,
        &:focus,
        &:active {
          border-color: @form-disabled-border;
          cursor: not-allowed;
        }
      }

      .form-danger & {
        border-color: @form-danger-border;
      }
    }
  }

  input[data-uk-datepicker] {
    &:extend(.input-date-icon-background-image all);
  }

  input[data-uk-timepicker][type="text"] {
    width: @datepicker-time-input-width;
    text-align: center;

    & + .uk-dropdown {
      text-align: center;
      margin-top: @dropdown-margin-top;
    }
  }

  // Add: `uk-datepicker-element`
  input.uk-datepicker-element {
    &:extend(.input-date-icon-background-image all);
  }

  // Add: `uk-timepicker-element`
  input.uk-timepicker-element {
    width: @datepicker-time-input-width;
    text-align: center;
  }

  .uk-datepicker-previous:after,
  .uk-datepicker-next:after {
    font-size: @md-icon-size;
    font-family: 'Material Symbols';
    vertical-align: middle;
  }
}
