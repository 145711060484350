.mtx-statistics-progress {
  @progress-values-line-height: unit(@global-line-height, rem);
  @progress-legend-line-height: 1.3;
  @progress-legend-square-size: 1rem;

  margin-top: @global-margin-medium * 2;

  // Label.
  .label {
    font-size: @global-font-size;
    margin-bottom: 0;
  }

  // Progress.
  .progress {
    position: relative;
    height: 2rem;
    background-color: @_grey_lighten_color;
    overflow: hidden;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }

    .item {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;

      &.average {
        background-color: @statistics-average-color;
      }
    }

    &,
    & .item {
      border-radius: @progress-border-radius;
      box-shadow: inset 0 0 0 1px @form-focus-border;
    }
  }

  // Legend.
  .legend {
    .item {
      display: inline-block;
      margin-top: @global-margin-medium;
      line-height: @progress-legend-line-height;

      &:before {
        content: '';
        display: inline-block;
        width: @progress-legend-square-size;
        height: @progress-legend-square-size;
        border-radius: calc(@progress-legend-square-size / @progress-border-radius-ratio);
        margin-bottom: -(@progress-legend-line-height - @progress-legend-square-size) / 2;
      }

      &.average:before {
        background-color: @statistics-average-color;
      }

      [dir=ltr] & {
        margin-right: @global-margin-xlarge;

        &:before {
          margin-right: @global-margin;
        }
      }

      [dir=rtl] & {
        margin-left: @global-margin-xlarge;

        &:before {
          margin-left: @global-margin;
        }
      }
    }
  }

  &-wrapper {
    [dir=ltr] & {
      padding-right: @global-margin * 3;
    }

    [dir=rtl] & {
      padding-left: @global-margin * 3;
    }
  }
}
