.mtx-api-two-columns-list {
  width: 100%; // This should be here!

  &-item {
    background-color: @_grey_lighten_color;
    border-radius: @global-border-radius;
    padding: @global-margin-medium;

    &.default {
      background-color: @_grey_highlight_color;
    }

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;

      &.default {
        background-color: @overlay-color-light;
      }
    }

    & + & {
      margin-top: @global-margin;
    }

    .title {
      font-weight: @global-font-weight-bold;
      font-size: @global-font-size;
      line-height: @global-line-height;
      margin: 0;
      .break-word;

      .icon {
        color: @_grey_dark_color;
        vertical-align: text-bottom;

        [dir=ltr] & {
          margin-right: @global-margin-small;
        }

        [dir=rtl] & {
          margin-left: @global-margin-small;
        }
      }

      .mark {
        color: @_grey_color;
        text-transform: uppercase;
        font-size: .75em;
        line-height: 2;
        margin: 0 10px;
      }

      .info {
        text-transform: uppercase;
        font-weight: normal;
        margin: 0 10px;
      }
    }
  }

  // Columns styles
  html:not(.modern-edge) & {
    @media all and (min-width: @breakpoint-medium) {
      &.columns {
        columns: 2;
        column-gap: @global-margin;

        .mtx-api-two-columns-list-item {
          break-inside: avoid;
          display: table;
          width: 100%;
        }
      }
    }
  }
}
