@social-icons-list: facebook-square, twitter-square, linkedin-square, dropbox;
@social-map-marker-color: #ee0000;
@social-colors-list: #3b5998, #5aacee, #0077b5, #0060ff, @social-map-marker-color;

.set-social-icons-colors() {
  .style(@i: 1) when (@i < (length(@social-icons-list) + 1)) {
    @icon: extract(@social-icons-list, @i);
    @color: extract(@social-colors-list, @i);

    &.fa-@{icon} {
      color: @color;
    }

    .style(@i + 1);
  }
  .style();
}

// Custom SVG icons
@google-active-icon: url('@{global-images-path}social/google-active-icon.svg');
@google-inactive-icon: url('@{global-images-path}social/google-inactive-icon.svg');
@windows-active-icon: url('@{global-images-path}social/windows-active-icon.svg');
@windows-inactive-icon: url('@{global-images-path}social/windows-inactive-icon.svg');

.mtx-social-icon(@icon-size: 32px, @bg-size: 86.5%) {
  .square(@icon-size);
  min-width: @icon-size;

  .active-state() {
    i {
      font-size: @icon-size;
      line-height: @icon-size;
      .square(@icon-size);
      .set-social-icons-colors();
    }

    // Custom SVG icons
    i.fa-google-plus-square:before,
    i.fa-windows:before {
      content: '';
      .square(@icon-size);
    }

    i.fa-google-plus-square:before {
      background: @google-active-icon center center no-repeat;
      background-size: @bg-size;
    }

    i.fa-windows:before {
      background: @windows-active-icon center center no-repeat;
      background-size: @bg-size;
    }

    .md-location_on {
      color: @social-map-marker-color;
    }
  }

  &.default-icon {
    @default-icon-size: 28px;

    border-radius: @global-border-radius;
    padding: @global-margin;
    color: @_white_color;
    background-color: @secondary_color;

    .fa {
      font-size: @default-icon-size;
      line-height: @default-icon-size;
      .square(@default-icon-size);

      [dir=ltr] & {
        margin: -3px 0 0 -3px;
      }

      [dir=rtl] & {
        margin: -3px -3px 0 0;
      }
    }
  }

  &:not(.default-icon) {
    &.inactive {
      i.fa {
        color: @_grey_dark_color;
      }

      // Custom SVG icons
      i.fa-google-plus-square:before {
        background: @google-inactive-icon center center no-repeat;
        background-size: @bg-size;
      }

      i.fa-windows:before {
        background: @windows-inactive-icon center center no-repeat;
        background-size: @bg-size;
      }
    }

    & {
      .active-state();
    }

    &:not(.icon):hover {
      .active-state();
    }
  }
}

.mtx-social-links {
  display: flex;
  align-items: center;

  li {
    margin-right: @global-margin-medium;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: @global-margin-medium;
    }

    &:last-child {
      margin: 0;
    }
  }

  a {
    .mtx-social-icon();
    text-decoration: none !important;

    i.fa {
      text-align: center;
    }
  }
}
