// Platform style.css
.left {
  float: left;
}

.right {
  float: right;
}

// Force hide js elements
html.js .js-hide {
  display: none !important;
}

/* 2014-11-09 BNY: Why is tr.draggable hidden here? Removed */
/* 2014-19-10 VG: It's looks like a Quick'n'Dirty "fix" for multiple fields
                  (like field_material) displayed in quick edit. We need to get
                   rid of that in future. Uncommented back for now. */
tr.draggable td.field-multiple-drag,
input.field-add-more-submit,
.tabledrag-toggle-weight-wrapper,
.filter-wrapper.form-wrapper {
  display: none !important;
}

// @todo: Don't know if we need this.
.page-node-edit .field-name-field-overviews-form {
  display: table-row !important;

  tr.draggable.even td {
    margin: 0;
    padding: 0;
  }
}

.field-type-number-integer.field-name-reset-timestamp.field-widget-number.form-wrapper {
  display: none;
}

div.fieldset-wrapper.with-legend {
  margin-top: 0 !important;
}

.mtx-popup-overlay {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
}
