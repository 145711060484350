.mtx-notifications {
  @badge-size: .75rem;
  @badge-line-height: 1.5;

  & > a.mtx-handler {
    .page-header-button();
  }

  .uk-badge {
    position: absolute;
    top: (@page-header-button-icon-size - @md-icon-size) / 2 - (@badge-size * @badge-line-height) + (@md-icon-size * .25);
    border-radius: .75em;
    font-size: @badge-size;
    font-weight: @global-font-weight-semibold;
    line-height: @badge-line-height;
    min-width: unit(@badge-line-height, em);
    padding: 0;
    text-align: center;

    [dir=ltr] & {
      left: 52%;
    }

    [dir=rtl] & {
      right: 50%;
    }
  }

  .uk-dropdown {
    width: 400px;

    li + li {
      margin-top: @global-margin;
    }
  }

  .mtx-notification {
    display: block;
    color: @global-text-color;
    padding: @global-margin;

    &:hover,
    &:focus,
    &:active {
      color: @global-text-color !important;
      background-color: @page_background_color !important;

      .user-name,
      .reminder-text {
        text-decoration: @base-link-hover-text-decoration;
      }
    }

    .user-avatar-wrapper {
      position: relative;
      width: @global-size-medium;
      margin: .25em 0;

      [dir=ltr] & {
        float: left;
        margin-right: @global-margin;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @global-margin;
      }

      img {
        width: 100%;
      }
    }

    &-reminder-icon {
      .circle(@global-size-medium);
      padding: @global-margin;
      text-align: center;
      font-size: 2em;
      background-color: darken(@page_background_color, 5%);
      color: @global-muted-color;
    }

    &.not-seen {
      @not-seen-bullet-size: 12px;

      .user-avatar-wrapper:before {
        content: '';
        display: block;
        position: absolute;
        left: 80%;
        bottom: 77%;
        width: @not-seen-bullet-size;
        height: @not-seen-bullet-size;
        border-radius: @not-seen-bullet-size / 2;
        background-color: @global-danger-background;
        box-shadow: 0 0 0 2px #ffffff;
      }
    }

    &.has-date-widget {
      .event-title {
        .text-ellipsis;
      }

      .event-date {
        margin-top: .125rem;
        font-size: .6875rem;
        color: darken(@global-muted-color, 26.5%);
      }

      .text-wrapper,
      .date-wrapper {
        margin-top: @global-margin-small;
      }
    }

    .content-holder {
      overflow: hidden;
    }

    .meta-wrapper,
    .date-wrapper {
      color: @global-muted-color;
      font-size: .75rem;
      line-height: 1.5;
      .text-ellipsis;
    }

    .text-wrapper {
      font-size: .8125rem;
      line-height: 1.5;
      margin: .125rem 0;
      .break-word;
    }

    .user-name,
    .reminder-text {
      color: @global-text-color;
      text-decoration: @base-link-text-decoration;
    }
  }

  .mtx-custom-scrollbar {
    max-height: 400px;
  }

  .spinner {
    margin: @global-margin;
    width: @md-icon-size;
    height: @md-icon-size;
  }

  .empty-message {
    padding: @global-margin-large @global-margin;
    text-align: center;
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .uk-dropdown {
      width: calc(100vw - 2 * @utility-container-padding-horizontal);
    }
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .uk-badge {
      top: (@page-header-button-icon-large-size - @md-icon-size) / 2 - (@badge-size * @badge-line-height) + (@md-icon-size * .25);
    }
  }
}
