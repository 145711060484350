//
// Component: Notify
//
// ========================================================================


// Variables
// ========================================================================

@notify-width:                                  550px;
@notify-z-index:                                (@global-z-index + 40) * 10;

@notify-message-border-radius:                  @global-border-radius;
@notify-message-padding-y:                      1.25rem;
@notify-message-padding-x:                      @global-margin-large;
@notify-message-font-size:                      @global-font-size;
@notify-message-line-height:                    @global-line-height;

@notify-message-success-background:             @global-text-color;
@notify-message-success-color:                  @_white_color;
@notify-message-success-dark-mode-background:   @_white_color;
@notify-message-success-dark-mode-color:        @global-text-color;

@notify-message-warning-background:             #ffab00;
@notify-message-warning-color:                  @global-text-color;

@notify-message-danger-background:              #de350b;
@notify-message-danger-color:                   @_white_color;

@notify-message-box-shadow:                     0 4px 12px fade(#000000, 12%);


// hu_site.messages.less is related to this file.


// Component
// ========================================================================

.hook-notify() {
  top: @global-margin;
  max-width: 90%;
  word-wrap: break-word;
  word-break: break-word;

  li + li {
    margin-top: @global-margin-medium;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }

    &.uk-close {
      text-decoration: none;
     .button-border-radius(@md-icon-size);
    }

    &:focus-visible {
      opacity: 1;
      outline: none !important;
      .focus-state-box-shadow(@inverted: true);
    }
  }
}

.hook-notify-message() {
  border-radius: @notify-message-border-radius;
  padding: @notify-message-padding-y @notify-message-padding-x;
  box-shadow: @notify-message-box-shadow;
}

.hook-notify-message-primary() {}

.hook-notify-message-success() {
  .mtx-dark-theme & {
    background-color: @notify-message-success-dark-mode-background;
    color: @notify-message-success-dark-mode-color;
  }
}

.hook-notify-message-warning() {}

.hook-notify-message-danger() {}


// Miscellaneous
// ========================================================================

.hook-notify-misc() {
  .uk-notify-top-center,
  .uk-notify-bottom-center {
    max-height: 96vh;
    overflow-y: auto;
  }

  .uk-notify-message > .uk-close {
    width: @md-icon-size;
    line-height: 1;
    visibility: visible;
    opacity: .7;

    &:after {
      .material-icon(@md-icon-size);
    }

    &:focus {
      outline: revert;
    }

    .uk-touch &,
    &:hover,
    &:focus {
      opacity: 1;
    }

    [dir=rtl] & {
      float: left;
    }
  }
}
