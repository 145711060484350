.mtx-report-chart {
  @filters-wrapper-padding-vertical: @global-margin-medium * 2; // @mtx-section-padding

  &-line-chart {
    position: relative;
    margin: @mtx-section-padding 0 @global-margin-large;
  }

  &-section {
    .mtx-section-header [class*="uk-align"] {
      margin-bottom: 0;
    }

    form {
      .clearfix();
    }
  }

  &-filters-wrapper {
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  &-filter {
    display: block;
    color: inherit !important;
    user-select: none;
    text-decoration: none;
    .clearfix;

    .value {
      font-weight: @global-font-weight-bold;
    }

    .description {
      line-height: 1.4;
      font-size: @global-font-size-small;
      color: @_grey_dark_color;
    }

    .legend {
      background-color: @_grey_lighten_color;
      transition: background-color .15s ease-in-out;
    }

    &:hover,
    &:focus {
      .legend {
        background-color: @_grey_light_color;
      }
    }

    &.active {
      .legend {
        background-color: @primary_color;
      }
    }
  }

  @media all and (min-width: @breakpoint-small) {
    @filter-margin-vertical: @global-margin-small;
    @filter-padding-vertical: @global-margin-small;

    &-section {
      .mtx-section-title {
        margin-bottom: @global-margin;
      }

      form {
        margin-bottom: @global-margin-small / 2;
      }

      .mtx-report-dates-filter {
        [dir=ltr] & {
          margin-right: @global-margin-large;
        }

        [dir=rtl] & {
          margin-left: @global-margin-large;
        }
      }
    }

    &-filters-wrapper {
      text-align: center;
      padding: (@filters-wrapper-padding-vertical - @filter-margin-vertical - @filter-padding-vertical) 0;
    }

    &-filter {
      display: inline-block;
      margin: @filter-margin-vertical 1%;
      padding: @filter-padding-vertical (@global-margin-small / 2);
      min-height: unit(157 / 16, rem);
      min-width: 8.125rem; // 130px
      vertical-align: top;

      & > div + div {
        margin-top: @global-margin-small;

        &.legend {
          margin-top: @global-margin;
        }
      }

      .label {
        line-height: 1.375;
      }

      .value {
        line-height: 1.2;
        font-size: 2.5rem;
      }

      .legend {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        border-radius: @global-border-radius;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    @filter-padding-vertical: @global-margin;
    @filter-legend-size: @global-size-mini / 2; // 12px;

    &-section {
      form {
        margin-bottom: @global-margin;
      }
    }

    &-filters-wrapper {
      padding: (@filters-wrapper-padding-vertical - @filter-padding-vertical) 0;
    }

    &-filter {
      position: relative;
      padding: @filter-padding-vertical 0;

      .label {
        font-weight: @global-font-weight-semibold;
      }

      .value {
        font-size: 1.5rem;
        line-height: 1;
      }

      .description {
        clear: both;
      }

      .legend {
        width: @filter-legend-size;
        position: absolute;
        top: calc(@filter-padding-vertical + 1px);
        bottom: calc(@filter-padding-vertical + 2px);
        border-radius: @global-border-radius;
      }

      [dir=ltr] & {
        padding-left: @filter-legend-size + @global-margin-medium;

        .legend {
          left: 0;
        }

        .label {
          float: left;
        }

        .value {
          float: right;
        }
      }

      [dir=rtl] & {
        padding-right: @filter-legend-size + @global-margin-medium;

        .legend {
          right: 0;
        }

        .label {
          float: right;
        }

        .value {
          float:left;
        }
      }
    }

    &-links.mtx-button-group > * {
      width: 100%;
    }
  }
}

.mtx-report-dates-filter {
  .form-item input {
    [dir=ltr] & {
      margin-right: 0;
    }

    [dir=rtl] & {
      margin-left: 0;
    }
  }
}
