.widget-settings-form {
  .file-info {
    display: inline-flex;
    white-space: nowrap;
    padding: 0 @global-margin;
    font-size: @global-font-size-small;
    height: @global-height-small;
    line-height: @global-height-small;
    border-radius: @button-border-radius-small;
    background-color: @_grey_lighten_color;
    max-width: 100%;

    .file-title {
      display: inline-flex;
      min-width: 0;
    }

    .file-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-size {
      opacity: .48;
    }


    .file-size,
    .file-name {
      margin-inline: @global-margin-mini;
    }

    #delete-file-button {
      color: @_grey_dark_color;
      transition: color .15s ease-in-out;

      &:hover,
      &:focus {
        color: @_grey_darken_color;

        .mtx-dark-theme & {
          color: lighten(@_grey_dark_color, 10%);
        }
      }

      .md-icon {
        font-size: @md-icon-size-small;
        line-height: @global-height-small;
        vertical-align: baseline;
      }
    }
  }

  .form-custom-size {
    @suffix-size: @form-height;

    .form-type-textfield {
      position: relative;

      input {
        padding-inline-end: @suffix-size;
      }

      &:after {
        content: 'px';
        position: absolute;
        bottom: @form-padding-y;
        inset-inline-end: 0;
        opacity: .48;
        text-align: center;
        width: @suffix-size;
      }
    }
  }

  .mtx-spectrum {
    .form-type-textfield {
      position: relative;
    }

    .form-text {
      display: block !important;
      padding-inline-end: calc(1.5rem + @form-padding-x + @global-margin-mini); // @spectrum-input-padding-x
    }

    .sp-replacer {
      top: auto;
      bottom: @form-padding-y;
    }

    .sp-container {
      top: calc(100% + @dropdown-margin-top) !important;
      inset-inline-start: auto !important;
      inset-inline-end: 0 !important;
    }

    .color-field-max-width {
      width: 100%;
    }
  }
}
