// Top banner styles
#mtx-top-banner {
  .banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: height .2s linear;

    &.banner-empty {
      height: 160px;
      background-color: @_grey_light_color;
    }

    &.banner-height-fixed img {
      height: 100%;
    }

    &.banner-fill img {
      width: 100%;
      height: auto;
    }
  }
}
