[data-show-more] {
  overflow: hidden;
}

.show-more-link {
  a {
    display: inline-block;
  }
}

.show-more-content-hidden {
  max-height: 0;
}

.table-show-more-link-icon {
  a {
    width: 1.25rem;
    height: 1.25rem;
  }

  .md-icon {
    font-size: 1.25rem;
  }
}
