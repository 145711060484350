.nei-section {
  & + &:empty ~ &,
  & + & {
    margin-top: @grid-gutter-vertical;

    @media (min-width: @breakpoint-xlarge) {
      margin-top: @grid-gutter-large-vertical;
    }
  }

  &:empty,
  &:empty + & {
    margin-top: 0;
  }
}

.ui-sortable-placeholder {
  background: @page_content_background_color;
  border: 1px dashed @primary_color !important;
  visibility: visible !important;
  box-sizing: content-box;
  transition: all .3s linear;
}
