.mtx-explore-trainings-section {
  .mtx-section-title {
    margin-bottom: @global-margin-medium;
  }
}

.mtx-explore-trainings-wrapper {
  margin-top: @global-margin-medium;

  .mtx-load-more-button {
    margin-bottom: @mtx-section-padding;
  }
}

.mtx-explore-trainings-filters {
  @close-icon-size: 1.25rem;

  .store-search {
    .form-item {
      margin: 0;
    }

    & + .form-actions {
      margin: 0;
    }

    &.field-is-active .store-tags-toggler {
      display: none;
    }
  }

  .store-tags {
    .reset-list;

    & > * {
      margin-top: @global-margin;
      margin-inline-end: @global-margin;
    }

    .uk-button {
      position: relative;
      padding: @global-margin-medium;
      min-width: @global-margin-medium * 2 + @md-icon-size;

      & > i:first-child + .name {
        margin-inline-start: @md-icon-size + @global-margin;
      }
    }

    .uk-button > i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
    }

    i.md-close {
      display: none;
    }

    .uk-button[data-type] {
      padding-inline-end: @global-margin-medium + @md-icon-size + @global-margin-small;

      i.md-close {
        display: block;
      }
    }

    [dir=ltr] & {
      & > * {
        float: left;
      }

      .uk-button {
        & > i:first-child {
          left: @global-margin-medium;
        }
      }

      i.md-close {
        right: @global-margin-medium;
      }
    }

    [dir=rtl] & {
      & > * {
        float: right;
      }

      .uk-button {
        & > i:first-child {
          right: @global-margin-medium;
        }
      }

      i.md-close {
        left: @global-margin-medium;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .store-tags {
      .parent-tag {
        @toggler-size: @global-size-medium;

        position: relative;

        [data-uk-dropdown] {
          position: absolute;
          top: 0;
          bottom: 0;

          & > .uk-button {
            display: block;
            height: 100%;
            width: @toggler-size;
            min-width: @toggler-size;
            padding: 0;
            text-align: center;

            &:after {
              content: '\e5c5'; // md-arrow_drop_down
            }
          }

          &.uk-open {
            & > .uk-button:after {
              content: '\e5c7'; // md-arrow_drop_up
            }
          }
        }

        &.selected {
          [data-uk-dropdown] > .uk-button:after {
            color: inherit !important;
          }
        }

        & > .uk-button {
          margin-inline-end: @toggler-size;
        }

        [dir=ltr] & {
          & > .uk-button {
            border-radius: @button-border-radius 0 0 @button-border-radius;
          }

          [data-uk-dropdown] {
            right: 0;

            & > .uk-button {
              border-radius: 0 @button-border-radius @button-border-radius 0;

              &:after {
                right: (@toggler-size - @md-icon-size) / 2;
              }
            }
          }
        }

        [dir=rtl] & {
          & > .uk-button {
            border-radius: 0 @button-border-radius @button-border-radius 0;
          }

          [data-uk-dropdown] {
            left: 0;

            & > .uk-button {
              border-radius: @button-border-radius 0 0 @button-border-radius;

              &:after {
                left: (@toggler-size - @md-icon-size) / 2;
              }
            }
          }
        }
      }
    }

    .store-tags-toggler {
      display: none;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .store-search {
      .form-item {
        overflow: hidden;
      }
    }

    .store-tags-toggler {
      height: @button-height;
      width: @button-height;
      min-width: @button-height;
      border-radius: @button-border-radius;
      text-align: center;

      .md-icon {
        line-height: @button-height;
      }
    }

    .store-tags {
      .tag.selected {
        i.md-close {
          font-size: @close-icon-size;
        }
      }

      .uk-button {
        @padding-y: @global-margin-small;

        min-height: auto;
        padding-top: @padding-y;
        padding-bottom: @padding-y;
        .button-border-radius((@padding-y * 2) + (@global-line-height * @global-font-size));

        .md-icon:first-child {
          display: none;

          & + .name {
            margin: 0 !important;
          }
        }

        &.filter-toggler .md-icon {
          display: block;
        }
      }

      .parent-tag {
        [data-uk-dropdown] {
          display: none;
        }
      }
    }

    .store-tags.collapsed {
      .tag.selected {
        i.md-close {
          display: block;
        }
      }

      .tag:not(.selected) {
        display: none;
        margin: 0;
      }
    }

    .store-tags .uk-button[data-type],
    .store-tags.collapsed .tag.selected .uk-button {
      padding-inline-end: @global-margin-medium + @close-icon-size + @global-margin-small;
    }
  }
}

.mtx-explore-trainings-timeline-wrapper {
  background-color: @_white_color;
  padding: @global-margin-medium 0 @mtx-section-padding;

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }
}

.catalog-timeline-course-item {
  .image-holder {
    width: 5rem;

    img {
      border-radius: @thumb-border-radius;
    }

    [dir=ltr] & {
      float: left;
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      float: right;
      margin-left: @global-margin;
    }

    @media (max-width: @breakpoint-small-max) {
      display: none;
    }
  }

  .content-wrapper {
    overflow: hidden;
  }

  .title {
    font-size: @global-font-size-small;
    font-weight: @global-font-weight-normal;
    margin-bottom: 0;
    .text-ellipsis-box(2);
  }
}
