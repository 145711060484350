// Name:            Upload
// Description:     Provides a dragover state for uploads
//
// Component:       `uk-upload`
//
// States:          `uk-dragover`
//
// ========================================================================


// Variables
// ========================================================================

@uk-upload-dragover-box-shadow:                 rgba(100,100,100,0.3);
@uk-upload-dragover-box-shadow-blur:            20px;


/* ========================================================================
   Component: Upload
 ========================================================================== */

/*
 * Create a box-shadow when dragging a file over the upload area
 */

.uk-dragover { box-shadow: 0 0 @uk-upload-dragover-box-shadow-blur @uk-upload-dragover-box-shadow; }


// Hooks
// ========================================================================

.hook-upload-misc;

.hook-upload-misc() {}