.mtx-user-biography-modal {
  @avatar-size: 17vw;
  @avatar-max-size: 240px;

  padding: (@global-margin-medium * 2) 0;

  &-avatar {
    .circle(@avatar-size);
    min-width: @avatar-size;
    max-width: @avatar-max-size;
  }

  &-info {
    .uk-h2 {
      margin-bottom: @mtx-section-title-margin-bottom;
    }
  }

  &-info-block {
    padding: @mtx-section-padding 0;
  }

  @media (min-width: @breakpoint-medium) {
    &-avatar {
      [dir=ltr] & {
        margin-right: @utility-container-padding-horizontal;
      }

      [dir=rtl] & {
        margin-left: @utility-container-padding-horizontal;
      }
    }
  }

  @media (min-width: @breakpoint-xlarge) {
    &-avatar {
      [dir=ltr] & {
        margin-right: @utility-container-large-padding-horizontal;
      }

      [dir=rtl] & {
        margin-left: @utility-container-large-padding-horizontal;
      }
    }
  }

  @media (min-width: @breakpoint-xxlarge) {
    &-avatar {
      .circle(@avatar-max-size);
      min-width: @avatar-max-size;

      [dir=ltr] & {
        margin-right: @avatar-max-size / 2;
      }

      [dir=rtl] & {
        margin-left: @avatar-max-size / 2;
      }
    }
  }

  @media (max-width: @breakpoint-small-max) {
    &-content {
      flex-wrap: wrap;
    }

    &-avatar {
      .circle(@avatar-max-size / 2);
      min-width: @avatar-max-size / 2;
      margin-bottom: @utility-container-padding-horizontal;
    }
  }
}
