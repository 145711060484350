//
// Component: HTML editor
//
// ========================================================================


// Variables
// ========================================================================

@htmleditor-navbar-background: transparent;
@htmleditor-navbar-nav-height: @form-height;
@htmleditor-navbar-nav-padding-horizontal: 0;
@htmleditor-navbar-nav-line-height: @htmleditor-navbar-nav-height;
@htmleditor-navbar-nav-color: inherit;

@htmleditor-navbar-nav-hover-color: @global-color;
@htmleditor-navbar-nav-onclick-color: @global-color;
@htmleditor-navbar-nav-active-color: @global-color;

@htmleditor-content-border: @global-border;
@htmleditor-content-background: @global-background;

@htmleditor-fullscreen-z-index: @global-z-index - 10;


// Component
// ========================================================================

.hook-htmleditor() {}

.hook-htmleditor-navbar() {
  margin: 0 @global-margin-medium @global-margin;
}

.hook-htmleditor-navbar-nav() {
  font-size: @md-icon-size;
  width: @htmleditor-navbar-nav-height;
  text-align: center;

  [class*=uk-icon-] {
    .material-icon();
  }

  .uk-icon-bold:before {
    content: '\e238'; // md-format_bold
  }

  .uk-icon-italic:before {
    content: '\e23f'; // md-format_italic
  }

  .uk-icon-link:before {
    content: '\e250'; // md-link
  }

  .uk-icon-picture-o:before {
    content: '\e432'; // md-photo
  }

  .uk-icon-expand:before {
    content: '\e5d0'; // md-fullscreen
  }
}

.hook-htmleditor-navbar-nav-hover() {
  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
    color: inherit;
  }
}

.hook-htmleditor-navbar-nav-onclick() {
  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
    color: inherit;
  }
}

.hook-htmleditor-navbar-nav-active() {
  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
    color: inherit;
  }
}

.hook-htmleditor-content() {
  border: 0 none;

  .uk-htmleditor & .CodeMirror {
    direction: ltr;
    padding: 0;
  }

  .CodeMirror-linenumber {
    color: @global-text-color;
  }
}


// Miscellaneous
// ========================================================================

.hook-htmleditor-misc() {
  .uk-htmleditor-navbar-flip {
    float: inline-end;
  }

  .uk-htmleditor-navbar-nav,
  .uk-htmleditor-navbar-nav > li {
    float: inline-start;
  }

  .uk-htmleditor-preview {
    padding: @global-margin;
  }

  [data-mode='split'] {
    .uk-htmleditor-code,
    .uk-htmleditor-preview {
      float: inline-start;
    }

    .uk-htmleditor-preview {
      border-inline-start: 1px solid @htmleditor-content-split-border;
    }
  }

  .CodeMirror-lint-tooltip {
    z-index: @modal-z-index + 1;
  }
}
