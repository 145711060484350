//
// Component: Comment
//
// ========================================================================

// Variables
// ========================================================================

@comment-avatar-size: 3rem;
@comment-avatar-size-mobile: 2rem;
@comment-avatar-margin-right: @global-margin;

// Component
// ========================================================================

.hook-comment() {}

// Sub-object `uk-comment-header`
// ========================================================================

.hook-comment-header() {}

// Sub-object `uk-comment-avatar`
// ========================================================================

.hook-comment-avatar() {
  // @Todo: Remove/reuse after refactoring places where it is still used.
  img&,
  & img {
    width: @comment-avatar-size;
    height: @comment-avatar-size;
    border-radius: @global-border-radius-circle;

    @media all and (max-width: @breakpoint-medium-max) {
      width: @comment-avatar-size-mobile;
      height: @comment-avatar-size-mobile;
    }
  }

  & canvas {
    @media all and (max-width: @breakpoint-medium-max) {
      height: @comment-avatar-size-mobile !important;
      width: @comment-avatar-size-mobile !important;
    }
  }

  img {
    margin-right: 0;
  }

  [dir=rtl] & {
    margin-right: 0;
    margin-left: @comment-avatar-margin-right;
    float: right;

    img {
      margin-left: 0;
    }
  }
}

// Sub-object `uk-comment-title`
// ========================================================================

.hook-comment-title() {}

// Sub-object `uk-comment-meta`
// ========================================================================

.hook-comment-meta() {}

// Sub-object `uk-comment-body`
// ========================================================================

.hook-comment-body() {}

// Sub-object `uk-comment-primary`
// ========================================================================

.hook-comment-primary() {}

// Miscellaneous
// ========================================================================

.hook-comment-misc() {}
