#mtx-translate-interface-languages {
  .default {
    .wrapper {
      background-color: @_grey_highlight_color;

      .mtx-dark-theme & {
        background-color: @overlay-color-light;
      }
    }

    .mark {
      [dir=ltr] & {
        padding-right: 0;
      }

      [dir=rtl] & {
        padding-left: 0;
      }
    }
  }
}
