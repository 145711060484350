//
// Component: Description list
//
// ========================================================================

// Variables
// ========================================================================

@description-list-line-border: @global-border;
@description-list-line-color: @global-muted-color;

// Miscellaneous
// ========================================================================

.hook-description-list-misc() {
  [dir=rtl] & {
    .uk-description-list-horizontal > dt {
      float: right;
    }

    .uk-description-list-horizontal > dd {
      margin-left: 0;
      margin-right: @description-list-horizontal-margin-left;
    }
  }
}