.mtx-course-resume-bar {
  @padding-x: @global-margin-medium * 2;
  @padding-y: @global-margin-medium;
  @icon-pos-x: @global-margin-medium; // @padding-x / 2

  margin: @global-margin 0;

  .mtx-list-item {
    position: relative;
    border-radius: @global-border-radius;
    background-color: @_white_color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .uk-close {
    position: absolute;
    top: @padding-y;
    color: @_grey_dark_color;
    opacity: 1;
    transition: color .15s ease-in-out;

    &:focus,
    &:hover {
      color: @_grey_darken_color;
    }

    .mtx-dark-theme & {
      color: @_grey_light_color;

      &:focus,
      &:hover {
        color: @_grey_lighten_color;
      }
    }
  }

  .back-link-wrapper + & {
    margin-top: 0;
  }

  [dir=ltr] & {
    .mtx-list-item {
      padding: @padding-y calc(@global-margin-large + @close-size + @icon-pos-x) @padding-y @padding-x;
    }

    .uk-close {
      right: @icon-pos-x;
    }
  }

  [dir=rtl] & {
    .mtx-list-item {
      padding: @padding-y @padding-x @padding-y calc(@global-margin-large + @close-size + @icon-pos-x);
    }

    .uk-close {
      left: @icon-pos-x;
    }
  }
}
