.hook-api-item() {
  &.h5p {
    .thumb {
      display: block;
      padding-top: @image-item-padding;
      position: relative;
      z-index: 1;
      border-radius: @thumb-border-radius;

      img {
        display: block;
        fill: @primary_color;
        height: auto;
        margin: auto;
        padding: @global-margin-small;
        .position-cover();
      }
    }
  }
}
