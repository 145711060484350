@statistics-average-color: mix(@_grey_color, @_grey_highlight_color, 33%); // #d1d9dc
@statistics-transition-duration: .5s;

.mtx-statistics-page {}

.mtx-statistics-activity-modules-list {
  .mtx-thumb-icon .md-icon {
    font-size: 2rem; // @date-widget-date-font-size
  }
}
