@load-more-margin: @global-margin-medium * 2;

.mtx-load-more-button {
  text-align: center;
  margin-bottom: @load-more-margin;
}

// Markup generated by theme_pager(). (pager.less)
.item-list .pager {
  &.pager-load-more,
  &.mtx-api-pager-load-more {
    .reset-list;
    margin-bottom: @load-more-margin;

    .mtx-list + & {
      margin-top: @load-more-margin;
      margin-bottom: 0;
    }

    &-empty {
      display: none;
    }

    li {
      display: inline-block;
    }

    a {
      &:before {
        display: none;
      }

      &:extend(.uk-button);
    }
  }

  .post-comments-list &,
  .view-team-recent-updates & {
    margin-bottom: @global-margin;
  }

  .mtx-file-kb-content & {
    margin-top: @global-margin;
  }
}
