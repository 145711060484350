.event-extra-info {
  margin: @global-margin-medium 0;

  & > * {
    [dir=ltr] & {
      float: left;
      margin-right: @global-margin-medium * 2;
    }

    [dir=rtl] & {
      float: right;
      margin-left: @global-margin-medium * 2;
    }
  }

  .attendees-total-users {
    color: @_grey_dark_color;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.md-icon.attendees-present {
  color: #6ec300;
}

.md-icon.attendees-absent {
  color: #ff8900;
}

.mtx-event-attendance-list-item {
  &.is-cancelled {
    .icon img,
    .mtx-avatar {
      opacity: @table-row-muted-avatar-opacity;

      .mtx-dark-theme & {
        opacity: @table-row-muted-avatar-opacity-dark-theme;
      }
    }
  }
}

.event-item-actions {
  .uk-button + .uk-dropdown {
    min-width: 100%;
  }
}
