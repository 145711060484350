//
// Component: Table
//
// ========================================================================


// Variables
// ========================================================================

@table-margin-vertical:                         @global-margin;

@table-caption-color:                           @global-muted-color;
@table-caption-font-size:                       round((@global-font-size * 0.85)); // 12px / 14px

@table-condensed-padding-vertical:              @global-margin-small;
@table-condensed-padding-horizontal:            @global-margin-large;

// Component
// ========================================================================

.hook-table() {}

.hook-table-active() {}

.hook-table-striped() {}

.hook-table-condensed() {}

.hook-table-hover() {}


// Miscellaneous
// ========================================================================

.hook-table-misc() {
  // Add: `uk-table-with-borders`
  table.uk-table-with-borders {
    margin-left: 0;
    margin-right: 0;

    th,
    td {
      border-bottom: 1px solid @_grey_light_color;

      .mtx-dark-theme & {
        border-bottom-color: @_grey_dark_color;
      }
    }

    &.uk-table-condensed {
      tr {
        & > th,
        & > td {
          padding: @table-condensed-padding-vertical @table-condensed-padding-horizontal;
        }
      }
    }
  }
}
