.trainings-list {
  .training-item {
    .icon {
      width: 5rem;
      max-width: 5rem;
      min-width: 5rem;

      [class*='mtx-thumb'], img, a {
        border-radius: calc(@thumb-border-radius * .5);
      }

      a {
        cursor: pointer;
        z-index: @mtx-thumb-overlay-z-index + 1;

        &:hover,
        &:focus {
          & + .mtx-thumb .mtx-thumb-overlay {
            opacity: 1;
          }
        }
      }
    }
  }
}

.trainings,
.training-participants {
  .form-item-search {
    width: @exposed-input-width;
    .input-search-icon;
  }

  .form-item-status {
    width: @exposed-input-width;
  }
}

.training-item,
.participant-item {
  &.cancelled {
    .icon,
    .info,
    .title {
      opacity: .32;
    }
  }
}

.training-course-item {
  .training-single-course-list & {
    .course-title {
      font-size: @global-font-size-small;
    }
  }

  .training-edit-course-icon {
    position: absolute;
    top: @global-margin;
    line-height: 1;
    color: @_white_color;
    z-index: 2;

    [dir=ltr] & {
      right: @global-margin-medium;
    }

    [dir=rtl] & {
      left: @global-margin-medium;
    }
  }
}
