// Name:            Form password
// Description:     Extends form component
//
// Component:       `uk-form`
//
// Sub-objects:     `uk-form-password`
//
// Markup:
//
// <!-- uk-form-password -->
// <form class="uk-form">
//     <div class="uk-form-password">
//         <input type="password">
//         <a href="" class="uk-form-password-toggle" data-uk-form-password></a>
//     </div>
// </form>
//
// ========================================================================


// Variables
// ========================================================================

@form-password-font-size:                       13px;
@form-password-line-height:                     @form-password-font-size;
@form-password-color:                           #999;
@form-password-hover-color:                     #999;
@form-password-padding:                         50px;


/* ========================================================================
   Component: Form password
 ========================================================================== */

/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Prevent `inline-block` consequences
 */

.uk-form-password {
    /* 1 */
    display: inline-block;
    /* 2 */
    position: relative;
    /* 3 */
    max-width: 100%;
}

.uk-form-password-toggle {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -(floor(@form-password-line-height/2));
    font-size: @form-password-font-size;
    line-height: @form-password-line-height;
    color: @form-password-color;
}

.uk-form-password-toggle:hover {
    color: @form-password-hover-color;
    text-decoration: none;
}

.uk-form-password > input { padding-right: @form-password-padding !important; }


// Hooks
// ========================================================================

.hook-form-password-misc;

.hook-form-password-misc() {}