@mp-preview-width: 200px;
@mp-preview-height: 112px;

.mp-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: @mp-grid / 2;
  padding: 0 @mp-grid;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  transition: all @mp-transition-delayed;

  .mp-progress {
    flex: 1;
    position: relative;
    cursor: pointer;
  }

  .mp-time,
  .mp-duration {
    font-size: .75em;
    width: 3em;
    text-align: center;
    transition: all @mp-transition-delayed;

    &.large {
      width: 5em;
    }
  }

  .mp-cue-points {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
  }

  .progress-bar,
  .cue-point {
    position: relative;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    height: 10px;
    background-color: fade(@_white_color, 30%);
    background-clip: padding-box;
    transition: all @mp-transition;
    z-index: 1; // Has to be over .progress-indicator.

    &:hover,
    &:focus-visible {
      transform: scaleY(3);
    }
  }

  .hover-progress,
  .load-progress {
    position: absolute;
    top: 0;
    height: 100%;
    inset-inline-start: 0;
    background-color: @_white_color;

    &.active {
      transition: all .1s linear;
    }
  }

  .progress-indicator {
    background-color: @_white_color;
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    transition: all .1s linear;
    pointer-events: none;
    .circle(13px);

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      transform: translate(-50%, -50%);
    }

    [dir=rtl] & {
      transform: translate(50%, -50%);
    }
  }

  .hover-progress {
    background-color: fade(@_white_color, 30%);
  }

  .mp-progress-preview {
    position: absolute;
    bottom: @mp-grid;
    left: 50%;
    text-align: center;
    color: @_white_color;
    pointer-events: none;
    text-shadow: @mp-text-shadow;
    z-index: 1;
    width: @mp-preview-width;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      transform: translateX(-50%);
    }

    [dir=rtl] & {
      transform: translateX(50%);
    }
  }

  .preview-canvas {
    width: @mp-preview-width;
    height: @mp-preview-height;
    background-color: @mp-text-alpha-color;
    border: 2px solid @_white_color;
    border-radius: @thumb-border-radius;
    box-shadow: @mp-text-shadow;
  }

  .preview-title,
  .preview-time {
    font-size: .75em;
    line-height: @mp-grid;
    margin-top: @mp-grid / 8;
  }
}
