// Name:            Article
// Description:     Defines styles for articles within your page
//
// Component:       `uk-article`
//
// Sub-objects:     `uk-article-title`
//                  `uk-article-meta`
//                  `uk-article-lead`
//                  `uk-article-divider`
//
// Markup:
//
// <!-- uk-article -->
// <div class="uk-article">
//     <h1 class="uk-article-title"></h1>
//     <p class="uk-article-meta"></p>
//     <p class="uk-article-lead"></p>
//     <p></p>
//     <hr class="uk-article-divider">
//     <p></p>
// </div>
//
// ========================================================================


// Variables
// ========================================================================

@article-margin-top:                            25px;

@article-title-font-size:                       36px;
@article-title-line-height:                     42px;
@article-title-font-weight:                     normal;
@article-title-text-transform:                  none;

@article-meta-font-size:                        12px;
@article-meta-line-height:                      18px;
@article-meta-color:                            #999;

@article-lead-color:                            #444;
@article-lead-font-size:                        18px;
@article-lead-line-height:                      24px;
@article-lead-font-weight:                      normal;

@article-divider-margin:                        25px;
@article-divider-border:                        #ddd;


/* ========================================================================
   Component: Article
 ========================================================================== */

/*
 * Micro clearfix to make articles more robust
 */

.uk-article:before,
.uk-article:after {
    content: "";
    display: table;
}

.uk-article:after { clear: both; }

/*
 * Remove margin from the last-child
 */

.uk-article > :last-child { margin-bottom: 0; }

/*
 * Vertical gutter for articles
 */

.uk-article + .uk-article { margin-top: @article-margin-top; }


/* Sub-object `uk-article-title`
 ========================================================================== */

.uk-article-title {
    font-size: @article-title-font-size;
    line-height: @article-title-line-height;
    font-weight: @article-title-font-weight;
    text-transform: @article-title-text-transform;
    .hook-article-title;
}

.uk-article-title a {
    color: inherit;
    text-decoration: none;
}


/* Sub-object `uk-article-meta`
 ========================================================================== */

.uk-article-meta {
    font-size: @article-meta-font-size;
    line-height: @article-meta-line-height;
    color: @article-meta-color;
    .hook-article-meta;
}


/* Sub-object `uk-article-lead`
 ========================================================================== */

.uk-article-lead {
    color: @article-lead-color;
    font-size: @article-lead-font-size;
    line-height: @article-lead-line-height;
    font-weight: @article-lead-font-weight;
    .hook-article-lead;
}


/* Sub-object `uk-article-divider`
 ========================================================================== */

.uk-article-divider {
    margin-bottom: @article-divider-margin;
    border-color: @article-divider-border;
    .hook-article-divider;
}

* + .uk-article-divider { margin-top: @article-divider-margin; }


// Hooks
// ========================================================================

.hook-article-misc;

.hook-article-title() {}
.hook-article-meta() {}
.hook-article-lead() {}
.hook-article-divider() {}
.hook-article-misc() {}