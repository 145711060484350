.mtx-api-image-widget {
  .image-preview {
    float: none;
    padding: 0;
    margin: @global-margin 0;

    img {
      border-radius: @thumb-border-radius;
    }
  }

  .image-widget-actions {
    margin-top: @global-margin;
  }
}
