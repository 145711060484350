.hook-api-item() {
  &.file {
    .thumb {
      display: block;
      position: relative;
      padding-top: 100%;
      z-index: 1;
      background-color: @_grey_light_color;

      &,
      &-image-hover {
        border-radius: @thumb-border-radius;
      }

      img {
        width: 5rem;
        max-width: 100%;
        .position-center();
      }

      .format {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
      }
    }
  }
}
