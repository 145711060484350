.mtx-user-incentives-orders-section {
  .filters {
    .search {
      width: @exposed-input-width;
      .input-search-icon;
    }
  }

  // Cells.
  .id-cell {
    max-width: 10em;
  }

  .product-cell > div {
    min-width: 10em;
    max-width: 16em;
  }

  .created-cell {
    min-width: 10em;
  }

  .track-number-cell {
    min-width: calc(10em + @md-icon-size + 2 * @global-margin);
    div.track-number {
      max-width: 20em;
      word-break: break-word;
    }

    .copy-track-number-button {
      padding: 0 @global-margin;

      .md-icon {
        min-width: @md-icon-size;
      }
    }
  }
}
