//
// Component: Alert
//
// ========================================================================


// Variables
// ========================================================================

@alert-margin-vertical:                         @global-margin;
@alert-padding:                                 @global-margin-medium;
@alert-background:                              @_white_color;
@alert-color:                                   inherit;


// Component
// ========================================================================

.hook-alert() {
  border-radius: @global-border-radius;

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  .uk-alert-container & {
    margin: 0;
    padding: @global-margin-medium;

    .mtx-button-group {
      .uk-dropdown {
        min-width: 100%;
      }
    }
  }
}


// Modifier: `uk-alert-success`
// ========================================================================

.hook-alert-success() {}


// Modifier: `uk-alert-warning`
// ========================================================================

.hook-alert-warning() {}


// Modifier: `uk-alert-danger`
// ========================================================================

.hook-alert-danger() {}


// Miscellaneous
// ========================================================================

.hook-alert-misc() {
  // Add: `uk-alert-container`.
  .uk-alert-container {
    margin: @alert-margin-vertical 0;

    .course-content-background-inherited &:not(.uk-hidden) + .mtx-section {
      padding-top: 0;
    }

    .back-link-wrapper + & {
      margin-top: 0;
    }

    &.subscription-alert {
      margin: @global-margin-medium 0;
    }

    &.activity-type-convert-alert {
      margin: @alert-margin-vertical 0 0;
    }
  }
}
