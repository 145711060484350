#mtx-marketplace-report {
  .report-total {
    background-color: @_white_color;
    padding: @global-margin;
    width: 16em;

    @media all and (max-width: 540px) {
      width: 100%;

      [dir=ltr] & {
        margin-left: 0;
      }

      [dir=rtl] & {
        margin-right: 0;
      }
    }
  }

  .total-title {
    color: #93a2b1;
    font-size: @global-font-size-small;
    font-weight: normal;
    line-height: @global-line-height;
    margin: 0;
    text-transform: uppercase;
  }

  .total-value {
    font-weight: @global-font-weight-bold;
    font-size: 2.25em;

    .currency {
      font-size: .5em;
    }
  }

  .report-table-total {
    font-weight: @global-font-weight-bold;
  }

  .total-revenue {
    color: @secondary_color;
  }

  .total-cost {
    color: #fb8c00;
  }

  .mtx-empty-message {
    margin-top: @global-margin;
    padding: 5% 20%; // Prevents overriding due to element's 'mtx-content-section' class.
  }

  .report-loader {
    .spinner {
      margin: @global-margin;
      width: @md-icon-size;
      height: @md-icon-size;
    }
  }
}

.mtx-marketplace-report-header {
  .inner-wrapper > div {
    border-radius: @global-border-radius;
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .inner-wrapper > div + div {
      margin-top: @global-margin;

      [dir=ltr] & {
        float: left;

        &:not(.uk-hidden) + div {
          margin-left: @global-margin;
        }
      }

      [dir=rtl] & {
        float: right;

        &:not(.uk-hidden) + div {
          margin-right: @global-margin;
        }
      }
    }
  }

  @media all and (min-width: @breakpoint-large) {
    margin: 0 -@global-margin;

    .inner-wrapper {
      display: table;
      width: 100%;
      border-collapse: separate;
      border-spacing: @global-margin 0;

      & > div {
        display: table-cell;
      }
    }
  }
}

.mtx-marketplace-reports-filters {
  .form-type-textfield {
    & > * {
      display: inline-block;
      vertical-align: middle;
    }

    @media all and (max-width: @breakpoint-small-max) {
      & > * {
        [dir=ltr] & {
          float: left;
          margin: 0 0 @global-margin; // this should be here
        }

        [dir=rtl] & {
          float: right;
          margin: 0 0 @global-margin; // this should be here
        }
      }

      label {
        width: 40%;
      }

      .form-item-datepicker {
        width: 60%;

        input {
          width: 100%;
        }
      }
    }
  }

  .uk-form-label {
    line-height: @form-height;
  }
}

table.mtx-report-table {
  margin: @global-margin 0 0;

  .responsive-table & {
    min-width: 45rem; // 720px
  }

  tr,
  td,
  th {
    background-color: transparent !important;
    border: 0 none;
    line-height: 2;
  }

  th {
    color: #93a2b1;
    font-weight: @global-font-weight-semibold;
  }

  td,
  th {
    padding: @global-margin 0 !important;
    border-bottom: 1px solid @page_content_background_color;

    &:first-child {
      [dir=ltr] & {
        text-align: left;
      }

      [dir=rtl] & {
        text-align: right;
      }
    }

    &:last-child {
      [dir=ltr] & {
        text-align: right;
      }

      [dir=rtl] & {
        text-align: left;
      }
    }
  }

  tr:last-child {
    td {
      border-bottom: 0 none;
    }
  }
}

.mtx-report-pager {
  font-size: @global-font-size-small;

  ul {
    .reset-list;
    text-align: center;
  }

  li {
    display: inline-block;
    color: @global-muted-color;
    line-height: 2;

    &.active {
      font-weight: bold;
      padding: 0 .5em;
    }
  }

  a {
    display: block;
    padding: 0 .5em;
    color: @global-muted-color;

    &:hover,
    &:focus {
      color: @global-text-color;
    }

    &[disabled] {
      color: @global-muted-color;
      cursor: default;
      opacity: .7;
    }
  }
}
