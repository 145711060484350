@h5p-border-color: #ccc;
@h5p-padding: 8px;
@h5p-padding-small: 6px;
@h5p-padding-xsmall: 4px;
@h5p-padding-xxsmall: 2px;
@h5p-input-bg: #f9f9f9;
@h5p-input-focus-bg: #fff;
@h5p-input-border-color: #a0a0a0;
@h5p-input-border-focus-color: #53a0ff;
@h5p-color-muted: #666666;
@h5p-caret-color: #838383;
@h5p-fieldset-legend-bg: #f4f4f4;
@h5p-text-font-size: .875em;

.uk-form .h5p-form-item {
  border: 1px solid @h5p-border-color;
  font-size: @global-font-size;
  max-width: 960px;
  padding: @h5p-padding;
  position: relative;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;

  & + .h5p-form-item {
    margin-top: @global-margin;
  }

  .form-item {
    margin: @h5p-padding 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: inline-block;
    }

    .description {
      color: @h5p-color-muted;
      font-size: @global-font-size-xsmall;
      letter-spacing: .5px;
      line-height: 15px;
      margin-top: .3em;
      margin-bottom: 1em;
    }

    button,
    .button,
    input[type="button"],
    input[type="submit"] {
      &,
      &:focus,
      &:active {
        background: linear-gradient(to bottom, @h5p-input-focus-bg 0, #f2f2f2 100%);
        padding: @h5p-padding-xsmall @h5p-padding;
        border: 1px solid @h5p-border-color;
        color: @global-text-color;
        cursor: pointer;
        font-size: @h5p-text-font-size;
        line-height: normal;
        margin: @h5p-padding-small @h5p-padding @h5p-padding 0;
        width: auto;
      }

      &:hover {
        border-color: @global-muted-color;
      }
    }

    input[type="checkbox"] {
      -webkit-appearance: checkbox;
      -moz-appearance: checkbox;
      appearance: checkbox;
      background: initial;
      border: none;
      font-size: @global-font-size-small;
      height: initial;
      width: initial;

      &:before {
        content: initial;
      }
    }

    input[type="text"],
    input[type="file"],
    textarea {
      background: @h5p-input-bg;
      border: 1px solid @h5p-input-border-color;
      min-height: 20px;
      outline: none;
      padding: @h5p-padding-xsmall @h5p-padding;
      word-wrap: break-word;
      border-radius: @global-border-radius;

      &:focus {
        background-color: @h5p-input-focus-bg;
        border-color: @h5p-input-border-focus-color;
        outline: none;
      }
    }

    [dir=ltr] & {
      .chosen-search input[type="text"] {
        padding-left: @form-icon-width;
      }
    }

    [dir=rtl] & {
      .chosen-search input[type="text"] {
        padding-right: @form-icon-width;
      }
    }
  }

  .h5p-certificate-text {
    display: none;
  }

  fieldset {
    background: none;
    margin-bottom: @h5p-padding !important;
    padding: 0;
    position: relative;
    min-width: 0;

    &.collapsed {
      height: auto;

      & > legend > .fieldset-legend a.fieldset-title:before {
        content: '\e5df'; // md-arrow_right
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > legend {
      font-size: @global-font-size;
      background: @h5p-fieldset-legend-bg;
      border: 1px solid @h5p-border-color;
      color: @global-text-color;
      cursor: pointer;
      padding: @h5p-padding-small @h5p-padding @h5p-padding-xsmall;
      overflow: hidden;
      outline: none;
      box-sizing: border-box;
      border-radius: 0;
      .clearfix();

      &:after {
        border: none;
      }

      & > .fieldset-legend {
        background: none !important;
        color: @global-text-color;
        padding: 0 !important;

        .summary {
          display: none;
        }

        a.fieldset-title {
          color: @global-text-color !important;
          display: block;
          font-size: @global-font-size;
          line-height: 1.5;
          padding: 0;
          background: none;

          &:before {
            .material-icon();
            content: '\e5c5'; // md-arrow_drop_down
            vertical-align: top;
          }
        }
      }
    }

    & > .fieldset-wrapper {
      border: 1px solid @h5p-border-color;
      margin-top: -1px !important;
      padding: @h5p-padding;
    }
  }

  table {
    margin: 0;
    font-size: @global-font-size-small;
  }

  .file a {
      word-break: break-all;
      display: inline-block;
      margin-top: @h5p-padding;
  }
}
