.view .organization-details {
  & > * {
    display: inline-block;
    vertical-align: middle;

    .md-icon {
      color: @_grey_dark_color;
    }

    [dir=ltr] & {
      margin-right: @global-margin-medium * 2;

      .md-icon {
        margin-right: @global-margin-mini;
      }
    }

    [dir=rtl] & {
      margin-left: @global-margin-medium * 2;

      .md-icon {
        margin-left: @global-margin-mini;
      }
    }

    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  [dir=ltr] & {
    .organization-credits {
      margin-right: @global-margin-medium;
    }
  }

  [dir=rtl] & {
    .organization-credits {
      margin-left: @global-margin-medium;
    }
  }

  & + .organization-actions {
    margin-top: @global-margin-medium * 2;
  }

  .organization-credits-spent {
    .md-icon {
      color: @text-danger-color;
    }
  }
}
