.mtx-commerce-settings-form {
  .form-item-notification-mtx-commerce-checkout-notify-email,
  .form-item-course-on-demand-mtx-course-on-demand-receiver-email {
    display: none;
  }

  .payment-methods-table {
    th {
      min-width: 5rem;

      &.stripe_button {
        min-width: 10rem;
      }
    }
  }

  .form-item-notification-mtx-commerce-checkout-notify-email,
  .form-item-catalog-course-fields-mtx-catalog-course-price-behaviour,
  .form-item-course-on-demand-mtx-course-on-demand-receiver-email {
    margin-inline-start: @field-type-checkbox-radio-siblings-margin;
  }
}

.mtx-commerce-order-confirmation-settings-form {
  .form-field-with-sign(~'.form-item-mtx-commerce-order-confirmation-template-top', false);
  .form-field-with-sign(~'.form-item-mtx-commerce-order-confirmation-template-left', false);
}
