.nei-widget-folder {
  .files {
    a {
      text-decoration: none;
      .break-word();
    }

    a.disabled {
      cursor: default;
      color: @global-muted-color;
    }
  }

  .empty {
    border: 1px solid @_grey_lighten_color;
    color: @_grey_color;
    line-height: 3;
    padding: @global-margin-small @global-margin;
    border-radius: @global-border-radius;

    .md-icon {
      font-size: 2em;
      line-height: 1.5;
      vertical-align: bottom;
    }
  }

  .mtx-text-resources {
    overflow: hidden;

    .resource-item {
      width: 30%;
      min-width: 14rem;
      max-width: 100%;
    }
  }
}
