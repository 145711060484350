.transaction-page {
  .wrapper {
    & + .wrapper {
      margin-top: @global-margin;
    }
  }

  .content {
    background-color: @_white_color;
    border-radius: @global-border-radius;
    padding: @global-margin;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .responsive-table {
    table {
      width: auto;
    }
  }

  .summary {
    .reset-list;
    font-size: @global-font-size-small;

    .header {
      font-weight: @global-font-weight-semibold;
    }

    .item + .item {
      margin-top: @global-margin;
    }
  }

  .order-table {
    tbody tr,
    th {
      background-color: @page_content_background_color;

      .mtx-dark-theme & {
        background-color: transparent;
      }
    }

    .product {
      min-width: 15em;
    }

    .sub-total,
    .discount,
    .total {
      min-width: 7em;
    }
  }

  .logs-table {
    .date {
      min-width: 11em;
    }

    .status {
      min-width: 12em;
    }
  }

  .user {
    border-radius: @global-border-radius;
    padding: @global-margin;
    background-color: @page_content_background_color;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }

    .name,
    .header {
      font-weight: @global-font-weight-bold;
    }

    .comment {
      font-size: @global-font-size-small;
    }

    & + .user {
      margin-top: @global-margin;
    }
  }
}
