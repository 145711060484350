.mtx-completion-icon {
  padding: @global-margin-mini;
  position: absolute;
  top: -@global-margin-small;
  z-index: 1;
  color: @_white_color;
  background-color: @global-success-color;
  border-radius: @global-border-radius-circle;
  line-height: 1;

  [dir=ltr] & {
    left: -@global-margin-small;
  }

  [dir=rtl] & {
    right: -@global-margin-small;
  }
}

.mtx-activity-module-type-training {
  .mtx-completion-icon {
    background-color: @_white_color;
    color: @global-success-color;
    box-shadow: inset 0 0 0 2px @global-success-color;
  }

  // Fixes broken styles on Statistics page.
  &.mtx-course-el .course-link + .course-details {
    .mtx-completion-icon[data-uk-tooltip] {
      position: absolute;
    }
  }
}
