.mtx-player {
  font-size: @mp-font-size;
  color: @mp-text-color;
  background-color: @mp-bg-color;
  user-select: none;
  cursor: default;

  mtx-player,
  video {
    display: block;
    width: 1px;
    min-width: 100%;
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  mtx-player {
    padding-top: 56.25%;
    position: relative;

    video {
      .position-cover();
      height: 100%;
    }

    .me_cannotplay {
      display: none;
    }
  }

  .player-error {
    background: #fafafa;
    padding: 70px 0;
    text-align: center;
    width: 100%;
    color: @_grey_color;

    .mtx-dark-theme & {
      color: inherit;
      background-color: @overlay-color-dark;
    }

    .md-icon {
      font-size: 4em;
      margin-bottom: @global-margin;
    }

    p {
      font-size: @global-font-size-small;
      font-weight: @global-font-weight-semibold;
    }
  }

  .mp-btn {
    .mp-button;
  }

  .mp-menu {
    position: absolute;
    bottom: 100%;
    background-color: @mp-bg-alpha-color;
    color: @mp-text-alpha-color;
    display: none;
    white-space: nowrap;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      left: auto;
      right: 0;
    }

    [dir=rtl] & {
      right: auto;
      left: 0;
    }

    &.opened {
      display: block;
      z-index: 2;
    }

    ul {
      .reset-list;
    }

    li {
      cursor: pointer;
      line-height: 1.5;
      font-size: .875em;
      padding: .25em 1em;

      &:hover {
        color: @mp-accent-color;
      }

      &.active {
        color: @mp-accent-color;
        background-color: fade(@mp-text-color, 10%);
      }
    }
  }

  .mp-header {
    color: @mp-text-color;
    font-size: 1em;
    line-height: @mp-controls-size;
    font-weight: @global-font-weight-semibold;
    padding: @mp-grid;
    .position-top();
    .clearfix;

    .mp-playlist-button {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        float: left;
        margin-right: @mp-grid / 4;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @mp-grid / 4;
      }
    }
  }

  .mp-hidden {
    display: none !important;
  }

  // Shadows base styles.
  .mp-top-shadow,
  .mp-bottom-shadow {
    height: @mp-shadow-size;
    transition: all @mp-transition-delayed;
  }

  .mp-top-shadow {
    background: linear-gradient(to bottom, @mp-bg-alpha-color, rgba(0, 0, 0, 0));
    .position-top();
  }

  .mp-bottom-shadow {
    background: linear-gradient(to top, @mp-bg-alpha-color, rgba(0, 0, 0, 0));
    .position-bottom();
  }

  // Center ui elements.
  .mp-loader,
  .mp-play-large {
    font-size: 4em;
    line-height: 1;
    width: 1em;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .md-icon {
      color: inherit !important;
    }
  }

  .mp-play-large {
    @mp-play-icon-size: unit(@md-icon-size, em);

    width: @mp-play-icon-size;
    height: @mp-play-icon-size;
    opacity: .65;
    text-shadow: @mp-text-shadow;
    transition: opacity @mp-transition;

    .md-icon {
      font-size: @mp-play-icon-size;
    }
  }

  &:hover {
    .mp-play-large {
      opacity: 1;
    }
  }

  // Handle sizes.
  &.large,
  &.normal {
    .mp-info {
      padding-bottom: 1em;
    }
  }

  &.small {
    .mp-header,
    .mp-info,
    .mp-subtitle {
      font-size: .875em;
      line-height: 1.5;
    }

    .mp-loader,
    .mp-play-large {
      font-size: 2em;
    }

    .mp-top-shadow,
    .mp-bottom-shadow {
      height: @mp-shadow-size * .875em;
    }

    .owl-next,
    .owl-prev {
      font-size: .875em;
    }

    .mp-playlist {
      font-size: .875em;
    }

    .mp-prev,
    .mp-next,
    .mp-seek-forward,
    .mp-seek-back,
    .mp-cue-points-button-prev,
    .mp-cue-points-button-next {
      display: none;
    }
  }

  &.x-small {
    .mp-header,
    .mp-info,
    .mp-subtitle {
      font-size: .75em;
      line-height: 1.5;
    }

    .mp-loader,
    .mp-play-large {
      font-size: 1.5em;
    }

    .mp-top-shadow,
    .mp-bottom-shadow {
      height: @mp-shadow-size * .75em;
    }

    .owl-next,
    .owl-prev {
      font-size: .75em;
    }

    .mp-prev,
    .mp-next,
    .mp-seek-forward,
    .mp-seek-back,
    .mp-progress-preview,
    .mp-cue-points-button,
    .mp-cue-points-button-prev,
    .mp-cue-points-button-next {
      display: none;
    }
  }

  &.xx-small {
    .mp-header,
    .mp-info,
    .mp-subtitle {
      font-size: .625em;
      line-height: 1.5;
    }

    .mp-loader,
    .mp-play-large {
      font-size: 1.125em;
    }

    .mp-top-shadow,
    .mp-bottom-shadow {
      height: @mp-shadow-size * .5em;
    }

    .owl-next,
    .owl-prev {
      font-size: .5em;
    }

    .mp-playlist {
      font-size: .625em;
    }
  }

  & .fullscreen {
    .mp-header,
    .mp-info,
    .mp-subtitle,
    .mp-controls {
      font-size: 1em;
    }

    .mp-loader,
    .mp-play-large {
      font-size: 4em;
    }

    .mp-top-shadow,
    .mp-bottom-shadow {
      height: @mp-shadow-size;
    }

    .mp-playlist {
      font-size: 1em;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .player-error {
      padding: 35px 0;

      &:before {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
  }
}

// iPad fullscreen fallback.
html.mtx-player-fullscreen {
  overflow: hidden !important;
}
