//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

@base-body-background:                          @global-background;
@base-body-font-size:                           @global-font-size;
@base-body-font-family:                         @global-font-family;
@base-body-line-height:                         @global-line-height;
@base-body-color:                               @global-text-color;

@base-link-color:                               @global-link-color;
@base-link-hover-color:                         @global-link-hover-color;
@base-link-text-decoration:                     var(--link-decoration, none);
@base-link-hover-text-decoration:               none;
@base-em-color:                                 inherit;
@base-ins-color:                                inherit;
@base-mark-color:                               inherit;

@base-margin-vertical:                          @global-margin;

@base-heading-color:                            inherit;
@base-heading-margin-top:                       @global-margin;
@base-heading-font-weight:                      @global-font-weight-bold;
@base-heading-font-family:                      inherit;

@base-h1-font-size:                             @global-font-size * 1.75; // 28px
@base-h1-line-height:                           @base-h1-font-size * 1.25; // 35px
@base-h2-font-size:                             @global-font-size * 1.5; // 24px
@base-h2-line-height:                           @base-h2-font-size * 1.25; // 30px
@base-h3-font-size:                             @global-font-size * 1.25; // 20px
@base-h3-line-height:                           @base-h3-font-size * 1.2; // 24px
@base-h4-font-size:                             @global-font-size * 1.125; // 18px
@base-h4-line-height:                           @base-h4-font-size * 1.333; // 24px
@base-h5-font-size:                             @global-font-size; // 16px
@base-h5-line-height:                           @base-h5-font-size * 1.25; // 20px
@base-h6-font-size:                             @global-font-size * 0.875; // 14px
@base-h6-line-height:                           @base-h6-font-size * 1.28571429; // 18px

@base-list-padding-left:                        @global-margin-medium * 2;

@base-hr-border:                                @global-border;

@base-blockquote-border:                        @global-border;
@base-blockquote-font-size:                     @global-font-size; // 16px
@base-blockquote-line-height:                   @global-line-height; // 21px

@base-pre-padding:                              @global-margin;

@base-code-font-size:                           @global-font-size * 0.85; // 12px / 14px

@base-pre-color:                                @global-color;
@base-pre-font-size:                            1em;
@base-pre-line-height:                          @base-body-line-height;


// Body
// ========================================================================

.hook-base-body() {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[lang='ar'],
  & div[lang='ar'] {
    font-family: Tahoma, @base-body-font-family;

    & [lang]:not([lang='ar']) {
      font-family: @base-body-font-family;
    }
  }
}


// Links
// ========================================================================

.hook-base-link() {
  &:focus,
  &.mtx-handler {
    text-decoration: none;
  }
}

.hook-base-link-hover() {
  text-decoration: none;
}


// Text-level semantics
// ========================================================================

.hook-base-code() {}


// Headings
// ========================================================================

.hook-base-h() {}

.hook-base-h1() {
  .nei-widget & {
    color: @course_h1_color;
  }
}

.hook-base-h2() {
  .nei-widget & {
    color: @course_h2_color;
  }
}

.hook-base-h3() {
  .nei-widget & {
    color: @course_h3_color;
  }
}

.hook-base-h4() {
  .nei-widget & {
    color: inherit !important;
  }
}

.hook-base-h5() {
  .nei-widget & {
    color: inherit !important;
  }
}

.hook-base-h6() {
  .nei-widget & {
    color: inherit !important;
  }
}


// Horizontal rules
// ========================================================================

.hook-base-hr() {}


// Blockquotes
// ========================================================================

.hook-base-blockquote() {
  padding-top: @global-margin-mini;
  padding-bottom: @global-margin-mini;

  p:last-child {
    margin-bottom: 0;
  }
}


// Preformatted text
// ========================================================================

.hook-base-pre() {
  margin: 0;
}


// Miscellaneous
// ========================================================================

.hook-base-misc() {
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  img,
  audio,
  video,
  embed,
  object {
    max-width: 100%;
    height: auto;
  }

  img {
    -ms-interpolation-mode: bicubic;
  }

  iframe {
    max-width: 100%;
  }

  // Override max-width 100% for map displays and widgets
  #map,
  #getlocations_map_canvas,
  #locationmap_map,
  .gmap,
  .view-gmap,
  .openlayers-map,
  .geofieldMap,
  .views_horizontal_slider,
  .field-widget-imagefield-crop-widget {
    img {
      max-width: none !important;
    }
  }

  //** Focus state */
  :focus-visible {
    outline: none;
    .focus-state-box-shadow();

    .uk-hidden&,
    [hidden]&,
    .chosen-disabled & {
      box-shadow: none;
    }
  }

  .page-sidebar .nav-link,
  .page-sidebar-toggler,
  .uk-nav a {
    &:focus-visible {
      .focus-state-box-shadow(true);
    }
  }

  input,
  textarea {
    &:focus {
      box-shadow: none;
    }

    &.has-outline:focus {
      .focus-state-box-shadow();
    }
  }

  // Dashed text decoration
  .uk-text-dashed {
    border-bottom: 1px dashed @global-text-color;

    &.uk-text-muted {
      border-bottom-color: @global-muted-color;
    }
  }

  ol,
  ul {
    padding-left: 0; // rtl
    padding-inline-start: @base-list-padding-left;
  }
}
