.nei-widget-h5p {
  figure {
    margin: 0;
  }

  .h5p-locked,
  .h5p-launch {
    padding: 3rem 6%;
    // contrib/h5p/library/styles/h5p.css .h5p-content {}
    background: #fefefe;
    border: 1px solid #eeeeee;

    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }

    .md-icon {
      font-size: 3rem;
      color: @_grey_dark_color;
    }

    .title {
      margin: @global-margin-large 0 0;
    }

    .message {
      margin: 1.25rem 0 0;
    }

    .launch-button {
      margin-top: 1.25rem;
    }
  }

  .h5p-launch .message {
    font-weight: @global-font-weight-normal;
  }
}

//*
//* JoubelUI score bar
//*
//* Copied from H5P.JoubelUI-1.3/css/joubel-score-bar.css
//* Version: 1.3.10
.h5p-joubelui-score-bar {
  display: inline-flex;
  width: 15em;
  max-width: 100%;
  background: #ffffff;
  border-radius: 1.5em;
  padding: 0.625em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  position: relative;

  &-visuals {
    flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    position: relative;
    overflow: visible;
  }

  &-progress-wrapper {
    position: relative;
    margin-right: 1.7em;
    height: 0.917em;
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
    background: #dddddd;
  }

  &-progress {
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
    background: #48b57e; // For browsers not supporting linear-gradient
    background: linear-gradient(to right, #52ca8d, #48b57e);
    transition: width 0.4s ease-in-out;
    -webkit-backface-visibility: hidden;
  }

  // The star
  &-star {
    height: 1.8em;
    width: 2.1em;
    position: absolute;
    right: 0;
    top: -0.4em;
    overflow: visible;
  }

  // Styling the star
  &-star svg {
    overflow: visible;
  }

  &-star-shadow {
    fill: #ffffff;
  }

  &-star-border {
    fill: none;
    stroke: #dddddd;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }

  &-star-fill {
    fill: #dddddd;
  }

  &-full-score &-star-border {
    stroke: #ab741a;
    stroke-width: 6;
  }

  &-star-fill-full-score {
    visibility: hidden;
    fill: #ffc80b;
  }

  &-full-score &-star-fill {
    fill: #ffc80b;
  }

  &-full-score &-star-fill-full-score {
    visibility: visible;
  }
}

// The numeric part on the end (score / maxScore)
.h5p-joubelui-score-numeric {
  margin: 0 0.4em;
  font-size: 1.333em;
  line-height: 0.7;
  font-weight: bold;
}

.h5p-score-bar-has-help .h5p-joubelui-score-numeric {
  // Need more space when icon is displayed
  margin-right: 0.625em;
}

.h5p-joubelui-score-number {
  color: #333333;
}

.h5p-joubelui-score-number-separator {
  color: #757575;
  padding: 0 0.1em;
}

.h5p-joubelui-score-bar .joubel-tip-container {
  position: absolute;
  top: 0.1em;
  right: 0.3em;
  font-size: 1em;
  line-height: 1;
  color: #1a73d9;
  margin-right: 0.2em;

  & > .help-icon {
    font-size: 1em;
    line-height: 1;
  }
}
