// Participants.
.view-participants {
  .api-dialog {
    width: 100%;
  }

  .empty-message > p {
    margin: 0;
  }
}

ul.mtx-participants-list {
  margin-bottom: 0;

  & > li {
    margin-left: -@global-margin;
    margin-right: -@global-margin;
    padding: @global-margin;

    & + li {
      border-top: @list-border-width solid @list-border-color;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .participant-avatar {
    width: @comment-avatar-size;
    max-width: @comment-avatar-size;
    margin: 0;

    & + div {
      margin-left: @comment-avatar-size + @global-margin;

      [dir=rtl] & {
        margin-left: 0;
        margin-right: @comment-avatar-size + @global-margin;
      }
    }
  }

  .participant-socials {
    &:not(.empty) {
      margin-top: @global-margin-small;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .participant-socials {
      .clearfix;

      .mtx-social-links {
        float: right;

        [dir=rtl] & {
          float: left;
        }
      }

      &:not(.empty) {
        margin-top: 0;
      }
    }
  }
}
