.view-main-calendar {
  .views-exposed-form {
    .views-widget-filter-field_activities_target_id_entityreference_filter,
    .views-widget-filter-team_select,
    .views-widget-filter-field_teams_members_target_id {
      display: none;
    }
  }
}

.view-main-calendar,
.view-mtx-team-calendar {
  .grouping-title {
    margin-bottom: @global-margin-large;
  }

  .event-item {
    .title {
      margin: 0;
    }

    &.mtx-list-item {
      .mtx-date-widget-icon a {
        text-decoration: none;
      }
    }
  }
}
