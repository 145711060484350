#promotions-list-items {
  .activity-cell {
    min-width: 12em;
    max-width: 20em;
  }

  .promoted-by-cell,
  .promoted-to-cell {
    min-width: 12em;
    max-width: 15em;
  }

  .date-created-cell {
    min-width: 12em;
  }

  .deadline-cell,
  .status-cell {
    min-width: 5em;
  }

  .promotion-item-inactive {
    .activity-cell,
    .promoted-by-cell,
    .promoted-to-cell,
    .date-created-cell,
    .deadline-cell,
    .status-cell {
      color: @global-muted-color;
    }
  }
}
