//
// Component: Accordion
//
// ========================================================================


// Variables
// ========================================================================

@accordion-title-margin-bottom:                 @global-margin;
@accordion-title-padding-horizontal:            @global-margin;
@accordion-title-font-size:                     @global-font-size * 1.28; // 18px / 20px
@accordion-title-line-height:                   @accordion-title-font-size * 1.32; // 24px / 26px


// Component
// ========================================================================

.hook-accordion() {}


// Modifier: `uk-accordion-title`
// ========================================================================

.hook-accordion-title() {
  -webkit-tap-highlight-color: transparent;

  &.uk-active {
    .md-expand_more:after {
      content: '\e5ce'; // md-expand_less
    }
  }
}


// Modifier: `uk-accordion-content`
// ========================================================================

.hook-accordion-content() {
  &:not(.uk-active) {
    // Fixes children z-index issue for collapsed state in Safari.
    // Fixes keyboard navigation issue for collapsed state.
    visibility: hidden;
  }
}


// Miscellaneous
// ========================================================================

.hook-accordion-misc() {}
