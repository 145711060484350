// Mtx filter select button and dropdown
#mtx-filter-select {
  & > .uk-button {
    background: none;
  }

  .uk-nav {
    a.active {
      pointer-events: none;
    }

    &-header {
      padding: @dropdown-padding;
      margin: 0;
    }
  }

  @media screen and (max-width: @breakpoint-small-max) {
    &,
    & > .uk-button,
    .uk-dropdown {
      width: 100%;
    }
  }

  @media screen and (min-width: @breakpoint-medium) {
    [dir=ltr] & {
      float: right;
      margin-left: @global-margin;
    }

    [dir=rtl] & {
      float: left;
      margin-right: @global-margin;
    }
  }
}
