.mtx-icons-dropdown {
  @icon-margin: @global-margin-small / 2;

  & &-toggle {
    height: @button-height;
    padding: calc(@button-padding-vertical - 1px) calc(@button-padding-horizontal - 1px);
    line-height: @global-line-height;
  }

  .uk-dropdown {
    width: auto;
    padding: @global-margin-large;

    .list.uk-nav {
      margin: -@icon-margin 0 0;
      .clearfix();
    }

    .item {
      margin-top: @icon-margin;

      [dir=ltr] & {
        float: left;
        margin-right: @icon-margin;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @icon-margin;
      }
    }

    .icon {
      border-radius: @global-border-radius;
      padding: @global-margin-small;
    }

    .icon.empty {
      .md-icon {
        width: @md-icon-size;
        height: @md-icon-size;
      }

      &:not(.active) {
        background-color: @_grey_lighten_color;

        // see .dropdown-links() in colors.less
        &:hover,
        &:focus {
          background-color: @_grey_light_color;
        }
      }
    }
  }
}
