.mtx-manager-users-section {
  .mtx-manager-users-org-filter {
    .uk-button.filter-toggler {
      &:extend(.mtx-api-filter .uk-button.filter-toggler all);
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .mtx-section-title {
      margin-bottom: @global-margin;
    }
  }
}

// @Todo: Create theme, apply at manager and platform admin Dashboard
.mtx-manager-users-rubrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &-wrapper {
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .item {
    color: inherit !important;
    user-select: none;
    .clearfix;

    .value {
      font-weight: @global-font-weight-bold;
    }

    .description {
      line-height: 1.4;
      font-size: @global-font-size-small;
      color: @_grey_dark_color;

      br {
        display: none;
      }
    }

    &.highlighted-item {
      .value {
        color: @badge-danger-background;
      }
    }

    &.credits-item {
      .description br {
        display: block;
      }
    }
  }

  @media all and (min-width: @breakpoint-small) {
    &-wrapper {
      padding: @global-margin-medium 0;
      text-align: center;
    }

    .item {
      min-height: unit(140 / 16, rem);
      min-width: 8.125rem; // 130px
      margin: @global-margin-small .75%;
      padding: @global-margin-small (@global-margin-small / 2);
      vertical-align: top;

      & > div + div {
        margin-top: @global-margin-small;
      }

      .label {
        line-height: 1.375;
      }

      .value {
        line-height: 1.2;
        font-size: 2.5rem;
      }

      .description br {
        display: block;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) and (max-width: @breakpoint-large-max) {
    .item {
      margin: @global-margin-small 2%;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    display: block;

    &-wrapper {
      padding: @global-margin-large 0;
    }

    .item {
      padding: @global-margin-small 0;

      .label {
        font-weight: @global-font-weight-semibold;
      }

      .value {
        font-size: 1.5rem;
        line-height: 1;
      }

      .description {
        clear: both;
      }

      [dir=ltr] & {
        .label {
          float: left;
        }

        .value {
          float: right;
        }
      }

      [dir=rtl] & {
        .label {
          float: right;
        }

        .value {
          float:left;
        }
      }
    }
  }
}

.mtx-manager-users-trainings-widget {
  padding: @mtx-section-padding 0 0;

  .filter-form .form-item-search {
    .input-search-icon;
  }

  .view-empty {
    padding: @global-margin-large 0 1.25rem;
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .mtx-button-group > * {
      width: 100%;
    }
  }
}

#manager-dashboard-list-items {
  @toggleable-item-toggle-pos: (@global-margin-medium * 2);
  @user-item-avatar-size: @global-size-large;
  @course-item-banner-height: 4rem; // 64px
  @course-item-banner-width: 6.25rem; // 100px

  .item {
    margin-bottom: @global-margin;

    &-container {
      position: relative;
      border-radius: @global-border-radius;
      background-color: @page_content_background_color;

      .mtx-section:nth-of-type(odd) & {
        background-color: @mtx-section-even-bg-color;
      }

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark !important;
      }
    }

    &-image {
      .card-element-top(absolute);
      top: 50%;
      transform: translateY(-50%);
    }

    &-info {
      &-title h3 {
        font-size: 1.25rem;
        font-weight: @global-font-weight-bold;
      }

      a {
        .card-element-top();
      }
    }

    &-toggler {
      &:hover,
      &:focus {
        & + .item-toggler-icon {
          color: @_grey_dark_color;
        }
      }
    }

    &-toggler-icon {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: @_grey_color;
    }

    &.expanded {
      .item-toggler-icon .md-icon:after {
        content: '\e5ce'; // md-expand_less
      }
    }
  }

  // User.
  .item.user {
    .item-image {
      width: @user-item-avatar-size;
      height: @user-item-avatar-size;

      .observe {
        .circle(1rem);
        position: absolute;
        top: 0;
        background-color: @badge-danger-background;
      }
    }

    .item-info {
      min-height: @user-item-avatar-size;

      &-title h3 {
        margin-bottom: @global-margin-mini;
      }
    }

    table {
      img {
        min-width: 5rem;
        width: 5rem;
        height: 3rem;
        border-radius: @thumb-border-radius;
      }
    }
  }

  // Course.
  .item.course {
    .item-image {
      height: @course-item-banner-height;
      width: @course-item-banner-width;

      .mtx-thumb {
        padding-top: (@course-item-banner-height / @course-item-banner-width) * 100%;

        &,
        &-image,
        &-overlay {
          border-radius: @thumb-border-radius;
        }
      }
    }

    .item-info {
      min-height: @course-item-banner-height;

      &-title h3 {
        margin-bottom: 0;
      }
    }

    table {
      img,
      .default-avatar-wrapper {
        min-width: 3rem;
        width: 3rem;
        height: 3rem;
      }
    }
  }

  // Table.
  table {
    margin: 0 -@global-margin-mini;

    // Reset common tables styles.
    &, img {
      margin: 0;
    }

    [role="heading"] {
      font-size: @global-font-size;

      & ~ div {
        margin-top: @global-margin-mini;
      }
    }

    th, td {
      padding: @global-margin-mini;
    }

    th {
      padding-bottom: @global-margin-medium;
      font-size: @global-font-size;
    }

    td {
      padding-top: 0;
      padding-bottom: (@global-margin-medium * 2);
      min-width: 8rem;

      &.title-cell {
        min-width: 14rem;
      }

      &.progress-cell {
        min-width: 10rem;
      }

      &.recertification-cell {
        min-width: 9rem;
      }
    }

    .mtx-statistics-learner-progress {
      width: 5rem; // 80px
    }

    .actions-cell {
      white-space: nowrap;

      .uk-button + .uk-dropdown {
        width: 100%;
        text-align: initial;

        li a {
          .text-ellipsis();
        }
      }
    }
  }

  [dir=ltr] & {
    .item {
      &-container {
        padding: @global-margin-large @toggleable-item-toggle-pos @global-margin-large @global-margin-large;
      }

      &-toggler-icon {
        right: @toggleable-item-toggle-pos;
      }
    }

    // User.
    .item.user {
      .item-image .observe {
        right: 0;
      }

      .item-info {
        padding: 0 (@md-icon-size + @global-margin-large) 0 (@user-item-avatar-size + @global-margin-large);
      }
    }

    // Course.
    .item.course {
      .item-info {
        padding: 0 (@md-icon-size + @global-margin-large) 0 (@course-item-banner-width + @global-margin-large);
      }
    }

    // Table.
    .item-table-container {
      padding: @global-margin-large 0 0 @global-margin-large;
    }
  }

  [dir=rtl] & {
    .item {
      &-container {
        padding: @global-margin-large @global-margin-large @global-margin-large @toggleable-item-toggle-pos;
      }

      &-toggler-icon {
        left: @toggleable-item-toggle-pos;
      }
    }

    // User.
    .item.user {
      .item-image .observe {
        left: 0;
      }

      .item-info {
        padding: 0 (@user-item-avatar-size + @global-margin-large) 0 (@md-icon-size + @global-margin-large);
      }
    }

    // Course.
    .item.course {
      .item-info {
        padding: 0 (@course-item-banner-width + @global-margin-large) 0 (@md-icon-size + @global-margin-large);
      }
    }

    // Table.
    .item-table-container {
      padding: @global-margin-large @global-margin-large 0 0 0;
    }
  }

  @media (min-width: @breakpoint-medium) {
    .item-info > :nth-child(odd) {
      [dir=ltr] & {
        padding-right: @global-margin;
      }

      [dir=rtl] & {
        padding-left: @global-margin;
      }

      &:last-child {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

#manager-dashboard-item-details {
  h3 {
    font-weight: @global-font-weight-semibold;
    font-size: @global-font-size;
  }

  .item-details {
    & div + div {
      margin-top: @global-margin-small;
    }
  }

  .item-rubrics {
    .mtx-statistics-learner-progress {
      width: 5rem; // 80px
    }
  }
}
