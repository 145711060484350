// Actions.
.mtx-statistics-actions {
  margin-top: @mtx-section-padding;
  margin-bottom: @mtx-section-padding;

  .mtx-api-toggler-button {
    margin-bottom: 0;
  }

  & + #mtx-statistics-details-wrapper .mtx-section:first-of-type {
    padding-top: 0;
  }
}
