.view-participants {
  .views-exposed-widget {
    margin-bottom: @global-margin;

    @media all and (min-width: @breakpoint-medium) {
      .form-item.form-type-select {
        width: 300px;
      }

      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      width: 100%;
    }
  }
}
