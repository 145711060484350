.nei-widget-page_feedback {
  .page-feedback-link {
    text-decoration: none;
    color: inherit;

    svg {
      height: 2rem;
      fill: currentColor;
    }

    .voted {
      display: none;
    }

    &.active {
      .default {
        display: none;
      }

      .voted {
        display: block;
      }
    }
  }
}
