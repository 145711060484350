//** Onboarding functionality.
.mtx-onboarding-page {
  .mtx-empty-message-with-button {
    display: none;
  }

  &.empty {
    .mtx-onboarding-actions {
      display: none;
    }

    .mtx-empty-message-with-button {
      display: block;
    }
  }
}

.mtx-onboarding-rule-page-header {
  padding-top: @mtx-section-padding;
}

.mtx-onboarding-rule-content-wrapper {
  .item-wrapper {
    padding: @mtx-section-padding;
    background-color: @mtx-section-even-bg-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }

  .item-wrapper + .item-wrapper {
    margin-top: @global-margin-large;
  }

  &.not-empty + .uk-container {
    margin-top: @global-margin-large;
  }
}

.mtx-campaign {
  &-items {
    margin: 0 -@global-margin-mini;
  }

  &-item {
    margin: @global-margin-mini;

    .md-icon {
      font-size: @global-font-size;
    }

    .item-info {
      border: 1px solid @_grey_dark_color;
      padding: @global-margin-mini @global-margin-small;
      font-size: @global-font-size-small;
      line-height: 1.125rem;
      .break-word;

      .mtx-dark-theme & {
        border-color: @overlay-color-light;
      }
    }
  }
}

.mtx-onboarding-adaptive-learning-popup {
  .mtx-player {
    border-radius: @modal-dialog-border-radius;
    overflow: hidden;
  }
}

.mtx-onboarding-items-list {
  table {
    .title-cell {
      min-width: 10em;
    }

    .content-cell {
      min-width: 12em;
      max-width: 20em;
    }
  }
}
