.mtx-knowledge-base-main-page {
  @article-margin: @global-margin-large;

  .topics-list {
    margin-top: @global-margin-medium * 2;

    & > li {
      margin-bottom: @grid-gutter-horizontal;
    }
  }

  .topic-articles-list {
    min-height: calc((@global-line-height * @global-font-size * 4) + @article-margin * 3); // 4 - MTX_KNOWLEDGE_BASE_MAIN_PAGE_COUNT_ARTICLES

    & > li + li {
      margin-top: @article-margin;
    }

    li > a {
      .text-ellipsis-box(1);
    }
  }

  .topic-articles-see-all {
    min-height: calc(@global-line-height * @global-font-size);
    margin-top: @article-margin;

    & > a {
      .text-ellipsis-box(1);
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .popular-acrticles-list {
    .title {
      .text-ellipsis-box(1);
    }
  }

  @media all and (min-width: @breakpoint-xlarge) {
    .page-title {
      margin-right: auto;
      margin-left: auto;
      width: 75%;
    }
  }
}

.mtx-knowledge-base-article-autocomplete {
  margin: @global-margin-medium * 2 0;

  & > div {
    margin: 0 auto;

    &.uk-autocomplete {
      display: block;
    }

    @media all and (min-width: @breakpoint-small) {
      width: 75%;
    }

    @media all and (min-width: @breakpoint-medium) {
      width: 50%;
    }
  }
}

.mtx-knowledge-base-contact-link {
  @position: @page-header-padding-x;
  @position-large: @page-header-padding-x-large;

  bottom: @position;
  inset-inline-end: @position;
  z-index: @mtx-api-page-footer-nav-z-index + 1;

  .mtx-api-page-footer-nav-fixed + & {
    bottom: 1.2 * @mtx-api-page-footer-nav-padding + @mtx-api-page-footer-nav-title-font-size * @mtx-api-page-footer-nav-title-line-height;
  }

  @media all and (min-width: @breakpoint-large) {
    bottom: @position-large;
    inset-inline-end: @position-large;
  }
}
