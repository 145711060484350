// Markup:
//
// <ul class="mtx-list [comments-list]">
//   <li class="mtx-list-item">
//     <div class="icon"><a href=#></a></div>
//     <div class="item-content">
//       [<div class="mtx-action-item">]
//         <header>
//           <h2 class="title">
//             <a href=#></>
//             <span class="meta"></span>
//           </h2>
//           [<div class="meta"></div>]
//         </header>
//         <!-- Class 'mtx-text-editor-content' means that it's text editor content. -->
//         <div class="description [large] [mtx-text-editor-content]"></div>
//         <!-- Class 'offset' means that summary is placed to the right side. -->
//         <footer class="summary [offset]">
//           [<div class="summary-meta"></div>]
//           [<div class="mtx-members-list">
//             <span class="label"></span>
//             <ul class="icons"></ul>
//           [</div>]
//         </footer>
//       [</div>]
//       <!-- Html below is used only for nested comments list that contains replies list. -->
//       [<ul class="mtx-list comment-replies-list"></ul>]
//     </div>
//   </li>
// </ul>

// FYI
// [] - optional markup/properties.
// Comments are already wrapped into <li> tag in some places. So 'mtx-list-item' class
// is added to the next <div> that wraps comments. Otherwise <div> inside <li> is unnecessary
// and 'mtx-list-item' class is added to <li>.

// List variables
@list-item-padding-y: @global-margin;
@list-icon-size: 4rem;
@list-icon-size-small: 3rem;
@list-icon-size-xsmall: 2.5rem;
@list-icon-size-xxsmall: 2rem;
@list-icon-margin: @global-margin-large;
@list-icon-margin-xsmall: @global-margin;
@list-icon-margin-xxsmall: @global-margin;
@list-border-color: @page_content_background_color;
@list-border-width: @global-margin-small;
@list-text-font-size: @global-font-size-small;
@list-title-font-size: @global-font-size;
@list-content-margin: @list-icon-size + @list-icon-margin;
@list-content-margin-small: @list-icon-size-small + @list-icon-margin;
@list-content-margin-xsmall: @list-icon-size-xsmall + @list-icon-margin-xsmall;
@list-content-margin-xxsmall: @list-icon-size-xxsmall + @list-icon-margin-xxsmall;
@list-meta-font-size: @global-font-size-small;

.mtx-list {
  .reset-list;

  .grouping-title {
    font-size: 1.5rem;
    font-weight: @global-font-weight-bold;
    line-height: 1;
    margin: 0 0 @global-margin;
    color: @_grey_color;
  }

  .mtx-list-item + .mtx-list-item,
  & > li + li {
    margin-top: @global-margin-large;
  }
}

// Fixes views issue on infinite scroll.
.mtx-list-view-content {
  & + & {
    margin-top: @global-margin-large; // The same as for .mtx-list li + li {}
  }
}

.mtx-list-item {
  padding: 0 0 @list-item-padding-y;

  div&,
  li& {
    .clearfix;
  }

  tr& {
    & > td {
      padding-top: @list-item-padding-y;
      padding-bottom: @list-item-padding-y;
    }

    & + & > td {
      padding-top: @list-item-padding-y + @global-margin-large;
    }
  }

  .mtx-list & {
    padding-top: @list-item-padding-y;
  }

  .icon {
    width: @list-icon-size;
    max-width: @list-icon-size;
    margin: 0;
    margin-inline-end: @list-icon-margin;

    &.circle {
      &,
      .md-icon {
        border-radius: @global-border-radius-circle;
      }
    }

    & + .item-content {
      margin-inline-start: @list-content-margin;
    }

    &.small {
      width: @list-icon-size-small;
      max-width: @list-icon-size-small;

      .md-icon {
        width: @list-icon-size-small;
        line-height: @list-icon-size-small;
      }

      & + .item-content {
        margin-inline-start: @list-content-margin-small;
      }
    }

    &.xsmall {
      width: @list-icon-size-xsmall;
      max-width: @list-icon-size-xsmall;
      margin-inline-end: @list-icon-margin-xsmall;

      .md-icon {
        width: @list-icon-size-xsmall;
        line-height: @list-icon-size-xsmall;
      }

      & + .item-content {
        margin-inline-start: @list-content-margin-xsmall;
      }
    }

    &.xxsmall {
      width: @list-icon-size-xxsmall;
      max-width: @list-icon-size-xxsmall;
      margin-inline-end: @list-icon-margin-xxsmall;

      .md-icon {
        width: @list-icon-size-xxsmall;
        line-height: @list-icon-size-xxsmall;
      }

      & + .item-content {
        margin-inline-start: @list-content-margin-xxsmall;
      }
    }

    .md-icon {
      display: block;
      color: @_grey_dark_color;
      background-color: @_grey_lighten_color;
      width: @list-icon-size;
      line-height: @list-icon-size;
      border-radius: @global-border-radius;
      text-align: center;

      .mtx-dark-theme & {
        background-color: @overlay-color-light;
      }
    }

    a {
      display: block;

      .announcements-list &,
      .team-posts-list &,
      .comments-list &,
      .recent-updates-list &,
      .submissions-items-list & {
        border-radius: @global-border-radius-circle;
      }
    }

    [dir=rtl] [dir=ltr] &,
    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
    }
  }

  .icon-rectangle {
    img {
      border-radius: @thumb-border-radius;
      width: 100%;
    }
  }

  .resource-icon {
    img {
      background-color: @_grey_lighten_color;
    }
  }

  .item-content {
    .break-word();
  }

  .mtx-actions {
    line-height: 1;

    .uk-list-inline {
      li {
        vertical-align: top;
      }
    }
  }

  h1.page-title {
    margin-bottom: @global-margin;
  }

  .title {
    margin: 0;
    font-size: @list-title-font-size;
    font-weight: @global-font-weight-bold;
    line-height: @global-line-height;

    & > .meta {
      display: inline-block;
      font-weight: @global-font-weight-normal;
      font-size: @list-meta-font-size;
    }

    & > a {
      &,
      &:hover,
      &:focus {
        color: inherit;
      }
    }

    .uk-badge {
      position: relative;
      top: -2px;
      font-weight: @global-font-weight-semibold;
    }

    & > .md-icon {
      vertical-align: bottom;
    }
  }

  .description {
    font-size: @list-text-font-size;

    .uk-form & {
      margin-top: 0;
    }

    & > a.description-link {
      display: block;
      color: inherit;
      text-decoration: none;
    }

    &.large {
      font-size: @global-font-size;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .meta,
  .summary-meta {
    color: @_grey_dark_color;
    font-size: @list-meta-font-size;
    line-height: @global-line-height;

    &.uk-text-xsmall {
      font-size: @global-font-size-xsmall;
    }
  }

  .meta {
    display: block;

    &.meta-color-inherit {
      color: inherit;
    }
  }

  .summary {
    &.offset {
      text-align: end;
    }
  }

  .comments-actions {
    font-size: unit(@list-meta-font-size, rem);
    margin: @global-margin 0 0;

    & > li {
      line-height: 1.5;
      margin-inline-end: @global-margin-large;
    }
  }

  // @todo: Remove this if new layout is implemented everywhere
  .with-border > & {
    .mtx-content-section & {
      border-bottom: @list-border-width solid @list-border-color;
      margin: 0 -@global-margin;
      padding: @global-margin;

      .mtx-dark-theme & {
        border-bottom-color: transparent;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      &:only-child {
        padding: 0 @global-margin;
      }
    }
  }

  // E.g. Announcements on 'My overview' page.
  a.card-element {
    & ~ .item-content {
      .title {
        text-decoration: @base-link-text-decoration;
      }
    }

    &:hover,
    &:focus {
      & ~ .item-content {
        .title {
          text-decoration: @base-link-hover-text-decoration;
        }
      }
    }
  }

  .nei-widget-auto-color & {
    .meta,
    .summary,
    .summary-meta {
      color: inherit;
    }

    .title a {
      color: inherit;

      &:hover,
      &:focus {
        color: inherit;
        opacity: @widget-contrast-opacity;
      }
    }
  }
}
