.existing-training-details-modal {
  padding: @global-margin-small @modal-dialog-padding;
  margin: -@global-margin-small -@modal-dialog-padding;
  max-height: 469px; // 650px - header - footer - modals paddings.
  overflow-y: auto;

  .training-content {
    max-height: 195px; // 320px - header - footer
    overflow-y: auto;
  }
}

.existing-training-item {
  @item-border-width: 2px;
  @item-border-radius: @thumb-border-radius;
  @item-footer-border-radius: calc(@item-border-radius - @item-border-width);
  @item-padding: @global-margin-small;

  border-radius: @item-border-radius;

  & + & {
    margin-top: @global-margin;
  }

  .training-header {
    border-radius: @item-border-radius;
    padding: @item-padding;
    transition: background-color .15s ease-in-out;
  }

  .training-footer {
    background-color: @global-page-background-color;
    border-radius: 0 0 @item-footer-border-radius @item-footer-border-radius;
    padding: @item-padding;
  }

  .training-content > div,
  .training-footer {
    padding: @global-margin @global-margin-large;
  }

  // Item link.
  .training-link {
    border-radius: @item-border-radius;
    cursor: pointer;
  }

  // Item info.
  .thumbnail {
    position: relative;
    width: 80px;
    min-width: 80px;
    margin-inline-end: @global-margin-medium;
  }

  .mtx-thumb {
    &, &-image > img {
      border-radius: calc(@thumb-border-radius * .5);
    }
  }

  .training-restrictions-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: initial;
    border-radius: calc(@global-size-small / @button-border-radius-ratio);
    width: @global-size-small;
    height: @global-size-small;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: fade(@_white_color, 80%);

    .mtx-dark-theme & {
      color: @_white_color;
      background-color: fade(@_black_color, 80%);
    }

    .md-icon {
      font-size: 1.125rem;
    }
  }

  .description {
    .title {
      margin: 0 0 (@global-margin-mini / 2);
      font-size: @global-font-size;
      font-weight: @global-font-weight-normal;
    }

    .meta {
      font-size: @global-font-size-small;

      span + span {
        &:before {
          content: ' / ';
        }
      }
    }
  }

  .toggle-icon {
    min-width: @md-icon-size;
    margin-inline-end: @global-margin-large - @item-padding;
  }

  // Training restrictions.
  .activity-requirements-list {
    font-size: @global-font-size-xsmall;

    ul {
      list-style-type: disc;
      padding-inline-start: @global-margin-large;

      li {
        margin-top: 0;
      }
    }
  }

  // Item modules.
  .uk-accordion-content {
    border: @item-border-width solid @global-text-color;
    border-top: none;
    border-radius: 0 0 @item-border-radius @item-border-radius;
    position: relative;
    z-index: 2;

    .mtx-list-item {
      padding: 0;
      margin: @global-margin 0;

      & + .mtx-list-item {
        margin-top: 0;
      }
    }

    .title {
      font-size: @global-font-size-small;
      font-weight: @global-font-weight-normal;
    }

    .meta {
      font-size: @global-font-size-xsmall;
    }
  }

  // Item states.
  &.is-selected {
    .mtx-list-item,
    .description {
      .meta {
        color: inherit;
        opacity: .8;
      }
    }
  }

  &:not(.is-selected) {
    &:hover,
    &.is-focused {
      .training-header {
        background-color: @global-page-background-color;
      }

      .mtx-dark-theme & {
        background-color: @overlay-color-lighten;
      }
    }
  }

  &.is-expanded {
    .training-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .toggle-icon:after {
      content: '\e5ce'; // md-expand_less
    }
  }

  &.is-disabled {
    .training-header {
      background-color: @global-page-background-color !important;
    }

    .training-link {
      cursor: default;
    }

    .toggle-icon,
    .thumbnail,
    .description,
    .uk-accordion-content .item-content {
      opacity: .6;
    }

    .uk-accordion-content {
      border-color: @global-page-background-color;

      .mtx-list-item .icon .md-icon {
        color: @_grey_color;
      }
    }

    .mtx-dark-theme & {
      background-color: @overlay-color-lighten;

      .uk-accordion-content .item-content,
      .description {
        .meta {
          color: inherit;
        }
      }
    }
  }
}
