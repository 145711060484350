@mtx-course-el-actions-pos-x: @global-margin;
@mtx-course-el-actions-pos-y: @global-margin;
@mtx-course-el-actions-border-radius: calc(unit(@global-line-height + @global-margin-small, rem) / @button-border-radius-ratio);
@mtx-course-el-info-color: @_white_color;
@mtx-course-el-margin-top: @global-margin-small;
@mtx-course-el-price-line-height: 1.375;
@mtx-course-el-price-option-padding: @global-margin-large;
@mtx-course-el-thumb-info-z-index: @mtx-thumb-overlay-z-index; // 1
@mtx-course-el-thumb-info-overlay-z-index: @mtx-course-el-thumb-info-z-index + 1; // 2
@mtx-course-el-link-z-index: @mtx-course-el-thumb-info-overlay-z-index + 1; // 3

.mtx-courses {
  @wrapper-margin-bottom: @global-margin-medium * 2;

  .mtx-course-wrapper {
    margin-bottom: @wrapper-margin-bottom;
  }

  & + .item-list,
  & + .mtx-load-more-button {
    margin-top: @global-margin-large - @wrapper-margin-bottom;
  }
}

// It should be standalone because of
// styles issues on items sorting. (Combicourse)
.mtx-course-el {
  position: relative;

  .course-link {
    border-radius: @thumb-border-radius;
    z-index: @mtx-course-el-link-z-index;

    &[disabled] {
      cursor: default;
    }

    & + .course-details a,
    & + .course-details button,
    & + .course-details ~ a,
    & + .course-details ~ button,
    & + .course-details [data-uk-tooltip] {
      position: relative;
      z-index: @mtx-course-el-link-z-index;
    }

    & + .course-details .course-title {
      text-decoration: @base-link-text-decoration;
    }
  }

  &:hover,
  &:focus-within {
    .course-link + .course-details .course-title {
      text-decoration: @base-link-hover-text-decoration;
    }
  }

  // Banner
  .mtx-thumb {
    &,
    &-image,
    &-image > img,
    &-overlay {
      border-radius: @thumb-border-radius;
    }
  }

  .course-code,
  .course-version {
    position: absolute;
    bottom: @mtx-course-el-actions-pos-y;
    max-width: 100%;
    padding: 0 @mtx-course-el-actions-pos-x;
    font-size: @global-font-size-small;
    color: @mtx-course-el-info-color;
    z-index: @mtx-course-el-thumb-info-overlay-z-index;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .course-code {
    [dir=ltr] & {
      right: 0;
    }

    [dir=rtl] & {
      left: 0;
    }
  }

  .course-version {
    &,
    & + .course-code {
      max-width: 50%;
    }

    [dir=ltr] & {
      left: 0;
    }

    [dir=rtl] & {
      right: 0;
    }
  }

  .certification-stamp {
    width: 8em;
    bottom: @mtx-course-el-actions-pos-y;
    border-radius: 0;
    padding: (@global-margin-small / 2) @global-margin-small;
    z-index: @mtx-course-el-thumb-info-z-index;

    [dir=ltr] & {
      right: 0;
    }

    [dir=rtl] & {
      left: 0;
    }
  }

  .course-info-overlay {
    .position-cover();
    border-radius: @thumb-border-radius;
    background-color: @overlay-color-darken;
    color: @mtx-course-el-info-color;
    z-index: @mtx-course-el-thumb-info-overlay-z-index;

    p {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      padding: 0 @mtx-course-el-actions-pos-x;
      text-align: center;
      .text-ellipsis-box(2);
    }

    &.unpublished {
      display: none;
    }

    &.restricted .course-label {
      position: absolute;
      top: @mtx-course-el-actions-pos-y;

      [dir=ltr] & {
        left: @mtx-course-el-actions-pos-x;
      }

      [dir=rtl] & {
        right: @mtx-course-el-actions-pos-x;
      }
    }
  }

  &.course-unpublished {
    .course-info-overlay.unpublished {
      display: block;
    }

    .mtx-thumb-overlay,
    .course-promote,
    .course-add-to-activity {
      display: none;
    }
  }

  &.course-restricted {
    .course-restrict-message {
      margin: @global-margin-medium 0 0;
      font-size: @global-font-size-small;
      color: @_grey_dark_color;
    }
  }

  // Course tags, duration, actions.
  .course-tags {
    max-width: calc(100% - @mtx-course-el-actions-pos-x);

    [dir=ltr] & {
      right: @mtx-course-el-actions-pos-x / 2;
      padding-right: @global-margin-small;

      .label + .label {
        margin-left: @global-margin-mini;
      }
    }

    [dir=rtl] & {
      left: @mtx-course-el-actions-pos-x / 2;
      padding-left: @global-margin-small;

      .label + .label {
        margin-right: @global-margin-mini;
      }
    }
  }

  .additional {
    .card-element-top(absolute, @mtx-course-el-thumb-info-overlay-z-index);
    top: @mtx-course-el-actions-pos-y;

    &.additional-with-interactive-els {
      z-index: @mtx-course-el-link-z-index;
    }

    & + .course-tags {
      max-width: calc(100% - @course-label-max-width - @mtx-course-el-actions-pos-x);
    }

    [dir=ltr] & {
      left: @mtx-course-el-actions-pos-x;
    }

    [dir=rtl] & {
      right: @mtx-course-el-actions-pos-x;
    }
  }

  .course-bookmark,
  .course-promote,
  .course-add-to-activity,
  .course-toggle-hidden {
    a {
      display: block;
      padding: @global-margin-mini;
      line-height: @md-icon-size;
      text-align: center;
      border-radius: @mtx-course-el-actions-border-radius;

      .md-icon {
        width: @md-icon-size;
        height: @md-icon-size;
        font-size: @md-icon-size;
        line-height: inherit;
        vertical-align: top;
      }

      &.active,
      &.uk-active {
        .md-icon {
          font-size: @md-icon-size-small;
        }
      }
    }
  }

  .course-labels {
    max-width: @course-label-max-width;
  }

  .course-labels + .course-bookmark,
  .course-labels + .course-promote,
  .course-labels + .course-add-to-activity,
  .course-labels + .course-toggle-hidden,
  .course-bookmark + .course-promote {
    [dir=ltr] & {
      margin-left: @mtx-course-el-actions-pos-x;
    }

    [dir=rtl] & {
      margin-right: @mtx-course-el-actions-pos-x;
    }
  }

  .course-labels + .course-bookmark,
  .course-labels + .course-promote,
  .course-labels + .course-add-to-activity,
  .course-labels + .course-toggle-hidden {
    .uk-touch[dir=ltr] & {
      margin-left: @mtx-course-el-actions-pos-x / 2;
    }

    .uk-touch[dir=rtl] & {
      margin-right: @mtx-course-el-actions-pos-x / 2;
    }
  }

  .course-bookmark + .course-promote {
    .uk-touch[dir=ltr] & {
      margin-left: 0;
    }

    .uk-touch[dir=rtl] & {
      margin-right: 0;
    }
  }

  .course-info-overlay.restricted {
    & + .additional {
      .course-labels,
      .course-toggle-hidden:first-child {
        [dir=ltr] & {
          margin-left: @mtx-course-el-actions-pos-x + @md-icon-size + @global-margin-mini;
        }

        [dir=rtl] & {
          margin-right: @mtx-course-el-actions-pos-x + @md-icon-size + @global-margin-mini;
        }
      }
    }
  }

  .mtx-actions {
    top: @mtx-course-el-actions-pos-y;
    inset-inline-end: @mtx-course-el-actions-pos-x;

    & > a.mtx-handler {
      padding: @global-margin-mini;
      border-radius: @mtx-course-el-actions-border-radius;
    }

    &.mtx-cloning-progress {
      .spinner {
        width: @md-icon-size;
        height: @md-icon-size;

        circle {
          stroke: @mtx-course-el-info-color;
        }
      }
    }
  }

  // Course progress
  .course-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @mtx-course-el-thumb-info-z-index;

    .mtx-progress-bar {
      // mtx-thumb-overlay should have class "light"
      // to prevent overlay and progressbar to be mixed
      background-color: @overlay-color-darken;
    }
  }

  // Info
  .course-title {
    margin: @global-margin 0 0;
    font-size: @global-font-size;
    .text-ellipsis-box(2, @global-line-height);
  }

  .course-description {
    margin: @global-margin-medium 0 0;
    font-size: @global-font-size-small;
    .text-ellipsis-box(3);
  }

  .course-status {
    margin-top: @global-margin-small;
    font-size: @global-font-size-small;
    color: @_grey_dark_color;

    .md-icon {
      font-size: 1.25rem;
    }
  }

  .activity-info,
  .course-language,
  .updated-date {
    margin-top: @mtx-course-el-margin-top;
    font-size: @global-font-size-small;
    line-height: 1.3;
  }

  .activity-info {
    .clearfix;
    margin-top: @mtx-course-el-margin-top;

    & > div {
      width: 50%;
      margin-top: @mtx-course-el-margin-top;

      &:only-child {
        width: 100%;
      }
    }

    [dir=ltr] & {
      & > div {
        float: left;

        &:nth-child(even) {
          text-align: right;
        }
      }
    }

    [dir=rtl] & {
      & > div {
        float: right;

        &:nth-child(even) {
          text-align: left;
        }
      }
    }
  }

  .get-certificate-link {
    display: inline-block;
    margin-top: @mtx-course-el-margin-top;
    font-size: @global-font-size-small;
  }

  .activity-deadline {
    display: inline-block;
    border: 1px solid transparent;
    padding: @button-small-padding-vertical 0;
    max-width: 100%;
    overflow: hidden;
  }

  .activity-deadline {
    border-radius: @global-border-radius-circle;
    padding: @button-small-padding-vertical @button-small-padding-horizontal;
  }

  .activity-status,
  .activity-schedule {
    padding: @global-margin-mini 0;
  }

  .course-price {
    margin-top: @global-margin-medium;
    line-height: @mtx-course-el-price-line-height;
    .text-ellipsis();
  }

  .price-raw {
    margin-top: @global-margin-medium;
    line-height: @mtx-course-el-price-line-height;
    .text-ellipsis();
  }

  .price-options {
    .clearfix();
    margin-top: @global-margin-medium;

    &.multiple {
      .price-option {
        [dir=ltr] & {
          float: left;

          &:last-child {
            border-left: 1px solid @_grey_light_color;
          }
        }

        [dir=rtl] & {
          float: right;

          &:last-child {
            border-right: 1px solid @_grey_light_color;
          }
        }
      }
    }

    .price-option {
      .value {
        margin-top: @global-margin-small;
        line-height: @mtx-course-el-price-line-height;
      }

      .option {
        font-size: @global-font-size-small;
      }

      [dir=ltr] & {
        &:first-child {
          padding-right: @mtx-course-el-price-option-padding;
        }

        & + .price-option {
          padding-left: @mtx-course-el-price-option-padding;
        }
      }

      [dir=rtl] & {
        &:first-child {
          padding-left: @mtx-course-el-price-option-padding;
        }

        & + .price-option {
          padding-right: @mtx-course-el-price-option-padding;
        }
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .mtx-thumb-overlay {
      opacity: 0;
      transition: @global-hover-transition; // To ensure the same behaviour with other elements.
    }

    .course-code,
    .course-version,
    .course-bookmark,
    .course-promote,
    .course-add-to-activity,
    .course-toggle-hidden {
      pointer-events: none;
      opacity: 0;
      transition: @global-hover-transition;
    }

    &:hover,
    &:focus-within {
      .mtx-thumb-overlay {
        opacity: 1;
      }

      .course-code,
      .course-version,
      .course-bookmark,
      .course-promote,
      .course-add-to-activity,
      .course-toggle-hidden,
      .mtx-actions {
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  .uk-touch & {
    .course-code,
    .course-version,
    .course-bookmark,
    .course-promote,
    .course-add-to-activity,
    .course-toggle-hidden {
      pointer-events: initial;
      opacity: 1;
    }
  }

  &.mtx-combi-course-item {
    .course-link .md-icon {
      position: absolute;
      top: @mtx-course-el-actions-pos-y;
      line-height: 1;
      color: @_white_color;
      opacity: 0;
      transition: @global-hover-transition;

      [dir=ltr] & {
        right: @mtx-course-el-actions-pos-x;
      }

      [dir=rtl] & {
        left: @mtx-course-el-actions-pos-x;
      }
    }

    &.combi-course-item-added {
      .mtx-thumb-overlay,
      .course-code,
      .course-version,
      .course-link .md-icon {
        opacity: 1;
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      .course-link .md-icon {
        opacity: 0;
        transition: @global-hover-transition;
      }

      &:hover,
      &:focus-within {
        .course-link .md-icon {
          opacity: 1;
        }
      }
    }

    .uk-touch & {
      .course-link .md-icon {
        opacity: 1;
      }
    }
  }
}

.view-courses {
  @media all and (max-width: @breakpoint-small-max) {
    .views-exposed-form {
      .form-item.form-type-textfield {
        margin-bottom: 0;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .views-exposed-form {
      .form-item.form-type-textfield {
        width: 100%;
      }
    }

    .filter-form {
      width: calc(100% - @form-height - @global-margin);
    }
  }
}
