.pager {
  &-first a:before,
  &-last a:before,
  &-previous a:before,
  &-next a:before {
    .fa-icon;
    font-size: 20px;
  }

  &-first a:before {
    content: '\00AB';
  }

  &-last a:before {
    content: "\00BB";
  }

  &-previous a:before {
    content: "\2039";
  }

  &-next a:before {
    content: "\203A";
  }

  &-current {
    font-weight: @global-font-weight-semibold;
  }

  .spinner {
    margin: @global-margin;
    width: @md-icon-size;
    height: @md-icon-size;
  }

  @media screen and (max-width: @breakpoint-mini-max) {
    &-ellipsis,
    &-item {
      display: none !important;
    }
  }
}

//** Markup generated by theme_pager().
.item-list {
  transition: opacity .15s ease-in-out;

  &.pager-remove {
    opacity: 0;
  }

  .pager {
    clear: both;
    text-align: center;
    font-size: @global-font-size-small;
    padding: @global-margin-small 0;
    margin: 0;

    li {
      background-image: none;
      display: inline;
      list-style-type: none;
      padding: 0;
      margin: 0;

      & ~ li {
        margin-left: @global-margin;

        [dir=rtl] & {
          margin-left: 0;
          margin-right: @global-margin;
        }
      }

      a {
        color: @global-muted-color;

        &:hover,
        &:active {
          color: inherit;
        }
      }

      &.pager-ellipsis {
        color: @global-muted-color;
      }
    }

    .pager-current {
      font-weight: @global-font-weight-semibold;
    }
  }
}
