// Unviewed items counter and bullet styles
.unviewed-entities-counter {
  display: inline-block;
  font-size: 12px;
  line-height: 1.25;
  background-color: #fb2b3a;
  color: #ffffff;
  margin: 3px 0 0;
  padding: 2px 5px;
  text-align: center;
  vertical-align: top;
  border-radius: 10px;
  opacity: 0;

  #dashboard-my-courses & {
    opacity: 1;
  }

  .menu & {
    margin-right: .25em;

    @media screen and (max-width: @breakpoint-small-max) {
      float: right;
    }
  }

  div.unviewed-entities-processed & {
    font-size: 0px;
    text-align: center;
    padding: 0;

    &-done {
      font-size: 12px;
      padding: 2px 5px;
      min-width: 19px;
      visibility: visible;
      opacity: 1;
      transition: all .15s ease-in-out;
    }
  }
}

span.unviewed-entities-bullet {
  i.fa {
    font-size: 10px;
    margin-right: .375em;
    vertical-align: middle;
  }

  .uk-badge {
    border-radius: @global-border-radius-circle;
  }
}
