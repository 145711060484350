.view-organizations {
  .uk-button,
  #term-appearance-update-appearance-batch-form {
    display: inline-block;
  }
}

.organizations-content-filter-form {
  padding: @focus-state-box-shadow-size;
  margin: -@focus-state-box-shadow-size;
  overflow-y: visible;

  .form-item-content-type {
    width: 15rem;

    .chosen-results li {
      .text-ellipsis();
    }

    @media all and (max-width: @breakpoint-small-max) {
      width: 100%;
    }
  }
}

.organizations-content-list-table {
  .title {
    min-width: 12.5em;
    max-width: 20rem;

    a {
      font-weight: @global-font-weight-semibold;
    }
  }

  .type {
    min-width: 5rem;
  }

  .organizations {
    min-width: 10rem;
    max-width: 22rem;
  }
}
