body.course-fullscreen {
  position: relative;
  min-height: 100%;
  padding: 0;
  margin: 0;

  .nei-container {
    margin: 0;
    padding: @nei-container-padding;
  }

  .nei-widget {
    border: none;
  }

  .mtx-additional-products-section,
  .mtx-api-page-footer-nav {
    padding-left: @nei-container-padding;
    padding-right: @nei-container-padding;
  }
}

.course-fullscreen-exit-link.page-quick-link {
  @icon-pos: @nei-container-padding + @global-margin;

  top: @icon-pos;
  z-index: 1;

  &:focus {
    opacity: .7 !important;
  }

  [dir=ltr] & {
    right: @icon-pos;
  }

  [dir=rtl] & {
    left: @icon-pos;
  }
}
