.bulk-operations {
  padding: @global-margin-large 0;
  background-color: @mtx-section-even-bg-color;

  &-select-all-button {
    text-decoration: underline;
  }

  .uk-button + .uk-button {
    margin-inline-start: 0;
  }

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  &-items {
    &.auto-select {
      .bulk-operations-hidden-actions {
        visibility: hidden;
      }
    }

    &.single-selected .selected {
      .bulk-operations-hidden-actions {
        visibility: visible;
      }
    }
  }
}
