.mtx-catalog-banner {
  @text-padding: 5rem; // 80px
  @text-padding-top: @text-padding * .75;

  &-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &-text {
    .break-word();
  }

  &-title {
    font-size: 2.25em; // 36px
    line-height: 1.2;
    margin: 0;
  }

  &-description {
    @padding: @global-margin-medium * 2; // 32px

    position: relative;
    padding-top: @padding * 2; // 64px

    &:before {
      content: '';
      width: @padding * 2; // 64px
      height: .25rem; // 4px
      position: absolute;
      top: @padding;
    }
  }

  &-button {
    margin-top: @global-margin-large * 2; // 48px
  }

  @media all and (max-width: @breakpoint-small-max) {
    &-img {
      display: none;
    }

    &-text {
      padding: (@text-padding-top / 4) 0 (@text-padding / 4);
    }

    .with-image &-text {
      padding-left: @utility-margin;
      padding-right: @utility-margin;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    &-text {
      padding: (@text-padding-top / 4) 0 (@text-padding / 4);
    }

    .with-image &-text {
      padding-left: @text-padding / 4;
      padding-right: @text-padding / 4;
    }
  }

  @media all and (min-width: @breakpoint-large) {
    &-text {
      padding: (@text-padding-top / 2) 0 (@text-padding / 2);
    }

    .with-image &-text {
      padding-left: @text-padding / 2;
      padding-right: @text-padding / 2;
    }
  }

  @media all and (min-width: @breakpoint-xlarge) {
    &-text {
      padding: @text-padding-top 0 @text-padding;
    }

    .with-image &-text {
      padding-left: @text-padding;
      padding-right: @text-padding;
    }
  }
}
