//** @file: Overrides User module styles

//** Password strength indicator.
.password-strength {
  width: 100%;
  margin-bottom: @global-margin;
  font-size: @global-font-size-small;

  &-title {
    display: inline;
  }

  &-text {
    font-weight: @global-font-weight-semibold;

    [dir=ltr] & {
      float: right;
    }

    [dir=rtl] & {
      float: left;
    }
  }
}

.password-indicator {
  background-color: @_grey_light_color;
  height: .3em;
  width: 100%;

  .mtx-dark-theme & {
    background-color: @overlay-color-light;
  }

  div {
    height: 100%;
    width: 0;
    background-color: @global-success-background;
  }
}

.confirm-parent,
.password-parent {
  position: relative;
}

div.password-confirm {
  position: absolute;
  bottom: 0;
  font-size: 0;

  span:before {
    .md-icon();
    display: block;
    line-height: @form-height;
    text-align: center;
  }

  .ok:before {
    content: '\f0be'; // md-check_circle
    color: @global-success-background;
  }

  .error:before {
    content: '\f8b6'; // md-error_outline
    color: @global-warning-background;
  }

  [dir=ltr] & {
    right: @form-padding-x;
  }

  [dir=rtl] & {
    left: @form-padding-x;
  }
}

div.password-suggestions {
  padding: @global-margin;
  margin: @global-margin 0;
  border: none;
  background: @_grey_light_color;
  color: @global-muted-color;
  font-size: @global-font-size-small;
  border-radius: @global-border-radius;

  .mtx-dark-theme & {
    background: @overlay-color-light;
    color: inherit;
  }
}

div.password-suggestions ul {
  margin-bottom: 0;

  .additional-requirements {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
}
