.mtx-statistics-block {
  @block-padding: @global-margin-medium;
  @block-padding-large: @block-padding * 2;
  @block-padding-mobile: @global-margin;
  @block-icon-opacity: .5; // @page-sidebar-icon-opacity

  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity .15s linear;
  -webkit-tap-highlight-color: transparent;
  box-sizing: content-box;
  z-index: 14; // webinar button case
  cursor: default;

  .content {
    position: relative;
    box-shadow: @page-sidebar-box-shadow;
  }

  .item {
    margin-bottom: 0;

    & + .item {
      margin-top: @global-margin-large;
    }

    .md-icon {
      opacity: @block-icon-opacity;
    }

    .md-icon ~ span {
      vertical-align: middle;
    }
  }

  .md-chevron_left,
  .md-chevron_right,
  .spinner {
    color: inherit !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .15s linear;
  }

  .md-chevron_left,
  .md-chevron_right {
    font-size: 2rem;
    font-weight: @global-font-weight-bold;
    opacity: @block-icon-opacity;
  }

  .spinner {
    width: @md-icon-size;
    height: @md-icon-size;
    opacity: 0;
  }

  &:not(.is-rendered) {
    visibility: hidden;
    opacity: 0;
  }

  &:not(.visible-details) {
    .items {
      opacity: 0;
    }
  }

  &.loading {
    .spinner {
      opacity: @block-icon-opacity;
    }
  }

  &.visible-details {
    .content {
      box-shadow: 0 0 8px fade(#000000, 6%); // @page-sidebar-opened-box-shadow
    }
  }

  &.loading,
  &.visible-details {
    .md-chevron_left,
    .md-chevron_right {
      opacity: 0;
    }
  }

  [dir=ltr]:not(.nei-container) & {
    right: 0;
    padding: @block-padding 0 @block-padding-large @block-padding-large;
    margin: -@block-padding 0 -@block-padding-large -@block-padding-large;

    .content {
      border-radius: 10px 0 0 10px;
      padding: @global-margin-large @global-margin-medium * 2 @global-margin-large @global-margin-large;
    }

    .md-chevron_left,
    .md-chevron_right {
      left: .0625rem;
    }

    .spinner {
      left: .375rem;
    }
  }

  [dir=rtl]:not(.nei-container) & {
    left: 0;
    padding: @block-padding @block-padding-large @block-padding-large 0;
    margin: -@block-padding -@block-padding-large -@block-padding-large 0;

    .content {
      border-radius: 0 10px 10px 0;
      padding: @global-margin-large @global-margin-large @global-margin-large @global-margin-medium * 2;
    }

    .md-chevron_left,
    .md-chevron_right {
      right: .0625rem;
    }

    .spinner {
      right: .4375rem;
    }
  }

  @media all and (max-width: @breakpoint-medium) {
    [dir=ltr]:not(.nei-container) & {
      padding: @block-padding-mobile 0 @block-padding-mobile @block-padding-mobile;
      margin: -@block-padding-mobile 0 -@block-padding-mobile -@block-padding-mobile;
    }

    [dir=rtl]:not(.nei-container) & {
      padding: @block-padding-mobile @block-padding-mobile @block-padding-mobile 0;
      margin: -@block-padding-mobile -@block-padding-mobile -@block-padding-mobile 0;
    }
  }
}
