// Avatar canvas icon.
.mtx-avatar-icon {
  max-width: 100%;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .05);

  &[data-rounded] {
    border-radius: @global-border-radius;
  }
}

.mtx-anonymous-avatar-icon(@size: 3rem) {
  .circle(@size);
  text-align: center;
  background-color: @_grey_lighten_color;
  color: @_grey_dark_color;

  .md-icon {
    line-height: @size;
  }

  td.active & {
    background-color: darken(@table-active-cell-bg-color, 5%);
  }

  .table-row-muted & {
    opacity: .5;
  }

  .mtx-dark-theme &,
  .mtx-dark-theme td.active & {
    background-color: @overlay-color-light;
    color: @_grey_light_color;
  }
}
