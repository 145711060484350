.mtx-store-front-form {
  .cke_contents {
    height: 6rem !important;
  }

  .form-item-banner-height {
    input {
      width: @field-type-number-width;
    }
  }
}

@media all and (max-width: @breakpoint-small-max) {
  .mtx-store-front-form {
    .uk-grid-width-medium-1-2 > div + div {
      margin-top: @global-margin;
    }
  }

  .mtx-store-front-preview {
    display: none;
  }
}
