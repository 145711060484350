.mtx-timezone-field {
  @field-height: 2rem;

  .chosen-container-single {
    .chosen-single {
      border-color: transparent !important;
      padding: 0;
      line-height: @field-height;
      height: @field-height;
      margin: (@form-height - @field-height) / 2 0;

      span {
        line-height: calc(@field-height - 2 * @form-border-width);

        &:before {
          .material-icon();
          content: '\e80b'; // md-public
          color: @global-placeholder-color;

          [dir=ltr] & {
            margin-right: @global-margin-small;
          }

          [dir=rtl] & {
            margin-left: @global-margin-small;
          }
        }
      }

      div b {
        display: none;
      }
    }
  }

  .chosen-container-active {
    background: transparent;
  }

  .chosen-drop {
    border-radius: @dropdown-border-radius;
  }

  select {
    visibility: hidden;
  }

  label {
    display: none;

    @media speech {
      display: block;
    }
  }
}

.mtx-timezone-field-with-offset {
  .chosen-container {
    a.chosen-single > span {
      margin: 0;
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    [dir=ltr] & {
      margin-left: unit(@datepicker-time-input-width + .35, rem);
    }

    [dir=rtl] & {
      margin-right: unit(@datepicker-time-input-width + .35, rem);
    }
  }
}
