//
// Component: Tooltip
//
// ========================================================================


// Variables
// ========================================================================

@tooltip-z-index:                               @global-z-index + 30;
@tooltip-max-width:                             280px;
@tooltip-padding-vertical:                      @global-margin;
@tooltip-padding-horizontal:                    @global-margin-medium;
@tooltip-background:                            @tooltip_color;
@tooltip-triangle-size:                         0;

@tooltip-color:                                 @tooltip_text_color;
@tooltip-font-size:                             @global-font-size-small;
@tooltip-line-height:                           1.25;
@tooltip-border-radius:                         @global-border-radius;

@tooltip-large-padding-vertical:                @global-margin-medium;
@tooltip-large-padding-horizontal:              @global-margin-medium-2;

@tooltip-xlarge-padding-vertical:               @global-margin-large;
@tooltip-xlarge-padding-horizontal:             @global-margin-large-2;


// Component
// ========================================================================

.hook-tooltip() {
  border-radius: @tooltip-border-radius;
  word-break: break-word;

  &:after {
    display: none !important;
  }

  & &-inner {
    white-space: pre-wrap;
  }

  @media screen and (max-width: @breakpoint-xlarge) {
    & {
      display: none !important;
    }
  }

  &-disable & {
    display: none !important;
  }

  &.medium {
    max-width: 300px;
  }

  &.large {
    max-width: 400px;
    padding: @tooltip-large-padding-vertical @tooltip-large-padding-horizontal;
  }

  &.xlarge {
    max-width: 500px;
    padding: @tooltip-xlarge-padding-vertical @tooltip-xlarge-padding-horizontal;
  }

  html.uk-modal-page & {
    z-index: @modal-z-index + 1;
  }
}


// Miscellaneous
// ========================================================================

.hook-tooltip-misc() {}
