.mtx-members-list {
  .label {
    color: @global-muted-color;
    display: inline-block;
    font-size: @global-font-size-small;
    vertical-align: middle;
  }

  .icons {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 0 0 @global-margin-small;
    vertical-align: middle;

    li {
      display: inline-block;
      max-width: @global-height;

      img {
        margin: 0;
        padding: 0;
      }
    }

    [dir=rtl] & {
      margin: 0 @global-margin-small 0 0;
    }
  }
}
