.editor-toolbar {
  font-family: @editor-font-sans-serif;
  font-size: @editor-font-size;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: @global-hover-transition;
  z-index: @editor-toolbar-z-index;
  background-color: @editor-bg-color;
  border: none;
  border-radius: @editor-border-radius;
  box-shadow: 0 4px 12px fade(@global-text-color, 21%);

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    [dir=ltr]& {
      float: left;
    }

    [dir=rtl]& {
      float: right;
    }
  }

  .editor-action {
    background-color: transparent;
    display: block;
    font-size: inherit;
    text-align: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: none;
    border-radius: @editor-border-radius;
    line-height: @editor-button-size;
    height: @editor-button-size;
    min-width: @editor-button-size;
    transition: all .2s ease-in;
    color: @editor-link-color;

    & > * {
      font-size: unit(@md-icon-size * @editor-font-size, px);
      font-weight: normal;
      vertical-align: middle;
    }
  }

  label,
  button,
  a {
    &.editor-action {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: @editor-link-hover-bg-color;
        color: @editor-link-hover-color;
        outline: none;
      }

      &.editor-button-active {
        background-color: @editor-link-active-bg-color;
        color: @editor-link-active-color;
      }
    }
  }

  .editor-button-actions .editor-button-active {
    .md-link {
      &:after {
        content: '\e16f'; // md-link_off
      }
    }
  }

  .editor-toolbar-actions {
    .clearfix;
  }

  .editor-action-bold {
    font-weight: bolder;
  }

  .editor-action-italic {
    font-style: italic;
  }

  .editor-action-underline {
    text-decoration: underline;
  }

  .editor-action-pre {
    font-family: @editor-font-fixed;
    font-size: 12px;
    font-weight: 100;
    padding: 15px 0;
  }
}

// Toolbar states
.editor-toolbar-active {
  visibility: visible;
  opacity: 1;

  &.editor-stalker-toolbar {
    animation: editor-pop-upwards 250ms forwards linear;
  }
}

.editor-sticky-toolbar {
  position: fixed;
  top: 1px;
}

.editor-relative-toolbar {
  position: relative;
}
