.mtx-video-preview-container {
  &.video-reactive {
    .mtx-video-preview {
      opacity: 1;
    }

    .mtx-thumb-overlay,
    .mtx-video-preview-poster {
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
}

.mtx-video-preview {
  .position-cover();
  border-radius: @thumb-border-radius;
  opacity: 0;
  overflow: hidden;

  mtx-video-preview {
    display: block;
    .position-cover();
    border-radius: inherit;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  html.modern-edge & {
    z-index: 0;

    video {
      .position-center();
      max-height: unset;
      z-index: -1;
    }
  }
}
