.hook-api-item() {
  &.product {
    .mtx-thumb {
      padding-top: 95%; // 172 / 180

      &,
      &-image {
        border-radius: @thumb-border-radius;
      }

      &-image > img {
       .mtx-product-thumb-image();
      }

      .md-icon {
        font-size: 3rem;
      }

      & ~ * {
        margin: @global-margin-small 0 0;
      }
    }

    .title {
      .text-ellipsis-box(2);
      margin-top: @global-margin;
    }
  }
}
