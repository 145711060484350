.subscription-page {
  &.subscription-failed {
    .subscription-payment-error {
      color: #ff0000;
    }
  }

  &.subscription-page-without-trainings {
    .subscription-page-header-block {
      padding-bottom: 0;

      & + .subscription-page-block {
        padding-top: 0;
      }
    }
  }
}

.subscription-page-block {
  padding: @mtx-section-padding 0;

  & > .uk-container {
    @media all and (min-width: @breakpoint-medium) {
      max-width: @utility-narrow-container-medium-max-width;
    }

    @media all and (min-width: @breakpoint-large) {
      max-width: @utility-narrow-container-large-max-width;
    }
  }
}

.subscription-page-overview-block {
  background-color: @mtx-section-even-bg-color;

  .mtx-courses {
    &.collapsed {
      & > li:nth-child(4) ~ li {
        display: none;
      }
    }
  }

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }
}

.subscription-page-types-block {
  .subscription-types {
    & + .payment-methods {
      margin-top: @global-margin-medium * 2 - @form-item-margin-top;
    }
  }

  .subscription-type {
    border-radius: @global-border-radius;
    padding: @global-margin-large @global-margin-medium * 2;
    background-color: @mtx-section-even-bg-color;

    .price-raw,
    .title {
      font-weight: @global-font-weight-semibold;
    }

    .details {
      text-align: end;
    }

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }

    @media (max-width: 640px) {
      .title {
        margin-bottom: @global-margin;
      }

      .details {
        text-align: start;
        width: 100%;
      }
    }
  }
}

.subscription-page-payments-block {
  .subscription-payment-methods {
    .form-item {
      margin: @form-item-margin-top 0 @form-item-margin-bottom;
    }
  }

  .subscription-payment-method-overview {
    & > div {
      border-radius: @global-border-radius;
      padding: @global-margin-large @global-margin-medium * 2;
      background-color: @mtx-section-even-bg-color;

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark;
      }
    }

    & + .subscription-payment-method {
      margin-top: @global-margin-medium * 2;
    }

    .title {
      font-weight: @global-font-weight-semibold;
    }
  }

  .subscription-payment-cards-list {
    img {
      max-height: @md-icon-size;
    }
  }

  .payment-confirm {
    margin-top: calc(2.5rem - @form-item-margin-bottom);

    p {
      margin: 0;
    }
  }

  .subscription-submit-button {
    margin-top: 2.5rem;
  }

  .subscription-security-block-wrapper {
    margin-top: calc(2.5rem - @global-margin);
    font-size: @global-font-size-small;
  }

  .subscription-security-block-item {
    margin-top: @global-margin;

    // The same as .mtx-list-item .icon {}
    .icon {
      width: @list-icon-size-xsmall;
      max-width: @list-icon-size-xsmall;
      margin-inline-end: @list-icon-margin-xsmall;

      &,
      .md-icon {
        border-radius: @global-border-radius-circle;
      }

      .md-icon {
        display: block;
        color: @_grey_dark_color;
        background-color: @_grey_lighten_color;
        width: @list-icon-size-xsmall;
        line-height: @list-icon-size-xsmall;
        border-radius: @global-border-radius-circle;
        text-align: center;

        .mtx-dark-theme & {
          background-color: @overlay-color-light;
        }
      }
    }
  }
}

.subscription-page-payment-details-block {
  @action-margin-bottom: @global-margin-small;

  .payment-details {
    margin-top: 2.5rem;

    a {
      display: inline-block;
      margin-bottom: @action-margin-bottom;
    }

    & + .payment-details {
      margin-top: calc(2.5rem - @action-margin-bottom);
    }
  }

  .payment-details-billing {
    .uk-text-right > div + div {
      margin-inline-start: @global-margin-medium;
    }
  }
}
