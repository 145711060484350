.course-tags {
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  font-size: @global-font-size-small;
  line-height: unit(@global-line-height, rem);
  padding: @global-margin-small / 2;
  background-color: fade(@_white_color, 80%);
  color: @global-text-color;
  position: absolute;
  top: @mtx-course-el-actions-pos-y;
  z-index: 1;
  border-radius: calc(unit(@global-line-height + @global-margin-small, rem) / @button-border-radius-ratio);

  .label {
    display: inline-block;
    font-weight: @global-font-weight-semibold;

    & > * {
      vertical-align: bottom;
      line-height: inherit;
    }
  }

  .md-icon {
    font-size: 1.125rem;
  }
}
