.mtx-coursebuilder {
  @connection-border-width: 4px;

  &-container {
    overflow-x: auto;
    padding-top: 6rem;
    margin-top: -6rem;
    user-select: none;
  }

  &-paper {
    position: relative;
    margin: @global-margin auto;
    width: 100%;
    min-height: 260px;

    &-container {
      min-width: @breakpoint-medium;
    }
  }

  &-actions {
    margin: @global-margin-large auto;
  }

  .course-item {
    position: absolute;

    &-bubble {
      position: relative;
      border-radius: @button-border-radius-large;
      padding: @global-margin;
      margin: 0;
    }

    &-connection {
      position: absolute;

      & > div {
        position: absolute;
        top: -@connection-border-width / 2;
        bottom: -@connection-border-width / 2;
        left: 50%;
        border-left: @connection-border-width solid @_grey_light_color;
        margin-left: -@connection-border-width / 2;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        border: @connection-border-width / 2 solid @_grey_light_color;
        width: 50%;
      }

      [dir=ltr] & {
        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }

      [dir=rtl] & {
        &:before {
          right: 0;
        }

        &:after {
          left: 0;
        }
      }

      &.top {
        &:before {
          bottom: -@connection-border-width / 2;
        }

        &:after {
          top: -@connection-border-width / 2;
        }
      }

      &.bottom {
        &:before {
          top: -@connection-border-width / 2;
        }

        &:after {
          bottom: -@connection-border-width / 2;
        }
      }
    }

    .item-icon {
      display: inline-block;
      .button-border-radius(@nei-controls-size / 16);
      padding: @global-margin-small;

      .md-icon {
        vertical-align: bottom;
      }
    }

    .item-title {
      padding: @global-margin-small;
      overflow: hidden;

      &-text-wrapper,
      &-input {
        display: block;
        width: auto;
        height: @md-icon-size;
      }

      &-text-wrapper {
        .text-ellipsis();

        .item-title-text {
          cursor: text;
        }
      }

      &-input {
        border: 0 none;
        background-color: transparent;
        color: inherit;
        padding: 0;
        font-size: inherit;
        font-family: inherit;

        &:focus {
          outline: 0 none;
          width: 100%;
        }
      }
    }

    .item-add-child-wrapper {
      position: absolute;
      z-index: 1;
      top: 50%;
      padding: @global-margin-medium;
      transform: translate(0, -50%);
      cursor: default;

      .item-add-child {
        border: 0 none;
        outline: 0 none;
        transition: all .15s ease-in-out;
      }
    }

    .item-open-menu {
      transition: all .15s ease-in-out;
    }

    [dir=ltr] & {
      .item-title {
        padding-left: @global-margin;
      }

      .item-add-child-wrapper {
        left: 100%;
      }
    }

    [dir=rtl] & {
      .item-title {
        padding-left: @global-margin;
      }

      .item-add-child-wrapper {
        right: 100%;
      }
    }
  }

  .course-item-drop-area {
    position: absolute;
  }

  .back-link {
    float: none;
    display: inline-block;
  }

  @media screen and (min-width: @breakpoint-small-max) {
    .uk-dropdown.course-item-menu {
      overflow: visible !important; // Overrides js added styles.

      &.uk-dropdown-top {
        // Position and classname are set in menu.js
        margin-bottom: @dropdown-margin-top;
      }
    }
  }
}

// Course item settings dialog.
.course-item-settings-dialog {
  .chosen-container {
    max-width: 100%;
    min-width: 100%;
  }

  .info-text {
    font-size: @global-font-size-small;
    padding: @global-margin-mini 0 0;
    font-weight: @global-font-weight-semibold;
    opacity: .8;

    .md-icon {
      vertical-align: middle;
    }
  }

  .date-restrictions {
    .form-controls {
      max-width: 100%;
    }

    .days {
      width: @field-type-number-width / 1.5;
      margin: 0;
    }
  }
}

// Confirm dialogs.
.course-confirm-dialog {
  .uk-modal-content {
    min-height: 60px;
  }
}

.access-type-selection {
  @row-padding-horizontal: @global-margin-medium;
  @row-padding-vertical: @global-margin;

  .additional-configs {
    padding: @row-padding-vertical @row-padding-horizontal;
  }

  input[name="accessType"] {
    & + label {
      display: block;
      cursor: pointer;
      font-weight: @global-font-weight-normal;
      padding: @row-padding-vertical @row-padding-horizontal;
      border-radius: @global-border-radius;

      .md-icon + span {
        display: block;
        overflow: hidden;

        [dir=ltr] & {
          margin-left: @md-icon-size + @global-margin-small;
        }

        [dir=rtl] & {
          margin-right: @md-icon-size + @global-margin-small;
        }
      }
    }

    & ~ .additional-configs {
      display: none;
    }

    &:checked ~ .additional-configs {
      display: block;
    }
  }

  & > .uk-form-row {
    margin: 0;
  }

  & + .fullscreen-section {
    .divider {
      margin: @global-margin-medium -@modal-dialog-padding;
      border-top: 1px solid @_grey_lighten_color;
    }

    .uk-form-row {
      margin: 0;
      padding: @global-margin 0 0;
    }
  }
}

// Course settings
.course-duration {
  .form-item {
    margin-top: 0;

    input {
      width: 6.25rem; // 100px
    }

    .field-suffix {
      line-height: @form-height;
    }

    [dir=ltr] & {
      float: left;
      margin-right: @global-margin-large;

      input,
      .field-suffix {
        float: left;
      }

      .field-suffix {
        margin-left: @global-margin;
      }
    }

    [dir=rtl] & {
      float: right;
      margin-left: @global-margin-large;

      input,
      .field-suffix {
        float: right;
      }

      .field-suffix {
        margin-right: @global-margin;
      }
    }
  }
}

#mtx-api-hierarchical-select {
  margin: @global-margin 0;

  & > .form-item {
    margin: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.mtx-course-completion {
  padding: @global-margin-small @global-margin-large;
  text-align: center;

  &-icon .md-icon {
    font-size: 5 * @global-font-size;
    color: @global-warning-color;
    margin-bottom: @global-margin-large;

    &.md-check_circle {
      color: @global-success-color;
    }
  }

  &-title {
    font-size: 2 * @global-font-size;
    line-height: 1;
  }

  &-text {
    margin: @global-margin-large 0;

    p {
      margin: 0;
    }
  }
}
