.nei-widget-image {
  img {
    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }
  }

  & > a {
    display: block;
  }
}
