.page-footer {
  font-size: @global-font-size-small;
  padding: @global-margin-large 0;
  text-align: center;

  .footer {
    &-copyright {
      float: left;
    }

    &-links {
      float: right;
    }

    [dir=rtl] & {
      &-copyright {
        float: right;
      }

      &-links {
        float: left;
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      &-copyright,
      &-links {
        float: none;
      }

      &-copyright {
        margin-bottom: @global-margin;
      }
    }
  }
}

.mtx-api-page-footer-nav-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .15s ease-in-out;
  z-index: @mtx-api-page-footer-nav-z-index;

  &-ready {
    opacity: 1;
    visibility: visible;
  }
}
