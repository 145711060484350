@videos-thumb-padding-top: 56.25%; // (9 / 16) * 100
@videos-thumb-overlay-opacity: .7;
@videos-thumb-overlay-info-icon-font-size: 1.25rem;
@videos-thumb-overlay-info-color: @_white_color;
@videos-thumb-overlay-info-pos-x: @global-margin-medium;
@videos-thumb-overlay-info-pos-y: @global-margin;
@video-preview-margin-top: 0.375rem;
@videos-progressbar-height: @global-margin-mini;

.mtx-videos {
  // Filters
  .filters {
    .search {
      .input-search-icon();
    }
  }

  // Video item
  .video-item {
    margin-top: @global-margin-large;

    .container {
      position: relative;
    }

    &:focus-within,
    & > .container:hover,
    &.selected,
    .uk-touch & {
      .play,
      .version,
      .code {
        display: block;
      }
    }

    &:focus-within,
    & > .container:hover,
    &.selected {
      .thumb-image.overlay {
        visibility: visible;
        opacity: @videos-thumb-overlay-opacity;
      }

      .mtx-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .video-item.selected {
    .thumb {
      background: none;
    }

    .thumb,
    .thumb-image,
    .mtx-expiration-thumb {
      border-radius: @thumb-border-radius @thumb-border-radius 0 0;
    }

    .info {
      border-radius: 0 0 @thumb-border-radius @thumb-border-radius;
      padding-left: @global-margin-small;
      padding-right: @global-margin-small;
    }

    .counter {
      border-radius: 0 @thumb-border-radius 0 0;

      [dir=rtl] & {
        border-radius: @thumb-border-radius 0 0 0;
      }
    }
  }

  .video-item:not(.mtx-action-item) {
    .api-item:hover {
      cursor: default;
    }
  }

  .api-item {
    display: block;
    color: inherit;
    text-decoration: none;
    border-radius: @thumb-border-radius;
    cursor: pointer;

    .code {
      .text-ellipsis;
      max-width: 65%;
    }

    .status,
    .version,
    .code {
      color: @videos-thumb-overlay-info-color;
    }

    .play,
    .version,
    .code {
      display: none;
    }

    .status {
      position: absolute;
      top: @videos-thumb-overlay-info-pos-y;
      inset-inline-start: @videos-thumb-overlay-info-pos-x;
      line-height: 1;
      z-index: 1;

      .md-icon {
        font-size: @videos-thumb-overlay-info-icon-font-size;
      }

      .md-icon ~ .md-icon {
        margin-inline-start: @global-margin-small;
      }
    }

    .duration {
      display: flex;
      align-items: center;

      .md-icon {
        font-size: @videos-thumb-overlay-info-icon-font-size;
        margin-inline-end: @global-margin-small;
      }
    }
  }

  .play {
    position: absolute;
    color: @videos-thumb-overlay-info-color;
    display: none;
    left: 50%;
    top: @videos-thumb-padding-top / 2;
    transform: translate(-50%, -(@videos-thumb-padding-top / 2));
    z-index: 1;
    border-radius: @global-border-radius-circle;
    cursor: pointer;

    .md-icon {
      font-size: 3em;
      line-height: 1;
    }
  }

  .api-item.video {
    .additional {
      position: absolute;
      left: @videos-thumb-overlay-info-pos-x;
      right: @videos-thumb-overlay-info-pos-x;
      bottom: @videos-thumb-overlay-info-pos-y;
      font-size: @global-font-size-small;
      color: @videos-thumb-overlay-info-color;
      z-index: 1;
    }
  }

  .api-item.playlist {
    .counter {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      height: 100%;
      min-width: 5rem;
      border-radius: 0 @thumb-border-radius @thumb-border-radius 0;
      padding: 12% 0 0;
      line-height: 1.25;
      text-align: center;
      background: fade(@global-text-color, 70%);
      color: fade(@videos-thumb-overlay-info-color, 70%);

      .mtx-dark-theme & {
        background: @overlay-color-lighten;
      }

      .text {
        font-size: @global-font-size-small;
        text-transform: uppercase;
      }

      [dir=rtl] & {
        border-radius: @thumb-border-radius 0 0 @thumb-border-radius;
      }
    }

    .version {
      position: absolute;
      inset-inline-start: @videos-thumb-overlay-info-pos-x;
      bottom: @videos-thumb-overlay-info-pos-y;
      font-size: @global-font-size-small;
      line-height: 1.3;
      color: @_white_color;
      z-index: 1;
    }
  }

  .list {
    margin-top: -@global-margin;
  }

  .list,
  .form {
    .thumb {
      display: block;
      position: relative;
      border-radius: @thumb-border-radius;
      padding-top: @videos-thumb-padding-top;
      overflow: hidden;
      background-color: @api-item-empty-bg;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }

      &-image {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: @thumb-border-radius;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &.overlay {
          background: none @overlay-color-darken;
          visibility: hidden;
          opacity: 0;
          transition: @global-hover-transition;
          z-index: 1;
        }
      }

      &.empty {
        height: 0; // for proper progress bar displaying
      }
    }
  }

  .info {
    padding: @global-margin-small 0;
    font-size: @global-font-size-xsmall;
    color: inherit;

    & > * {
      .text-ellipsis();
    }

    .title {
      margin: 0 0 @global-margin-small;
      font-size: @global-font-size-small;
      line-height: @global-line-height;
    }

    .author ~ * {
      margin-top: @global-margin-mini;
    }
  }

  // Misc
  .ajax-progress {
    .position-center();
  }

  .progress {
    width: 0;
    height: @videos-progressbar-height;

    &-wrapper {
      margin-top: -@videos-progressbar-height;
    }

    .preview &-wrapper {
      .position-bottom();
    }
  }

  .pager {
    line-height: 2;

    .fa {
      color: @global-muted-color !important;
    }
  }
}

.mtx-videos-form {
  .form-block {
    padding-bottom: @global-margin-xlarge;
  }

  form.video {
    .checkbox-label {
      display: inline;
      font-weight: @global-font-weight-normal;
    }

    .video-block {
      position: relative;
    }

    .video-preview {
      position: relative;
      width: 100%;
      padding-top: @videos-thumb-padding-top;
      margin-bottom: @videos-progressbar-height;
      background-color: #bababa; // fineuploader.css

      figure {
        margin: 0;
      }

      .mtx-player {
        .position-cover();
      }
    }

    .video-thumb.default {
      .position-cover();
      background-image: url('@{global-images-path}videos/replaced.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: @videos-progressbar-height;
    }

    .video-replace-button-wrapper {
      position: absolute;
      margin: @global-margin-small 0 0 0;
      margin-inline-start: @global-margin-small;
      z-index: 1;
    }

    .thumbnails {
      @item-padding: @global-margin-small;

      .mtx-slides-set {
        margin-left: -@item-padding;
      }

      .mtx-slide-item {
        margin-bottom: @global-margin-small;
        padding-left: @item-padding;
      }

      .mtx-slide-holder {
        cursor: pointer;
      }

      .active {
        cursor: default;
      }
    }

    .slides-list {
      &:not(:empty) {
        margin-top: @global-margin-large;
      }
    }

    .slide {
      display: inline-block;
      margin: 0 0 @global-margin-large 0;

      .thumb:hover,
      .thumb:focus {
        .remove-icon,
        .overlay {
          visibility: visible;
          opacity: @videos-thumb-overlay-opacity;
        }

        .remove-icon {
          visibility: visible;
          opacity: 1;
        }

        .md-icon {
          z-index: 2;
        }
      }

      .remove-icon {
        visibility: hidden;
        opacity: 0;
        color: @_white_color;
      }

      .md-icon {
       .position-center();
        font-size: 2em;
      }

      &-time {
        margin-top: @global-margin;
        text-align: center;
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .subtitles-select-label {
      .text-ellipsis();
      font-weight: @global-font-weight-semibold;
    }

    @media all and (min-width: @breakpoint-medium) {
      .video-preview {
        margin-top: @video-preview-margin-top;
      }

      .video-replace-button-wrapper {
        top: @video-preview-margin-top;
      }
    }
  }

  form.playlist {
    .form-item:extend(.form-block .form-item all) {}
  }

  .selected-videos,
  .suggested-videos {
    .video-item {
      .uk-touch &,
      & > .container:hover,
      &:focus-within {
        .action {
          display: block;
        }
      }
    }
  }

  .video-item.added {
    .action.remove:before {
      content: '\e5ca'; // md-check
    }

    .thumb-image:not(.overlay) {
      opacity: @videos-thumb-overlay-opacity;
    }

    .action,
    .version,
    .code {
      display: block;
    }
  }

  .action {
    display: none;
    width: 100%;
    height: 100%;

    &-wrapper {
      .position-cover();
      z-index: 1;
      cursor: pointer;
    }

    &:before {
      .material-icon(@videos-thumb-overlay-info-icon-font-size);
      position: absolute;
      top: @videos-thumb-overlay-info-pos-y;
      inset-inline-end: @videos-thumb-overlay-info-pos-x;
      color: @videos-thumb-overlay-info-color;
    }

    &.add:before {
      content: '\e145'; // md-add
    }

    &.remove:before {
      content: '\e15b'; // md-remove
    }
  }

  .cue-point {
    &-time {
      width: 7.5rem; // 120px
      text-align: center;
    }
  }
}

.video-modal {
  background: none !important;

  .uk-modal {
    &-content {
      margin: 0;
    }

    &-close {
      z-index: 2;
    }
  }
}

// fine-uploader related styles.
.qq-upload-button-selector {
  input::file-selector-button,
  input::-webkit-file-upload-button {
    // Field's hover issue (Google Chrome, Safari).
    cursor: pointer;
    // Field's focus issue on using screen reader (Safari, Firefox).
    .position-cover();
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0 none;
  }

  input[qq-button-id] {
    width: 100%;
    height: 100%;
  }

  &:focus-within {
    .focus-state-box-shadow();
  }
}
