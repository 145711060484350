.mtx-report-marketplace-overview {
  @item-margin-top: @global-margin-large;
  @item-padding-left: @global-margin-medium * 4;

  .clearfix();
  margin-top: -@item-margin-top;
  margin-left: -@item-padding-left;

  .item {
    margin-top: @item-margin-top;
    padding-left: @item-padding-left;

    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
    }
  }

  .total {
    font-size: 2.5rem;
    font-weight: @global-font-weight-bold;
    line-height: 1;
  }

  .title {
    margin-top: @global-margin-small / 2;
  }

  .mtx-report-courses-overview-section & {
    @media all and (max-width: @breakpoint-mini-max) {
      @item-padding-left: @global-margin-large;

      margin-left: -@item-padding-left;

      .item {
        padding-left: @item-padding-left;

        &:not(:only-child) {
          width: 50%;
        }
      }
    }
  }
}
