.nei-widget-comments {
  background-color: @_white_color;
  padding: 24px; // @see: widget.js customize method
  border-radius: @course-content-border-radius;

  .mtx-dark-theme & {
    background-color: @_black_color;
  }

  .course-comments-placeholder {
    padding: @global-margin-large 0;
  }

  .course-comments-count {
    font-size: @global-font-size;
    opacity: @widget-contrast-opacity;
  }
}

#mtx-course-page-comments {
  .cke_editable {
    [dir=rtl] [dir=ltr] &,
    [dir=ltr] & {
      direction: ltr;
    }

    [dir=rtl] & {
      direction: rtl;
    }
  }
}
