@mtx-api-page-footer-nav-padding: @global-margin-medium * 2;
@mtx-api-page-footer-nav-title-font-size: @global-font-size;
@mtx-api-page-footer-nav-title-line-height: @global-line-height;
// Prevents overflow by code snippet widget in learn mode.
// See .CodeMirror-gutter-wrapper {}
@mtx-api-page-footer-nav-z-index: @page-sidebar-z-index + 5;

.mtx-api-page-footer-nav {
  padding-top: @mtx-api-page-footer-nav-padding;
  padding-bottom: @mtx-api-page-footer-nav-padding;
  box-shadow: 0 0 2px fade(#000000, 6%); // The same as @page-sidebar-box-shadow

  .title {
    .text-ellipsis();
    font-size: @mtx-api-page-footer-nav-title-font-size;
    line-height: @mtx-api-page-footer-nav-title-line-height;
    font-weight: @global-font-weight-semibold;
  }

  .nav-link {
    display: block;
    position: relative;

    &:before {
      .material-icon();
      position: absolute;
    }

    .title {
      transition: color .15s ease-in-out;
    }

    &.restricted {
      cursor: default;
    }

    &.previous {
      padding-inline-start: @md-icon-size + @global-margin-medium;
    }

    &.next {
      padding-inline-end: @md-icon-size + @global-margin-medium;
    }

    [dir=ltr] & {
      &.previous:before {
        content: '\e5c4'; // md-arrow_back
        left: 0;
        right: auto;
      }

      &.next:before {
        content: '\e5c8'; // md-arrow_forward
        right: 0;
        left: auto;
      }
    }

    [dir=rtl] & {
      &.previous:before {
        content: '\e5c8'; // md-arrow_forward
        right: 0;
        left: auto;
      }

      &.next:before {
        content: '\e5c4'; // md-arrow_back
        left: 0;
        right: auto;
      }
    }
  }

  .nav-link-learning-path {
    display: block;
    position: relative;

    &:before {
      .material-icon();
      position: absolute;
    }

    &.restricted {
      cursor: default;

      &:hover,
      &:focus {
        cursor: pointer !important;
      }
    }

    &.previous {
      padding-inline-start: @global-margin + @md-icon-size + @global-margin-large;
    }

    &.next {
      padding-inline-end: @global-margin + @md-icon-size + @global-margin-large;
    }

    [dir=ltr] & {
      &.previous:before {
        content: '\e5c4'; // md-arrow_back
        left: @global-margin-large;
        right: auto;
      }

      &.next:before {
        content: '\e5c8'; // md-arrow_forward
        right: @global-margin-large;
        left: auto;
      }
    }

    [dir=rtl] & {
      &.previous:before {
        content: '\e5c8'; // md-arrow_forward
        right: @global-margin-large;
        left: auto;
      }

      &.next:before {
        content: '\e5c4'; // md-arrow_back
        left: @global-margin-large;
        right: auto;
      }
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .nav-link,
    .nav-link-learning-path {
      max-width: 49%;
    }

    .title {
      max-width: 20em; // 320px
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .nav-link,
    .nav-link-learning-path {
      max-width: 75%;
    }

    .previous {
      display: none;
    }

    &.mtx-api-page-footer-nav-reversed {
      .previous {
        display: block;
      }

      .next {
        display: none;
      }
    }
  }
}
