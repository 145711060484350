.editor-toolbar-form {
  display: none;
  background: @editor-bg-color;
  border-radius: calc(@editor-border-radius - 1px);
  color: @editor-link-color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  .clearfix;

  &-active {
    display: block;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: @editor-button-size;

    &:before,
    &:after {
      width: 0;
    }
  }

  .editor-toolbar-input-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin: 0 @global-margin-small;
    height: 100%;
  }

  .editor-toolbar-input {
    flex-grow: 1;
    background: transparent;
    color: @editor-link-color;
    font-size: inherit;
  }

  .editor-toolbar-file-title {
    cursor: pointer;
    .position-cover();

    &:hover {
      &:after {
        color: inherit;
      }
    }
  }

  &:not(.editor-file-selected) .editor-toolbar-file-title {
    &:after {
      .material-icon();
      content: '\e8b6'; // md-search
      color: @global-muted-color;
      line-height: unit(@editor-button-size, rem);

      [dir=rtl] [dir=ltr] &,
      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }

    &:hover {
      &:after {
        color: inherit;
      }
    }
  }

  .editor-toolbar-input,
  .editor-toolbar-file-title,
  label {
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    height: @editor-button-size;
    line-height: @editor-button-size;

    &:focus {
      appearance: none;
      border: none;
      box-shadow: none;
      outline: 0;
    }
  }

  .editor-toolbar-input {
    .placeholder({
      color: @editor-placeholder-color;
    });
  }

  .editor-toolbar-file-title {
    display: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .editor-toolbar-controls {
    display: flex;
  }

  label {
    .md-icon {
      margin: 0 12px;
    }
  }

  &.editor-file-type-selected {
    .editor-toolbar-file-title {
      display: block;
    }
  }

  &.editor-file-selected {
    .editor-toolbar-input {
      display: none;
    }
  }

  .editor-toolbar-unlink,
  .editor-toolbar-save {
    display: none;
  }

  &.editor-form-has-value {
    .editor-toolbar-save {
      display: block;
    }

    &:not(.editor-form-changed) {
      .editor-toolbar-unlink {
        display: block;
      }

      .editor-toolbar-save {
        display: none;
      }
    }
  }
}

.editor-toolbar-checkbox-label {
  input {
    display: none;
  }

  &-text {
    display: inline-block;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      text-align: left;
    }

    [dir=rtl] & {
      text-align: right;
    }
  }

  input:checked + &-text {
    .md-icon:after {
      content: '\e834'; // md-check_box
    }
  }
}

.editor-anchor-preview {
  background: @global-text-color;
  border-radius: @editor-border-radius;
  padding: @global-margin-small @global-margin-medium;

  a {
    color: fade(@_white_color, 85%);

    &:hover,
    &:focus {
      color: @_white_color;
    }

    &:before {
      .material-icon(@md-icon-size-small);
      margin-inline-end: @global-margin-small;
    }

    &.anchor-preview-url:before {
        content: '\e250'; // md-link
    }

    &.anchor-preview-email:before {
        content: '\e0e6'; // md-alternate_email
    }

    &.anchor-preview-file:before {
        content: '\e66d'; // md-insert_drive_file
    }
  }
}
