.enter-items-wrapper {
  @close-icon-size: 1.25rem; // @close-size - 20px
  @close-icon-padding: .1875rem;
  @badge-padding-x: @global-margin;
  @input-inner-height: calc(@form-height - 2 * @form-border-width);

  .input-base();
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  &.focus {
    border-color: @form-focus-border;
  }

  & > * {
    vertical-align: middle;
  }

  .input-wrapper {
    display: inline-block;
    max-width: 100%;
    position: relative;
    flex: 1;
  }

  .text-placeholder {
    color: transparent;
    white-space: nowrap;
    min-width: 100px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 @form-padding-x;
    line-height: @input-inner-height;

    &.has-placeholder {
      color: @global-muted-color;
    }

    &:after {
      content: '\00a0'; // &nbsp;
    }
  }

  input.enter-items-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    padding: 0 @form-padding-x !important;
    border: 0 none !important;
    height: @input-inner-height;
  }

  .uk-badge {
    display: flex;
    align-items: center;
    max-width: 100%;
    font-size: @global-font-size;
    font-weight: normal;
    border-radius: @inputs-border-radius;
    padding: @global-margin-small @global-margin;
    margin: @global-margin-mini 0;
    line-height: @close-icon-size;

    .badge-text {
      .text-ellipsis();
    }

    .uk-close {
      opacity: .5;
      width: @close-icon-size;
      line-height: @close-icon-size;

      &:after {
        font-size: @close-icon-size;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    [dir=ltr] & {
      &,
      .uk-close {
        margin-left: @global-margin-small;
      }
    }

    [dir=rtl] & {
      &,
      .uk-close {
        margin-right: @global-margin-small;
      }
    }
  }
}
