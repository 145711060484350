//
// Component: Sortable
//
// ========================================================================


// Variables
// ========================================================================

@sortable-dragged-z-index:                      @global-z-index + 50;


// Component
// ========================================================================

.hook-sortable() {
  ul& {
    margin-bottom: 0;
  }
}

.hook-sortable-dragged() {}

.hook-sortable-placeholder() {}

.hook-sortable-over() {}


// Miscellaneous
// ========================================================================

.hook-sortable-misc() {}
