// Bar.
.mtx-api-bar-chart-legend {
  & > div {
    row-gap: @global-margin;
    column-gap: 2.5rem;
  }

  .box {
    .square(@global-size-mini);
    border-radius: @global-border-radius;
  }
}

// Radar.
.mtx-api-spider-chart {
  &.clickable {
    cursor: pointer;
  }
}

.mtx-api-spider-chart-modal {
  .mtx-api-spider-chart {
    padding-top: @global-margin-xlarge;
  }
}
