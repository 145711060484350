//
// Component: Subnav
//
// ========================================================================


// Variables
// ========================================================================

@subnav-color:                                  @global-color;
@subnav-hover-color:                            @global-link-color;
@subnav-active-color:                           @global-link-color;

@subnav-line-border:                            @global-border;

@subnav-pill-hover-color:                       @global-color;
@subnav-pill-active-background:                 @global-primary-background;
@subnav-pill-active-color:                      @global-contrast-color;

@subnav-disabled-color:                         @global-muted-color;


// Component
// ========================================================================

.hook-subnav() {
  font-size: @global-font-size-xsmall;
}

.hook-subnav-hover() {}

.hook-subnav-active() {}


// Modifier: `uk-subnav-line'
// ========================================================================

.hook-subnav-line-divider() {}


// Modifier: `uk-subnav-pill'
// ========================================================================

.hook-subnav-pill() {}

.hook-subnav-pill-hover() {}

.hook-subnav-pill-active() {}


// Disabled state
// ========================================================================

.hook-subnav-disabled() {}


// Miscellaneous
// ========================================================================

.hook-subnav-misc() {
  .uk-subnav .back a {
    font-size: @global-font-size-small;
  }
}
