@nei-container-padding: @global-margin;
@nei-container-font-size: @global-font-size;
@nei-container-bg-color: @page_content_background_color;
@nei-container-z-index: 9;

.nei-container {
  font-size: @nei-container-font-size;
  clear: both;
  position: relative;

  h1 {
    font-size: unit(@base-h1-font-size / @nei-container-font-size, em);
  }

  h2 {
    font-size: unit(@base-h2-font-size / @nei-container-font-size, em);
    line-height: 1.25;
  }

  h3 {
    font-size: unit(@base-h3-font-size / @nei-container-font-size, em);
  }

  h4 {
    font-size: unit(@base-h4-font-size / @nei-container-font-size, em);
  }

  h5 {
    font-size: unit(@base-h5-font-size / @nei-container-font-size, em);
  }

  h6 {
    font-size: unit(@base-h6-font-size / @nei-container-font-size, em);
  }

  &.has-overlay {
    z-index: @nei-container-z-index;
  }

  & > .nei-widget-editing-overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 13;
  }
}

.nei-page-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: @nei-container-z-index;
  transform: translateZ(0);
  transition: all .15s linear;
  background-color: @overlay-color-lighten;
  backdrop-filter: blur(4px);

  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }

  html.uk-modal-page & {
    background: fade(@page_content_background_color, 0.1%);
    transform: translateZ(0);
    transition: all .15s linear;
  }
}
