@banner-placeholder-image-background: #eee;
@banner-placeholder-padding: (10 / 16) * 100%;

.nei-widget-banner {
  &:extend(.nei-widget-playlist all);

  figure {
    margin-bottom: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  .banner-overlay,
  .uk-cover-background,
  .mp-thumbnail,
  img {
    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }
  }

  .banner {
    @banner-text-color: @_white_color;
    @test-passed-color: #a1ce0c;
    @total-border-radius: 5px;
    @passed-border-radius: @total-border-radius - 1px;

    &-overlay {
      background-color: rgba(0, 0, 0, .5);
      color: @banner-text-color;
    }

    &-title {
      margin: 0;
      font-size: 2.5em;
      color: @banner-text-color !important;
    }

    &-description {
      margin: @global-margin-large * 2 0 0;
    }

    &-info {
      margin: @global-margin-large 0 0;
      font-size: 1.3em;
      font-weight: @global-font-weight-semibold;
      word-break: break-word;
      word-wrap: break-word;

      .item {
        display: inline-block;
        margin-top: @global-margin;
      }

      .nei-container[dir=ltr] & {
        .md-icon {
          margin-right: @global-margin;
        }

        .item:not(:last-child) {
          margin-right: 10%;
        }
      }

      .nei-container[dir=rtl] & {
        .md-icon {
          margin-left: @global-margin;
        }

        .item:not(:last-child) {
          margin-left: 10%;
        }
      }
    }

    &-tests {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2.8125rem 0 0;

      .title,
      .result,
      .progress {
        display: inline-block;
      }

      .title {
        margin: 0;
        font-size: 1.15em;
      }
    }

    &-tests.all-passed {
      .result .md-icon {
        color: @test-passed-color;
        opacity: 1;
      }

      .progress .passed {
        border-radius: @passed-border-radius !important;

        .nei-container[dir=ltr] & {
          margin-right: 1px;
        }

        .nei-container[dir=rtl] & {
          margin-left: 1px;
        }
      }
    }

    .progress {
      position: relative;
      width: 60%;
      height: .75rem;
      margin: 0 @global-margin-medium;

      .total {
        .position-cover();
        border-radius: @total-border-radius;
        background-color: @banner-text-color;
        opacity: .3;
      }

      .passed {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        background-color: @test-passed-color;
        z-index: 1;

        .nei-container[dir=ltr] & {
          border-radius: @passed-border-radius 1px 1px @passed-border-radius;
        }

        .nei-container[dir=rtl] & {
          border-radius: 1px @passed-border-radius @passed-border-radius 1px;
        }
      }
    }

    .result {
      position: relative;

      @icon-size: 3em;

      .md-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: @icon-size;
        color: @banner-text-color;
        opacity: .3;
      }

      .value {
        margin-bottom: 0;
      }

      .nei-container[dir=ltr] & {
        .md-icon {
          left: 0;
        }

        .value {
          margin-left: @icon-size + .5em;
        }
      }

      .nei-container[dir=rtl] & {
        .md-icon {
          right: 0;
        }

        .value {
          margin-right: @icon-size + .5em;
        }
      }
    }

    .course-code {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        padding: 0 @global-margin-large @global-margin 0;
      }

      [dir=rtl] & {
        padding: 0 0 @global-margin @global-margin-large;
      }
    }

    @media all and (max-width: @breakpoint-small-max) {
      .small-view-styles();
    }

    @media all and (min-width: @breakpoint-medium) {
      &-content-wrapper {
        padding: 5rem @global-margin-medium * 2;
      }

      &-title,
      &-info,
      &-tests {
        text-align: center;
      }
    }

    @media all and (min-width: @breakpoint-xlarge) {
      &-content-wrapper {
        padding: 5rem 15%;
      }
    }
  }

  &.small,
  &.x-small {
    .banner {
      &-title,
      &-info {
        text-align: initial;
      }

      .small-view-styles();
    }
  }

  .small-view-styles() {
    &-content-wrapper {
      padding: @global-margin-large * 2 @global-margin-large;
    }

    &-description {
      margin: @global-margin-medium * 2 0 0;
    }

    &-tests .progress {
      height: .5rem;
    }
  }
}

.banner-dialog {
  width: 800px;

  .placeholder {
    margin-bottom: @global-margin;
    overflow: hidden;
    padding-top: @banner-placeholder-padding;
    position: relative;
    text-align: center;
    z-index: 1;
    border-radius: @thumb-border-radius;

    &-image {
      background: @banner-placeholder-image-background;
      cursor: pointer;
      height: 100%;
      left: 0;
      padding: @global-margin-small;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      .banner-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: @global-background;
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
        border-radius: calc(@thumb-border-radius - @global-margin-small);
      }

      & > .empty-image {
        bottom: 0;
        font-size: 3rem;
        height: 1.1em;
        left: 0;
        line-height: 1.1em;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;

        .md-icon {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }
}
