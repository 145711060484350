@mtx-empty-message-font-size: unit(@global-font-size-small, rem);

// @Todo: Align empty messages styles.
.view-empty {
  & > p,
  .uk-form > p {
    color: @global-muted-color;
    font-size: @mtx-empty-message-font-size;
    font-weight: @global-font-weight-semibold;
    margin: 0;
    padding: @global-margin 0;
  }

  .not-found {
    color: @global-muted-color;
    display: block;
    padding: 5% 0;
    text-align: center;
    width: 100%;

    & > i {
      font-size: 4rem;
    }

    & > .empty-message {
      margin: @global-margin-medium 0 0;
      font-size: @mtx-empty-message-font-size;
      font-weight: @global-font-weight-semibold;
    }
  }
}

.mtx-empty-message {
  padding: 5% 20%;
  font-size: @mtx-empty-message-font-size;
  line-height: 2;
  text-align: center;

  & > i {
    font-size: 3em;
    line-height: 1.5;
    color: @global-muted-color;
  }

  h4 {
    font-size: unit(@global-font-size / @mtx-empty-message-font-size, rem);
  }
}

.mtx-empty-message-with-button {
  padding: 5 * @global-margin-large @global-margin-large;
  text-align: center;

  h3 {
    margin: 0;
  }

  p {
    font-size: @mtx-empty-message-font-size;
    margin: @global-margin-large 0 2 * @global-margin-large;
  }
}

.mtx-empty-view p {
  margin: 0;
  padding: 15% 0;
  text-align: center;
}

.mtx-section-empty-message {
  .text {
    margin: @global-margin-large 0 @global-margin-medium;

    &:only-child {
      margin: 2.5rem 0 5rem;
    }
  }

  .uk-button {
    margin: @global-margin-medium 0;
  }
}
