.simple-layout-page {
  @margin: 9vh;

  background-color: inherit;

  .page-logo {
    margin: @margin auto 0;
    text-align: center;

    #logo {
      display: inline;
      float: none;
    }

    img {
      max-height: 3rem;
    }
  }

  #main-content {
    padding: 0 @global-margin-large @global-margin-large;
  }

  .content-wrapper {
    margin: @margin auto @global-margin-large;

    @media (min-width: @breakpoint-small) {
      max-width: 85%;
    }

    @media (min-width: @breakpoint-medium) {
      max-width: 75%;
    }

    @media (min-width: @breakpoint-xlarge) {
      max-width: 50%;
    }
  }

  #qr-code-questions {
    .message {
      h1 {
        // The same styles as in .page-title {}
        font-size: 2rem;
        line-height: 1;
        margin: 0 0 @global-margin-large;
      }

      p {
        margin: 0 0 @global-margin-medium;
      }
    }

    .uk-form {
      .uk-grid > .form-item:first-child {
        margin-top: @global-margin;
      }
    }
  }
}
