@card-element-z-index: 1;

// Element.
.card-element {
  .position-cover();
  z-index: @card-element-z-index;
}

// Mixin.
.card-element-top(@position: relative, @z-index: @card-element-z-index + 1) {
  position: @position;
  z-index: @z-index;
}
