//
// Component: Button
//
// ========================================================================


// Variables
// ========================================================================

@button-height:                                 @global-height;
@button-mini-height:                            @global-height-mini;
@button-small-height:                           @global-height-small;
@button-large-height:                           @global-height-large;

@button-line-height:                            @global-line-height;
@button-mini-line-height:                       @global-line-height;
@button-small-line-height:                      @global-line-height;
@button-large-line-height:                      @global-line-height;

@button-font-size:                              @global-font-size;
@button-mini-font-size:                         @global-font-size-xsmall;
@button-small-font-size:                        @global-font-size-small;
@button-large-font-size:                        @global-font-size;

@button-padding-horizontal:                     @global-margin-large;
@button-mini-padding-horizontal:                @global-margin-small;
@button-small-padding-horizontal:               @global-margin-medium;
@button-large-padding-horizontal:               @global-margin-medium * 2;

@button-padding-vertical:                       (@button-height - (@button-font-size * @button-line-height)) / 2;
@button-mini-padding-vertical:                  (@button-mini-height - (@button-mini-font-size * @button-mini-line-height)) / 2;
@button-small-padding-vertical:                 (@button-small-height - (@button-small-font-size * @button-small-line-height)) / 2;
@button-large-padding-vertical:                 (@button-large-height - (@button-large-font-size * @button-large-line-height)) / 2;

@button-background:                             transparent;
@button-color:                                  @global-text-color;
@button-hover-background:                       transparent;
@button-hover-color:                            @_grey_dark_color;
@button-active-background:                      transparent;
@button-active-color:                           @_grey_dark_color;

@button-primary-background:                     @global-primary-background;
@button-primary-color:                          @global-contrast-color;
@button-primary-hover-color:                    @global-contrast-color;
@button-primary-active-color:                   @global-contrast-color;

@button-success-background:                     @global-success-background;
@button-success-color:                          @global-contrast-color;
@button-success-hover-color:                    @global-contrast-color;
@button-success-active-color:                   @global-contrast-color;

@button-danger-background:                      @global-danger-background;
@button-danger-color:                           @global-contrast-color;
@button-danger-hover-color:                     @global-contrast-color;
@button-danger-active-color:                    @global-contrast-color;

@button-disabled-color:                         @global-muted-color;

@button-link-color:                             @global-link-color;
@button-link-hover-color:                       @global-link-hover-color;
@button-link-disabled-color:                    @global-muted-color;
@button-link-hover-text-decoration:             none;

@button-badge-size:                             1.5rem;
@button-medium-badge-size:                      1rem;
@button-small-badge-size:                       .75rem;

@button-group-margin-x:                         @global-margin-small;
@button-group-margin-y:                         @global-margin-small;

@button-group-dense-margin-x:                  @global-margin-mini;
@button-group-dense-margin-y:                  @global-margin-mini;

@button-html-entity-size:                      1.5rem; // @md-icon-size

// Component
// ========================================================================

.hook-button() {
  cursor: pointer;
  user-select: none;
  transition: all .15s ease-in-out;
  padding: calc(@button-padding-vertical - 1px) calc(@button-padding-horizontal - 1px);
  border: 1px solid @global-border-color;

  .bulk-operations-hidden-actions &,
  .vbo-hidden-actions & {
    transition: all .15s ease-in-out, visibility 0s;
  }

  & + &,
  .ajax-progress + & {
    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      margin-left: @global-margin-mini;
    }

    [dir=rtl] & {
      margin-right: @global-margin-mini;
    }
  }

  &.progress-disabled {
    float: none;
  }

  &,
  button&,
  input& {
    border-radius: @button-border-radius;
    font-size: @button-font-size;
    .break-word();
  }

  &.uk-button-mini,
  button&.uk-button-mini,
  input&.uk-button-mini {
    padding: calc(@button-mini-padding-vertical - 1px) calc(@button-mini-padding-horizontal - 1px);
    font-size: @button-mini-font-size;
    line-height: @button-mini-line-height;
    border-radius: @button-border-radius-mini;
  }

  &.uk-button-small,
  button&.uk-button-small,
  input&.uk-button-small {
    padding: calc(@button-small-padding-vertical - 1px) calc(@button-small-padding-horizontal - 1px);
    font-size: @button-small-font-size;
    line-height: @button-small-line-height;
    border-radius: @button-border-radius-small;

    .md-icon,
    .custom-icon {
      font-size: @md-icon-size-small;

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        margin-left: -@button-padding-horizontal / 4;
      }

      [dir=rtl] & {
        margin-right: -@button-padding-horizontal / 4;
      }
    }
  }

  .fa,
  .uk-icon,
  .md-icon {
    color: inherit;

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      margin-left: -@button-padding-horizontal / 2;

      &:only-child {
        margin-left: 0;
      }

      & + span {
        margin-left: @global-margin-mini;
      }
    }

    [dir=rtl] & {
      margin-right: -@button-padding-horizontal / 2;

      &:only-child {
        margin-right: 0;
      }

      & + span {
        margin-right: @global-margin-mini;
      }
    }
  }

  .custom-icon {
    color: @_grey_dark_color;
  }

  .md-icon {
    vertical-align: top;
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    cursor: default !important;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  [class*="uk-gap"] > & + & {
    margin: 0 !important;
  }
}

.hook-button-hover() {}

.hook-button-active() {}


// Color modifiers
// ========================================================================

//
// Modifier: `uk-button-primary`
//

.hook-button-primary() {}

.hook-button-primary-hover() {}
.hook-button-primary-active() {}

//
// Modifier: `uk-button-success`
//

.hook-button-success() {}

.hook-button-success-hover() {}
.hook-button-success-active() {}

//
// Modifier: `uk-button-danger`
//

.hook-button-danger() {}

.hook-button-danger-hover() {}
.hook-button-danger-active() {}


// Disabled state
// ========================================================================

.hook-button-disable() {
  cursor: default;

  &:hover {
    opacity: 1 !important;
    color: @button-disabled-color;
  }
}


// Modifier: `uk-button-link`
// ========================================================================

.hook-button-link() {
  display: inline;
  width: auto;
  min-height: @button-font-size * @global-line-height;
  margin: 0;
  padding: 0;
  border: none;
  text-transform: none;
  font-size: @global-font-size;
  font-family: @global-font-family;
  line-height: @global-line-height;
  text-decoration: @base-link-text-decoration;
  vertical-align: middle;
  overflow: visible;
  transition: color .15s ease-in-out;
  appearance: none;

  &,
  &:focus {
    outline: 0 none;
  }

  &.uk-text-small {
    font-size: @global-font-size-small;
  }
}


// Size modifiers
// ========================================================================

.hook-button-large() {
  &,
  button&,
  input& {
    padding: calc(@button-large-padding-vertical - 1px) calc(@button-large-padding-horizontal - 1px);
    font-size: @button-large-font-size;
    line-height: @button-large-line-height;
    border-radius: @button-border-radius-large;
  }
}


// Miscellaneous
// ========================================================================

.hook-button-misc() {
  // Add: `uk-button-circle`
  button.uk-button-circle,
  .uk-button-circle {
    //border-radius: @global-border-radius-circle;
    padding: calc(@button-padding-vertical - 1px);

    .md-icon {
      [dir=ltr] & {
        margin-left: 0;
      }

      [dir=rtl] & {
        margin-right: 0;
      }

      & + span {
        display: none;
        margin: 0 !important;
      }
    }
  }

  .mtx-button {
    @icon-pos: @button-padding-horizontal / 2;
    @padding: @global-margin-small + @md-icon-size + @icon-pos;

    // Next / Previous.
    &-previous,
    &-next,
    &-input {
      position: relative;
    }

    &-previous,
    &-next {
      &:after {
        .material-icon();
        position: absolute;
      }
    }

    &-input .md-icon {
      position: absolute;
    }

    &-previous:after,
    &-next:after,
    &-input .md-icon {
      top: 50%;
      transform: translateY(-50%);
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      &-previous,
      &-input-previous .uk-button {
        padding-left: @padding;
      }

      &-next,
      &-input-next .uk-button {
        padding-right: @padding;
      }

      &-previous:after,
      &-input-previous .md-icon {
        left: @icon-pos;
      }

      &-previous:after,
      &-input-previous .md-icon:after {
        content: '\e5cb'; // md-chevron_left
      }

      &-next:after,
      &-input-next .md-icon {
        right: @icon-pos;
      }

      &-next:after,
      &-input-next .md-icon:after {
        content: '\e5cc'; // md-chevron_right
      }
    }

    [dir=rtl] & {
      &-previous,
      &-input-previous .uk-button {
        padding-right: @padding;
      }

      &-next,
      &-input-next .uk-button {
        padding-left: @padding;
      }

      &-previous:after,
      &-input-previous .md-icon {
        right: @icon-pos;
      }

      &-previous:after,
      &-input-previous .md-icon:after {
        content: '\e5cc'; // md-chevron_right
      }

      &-next:after,
      &-input-next .md-icon {
        left: @icon-pos;
      }

      &-next:after,
      &-input-next .md-icon:after {
        content: '\e5cb'; // md-chevron_left
      }
    }

    &-input .md-icon {
      pointer-events: none;
    }
  }

  .mtx-button-group {
    display: block;
    margin-top: -@button-group-margin-y;
    font-size: 0.001px;

    & > *,
    & > .uk-button {
      display: inline-block;
      margin-top: @button-group-margin-y;
      vertical-align: top;
    }

    &.mtx-button-group-dense {
      & > *,
      & > .uk-button {
        margin-top: @button-group-dense-margin-y;
      }
    }

    &.uk-text-right {
      .uk-dropdown {
        text-align: initial;
      }
    }

    [dir=rtl] [dir=ltr] &,
    [dir=ltr] & {
      &.mtx-button-group-dense {
        & > *,
        & > .uk-button {
          margin-right: @button-group-dense-margin-x;
        }
      }

      & > *,
      & > .uk-button {
        margin-left: 0;
        margin-right: @button-group-margin-x;

        &:last-child {
          margin-right: 0;
        }
      }

      &.uk-text-right {
        &.mtx-button-group-dense {
          & > *,
          & > .uk-button {
            margin-left: @button-group-dense-margin-x;
          }
        }

        & > *,
        & > .uk-button {
          margin-left: @button-group-margin-x;
          margin-right: 0;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    [dir=rtl] & {
      &.mtx-button-group-dense {
        & > *,
        & > .uk-button {
          margin-left: @button-group-dense-margin-x;
        }
      }

      & > *,
      & > .uk-button {
        margin-right: 0;
        margin-left: @button-group-margin-x;

        &:last-child {
          margin-left: 0;
        }
      }

      &.uk-text-right {
        &.mtx-button-group-dense {
          & > *,
          & > .uk-button {
            margin-right: @button-group-dense-margin-x;
          }
        }

        & > *,
        & > .uk-button {
          margin-right: @button-group-margin-x;
          margin-left: 0;

          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  // Add: `uk-button-with-badge`
  .uk-button-with-badge {
    position: relative;

    .uk-badge {
      .circle(@button-badge-size);
      position: absolute;
      top: -@button-badge-size / 3;
      inset-inline-end: -@button-badge-size / 3;
      min-width: @button-badge-size;
      width: auto;
      border-radius: 50%;
      padding: 0 .25rem;
      font-size: 1rem;
      line-height: @button-badge-size;
      font-weight: @global-font-weight-semibold;
    }

    .uk-badge.is-medium {
      .circle(@button-medium-badge-size);
      top: -(@button-medium-badge-size / 3);
      inset-inline-end: -(@button-medium-badge-size / 3);
      min-width: @button-medium-badge-size;
      font-size: 0.625rem;
      line-height: @button-medium-badge-size;
      text-align: center;
    }

    .uk-badge.is-small {
      .circle(@button-small-badge-size);
      top: -(@button-small-badge-size / 3);
      inset-inline-end: -(@button-small-badge-size / 3);
      min-width: @button-small-badge-size;
      padding: 0;
    }
  }

  // Add: `uk-button-link-input`
  .uk-button-link-input {
    transition: color .15s ease-in-out;

    input {
      .input-styles-remove();
    }

    .md-icon + input {
      padding-inline-start: @md-icon-size + @global-margin-mini;
      margin-inline-start: -(@md-icon-size + @global-margin-mini);
    }
  }

  // Add: `uk-button-html-entity`
  input.uk-button-html-entity {
    .material-icon(@button-html-entity-size);
    .input-styles-remove();
    transition: color .15s ease-in-out;

    &.uk-button-circle {
      height: @button-height;
      width: @button-height;
    }

    &:not(.uk-button) {
      min-width: @button-html-entity-size;
      height: @button-html-entity-size;
    }
  }

  // Add: `uk-button-form-section-errors`
  div.uk-button-form-section-errors {
    background-color: rgba(@global-danger-background, 10%) !important;
    color: @global-danger-background !important;
    cursor: default !important;
  }

  // Add: `uk-button-circle-prevent-secondary-styles`
  // @todo: try fo fix that issue in other way (separate task).
  .uk-button-circle-prevent-secondary-styles {}
}
