// Variables for inputs and forms
@input-paddings: 4px;

//** Mixins for form elements
.input-base() {
  background-color: @form-background;
  border: 1px solid @form-border;
  color: @form-color;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  outline: 0 none;
  padding: @form-padding;
  border-radius: @inputs-border-radius;
  box-sizing: border-box;
  transition: @form-border-transition;
}

// Input styles for active states
.input-active() {
  &:focus,
  &:active {
    border-color: @form-focus-border;
  }
}

// Readonly inputs mixin
.input-readonly() {
  &[readonly] {
    &,
    &:focus,
    &:active {
      background-color: @form-background;
      border-color: @form-border;
      color: @form-color;
      cursor: default;
    }
  }
}

// Disabled inputs mixin
.input-disabled() {
  &[disabled],
  &.disabled {
    &,
    &:focus,
    &:active {
      background-color: @form-disabled-background;
      border-color: @form-disabled-border;
      color: @form-disabled-color;
      cursor: not-allowed;
    }
  }
}

// Remove input styles
.input-styles-remove() {
  border: 0;
  padding: 0;
  background-color: transparent;
}

//** End mixins.

input {
  margin: 0;
}

input.title {
  font-size: 1.385em;
}

input.text,
input.title {
  border: 1px solid @form-border;
  margin: 0;
}

input[type=search],
input.form-text,
input.form-password,
input.form-file,
textarea,
select {
  // Base input styles
  .input-base;

  // Input styles for active states
  .input-active();

  // Readonly inputs
  .input-readonly();

  // Disabled inputs
  .input-disabled();

  &.element-invisible {
    width: 0;
  }

  .placeholder({
    color: @global-placeholder-color;
  });
}

select {
  height: unit(@global-line-height + (2 * @form-padding-y) + 2, rem);
}

.form-textarea-wrapper.resizable textarea {
  border-radius: @inputs-border-radius;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 15rem;
  height: @global-size-tiny / 2;
  border-radius: @global-border-radius;
  background-image: linear-gradient(@secondary_color, @secondary_color);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  // Combining multiple vendor-specific selectors into a single block won't work, they should be split.
  // When a user agent cannot parse the selector (i.e., it is not valid CSS 2.1),
  // it must ignore the selector and the following declaration block (if any) as well.
  // https://www.w3.org/TR/CSS21/syndata.html#rule-sets
  &::-webkit-slider-thumb {
    .thumb();
  }

  &::-moz-range-thumb {
    .thumb();
  }

  &::-webkit-slider-runnable-track {
    .track();
  }

  &::-moz-range-track {
    .track();
  }

  .thumb() {
    -webkit-appearance: none;
    height: @global-size-tiny;
    width: @global-size-tiny;
    border-radius: 50%;
    background: @secondary_color;
    cursor: pointer;
    box-shadow: 0 0 2px 0 fade(#212222, 8%);
    transition: background .15s ease-in-out;
  }

  .track() {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  [dir=rtl] & {
    background-position-x: right;
  }
}
