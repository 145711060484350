.mtx-content-location-section {
  #map {
    height: 100%;
  }

  .form-item-location {
    &.location-search-enabled {
      .input-search-icon();
    }
  }

  .location-map {
    padding-top: 1.5em;
  }
}

.mtx-location-map {
  display: inline-block;
  // Reset buttons styles.
  padding: 0;
  border: 0 none;
  background-color: transparent;

  .md-location_on {
    color: @social-map-marker-color;
  }

  .views-table & {
    .md-icon {
      font-size: @table-font-size * @global-line-height;
    }
  }

  a > & {
    z-index: 1;
  }
}
