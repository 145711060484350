.navigation-widget {
  .widget-title {
    margin-bottom: @global-margin-small;
    font-weight: @global-font-weight-bold;
  }

  .navigation-item {
    .clearfix();
    margin-bottom: @global-margin;

    &:last-child {
      margin-bottom: 0;
    }

    .md-icon {
      width: 1em;
      text-align: center;

      &:before {
        vertical-align: middle;
      }
    }

    a {
      display: block;
      border: 1px solid @_grey_light_color;
      border-radius: @global-border-radius;
      padding: @global-margin;
      transition: all .15s ease-in-out;

      .md-icon {
        color: @_grey_color;
      }

      .status-icon {
        .circle(.75rem);
        margin-top: @global-margin / 2;
        background-color: @_grey_color;
      }

      span {
        display: block;
        padding-left: 2.25rem;
        padding-right: 2.25rem;

        &.title {
          font-weight: @global-font-weight-bold;
          font-size: unit(@global-font-size / @nei-container-font-size, em);
          line-height: @global-line-height;
        }

        &:not(.title) {
          margin-top: @global-margin-small;
        }
      }
    }

    &.inactive a,
    &.invisible a {
      color: @global-muted-color;
    }

    &.inactive a {
      cursor: default;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      .md-icon {
        float: right;
      }

      .status-icon {
        float: left;
        margin-left: @global-margin;
      }
    }

    [dir=rtl] & {
      .md-icon {
        float: left;
      }

      .status-icon {
        float: right;
        margin-right: @global-margin;
      }
    }
  }
}
