@form-block-margin: @global-margin-xlarge;
@form-block-item-margin: @global-margin-large;

.form-block {
  padding-bottom: @form-block-margin - @form-block-item-margin;

  .form-item {
    &:not(.form-type-checkbox) {
      margin: 0 0 @form-block-item-margin;
    }

    &:empty {
      margin: 0;
    }
  }

  .uk-grid > .form-item {
    input[type="text"] {
      width: 100%;
    }
  }
}
