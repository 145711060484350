.mtx-slides-set {
  .form-item {
    margin: 0;
  }
}

.mtx-slide {
  .position-cover();
  border-radius: @thumb-border-radius;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: @api-item-empty-bg;

  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }

  &-item {
    position: relative;
    margin-bottom: @global-margin;
  }

  &-holder {
    position: relative;
    padding-top: 56.25%; // (9 / 16) * 100
    outline: 0 none;
    border-radius: @thumb-border-radius;

    .mtx-handler {
      border-radius: calc(@md-icon-size / @button-border-radius-ratio);
    }

    &:hover,
    &:focus {
      .mtx-actions-wrapper .mtx-actions {
        visibility: visible;
        opacity: 1;
      }

      .mtx-slide-overlay {
        visibility: visible;
        opacity: @videos-thumb-overlay-opacity;
      }

      .mtx-slide-plus {
        color: @_grey_dark_color;
      }
    }
  }

  &-overlay {
    background: none @overlay-color-darken;
    visibility: hidden;
    opacity: 0;
    transition: @global-hover-transition;
  }

  &-plus {
    .position-cover();
    text-align: center;
    font-size: 1.75em;
    color: @_grey_color;
    cursor: pointer;
    transition: color .15s ease-in-out;

    .md-icon {
      .position-center();
    }
  }
}
