.mtx-reminders-section {
  @item-padding: @global-margin-medium;
  @checkbox-size: @global-size-small;

  .mtx-list-item {
    position: relative;
    padding: @item-padding;

    & + .mtx-list-item {
      margin-top: 0;
    }

    .icon,
    .checkbox {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .checkbox {
      color: @_grey_dark_color;

      .md-icon {
        font-size: @checkbox-size;
      }

      &.checked .md-icon:after {
        content: '\e834'; // md-check_box
      }
    }

    [dir=ltr] & {
      .icon {
        left: @item-padding;
      }

      .item-content {
        margin-right: @checkbox-size + @global-margin-medium;
      }

      .checkbox {
        right: @item-padding;
      }
    }

    [dir=rtl] & {
      .icon {
        right: @item-padding;
      }

      .item-content {
        margin-left: @checkbox-size + @global-margin-medium;
      }

      .checkbox {
        left: @item-padding;
      }
    }
  }

  .views-exposed-widgets {
    margin: 0;
  }

  @media all and (min-width: @breakpoint-medium) {
    .mtx-section-header {
      margin-bottom: @global-margin;
    }

    .mtx-section-title {
      margin-bottom: 0;
    }

    .mtx-list-item {
      min-height: (@item-padding * 2) + @list-icon-size-small;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    @list-icon-size-small: @md-icon-size;

    .views-exposed-widget + .views-exposed-widget {
      margin-top: @global-margin-small;

      &.views-submit-button {
        margin: 0;
      }
    }

    .mtx-list-item {
      min-height: (@item-padding * 2) + @list-icon-size-small;

      .icon.small {
        width: @list-icon-size-small;
        max-width: @list-icon-size-small;
        margin: 0;

        .md-icon {
          width: @list-icon-size-small;
          line-height: @list-icon-size-small;
          background-color: transparent;
          color: @_grey_dark_color;
        }

        & + .item-content {
          margin-inline-start: @list-icon-size-small + @list-icon-margin;
        }
      }
    }
  }
}
