.editor-anchor-preview {
  font-family: @editor-font-sans-serif;
  font-size: @tooltip-font-size;
  left: 0;
  line-height: @tooltip-line-height;
  max-width: 25rem;
  position: absolute;
  text-align: center;
  top: 0;
  word-break: break-all;
  word-wrap: break-word;
  visibility: hidden;
  z-index: @editor-toolbar-z-index + 1;

  a {
    color: #fff;
    display: inline-block;
    margin: 3px 5px;
  }
}

.editor-anchor-preview-active {
  visibility: visible;
}
