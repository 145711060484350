// Team tasks filters
.team-tasks-filters {
  & > div {
    width: @exposed-input-width;

    &.search {
      .input-search-icon;

      @media all and (max-width: @breakpoint-small-max) {
        width: 100%;
      }
    }
  }

  .team-tasks-period .disabled-result {
    @media (max-width: @breakpoint-small-max) {
      display: none !important;
    }
  }
}

// Team tasks list
.view-team-tasks {
  .mtx-list-item .icon {
    .md-icon {
      display: block;
      background-color: transparent;
      font-size: @list-icon-size-xxsmall;
    }
  }
}

.team-task-actions {
  margin-top: @global-margin-large;
  margin-bottom: @global-margin-large;
}

.teams-tasks-list {
  & > .mtx-list + .mtx-section-title {
    margin-top: @global-margin-xlarge;
  }
}
