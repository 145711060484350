@widget-margin-top: @global-grid-gutter;
@widget-actions-font-size: 1.25rem;
@widget-title-line-height: 1.3;
@widget-empty-text-font-size: unit(@global-font-size-small, rem);
@widget-empty-text-line-height: 1.375;

.widget {
  background-color: @content-section-color;
  margin-bottom: @global-margin;
  padding: @global-margin;
  border-radius: @global-border-radius;

  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }

  & > &-header {
    display: block;
    .clearfix();

    .widget-title {
      margin-top: 0;
      margin-bottom: @global-margin-large;
      line-height: @widget-title-line-height;
      word-break: break-word;
      word-wrap: break-word;

      span {
        [dir=ltr] & {
          margin-right: @global-margin;
        }

        [dir=rtl] & {
          margin-left: @global-margin;
        }
      }
    }

    .widget-link {
      color: @global-muted-color;
      display: inline-block;
      font-size: @global-font-size-small;
      font-weight: @global-font-weight-normal;
      line-height: inherit;
    }
  }

  & > &-body {
    display: block;
    position: relative;

    .empty {
      font-size: @widget-empty-text-font-size;
      line-height: @widget-empty-text-line-height;
      margin-bottom: @global-margin-large;
    }
  }

  // Widgets containing 'mtx-list'.
  .mtx-list-item {
    .title {
      margin: 0 0 (@global-margin-small / 2);
    }

    .description,
    .meta {
      font-size: @global-font-size-xsmall;
    }
  }
}
