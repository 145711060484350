.mtx-player-placeholder {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  width: 100%;

  .mp-info {
    .position-bottom();
    font-size: 1em;
    font-weight: @global-font-weight-normal;
    line-height: 1 * @mp-grid;
    padding: 0 @mp-grid/2 @mp-grid/2;

    .mp-playlist-info {
      text-transform: uppercase;
    }
  }

  .mp-thumbnail {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    .position-cover();
  }

  & + .mp-video-wrapper {
    &,
    & + .mp-slides-wrapper {
      display: none;
    }
  }

  &.playing {
    display: none;

    & + .mp-video-wrapper {
      &,
      & + .mp-slides-wrapper {
        display: block;
      }
    }
  }
}
