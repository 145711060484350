.mtx-report {
  @reset-icon-size: @form-height;

  &-dates-filter {
    .form-item {
      display: inline-block;
      margin-top: 0;
    }

    .reset {
      display: inline-block;
      width: @reset-icon-size;
      text-align: center;
      vertical-align: middle;
      line-height: @reset-icon-size;
      color: @_grey_dark_color;

      &[disabled] {
        color: @_grey_color;
        cursor: default;
      }
    }
  }

  &-country-filter {
    .form-item {
      margin-bottom: 0;
    }
  }

  @media all and (min-width: @breakpoint-small) {
    &-dates-filter {
      display: inline-block;
    }

    &-country-filter {
      min-width: 12.5rem;

      .chosen-container {
        max-width: 12.5rem !important;
      }

      [dir=ltr] & {
        float: right;
      }

      [dir=rtl] & {
        float: left;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    &-dates-filter {
      &.mtx-date-field input[data-uk-datepicker] + .field-suffix,
      .reset {
        display: none;
      }

      .form-item-start {
        [dir=ltr] & {
          margin-right: @global-margin-small / 2;
        }

        [dir=rtl] & {
          margin-left: @global-margin-small / 2;
        }
      }
    }
  }
}
