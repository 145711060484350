.nei-column {
  display: block;

  .nei-mode-edit & {
    height: 100%;

    &.empty {
      min-height: 4 * @nei-controls-padding + @nei-controls-size;
      position: relative;

      .empty-column-controls {
        background-color: @overlay-color-dark;
        border: 1px dashed @_grey_color;
        cursor: pointer;

        .mtx-dark-theme & {
          border-color: @overlay-color-lighten;
        }

        .control {
          display: block;
          width: @nei-controls-size;
          height: @nei-controls-size;
          background-color: @nei-controls-bg;
          color: @global-text-color;
          box-shadow: @nei-controls-box-shadow;
          .button-border-radius(@nei-controls-size / 16);
          text-align: center;
          transition: background-color .2s linear;

          &:focus {
            background-color: @nei-controls-bg-hover;
          }

          &.paste {
            display: none;
          }

          .md-icon {
            .material-icon(@nei-controls-icon-font-size);
            line-height: @nei-controls-size;
          }

          [dir=ltr] &,
          [dir=rtl] [dir=ltr] & {
            & + .control {
              margin-left: @nei-controls-padding / 2;
            }
          }

          [dir=rtl] & {
            & + .control {
              margin-right: @nei-controls-padding / 2;
            }
          }
        }

        &:hover,
        &:focus-within {
          border-color: @_grey_dark_color;
        }

        &:hover {
          .control {
            background-color: @nei-controls-bg-hover;
          }
        }

        .has-buffer & {
          cursor: default;

          &:hover {
            .control {
              background-color: @_white_color;
            }
          }

          .control {
            &.add {
              display: none;
            }

            &.paste {
              display: block;
            }

            &:hover,
            &:focus {
              background-color: @nei-controls-bg-hover;
            }
          }
        }
      }

      &.sorting {
        background-color: transparent;
        border-color: transparent;

        .empty-column-controls {
          display: none;
        }

        .nei-section {
          height: 100%;

          & + .nei-section {
            display: none;
          }
        }
      }

      .nei-section:only-of-type {
        height: 100%;
        min-height: 2 * @nei-controls-padding + @nei-controls-size !important;
      }
    }
  }
}
