.checkout-page {
  @block-margin: @global-margin-medium * 2;

  // Checkout info widget, Order confirmation page
  .checkout-list,
  .checkout-info {
    & > :first-child {
      padding-top: @block-margin - @global-margin;
    }

    &:after {
      content: '';
      display: block;
      width: @global-size-medium;
      height: .25rem;
      margin-top: @block-margin;
    }
  }

  .checkout-list {
    margin-top: 0;

    .title {
      word-break: break-word;
    }

    .multiplication-sign {
      display: inline-block;
    }
  }

  .checkout-summary {
    margin-top: @block-margin;

    .title {
      word-break: break-word;
    }

    div.total {
      font-size: 1.5rem;
      font-weight: @global-font-weight-bold;
    }
  }

  .checkout-price-related-item {
    white-space: nowrap;
  }

  // Order confirmation page
  &.order-confirmation {
    max-width: 100%;
  }
}

// Order confirmation page
.order-confirmation {
  &-section {
    @margin-top: @global-margin-xlarge;

    & + .order-confirmation-section {
      margin-top: @margin-top;
    }

    &.upcoming-events {
      & + .order-confirmation-section {
        margin-top: @margin-top - @list-item-padding-y;
      }
    }
  }

  &-section-title {
    @margin-bottom: @global-margin-large;

    margin-bottom: @margin-bottom;

    h2 {
      [dir=ltr] & {
        margin: 0 @global-margin-medium 0 0;
      }

      [dir=rtl] & {
        margin: 0 0 0 @global-margin-medium;
      }
    }

    a {
      .md-icon {
        vertical-align: bottom;
      }
    }

    .upcoming-events & {
      margin-bottom: @margin-bottom - @list-item-padding-y;
    }
  }

  // Course overview
  &-course-overview {
    & > div + div {
      margin-top: @global-margin;
    }

    .md-icon {
      color: @global-muted-color;
    }
  }

  // Courses (bundle)
  .order-item {
    & + .order-item {
      margin-top: @global-margin-medium * 2;
    }

    &-banner {
      .mtx-thumb {
        padding-top: 66.7%; // 80px / 120px
        width: 7.5rem; // 120px
        border-radius: @global-border-radius;
      }
    }

    &-info {
      & > * {
        margin-bottom: @global-margin-mini;

        &:last-child {
          margin-bottom: 0;
        }
      }

      div {
        font-size: @global-font-size-small;
      }
    }

    &-title {
      font-size: @base-h5-font-size;
    }

    [dir=ltr] & {
      &-banner {
        margin-right: @global-margin-large;
      }
    }

    [dir=rtl] & {
      &-banner {
        margin-left: @global-margin-large;
      }
    }
  }

  // Event
  .order-event {
    @event-padding: @date-widget-min-width + @global-margin;

    & + .order-event {
      margin-top: (@global-margin-medium * 2) - (@list-item-padding-y * 2);
    }
  }

  // Accounts
  .checkout-account {
    @info-font-size: @global-font-size-small;

    .user-avatar {
      width: @global-size-medium;
    }

    .contact-info {
      .name {
        margin-bottom: 0;
        font-size: @base-h4-font-size;
      }

      .email {
        font-size: @info-font-size;
        color: @_grey_dark_color;
      }
    }

    .contact-details {
      font-size: @info-font-size;

      & > div {
        margin-top: @global-margin-small;
      }

      .title {
        font-weight: @global-font-weight-semibold;
        font-size: @base-h4-font-size;
      }
    }

    & + .checkout-account {
      margin-top: @global-margin-medium * 2;
    }

    [dir=ltr] & {
      .user-avatar {
        margin-right: @global-margin-large;
      }
    }

    [dir=rtl] & {
      .user-avatar {
        margin-left: @global-margin-large;
      }
    }
  }
}

@media print {
  .order-confirmation-page {
    * {
      font-family: @global-font-family;
    }

    .md-icon {
      font-family: "Material Symbols";
    }

    #skip-link,
    #navbar-administration,
    .mtx-cookies-disclaimer,
    .page-sidebar,
    .page-header,
    .page-breadcrumbs,
    .page-footer,
    .back-link {
      display: none;
    }

    .mtx-widget-banner .course-label,
    .mtx-interface-link.add-to-calendar,
    .order-confirmation-items .order-item-banner,
    .checkout-account .user-avatar,
    .checkout-action-button,
    .mtx-thumb-error {
      display: none;
    }

    .mtx-widget-banner {
      border-radius: @global-border-radius;
    }

    .mtx-widget-body {
      padding: @mtx-widget-padding 0;
    }

    [dir=ltr] & {
      .page-sidebar ~ .page-content-wrapper {
        margin-left: 0;
      }
    }

    [dir=rtl] & {
      .page-sidebar ~ .page-content-wrapper {
        margin-right: 0;
      }
    }
  }
}
