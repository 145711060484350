.mtx-api-horizontal-menu {
  @gradient-box-width: 28px;
  @active-item-border-height: 2px;

  position: relative;

  ul {
    white-space: nowrap;
    overflow: auto;
    padding: @focus-state-box-shadow-size;
    margin: -@focus-state-box-shadow-size;
  }

  li {
    display: inline-block;
  }

  .left,
  .right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: @gradient-box-width;
    opacity: 0;
    transition-duration: .3s;
    transform: translateZ(0);

    &.scrolling {
      opacity: 1;
    }
  }

  .left {
    left: -@focus-state-box-shadow-size;
  }

  .right {
    right: -@focus-state-box-shadow-size;
  }

  &.page-tabs {
    border-bottom: 1px solid @_grey_light_color;
    margin: 0 0 @global-margin-large;

    .mtx-dark-theme & {
      border-bottom-color: @_grey_dark_color;
    }

    .mtx-content-section & {
      margin-left: -@content-section-padding;
      margin-right: -@content-section-padding;
    }

    ul {
      font-size: @global-font-size-small;
    }

    li {
      &.active {
        border-bottom: @active-item-border-height solid;
      }

      a {
        display: block;
        padding: @global-margin 1.375rem;
      }
    }

    a {
      &,
      &:hover,
      &:focus,
      &.active {
        color: inherit;
      }
    }

    .left,
    .right {
      bottom: @active-item-border-height;
    }
  }

  &.form-steps {
    @item-margin: @global-margin-medium * 2;
    @line-height: 1.5;
    @font-size: 1.25rem;

    margin: @global-margin-xlarge 0;

    .menu-item {
      line-height: @line-height;
      font-size: @font-size;
      font-weight: @global-font-weight-bold;
      color: @_grey_color;

      &.active {
        color: inherit;
      }
    }

    .menu-item:not(:last-child) {
      position: relative;

      &:after {
        .material-icon();
        position: absolute;
        line-height: @line-height * @font-size;
      }

      &.active:after {
        color: @_grey_color;
      }

      [dir=ltr] & {
        margin-right: @item-margin;
        padding-right: @item-margin + @md-icon-size;

        &:after {
          content: '\e5cc'; // md-chevron_right
          right: 0;
        }
      }

      [dir=rtl] & {
        margin-left: @item-margin;
        padding-left: @item-margin + @md-icon-size;

        &:after {
          content: '\e5cb'; // md-chevron_left
          left: 0;
        }
      }
    }
  }
}
