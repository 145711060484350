.nei-widget-discussion {
  border-top: 4px solid @global-text-color;
  padding-top: 2 * @global-margin-medium;

  .nei-mode-view & {
    margin-top: @global-margin-xlarge;
  }

  .wrapper {
    position: relative;

    & > h2 {
      margin-bottom: @global-margin-medium;
    }

    .team-page-link {
      position: absolute;
      top: 0;
      font-size: @global-font-size;

      [dir=rtl] & {
        left: 0;
      }

      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        right: 0;
      }
    }

    .mtx-actions,
    .post-dropdown-actions {
      display: none;
    }

    .post-actions {
      display: block;
    }

    .post-filters {
      @filter-input-width: 120px;

      .form-type-select,
      .form-type-textfield {
        width: @exposed-input-width;
        min-width: @filter-input-width;
      }

      .teams-select {
        width: 30%;
        min-width: @filter-input-width;
        margin-bottom: @global-margin;
      }
    }

    .mtx-list-item .mtx-actions li {
      margin: 0;
    }

    .widget[post-switching="0"] {
      .discussion-block {
        display: none;
      }

      .post-filters {
        position: relative;
      }

      .without-teams .create-post {
        position: absolute;
        top: 0;
      }
    }

    .add-discussion,
    .discussion-action {
      margin-bottom: @global-margin;
    }

    .team-discussion-topic {
      h1.page-title {
        font-size: 1.25rem;
        margin-bottom: @global-margin;
      }

      & > .mtx-list-item {
        margin-top: 0;
      }

      .post-actions {
        display: none;
      }
    }
  }

  .team-discussion-topic {
    .post-comments-list {
      margin-top: 2 * @global-margin-medium !important; // Overrides uk-margin-top important
      padding-top: 2 * @global-margin-medium;
      border-top: 1px solid @_grey_light_color;
    }
  }

  .view-message-board-comments {
    .item-list .pager.pager-load-more {
      a {
        font-size: @global-font-size-small;
        padding: 0;
        margin: 0;
        min-height: auto;
        background: transparent !important;

        &:after {
          .material-icon(1.125rem);
          content: '\e5d8'; // md-arrow_upward
          vertical-align: text-top;
          margin: 0 @global-margin-mini;
        }
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .resizeFields();
  }

  .without-access-create .discussions select {
    border-radius: @global-border-radius;
  }
}

.uk-width-medium-1-3,
.uk-width-medium-4-10,
.uk-width-medium-6-10,
.uk-width-large-7-10 .uk-width-medium-1-2,
.uk-width-large-7-10 .uk-width-medium-2-3,
.uk-width-medium-2-3 .uk-width-medium-1-2 {
  .nei-widget-discussion {
    .resizeFields();
  }
}

.resizeFields() {
  .filters-wrapper,
  .teams-actions,
  .left-wrapper,
  .discussions {
    margin: 0 !important;
  }

  .post-filters {
    .teams-select,
    .views-exposed-widget {
      margin: 0 0 @global-margin;
    }

    .views-exposed-widget:last-child {
      margin-bottom: 0;
    }
  }

  .teams-actions,
  .discussions,
  .discussion-block,
  .left-block,
  .teams-select,
  .view-filters,
  .teams-select *,
  .view-filters * {
    width: 100% !important;
    max-width: 100% !important;
  }

  .create-post {
    margin-bottom: @global-margin;
  }

  .without-teams .create-post {
    position: relative !important;
    top: auto !important;
  }

  .discussion-actions {
    margin-bottom: @global-margin;
  }

  .without-access-create .team-discussions {
    margin-bottom: @global-margin;
  }
}

.mtx-api-team-filter {
  .input-wrapper {
    margin-bottom: @global-margin-medium;
    .input-search-icon;
  }

  .search-input {
    display: block;
    width: 100%;
  }

  .teams-list {
    .reset-list;
  }

  .team {
    a {
      display: block;
      border-radius: @global-border-radius;
      padding: @global-margin-small;
      outline: 0 none;

      &,
      &:hover,
      &:focus {
        color: inherit;
      }
    }

    &.uk-active,
    a:hover,
    a:focus {
      background-color: #f7f7f7;
    }

    & + .team {
      margin-top: .1875rem;
    }
  }

  .empty {
    width: 100%;
    padding: @global-margin-small;
    text-align: center;
    color: @global-muted-color;
    .user-select();
  }
}
