@step-size: .625rem;

.mtx-steps-indicator {
  ul {
    padding: @global-margin-large 0;
    text-align: center;
  }

  li {
    display: inline-block;
    width: @step-size;
    height: @step-size;
    border-radius: @step-size / 2;
    background-color: @_grey_lighten_color;
    margin: 0 @step-size / 2;

    &.active {
      background-color: @secondary_color;
    }
  }
}
