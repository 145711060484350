// Buttons
input[type=submit],
button {
  &:not(:disabled) {
    cursor: pointer;
  }
}

.field-widget-file-generic table td input.form-submit {
  font-size: 12px;
}
