// @see: mtx-api-menu-auto-list.tpl.php
// @todo: Rework this styles according to new design.
.mtx-api-menu-auto-list {
  .list {
    margin-bottom: -@global-margin;
  }

  .item {
    margin-bottom: @global-margin;
  }

  .wrapper {
    height: 100%;
    border-radius: @global-border-radius;
    padding: @global-margin-medium 0;
  }

  .icon {
    padding: 0;
    text-align: center;

    .md-icon {
      vertical-align: baseline;
    }
  }

  .title {
    line-height: 1;
    margin: 0 0 @global-margin-small;
  }

  .description {
    font-size: @global-font-size-small;
    line-height: unit(@global-line-height, rem);
  }

  .icon,
  .title a,
  .description {
    color: inherit;
  }

  [dir=ltr] & {
    .item {
      text-align: left;
    }

    .icon + .text {
      margin: 0 0 0 unit(@md-icon-size + @global-margin-large, rem);
    }
  }

  [dir=rtl] & {
    .item {
      text-align: right;
    }

    .icon + .text {
      margin: 0 unit(@md-icon-size + @global-margin-large, rem) 0 0;
    }
  }
}
