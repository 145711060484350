.api-breadcrumbs {
  .reset-list;

  li {
    display: inline-block;
    vertical-align: bottom;

    & + li {
      [dir=ltr] & {
        margin-left: .5em;
      }

      [dir=rtl] & {
        margin-right: .5em;

        .md-chevron_left {
          margin: 0 0 0 .35em;

          &:after {
            content: '\e5cc'; // md-chevron_right
          }
        }
      }
    }
  }
}
