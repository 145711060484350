.nei-widget-title {
  h1.title {
    margin-bottom: 0;
    line-height: 1.5;
    outline: 0 none;
    float: none !important;
  }

  .nei-mode-edit & {
    padding: @global-margin-small 0;

    h1.title * {
      display: contents;
      font-size: 1em !important;
    }
  }
}
