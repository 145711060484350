.mtx-advanced-instructor-activity {
  &-info-section {
    .activity-info {
      @item-margin: @global-margin;

      margin: 0 0 (@mtx-section-padding - @item-margin);

      & > * {
        margin-bottom: @item-margin;

        [dir=ltr] & {
          margin-right: @global-margin-medium * 2;
        }

        [dir=rtl] & {
          margin-left: @global-margin-medium * 2;
        }
      }

      .md-icon {
        color: @_grey_dark_color;
      }
    }
  }

  &-participants-section {
    .mtx-section-title {
      margin-bottom: 1.25rem;
    }

   .responsive-table table {
     margin: 0;
   }

    .signup {
      .text > div + div {
        margin-top: @global-margin-mini;
      }

      .name-cell {
        min-width: 18rem;
        max-width: 21rem;

        @media (min-width: @breakpoint-medium) {
          max-width: calc(@utility-container-max-width / 2);
        }

        @media (min-width: @breakpoint-xlarge) {
          max-width: calc(@utility-container-large-max-width / 2);
        }
      }
    }
  }

  &-courses {
    .course-title {
      font-size: @global-font-size-small;
    }
  }
}

.mtx-activity-templates-page-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: @modal-z-index;
  transform: translateZ(0);
  transition: all .15s linear;
  opacity: 0;
  background: @modal-dialog-background;
  padding-bottom: 300px;

  &.visible {
    opacity: 1;
  }

  html.uk-modal-page & {
    transform: translateZ(0);
    transition: all .15s linear;
  }

  .activity-templates-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: @_white_color;
    padding-top: @global-margin-large;
    padding-bottom: @global-margin-medium * 2;
  }

  .activity-templates-wrapper {
    margin-bottom: @global-margin-large + @global-margin-medium * 2 + @form-height;

    .view-empty {
      width: 100%;
      padding: 0;
    }
  }

  .activity-templates-filter-actions {
    padding-top: @global-margin-medium * 2;
    padding-bottom: @global-margin;
  }

  .form-item-search {
    width: @exposed-input-width;
    .input-search-icon;
  }
}

.mtx-activity-nameplates-export-text-settings-dialog {
  .uk-grid > .form-item {
    margin-top: 0;
  }
}
