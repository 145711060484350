// @Todo: Rework using the unique class name.
.team-files-filters {
  .form-item-search {
    width: @exposed-input-width;
    .input-search-icon;
  }
}

.team-files-list {
  // @Todo: Make it universal, move to list.less
  // See .mtx-suborganizations-list {}
  .icon img {
    background-color: @_grey_lighten_color;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }
  }
}

.team-files-folders-list {
  a.mtx-list-item {
    display: block;
    padding: @global-margin-small;
    border-radius: @global-border-radius;
    color: inherit;

    &:hover,
    &:focus,
    &.active {
      background-color: @page_content_background_color;

      .mtx-dark-theme & {
        background-color: @overlay-color-dark;
      }
    }
  }
}

// @todo: adjust and make this universal.
// @see theme_mtx_teams_files_resource_icon.
.resource-icon {
  position: relative;

  img {
    width: auto !important;
  }

  // Do not show extension
  span.overlay {
    display: none;
  }
}
