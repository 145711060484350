.editor-placeholder {
  position: relative;

  &:after {
    content: attr(data-placeholder) !important;
    font-style: italic;
    position: absolute;
    left: 0;
    top: 0;
    white-space: pre;
    padding: inherit;
    margin: inherit;
  }
}

.editor-placeholder-relative {
  position: relative;

  &:after {
    content: attr(data-placeholder) !important;
    font-style: italic;
    position: relative;
    white-space: pre;
    padding: inherit;
    margin: inherit;
  }
}
