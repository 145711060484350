.mtx-expiration-thumb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: @global-border-radius;
    background-color: fade(#000000, 50%);
    color: @mtx-course-el-info-color;
    text-align: center;
    z-index: 1;
}

.mtx-expiration-text {
  width: 100%;
  margin: 0;
  padding: 0 @global-margin-small;
  .text-ellipsis-box(2);
}

// Copyright sign is used for small items instead of expiration text.
.mtx-expiration-text,
.mtx-expiration-thumb .mtx-copyright-sign {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: unit(@global-font-size-small, rem);
  transform: translate(-50%, -50%);
}
