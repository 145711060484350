// Name:            Popover
// Description:     Defines styles for popovers
//
// Component:       `uk-popover`
//
// Modifiers        `uk-popover-top`
//                  `uk-popover-top-left`
//                  `uk-popover-top-right`
//                  `uk-popover-bottom`
//                  `uk-popover-bottom-left`
//                  `uk-popover-bottom-right`
//                  `uk-popover-left`
//                  `uk-popover-right`
//
// ========================================================================

// Variables
// ========================================================================

@popover-z-index:                               @tooltip-z-index;

@popover-border-radius:                         @global-border-radius;
@popover-background:                            @tooltip-color;
@popover-color:                                 @tooltip-background;
@popover-font-size:                             @global-font-size-small;
@popover-font-size-mobile:                      @global-font-size-xsmall;
@popover-line-height:                           1.25;

@popover-max-width:                             250px;
@popover-max-width-mobile:                      170px;

@popover-padding-horizontal:                    @tooltip-padding-horizontal;
@popover-padding-vertical:                      @tooltip-padding-vertical;

@popover-close-icon-font-size:                  1rem;
@popover-text-align:                            center;


/* ========================================================================
   Component: Popover
 ========================================================================== */

.uk-popover {
  background: @popover-background;
  box-sizing: border-box;
  color: @popover-color;
  display: none;
  font-size: @popover-font-size;
  line-height: @popover-line-height;
  max-width: @popover-max-width;
  padding: @popover-padding-vertical @popover-padding-horizontal;
  position: absolute;
  text-align: @popover-text-align;
  white-space: pre-wrap;
  z-index: @popover-z-index;
  border-radius: @popover-border-radius;
  .hook-popover;

  @media (max-width: @breakpoint-small-max) {
    font-size: @popover-font-size-mobile;
    max-width: @popover-max-width-mobile;
  }

  & &-header {
    font-size: @popover-font-size + 2;
    font-weight: bold;

    @media (max-width: @breakpoint-small-max) {
      font-size: @popover-font-size-mobile;
    }
  }

  & &-close {
    color: @popover-color;
    position: absolute;
    top: 4px;
    opacity: .7;
    transition: @global-hover-transition;

    &:hover,
    &:focus {
      opacity: 1;
    }

    [dir=ltr] & {
      right: 4px;
    }

    [dir=rtl] & {
      left: 4px;
    }

    &:before {
      .material-icon(@popover-close-icon-font-size);
      content: '\e5cd'; // md-close
    }

    .hook-popover-close;
  }
}

// Hooks
// ========================================================================

.hook-popover-misc;

.hook-popover() {}
.hook-popover-misc() {}
.hook-popover-close() {};
