.widget-content.math-widget {
  *:focus {
    outline: 0 none;
  }
}

.uk-form textarea.math-input,
.math-preview-area {
  max-width: 100%;
  height: 400px;
  max-height: 400px;
  border: 1px solid @form-border;
  font-size: @nei-container-font-size;
  line-height: @global-line-height;
  border-radius: @global-border-radius;
  padding: 5px;
  color: @form-color;
  resize: none;
  overflow: hidden;
  overflow-y: auto;
  background: @_white_color;

  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }
}

.mjx-chtml[tabindex]:focus {
  .math-widget & {
    display: inline-block;
    outline: 0 none;
  }
}
