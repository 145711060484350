.mtx-statistics-details {
  @details-item-margin: @global-margin-large;
  @details-item-line-height: 1.375;

  .list {
    .item {
      line-height: @details-item-line-height;

      // Similar to status-icon in NEI.
      .list-marker-disc(
        .75rem,
        @details-item-line-height,
        @global-margin-medium
      );

      & + .item {
        margin-top: @details-item-margin;
      }

      &.last-visible ~ .item {
        display: none;
      }

      .slash {
        margin: 0 @global-margin-small;
        font-size: .75rem;
      }

      .meta {
        color: @_grey_dark_color;
      }
    }

    .item.page-progress-wrapper {
      &:before {
        display: none !important;
      }

      .mtx-circular-progress {
        top: .15rem;
      }

      [dir=ltr] & {
        padding-left: @mtx-circular-page-progress-size + @global-margin-medium;

        .mtx-circular-progress {
          left: 0;
        }
      }

      [dir=rtl] & {
        padding-right: @mtx-circular-page-progress-size + @global-margin-medium;

        .mtx-circular-progress {
          right: 0;
        }
      }
    }

    & + .mtx-api-toggler-button {
      margin: @global-margin-medium * 2 0 0;
    }
  }

  .table {
    .item {
      &.last-visible ~ .item {
        display: none;
      }
    }

    .mtx-api-toggler-button {
      margin: @global-margin-medium * 2 0 0;
    }
  }

  &.tests {
    @chart-wrapper-margin-x: @global-margin-medium * 4;
    @chart-wrapper-margin-bottom: @global-margin-large * 2;
    @chart-value-padding-bottom: @global-margin-small;
    @chart-text-fs: @global-font-size-small;

    .charts-list {
      margin-top: @details-item-margin;

      & + .details-btn {
        margin-top: @global-margin-medium - @chart-wrapper-margin-bottom;

        .uk-button {
          margin-bottom: @details-item-margin;
        }
      }
    }

    .chart-wrapper {
      display: inline-block;
      margin-bottom: @chart-wrapper-margin-bottom;
    }

    .chart {
      position: relative;
      display: inline-block;
      width: 5rem; // 80px
      height: 6.25rem; // 100px
      margin-top: unit(@details-item-line-height, rem) + @chart-value-padding-bottom;

      .value {
        position: absolute;
        padding-bottom: @chart-value-padding-bottom;
        text-align: center;
        font-size: @chart-text-fs;
        opacity: 0;
        transition: opacity .2s linear @statistics-transition-duration;
        .text-ellipsis();

        &.visible {
          opacity: 1;
        }
      }

      .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        min-height: 1rem;
        border-top-left-radius: @global-border-radius;
        border-top-right-radius: @global-border-radius;
        transition: height @statistics-transition-duration linear;
        background-color: @_grey_lighten_color;
      }

      &.average:not(.empty) .inner {
        background-color: @statistics-average-color;
      }
    }

    .legend {
      margin-top: @global-margin-medium;
      text-align: center;
      font-size: @chart-text-fs;
    }

    .mtx-statistics-scorm-xapi-test {
      &-item .details-btn {
        margin-top: @details-item-margin;
      }

      &-details-toggler {
        .button-toggler();
        margin-bottom: @details-item-margin;

        &.uk-open {
          margin-top: @global-margin;
        }
      }
    }

    [dir=ltr] & {
      .chart-wrapper {
        margin-right: @chart-wrapper-margin-x;
      }
    }

    [dir=rtl] & {
      .chart-wrapper {
        margin-left: @chart-wrapper-margin-x;
      }
    }
  }
}
