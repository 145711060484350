//
// Component: Breadcrumb
//
// ========================================================================


// Variables
// ========================================================================

@breadcrumb-disabled-color:                     @global-muted-color;


// Component
// ========================================================================

.hook-breadcrumb() {
  font-size: 1em;
}


// Items
// ========================================================================

.hook-breadcrumb-divider() {
  font-size: @global-font-size-xsmall;

  [dir=rtl] & {
    content: '\\';
  }
}


// Miscellaneous
// ========================================================================

.hook-breadcrumb-misc() {}
