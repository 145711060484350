// @todo: Create separate file and rework this
.mtx-commerce-countries {
  .country {
    background-color: @_grey_light_color;
    font-size: @global-font-size-small;
    line-height: 1.75;
    margin-top: @global-margin;
    padding: @global-margin;

    .default & {
      background-color: @_grey_highlight_dark_color;
    }

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }
  }

  .item-title {
    font-weight: @global-font-weight-normal;
    line-height: inherit;
    margin-bottom: @global-margin-small;
    .break-word;
  }
}
