// Name:            Slideshow
// Description:     Defines styles for a slideshow
//
// Component:       `uk-slideshow`
//
// Modifier:        `uk-slideshow-fullscreen`
//
// Sub-modifier:    `uk-slideshow-fade-out`
//                  `uk-slideshow-scroll-forward-in`
//                  `uk-slideshow-scroll-forward-out`
//                  `uk-slideshow-scroll-backward-in`
//                  `uk-slideshow-scroll-backward-out`
//                  `uk-slideshow-scale-out`
//                  `uk-slideshow-swipe-forward-in`
//                  `uk-slideshow-swipe-forward-out`
//                  `uk-slideshow-swipe-backward-in`
//                  `uk-slideshow-swipe-backward-out`
//
// States:          `uk-active`
//
// Uses:            Cover (JS)
//
// Markup:
//
// <!-- uk-slideshow -->
// <ul class="uk-slideshow" data-uk-slideshow>
//     <li><img src="" width="" height="" alt=""></li>
// </ul>
//
// ========================================================================


/* ========================================================================
   Component: Slideshow
 ========================================================================== */

/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Set width because child elements are positioned absolute. Height is set via JS
 * 4. Reset list style
 * 5. Clip child elements
 * 6. Deactivate browser history navigation in IE11
 */

.uk-slideshow {
    /* 1 */
    position: relative;
    /* 2 */
    z-index: 0;
    /* 3 */
    width: 100%;
    /* 4 */
    margin: 0;
    padding: 0;
    list-style: none;
    /* 5 */
    overflow: hidden;
    /* 6 */
    touch-action: pan-y;
}

/*
 * Sub-object item
 * 1. Position items above each other
 * 2. Expand to parent container width
 * 3. Hide by default
 */

.uk-slideshow > li {
    /* 1 */
    position: absolute;
    top: 0;
    left: 0;
    /* 2 */
    width: 100%;
    /* 3 */
    opacity: 0;
}

/*
 * Active
 * 1. Stack at first
 * 2. Show slide
 */

.uk-slideshow > .uk-active {
    /* 1 */
    z-index: 10;
    /* 2 */
    opacity: 1;
}

/*
 * Hide default images which is only relevant to keep existing proportions
 */

.uk-slideshow > li > img { visibility: hidden; }

/*
 * Pointer for controls
 */

[data-uk-slideshow-slide] { cursor: pointer; }


/* Modifier: `uk-slideshow-fullscreen`
 ========================================================================== */

.uk-slideshow-fullscreen,
.uk-slideshow-fullscreen > li { height: 100vh; }


/* Animations
 ========================================================================== */

/*
 * Fade
 */

 .uk-slideshow-fade-in {
     -webkit-animation: uk-fade 0.5s linear;
     animation: uk-fade 0.5s linear;
 }

.uk-slideshow-fade-out {
    -webkit-animation: uk-fade 0.5s linear reverse;
    animation: uk-fade 0.5s linear reverse;
}

/*
 * Scroll
 */

.uk-slideshow-scroll-forward-in {
    -webkit-animation: uk-slide-right 0.5s ease-in-out;
    animation: uk-slide-right 0.5s ease-in-out;
}

.uk-slideshow-scroll-forward-out {
    -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
    animation: uk-slide-left 0.5s ease-in-out reverse;
}

.uk-slideshow-scroll-backward-in {
    -webkit-animation: uk-slide-left 0.5s ease-in-out;
    animation: uk-slide-left 0.5s ease-in-out;
}

.uk-slideshow-scroll-backward-out {
    -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
    animation: uk-slide-right 0.5s ease-in-out reverse;
}

/*
 * Scale
 */

.uk-slideshow-scale-out {
    -webkit-animation: uk-fade-scale-15 0.5s ease-in-out reverse;
    animation: uk-fade-scale-15 0.5s ease-in-out reverse;
}

/*
 * Swipe
 */

.uk-slideshow-swipe-forward-in {
    -webkit-animation: uk-slide-left-33 0.5s ease-in-out;
    animation: uk-slide-left-33 0.5s ease-in-out;
}

.uk-slideshow-swipe-forward-out {
    -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
    animation: uk-slide-left 0.5s ease-in-out reverse;
}

.uk-slideshow-swipe-backward-in {
    -webkit-animation: uk-slide-right-33 0.5s ease-in-out;
    animation: uk-slide-right-33 0.5s ease-in-out;
}

.uk-slideshow-swipe-backward-out {
    -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
    animation: uk-slide-right 0.5s ease-in-out reverse;
}

.uk-slideshow-swipe-forward-in:before,
.uk-slideshow-swipe-backward-in:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(0,0,0,0.6);
    -webkit-animation: uk-fade 0.5s ease-in-out reverse;
    animation: uk-fade 0.5s ease-in-out reverse;
}


// Hooks
// ========================================================================

.hook-slideshow-misc;

.hook-slideshow-misc() {}
