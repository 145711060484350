//
// Component: Form password
//
// ========================================================================


// Variables
// ========================================================================

@form-password-font-size:                       @form-font-size;
@form-password-line-height:                     @form-height;
@form-password-color:                           @_grey_dark_color; // @form-icon-color
@form-password-hover-color:                     @_grey_darken_color;
@form-password-padding:                         @form-icon-width;


// Miscellaneous
// ========================================================================

.hook-form-password-misc() {
  .uk-form-password-toggle {
    top: auto;
    bottom: 0;
    margin: 0;
    padding: 0 (@form-password-padding - @md-icon-size) / 2;
    transition: color .15s ease-in-out;

    &:active,
    &:focus {
      color: @form-password-hover-color;
    }

    &:focus-visible {
      border-radius: @inputs-border-radius;
    }
  }

  [dir=ltr] & {
    .uk-form-password-toggle {
      right: 0;
    }
  }

  [dir=rtl] & {
    .uk-form-password-toggle {
      left: 0;
      right: auto;
    }

    .uk-form-password > input {
      padding-left: @form-password-padding;
      padding-right: @form-padding-x !important;
    }
  }
}
