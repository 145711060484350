@widget-caption-color: @global-muted-color;
@widget-caption-size: unit(@global-font-size-small / @nei-container-font-size, em);
@widget-overlay-z-index: 12;
@widget-contrast-opacity: .71;
@widget-contrast-opacity-hover: .86;

.nei-widget {
  border: 1px solid transparent;
  min-height: 1.5rem;
  outline: none;
  padding: 0;
  transition: border-color .3s linear;

  .nei-content-roundness & {
    border-radius: @course-content-border-radius;
  }

  & + & {
    margin-top: @grid-gutter-vertical;

    @media (min-width: @breakpoint-xlarge) {
      margin-top: @grid-gutter-large-vertical;
    }
  }

  .nei-widget-edit-wrapper {
    min-height: @global-height;
    position: relative;

    .nei-widget-overlay {
      .position-cover();
      background: none;
      height: 100%;
      width: 100%;
      z-index: @widget-overlay-z-index;
    }
  }

  .widget-caption {
    color: @widget-caption-color;
    font-size: @widget-caption-size;
    margin: @global-margin-small 0;
    position: relative;

    .caption-text {
      cursor: text;
      display: block;
      line-height: 1.5;
      outline: none;
      width: 100%;

      &[contenteditable]:empty:before {
        content: attr(data-placeholder);
        display: block;
        min-height: 1.5em;
      }
    }
  }

  .widget-headline {
    position: relative;

    .headline-text {
      cursor: text;
      display: block;
      outline: none;
      width: 100%;

      &[contenteditable]:empty:before {
        content: attr(data-placeholder);
        display: block;
        color: @widget-caption-color;
        font-weight: normal;
        min-height: 1.5em;
      }
    }
  }

  .anchor-indicator {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @nei-controls-size;
    height: @nei-controls-size;
    color: @global-text-color;
    background-color: @nei-controls-bg;
    border: 1px solid;
    pointer-events: none;
    z-index: 12;
    .button-border-radius(@nei-controls-size / 16);
  }

  // Edit mode styles
  .nei-mode-edit & {
    position: relative;
    transition: padding .2s ease-in-out;

    &:hover,
    &.editing {
      border-color: @_grey_dark_color;

      .anchor-indicator {
        display: none;
      }
    }
  }

  // Revisions mode styles
  .nei-mode-revisions & {
    position: relative;

    &.nei-widget-changed,
    &.nei-widget-deleted {
      &:before {
        opacity: .4;
        content: '';
        .position-cover();
        margin: -3px;
        z-index: 100;
      }
    }

    &.nei-widget-changed:before {
      background: #8cc05f;
    }
  }

  &.nei-widget-deleted {
    display: none;
  }

  &.nei-widget-hidden {
    display: none;

    .nei-mode-edit & {
      display: block;

      .nei-widget-edit-wrapper {
        opacity: .32;
      }
    }

    .nei-mode-revisions & {
      display: block;
      opacity: .32;
    }
  }

  &.mtx-course-widget-highlighted {
    position: relative;

    &:hover,
    &:focus {
      transition: all .3s ease-in;
    }

    &.uk-active {
      transition: all .8s ease-in;
    }

    &:after {
      content: '';
      .position-cover();
    }
  }

  .nei-mode-revisions &.nei-widget-deleted {
    display: block;

    &:before {
      background: #e62f2d;
    }
  }

  &.nei-over {
    background: #a1a9b6;
    box-sizing: content-box;
  }

  &.editing {
    z-index: 100;
  }

  &.cutting {
    opacity: .5;
  }
}
