.api-dialog .api-item {
  @icon-width: 2.5rem;

  &.folders-item {
    background-color: @_grey_lighten_color;
    border-radius: @thumb-border-radius;
    padding: @global-margin;
    margin-bottom: @global-margin;
    position: relative;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }

    .thumb {
      background-color: transparent;

      .folder-icon {
        display: block;
        fill: @_grey_dark_color;
        width: @icon-width;
        height: auto;
      }
    }

    .info {
      .title {
        font-weight: @global-font-weight-semibold;
        margin-top: 0;
      }

      .children-count {
        color: @global-muted-color;
      }
    }

    .folder {
      position: absolute;
      top: 0;
      bottom: 0;
      width: @icon-width;
      background-color: rgba(255, 255, 255, .06);
      transition: all .2s ease-in-out;

      i {
        font-size: 1.25rem;
        color: @global-muted-color;
        position: absolute;
        left: 50%;
        top: 50%;
      }

      &:hover {
        background-color: rgba(255, 255, 255, .3);
      }
    }

    .folders-count,
    .files-count {
      display: inline-block;
    }

    .folders-count + .files-count:before {
      content: ' / ';
    }

    &.selected {
      background-color: @primary_color;

      .thumb {
        .folder-icon {
          fill: @_white_color;
        }
      }

      .info {
        color: @_white_color;
      }

      .children-count {
        opacity: 50%;
      }
    }

    // LTR styles
    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      text-align: left;

      .thumb {
        float: left;
      }

      .info {
        padding-left: @icon-width + @global-margin;
      }

      .folder {
        left: auto;
        right: 0;
        border-radius: 0 @thumb-border-radius @thumb-border-radius 0;

        i {
          transform: translate(-50%, -50%);
        }
      }
    }

    // RTL styles
    [dir=rtl] & {
      text-align: right;

      .thumb {
        float: right;

        .folder-icon {
          transform: rotateY(180deg);
          transform-origin: center;
        }
      }

      .info {
        padding-right: @icon-width + @global-margin;
      }

      .folder {
        right: auto;
        left: 0;
        border-radius: @thumb-border-radius 0 0 @thumb-border-radius;

        i {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }
}
