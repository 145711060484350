.widget-announcements {
  position: relative !important;
  margin: @global-margin 0;

  .mtx-list-item {
    margin: 0;
    position: relative;
    border-radius: @global-border-radius;
    padding: @global-margin-medium;
    background-color: @_white_color;

    .card-element {
      border-radius: inherit;

      & ~ * a {
        .card-element-top();
      }
    }

    & + .mtx-list-item {
      margin-top: @global-margin;
    }

    .title {
      transition: color .15s ease-in-out;
    }

    .title,
    .description p {
      margin-bottom: 0;
    }

    .uk-close {
      padding: @global-margin-small;
      .card-element-top(absolute);
      top: @global-margin-medium;
    }

    [dir=ltr] & {
      .item-content {
        margin: 0 @global-margin-large 0 @list-content-margin;
      }

      .uk-close {
        right: @global-margin-medium;
        margin: -@global-margin -@global-margin -@global-margin-small -@global-margin-small;
      }
    }

    [dir=rtl] & {
      .item-content {
        margin: 0 @list-content-margin 0 @global-margin-large;
      }

      .uk-close {
        left: @global-margin-medium;
        margin: -@global-margin -@global-margin-small -@global-margin-small -@global-margin;
      }
    }

    &.announcement,
    &.event {
      .description {
        .text-ellipsis-box(1);
      }
    }

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }
  }
}
