@admin-navbar-z-index: 501;
@admin-navbar-tray-z-index: 502;
@content-section-color: @_white_color;
@content-section-padding: @global-margin;

html, body {
  display: flex;
  flex-direction: column;
  background-color: @page_content_background_color;
}

body {
  flex: 1 0 auto;

  &.page-sidebar-opened {
    &.page-sidebar-default-mode {
      overflow: hidden;
    }

    &.page-sidebar-fixed-mode {
      @media all and (max-width: @breakpoint-small-max) {
        overflow: hidden;
      }
    }
  }

  &.navbar-tray-open.navbar-vertical.navbar-fixed {
    [dir=ltr] & {
      margin-left: 0;
    }

    [dir=rtl] & {
      margin-right: 0;
    }
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow-x: hidden;
}

.page-content-wrapper {
  // Background image, NEI overlay in edit mode.
  position: relative;

  &,
  .page-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .page-content-background-enabled & {
    background-color: @page_content_background_color;
    z-index: 0;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
  }

  .mtx-content-section {
    background-color: @content-section-color;
    padding: @content-section-padding;
    border-radius: @global-border-radius;
    word-wrap: break-word;

    .mtx-dark-theme & {
      background-color: @overlay-color-darken;
    }
  }

  .uk-text-bold a {
    &,
    &.active,
    &:hover,
    &:focus {
      color: @global-text-color;
    }
  }
}

.page-content-layout {
  margin: @global-margin 0;

  .landing-layout &,
  .primary-layout & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Right sidebar
.page-sidebar-right {
  @sidebar-right-width: 464px;
  @sidebar-right-transition: .1s ease;

  position: fixed;
  top: 0;
  bottom: 0;
  z-index: @page-sidebar-z-index + 6;
  background-color: @_white_color;
  color: contrast(@_white_color);
  margin: 0;
  max-width: 100%;
  width: @sidebar-right-width;

  [dir=ltr] & {
    right: -@sidebar-right-width;
  }

  [dir=rtl] & {
    left: -@sidebar-right-width;
  }

  & ~ .page-content-wrapper {
    &,
    & .mtx-api-page-footer-nav-fixed {
      transition: margin @sidebar-right-transition;
    }
  }

  &.ready {
    transition: left @sidebar-right-transition, right @sidebar-right-transition;
  }

  &.open {
    box-shadow: @page-sidebar-box-shadow;

    [dir=ltr] & {
      right: 0;
    }

    [dir=rtl] & {
      left: 0;
    }

    & ~ .page-content-wrapper {
      &,
      & .mtx-api-page-footer-nav-fixed {
        margin-inline-end: @sidebar-right-width;
      }
    }
  }
}
