.mtx-report-courses-overview {
  margin: (@global-margin-medium * 2) 0 @global-margin;

  @colors: #57be44, #009fed, #e20014, #8c00b7, #ecbf00;
  .set-items-colors() {
    .style(@i: 1) when (@i < (length(@colors) + 1)) {
      &.color-@{i} {
        background-color: extract(@colors, @i);
      }

      .style(@i + 1);
    }
    .style();
  }

  .charts {
    height: 4rem;
    border-radius: @global-border-radius;
    background-color: @mtx-progress-bar-bg-color;
    overflow: hidden;
    .clearfix;
  }

  .chart {
    height: 100%;
    .set-items-colors();

    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
    }
  }

  .legend {
    margin-top: @global-margin;
    .clearfix;

    & > span {
      display: inline-block;
      vertical-align: middle;

      [dir=ltr] & {
        &.square {
          margin-right: @global-margin;
        }

        &.value {
          margin-right: @global-margin-small;
        }
      }

      [dir=rtl] & {
        &.square {
          margin-left: @global-margin;
        }

        &.value {
          margin-left: @global-margin-small;
        }
      }
    }

    .square {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: @global-border-radius;
      .set-items-colors();
    }

    .value {
      font-weight: @global-font-weight-semibold;
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .legends {
      margin-top: @global-margin;
      .clearfix;
    }

    .legend {
      [dir=ltr] & {
        float: left;
        margin-right: @global-margin-medium * 4;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @global-margin-medium * 4;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    margin-top: @global-margin-large;

    .legends {
      margin-top: @global-margin-small / 2;
    }

    .legend .square {
      width: @global-size-mini / 2; // 12px
    }
  }
}
