//**
// Styles for MTX scrollbar.
//
// Layout:
// <div class="mtx-custom-scrollbar">
//   <div class="scroll-content"> ** optional
//     ** Put your html content here. **
//   </div>
// </div>
//*

@mtx-scrollbar-padding: 12px;
@mtx-scrollbar-color: @_grey_lighten_color;
@mtx-scrollbar-thumb-color: @_grey_color;

.mtx-custom-scrollbar {
  @scrollbar-width: 8px;
  @thumb-width: 4px;

  position: relative;
  margin-inline-start: -@mtx-scrollbar-padding;
  margin-inline-end: 0;

  .os-content {
    padding: 0 @mtx-scrollbar-padding !important;
  }

  &.os-host-scrollbar-vertical-hidden {
    .os-content {
      padding: 0 !important;
      margin: 0;

      .uk-modal &,
      .api-dialog &,
      .mtx-api-filter & {
        padding: 0 @mtx-scrollbar-padding !important;
      }
    }
  }

  // Colors
  .os-scrollbar-track {
    background: @mtx-scrollbar-color !important;
    cursor: pointer;

    .mtx-dark-theme & {
      background-color: fade(@_grey_darken_color, 50%) !important;
    }
  }

  .os-scrollbar-handle {
    background: @mtx-scrollbar-thumb-color !important;

    .mtx-dark-theme & {
      background: fade(@mtx-scrollbar-thumb-color, 50%);
    }
  }

  // Scrollbar in dropdown.
  .uk-dropdown & {
    .uk-nav {
      margin: 0;
    }

    margin-inline-end: -(@dropdown-padding / 2);

    &.os-host-scrollbar-vertical-hidden {
      margin-inline-end: -@dropdown-padding;
    }

    .os-content {
      padding-inline-start: 0 !important;
    }
  }

  .uk-dropdown-small & {
    margin-inline-start: -@dropdown-small-padding;
    margin-inline-end: -(@dropdown-small-padding / 2);

    &.os-host-scrollbar-vertical-hidden {
      margin-inline-end: -@dropdown-small-padding;
    }
  }
}
