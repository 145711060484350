.nei-widget-external {
  iframe {
    width: 100% !important;

    .nei-content-roundness & {
      border-radius: @course-content-border-radius;
    }
  }
}

.external-video-preview {
  .preview-thumb {
    border-radius: @thumb-border-radius;
  }

  .preview-text {
    .text-ellipsis-box(3);
  }
}
