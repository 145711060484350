.mtx-report-active-users {
  &-section {
    .mtx-report-get-report .uk-button {
      margin-top: @global-margin-small;
    }

    .mtx-report-dates-filter .reset {
      vertical-align: top;
    }
  }

  &-bar-chart {
    margin: 0 0 @global-margin-medium;
  }

  &-form {
    .form-item + .form-item {
      margin-top: 0;
    }
  }

  @media all and (min-width: @breakpoint-small) {
    &-bar-chart-legend {
      &.mtx-api-bar-chart-legend {
        margin-top: @global-margin-medium;
        float: inline-end;
      }
    }

    &-form .form-item {
      display: inline-block;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    &-section {
      .mtx-report-get-report .uk-button {
        width: 100%;
        margin-top: @global-margin-large;
      }
    }

    &-bar-chart-legend {
      &.mtx-api-bar-chart-legend > div {
        column-gap: 1.25rem;
      }
    }

    &-form {
      .clearfix();
      margin-left: -@global-margin-small;

      .form-item {
        width: 50%;
        padding-left: @global-margin-small;
        float: inline-start;
      }
    }
  }
}
