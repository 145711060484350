.form-type-file,
.form-type-managed-file {
  &.form-item {
    margin-bottom: @global-margin-medium;
  }
}

.form-file {
  .js & {
    width: .1px;
    height: .1px;
    padding: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.file-upload-wrapper {
  label + & {
    margin-top: @global-margin-small;
  }

  .description {
    color: inherit;
    margin-bottom: @global-margin-medium;
  }

  label.file-upload-button {
    font-weight: normal;
  }

  img {
    margin-top: @global-margin-small;
    border-radius: @thumb-border-radius;
  }

  // Hide errors (e.g. related to wrong file format).
  .messages {
    display: none;
  }
}

.file-uploaded-info-wrapper {
  span.file-uploaded-info {
    border-radius: @button-border-radius;
    padding: @global-margin @global-margin-large;
    background-color: @_grey_lighten_color;

    .file {
      .text-ellipsis();

      a {
        color: inherit;
      }
    }

    .file-size {
      opacity: .7;
    }

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
    }
  }

  & + & {
    margin-top: @global-margin-medium;
  }
}

// Styles for "mtx_api_image_archive" image field widget.
.mtx-api-image-archive-wrapper {
  .mtx-archive-image {
    .position-cover();
    border-radius: @thumb-border-radius;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @api-item-empty-bg;

    .mtx-dark-theme & {
      background-color: @overlay-color-darken;
    }

    &-item {
      max-width: 200px;
      position: relative;
      margin-bottom: @global-margin;
    }

    &-holder {
      position: relative;
      padding-top: 56.25%;
      outline: 0 none;
      border-radius: @thumb-border-radius;

      .mtx-handler {
        border-radius: calc(@md-icon-size / @button-border-radius-ratio);
      }

      &:hover,
      &:focus {
        .mtx-actions-wrapper .mtx-actions {
          visibility: visible;
          opacity: 1;
        }

        .mtx-archive-image-overlay {
          visibility: visible;
          opacity: @videos-thumb-overlay-opacity;
        }

        .mtx-archive-image-plus {
          color: @_grey_dark_color;
        }
      }
    }

    &-overlay {
      background: none @overlay-color-darken;
      visibility: hidden;
      opacity: 0;
      transition: @global-hover-transition;
    }

    &-plus {
      .position-cover();
      text-align: center;
      font-size: 1.75em;
      color: @_grey_color;
      cursor: pointer;
      transition: color .15s ease-in-out;

      .md-icon {
        .position-center();
      }
    }
  }
}
