// Image rotate
.mtx-rotate-actions {
  position: relative;
  .transition(all .1s .1s linear);

  .image-actions {
    .nei-widget-controls;

    .control .md-icon {
      font-size: @md-icon-size;
    }
  }

  &:hover {
    background-color: #f0f0f0;

    .image-actions {
      z-index: 13;
      margin-bottom: -2.5rem;
      .opacity(1);
    }
  }
}
