.announcements-list {
  .mtx-list-item .is-scheduled {
    .icon {
      position: relative;
    }

    .schedule-icon {
      @icon-size: 1rem;
      @size: @icon-size + @global-margin-mini * 2;

      .square(@size);
      display: flex;
      min-width: @size;
      position: absolute;
      top: -(@size / 6);
      inset-inline-end: -(@size / 6);
      padding: @global-margin-mini;
      border-radius: @global-border-radius-circle;

      &:after {
        .md-icon();
        content: '\efd6'; // md-schedule
        font-size: @icon-size;
      }
    }

    .title > a {
      opacity: .6;
    }
  }
}

.node-teachers_announcement-form {
  .form-type-checkbox {
    & + .expiration-fields-container,
    & + .schedule-fields-container {
      margin-inline-start: @field-type-checkbox-radio-siblings-margin;
    }
  }
}
