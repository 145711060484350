// Table.
.mtx-oauth2-server-client-secrets-wrapper {
  .title-cell {
    min-width: 8rem;
  }

  .client-id-cell .client-id,
  .created-cell,
  .expires-cell {
    min-width: 10rem;
  }
}

// Form.
.mtx-oauth-2-server-client-secret-form {
  .form-type-select.form-item-scopes {
    width: 100%;
  }

  @media all and (min-width: @breakpoint-large) {
    .form-item-requests-per-time-window input {
      width: 50%;
    }
  }
}

// Overview page.
.mtx-oauth2-server-client-secret-description-list {
  [data-show-more] {
    max-height: calc(@global-font-size * @global-line-height * 3);
  }
}
