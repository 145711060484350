.questionnaire-form {
  [aria-invalid=true] {
    border-color: @form-danger-border !important;
  }

  .question-type-range-container {
    .form-item {
      margin: 0;

      .chosen-container {
        max-width: auto;
        min-width: @field-type-number-width;
      }
    }
  }

  .field-switchable {
    margin-inline-start: @field-type-checkbox-radio-siblings-margin;
  }

  .question-options-wrapper {
    margin-top: @global-margin-large;
  }
}

.questionnaire-form-question {
  &[data-type="text"] &-description {
    .description-text {
      .text-ellipsis-box(3);
    }
  }

  &[data-type="divider"] {
    [id$="field-group-html"].switchable-fieldset {
      border: @form-border-width solid @form-border;
      border-radius: @inputs-border-radius;
    }
  }
}
