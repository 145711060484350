@page-quick-link-size: @global-size-medium;
@page-quick-link-dim-transition-opacity: opacity .3s ease-in;
@page-quick-link-dim-transition-opacity-dimmed: opacity .5s ease-out;

.page-quick-link {
  position: fixed;
  box-shadow: 0 4px 12px fade(@global-text-color, 6%);
  -webkit-tap-highlight-color: transparent;

  &:after {
    content: '';
    border-radius: inherit;
    position: absolute;
    top: 0;
    box-shadow: 0 4px 12px fade(@global-text-color, 16%);
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &-dim {
    transition: @page-quick-link-dim-transition-opacity;

    &.inactive {
      transition: @page-quick-link-dim-transition-opacity-dimmed;
    }
  }

  &:hover,
  &:focus {
    color: inherit;

    &:after {
      opacity: 1;
    }
  }

  &:not(.uk-button) {
    .page-quick-link-shape();
  }

  &.uk-button-large {
    .md-icon {
      margin-inline-end: @global-margin-small;
    }
  }

  .mtx-dark-theme & {
    box-shadow: 0 4px 12px fade(@_white_color, 6%);

    &:after {
      box-shadow: 0 4px 12px fade(@_white_color, 16%);
    }
  }

  @media all and (min-width: @breakpoint-small) {
    &.uk-button-large {
      .md-icon {
        @icon-fs: 2rem;
        @icon-margin-y: calc((@icon-fs - @md-icon-size) / 2 * -1);

        font-size: @icon-fs;
        margin-top: @icon-margin-y;
        margin-bottom: @icon-margin-y;
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .text {
      display: none;
    }

    &.uk-button {
      padding: 0;
      min-height: auto;
      .page-quick-link-shape();

      .md-icon {
        margin: 0 !important;
      }
    }
  }

  // Mixins.
  .page-quick-link-shape() {
    .square(@page-quick-link-size);
    border-radius: @button-border-radius;
    text-align: center;

    &:after {
      .square(@page-quick-link-size);
    }

    .md-icon {
      line-height: @page-quick-link-size;
    }
  }
}
