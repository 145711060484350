@mtx-thumb-overlay-z-index: 1;

.mtx-thumb {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  background-color: @api-item-empty-bg;

  .mtx-dark-theme & {
    background-color: @overlay-color-darken;
  }

  &,
  &-image,
  &-image > img,
  &-overlay {
    border-radius: @thumb-border-radius @thumb-border-radius 0 0;
  }

  &-image,
  &-overlay {
    .position-cover();
  }

  &-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-image.background {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-overlay {
    background-color: @overlay-color-darken;
    opacity: 0;
    transition: @global-hover-transition;
    z-index: @mtx-thumb-overlay-z-index;

    &.light {
      background-color: @overlay-color-dark;
    }
  }

  &-icon {
    .position-center();
  }
}
