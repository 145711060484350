@nei-customizer-z-index: @tooltip-z-index + 2;

.nei-widget-customizer {
  @option-lh: 1.25em;
  @option-br: calc(unit(@option-lh * 16, px) / @inputs-border-radius-ratio);
  @width: 320px;

  display: none;
  position: absolute;
  left: 50%;
  top: 100%;
  cursor: default;
  width: @width;
  transform: translateX(-50%);
  margin-top: @global-margin-small;
  color: @global-text-color !important;
  z-index: @nei-customizer-z-index;

  .freeze-controls & {
    display: block;
  }

  &-overlay {
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @nei-customizer-z-index - 1;
  }

  .wrapper {
    position: relative;
    background-color: @dropdown-background;
    line-height: @option-lh;
    text-align: start;
    z-index: 1;
    border-radius: @dropdown-border-radius;
    box-shadow: @dropdown-box-shadow;
    overflow: hidden;
  }

  .customizer-container {
    padding: @global-margin-medium @global-margin-large;
  }

  button {
    color: @global-text-color;
    background: transparent;
    border: 0 none;
    outline: 0 none;
    text-align: center;
    cursor: pointer;
    appearance: none;

    &:hover,
    &:focus {
      background-color: @_grey_lighten_color;
    }

    &:disabled {
      background-color: transparent;
      cursor: default;
      opacity: .48;
    }
  }

  .range-input-wrapper,
  .color-input {
    width: 220px;
  }

  input[type=range] {
    height: @global-size-tiny / 4;
    width: 195px;
    margin-inline-end: @global-margin-small;
  }

  label {
    font-size: @global-font-size-small;
    font-weight: normal;
    margin-bottom: @global-margin-mini;
  }

  .options {
    display: flex;
    border-bottom: 1px solid @_grey_lighten_color;
  }

  .option {
    flex: 1;
    text-align: center;
    padding: 8px 2px;
    font-size: 14px;
    height: @form-height;
    line-height: @option-lh;
    border: 0 none;
    border-bottom: 2px solid transparent;
    .text-ellipsis();

    &:only-of-type {
      border-radius: @option-br !important;
      cursor: default;
      width: 100%;
    }

    > span {
      display: inline-block;
      vertical-align: middle;
    }

    &.active {
      border-bottom-color: @secondary_color;
    }
  }

  .color-actions {
    margin: 0 0 16px;
  }

  .clear-button {
    padding: 0;
    border-radius: @global-border-radius;

    .md-icon {
      font-size: @md-icon-size;
      line-height: @md-icon-size;
      vertical-align: middle;
    }
  }
}

.customizer-saved-colors {
  gap: 7px; // 9 * 24 + 8 * 7 = 272px; - width of color picker.

  @color-item-size: @md-icon-size;

  .save-color-button,
  .color-item {
    border-radius: @global-border-radius;
  }

  .save-color-button {
    padding: 0;

    .md-icon {
      font-size: @color-item-size;
      line-height: @color-item-size;
      vertical-align: middle;
    }
  }

  .color-item {
    width: @color-item-size;
    min-width: @color-item-size;
    height: @color-item-size;
    cursor: pointer;
  }
}
