@nei-actions-size: @nei-controls-size;
@nei-actions-position: -(@nei-actions-size + @grid-gutter-vertical * 16) / 2;
@nei-actions-position-large: -(@nei-actions-size + @grid-gutter-large-vertical * 16) / 2;

.nei-widget-actions {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 13; // Should be more than value in .nei-widget-overlay {}
  .clearfix();

  .nei-widget.editing &,
  .nei-widget.freeze-controls &,
  .hide-controls & {
    display: none;
  }

  .action {
    display: block;
    background: @nei-controls-bg;
    box-shadow: @nei-controls-box-shadow;
    .button-border-radius(@nei-controls-size / 16);
    cursor: pointer;
    width: @nei-controls-size;
    height: @nei-controls-size;
    text-align: center;
    color: @global-text-color;
    transition: background-color .2s linear;

    &.paste {
      display: none;
    }

    &:hover,
    &:focus,
    &.active {
      background-color: @nei-controls-bg-hover;
    }

    .md-icon {
      font-size: @nei-controls-icon-font-size;
      line-height: @nei-controls-size;
      vertical-align: baseline;
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      & + .action {
        margin-left: @nei-controls-padding / 2;
      }
    }

    [dir=rtl] & {
      & + .action {
        margin-right: @nei-controls-padding / 2;
      }
    }
  }

  @media (min-width: @breakpoint-large) {
    opacity: 0;
    transition: all .1s .1s linear;

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }

    .nei-widget:hover & {
      opacity: 1;

      &.bottom {
        bottom: @nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          bottom: @nei-actions-position-large;
        }
      }

      &.top {
        top: -@nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          top: -@nei-actions-position-large;
        }
      }
    }
  }

  @media (max-width: @breakpoint-small-max) {
    display: none;
  }
}

.uk-touch {
  .nei-widget-actions {
    opacity: 1;

    &.bottom {
      bottom: @nei-actions-position;

      @media (min-width: @breakpoint-xlarge) {
        bottom: @nei-actions-position-large;
      }
    }

    &.top {
      top: @nei-actions-position;

      @media (min-width: @breakpoint-xlarge) {
        top: @nei-actions-position-large;
      }
    }
  }

  .nei-section {
    .nei-widget-actions.top {
      display: none;
    }

    & .nei-widget:first-child {
      .nei-widget-actions.top {
        display: block;
      }
    }

    &:not(:empty) + .nei-section {
      & > .nei-widget:first-child {
        .nei-widget-actions.top {
          display: none;
        }
      }
    }
  }
}

.uk-notouch {
  @media all and (min-width: @breakpoint-medium) {
    .nei-widget-actions {
      opacity: 0;
      transition: all .1s .1s linear;
    }

    .nei-widget:hover .nei-widget-actions {
      opacity: 1;

      &.bottom {
        bottom: @nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          bottom: @nei-actions-position-large;
        }
      }

      &.top {
        top: @nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          top: @nei-actions-position-large;
        }
      }
    }
  }
}

.has-buffer {
  .nei-widget {
    .nei-widget-actions {
      opacity: 1;

      &.bottom {
        bottom: @nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          bottom: @nei-actions-position-large;
        }
      }

      &.top {
        top: @nei-actions-position;

        @media (min-width: @breakpoint-xlarge) {
          top: @nei-actions-position-large;
        }
      }
    }
  }

  .nei-section {
    .nei-widget-actions.top {
      display: none;
    }

    & .nei-widget:first-child {
      .nei-widget-actions.top {
        display: block;
      }
    }

    &:not(:empty) + .nei-section {
      & > .nei-widget:first-child {
        .nei-widget-actions.top {
          display: none;
        }
      }
    }
  }

  .nei-widget-actions {
    .action {
      &.add {
        display: none;
      }

      &.paste {
        display: block;
      }
    }
  }
}
