// Team latest activities.
.view-team-activities {
  .views-exposed-form {
    .views-exposed-widget {
      margin: 0;
    }
  }
}

// Teams filters.
.view-team-posts {
  .post-filters {
    .form-item {
      margin-bottom: 0;
    }
  }
}

.team-posts-list {
  .team-post .title {
    .mtx-flag-wrapper {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
}

.teams-exposed-forms {
  .views-exposed-widgets {
    margin-bottom: 0;
  }

  .views-exposed-form {
    .views-submit-button,
    &.views-submit-button {
      margin: 0;
      padding: 0;

      input {
        display: none;
      }
    }

    .form-item {
      margin-bottom: 0;
    }
  }
}

// Teams discussions.
.teams-actions {
  .discussions {
    width: @exposed-input-width;

    select {
      &:focus {
        position: relative;
        z-index: 1;
      }
    }
  }

  [dir=ltr] & {
    .discussions select {
      border-radius: 0 @global-border-radius @global-border-radius 0;
    }
  }

  [dir=rtl] & {
    .discussions select {
      border-radius: @global-border-radius 0 0 @global-border-radius;
    }
  }
}
