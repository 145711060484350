.hook-api-item() {
  &.folder {
    .thumb {
      display: block;
      overflow: hidden;
      padding-top: @image-item-padding;
      position: relative;
      z-index: 1;
      border-radius: @thumb-border-radius;
      background-color: @_grey_lighten_color;
      color: @_grey_dark_color;

      .folder-icon {
        font-size: 2rem;
        .position-center();

        [dir=rtl] & {
          transform: translate(-50%, -50%) rotateY(180deg);
          transform-origin: center;
        }
      }
    }
  }
}
