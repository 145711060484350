// Item.
.mtx-product-item {
  margin-bottom: @global-margin-large;

  .mtx-product-banner {
    .mtx-thumb {
      .mtx-product-thumb();

      &,
      &-image {
        border-radius: @thumb-border-radius;
      }

      &-image > img {
        .mtx-product-thumb-image();
      }
    }

    .md-icon {
      font-size: 3rem;
    }

    [dir=ltr] & {
      margin-right: @global-margin-large;
    }

    [dir=rtl] & {
      margin-left: @global-margin-large;
    }
  }

  .mtx-product-details {
    word-break: break-word;
  }

  .mtx-product-title {
    margin: 0;
    font-size: @global-font-size;
    .text-ellipsis-box(2);
  }

  .mtx-product-description {
    .text-ellipsis-box(3);
  }

  // Activity form.
  .activity-form & {
    .mtx-product-banner {
      .mtx-thumb {
        .mtx-product-thumb(7vw, 5rem, 6.25rem);
      }

      .md-icon {
        font-size: 2rem;
      }
    }
  }

  // Products widget.
  .mtx-additional-products-section & {
    margin-bottom: @global-margin-medium * 2;

    .mtx-product-banner {
      .mtx-thumb {
        .mtx-product-thumb(10vw, 5rem, 9rem);
      }

      .md-icon {
        font-size: 3rem;
      }
    }

    @media all and (min-width: @breakpoint-medium) and (max-width: @breakpoint-large-max) {
      .page-sidebar-opened & {
        width: 100%;

        & > .uk-flex {
          flex-wrap: wrap;
        }
      }
    }
  }

  // Checkout form.
  .checkout-page & {
    .mtx-product-banner {
      .md-icon {
        font-size: 2rem;
      }
    }

    .mtx-product-details {
      @media all and (max-width: @breakpoint-mini-max) {
        display: block;

        .mtx-product-buy-button {
          margin-top: @global-margin-medium;
        }
      }

      @media all and (min-width: @breakpoint-small) {
        .mtx-product-buy-button {
          flex-shrink: 0;

          [dir=ltr] & {
            margin-left: auto;
            padding-left: @global-margin-large;
          }

          [dir=rtl] & {
            margin-right: auto;
            padding-right: @global-margin-large;
          }
        }
      }
    }
  }
}

// Mixins.
.mtx-product-thumb(@size: 8vw, @min-size: 5rem, @max-size: 7.5rem) {
  min-width: @min-size;
  width: @size;
  max-width: @max-size;
  min-height: @min-size;
  height: @size;
  max-height: @max-size;
  padding-top: unset;
}

.mtx-product-thumb-image(@max-width: 80%, @max-height: 80%) {
  .position-center(relative);
  display: block;
  height: auto;
  max-width: @max-width;
  max-height: @max-height;
  border-radius: 0;
  object-fit: contain;
}
