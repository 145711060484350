.mtx-api-rating {
  @rating-gap: @global-margin-mini;
  @rating-button-size: 2.5rem;

  text-align: center;

  &-widget {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: @rating-gap;
  }

  &-button {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: @rating-button-size;
    height: @rating-button-size;
    border-radius: calc(@rating-button-size / @button-border-radius-ratio);
    text-align: center;
    font-size: @base-h3-font-size;
    font-family: @base-heading-font-family;
    user-select: none;

    .point {
      display: block;
      min-width: @md-icon-size;
      margin: 0;
    }
  }
}

.mtx-activity-rating-popup {
  padding: @global-margin-small @global-margin-large;
  text-align: center;

  .icon .md-icon {
    font-size: 5 * @global-font-size;
    color: @global-warning-color;
    margin-bottom: @global-margin-large;

    &.md-check_circle {
      color: @global-success-color;
    }
  }

  .title {
    font-size: 2 * @global-font-size;
    line-height: 1;
  }
}
