@enroll-info-block-meta-font-size: unit(@global-font-size-xsmall, rem);
@enroll-info-block-meta-line-height: 1.4;
@enroll-info-block-meta-color: @_grey_dark_color;
@enroll-info-block-icon-color: @_grey_dark_color;

.enroll-info-block {
  // Info block.
  .enroll-info {
    .includes,
    .additional-products {
      line-height: 1.3;

      .md-icon {
        color: @enroll-info-block-icon-color;
      }
    }

    .contact-hours-wrapper {
      strong {
        min-width: @md-icon-size;
      }
    }

    h5.uk-display-inline-block {
      margin-bottom: 0;
    }
  }

  // Options.
  .options-wrapper {
    .uk-dropdown > ul {
      max-height: 21.875rem; // 350px
      overflow-y: auto;
    }
  }

  .options-item {
    display: table;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    .user-select;

    .table {
      display: table;
      width: 100%;
      font-size: inherit;
      word-break: break-word;

      & > div {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .activity-price {
      min-width: 6.5rem;
      max-width: 50%;
    }

    .price s {
      display: inline-block;
    }

    .title,
    .price {
      line-height: 1.4rem;
    }

    .time,
    .location,
    .seats,
    .price-with-vat {
      font-size: @enroll-info-block-meta-font-size;
      line-height: @enroll-info-block-meta-line-height;
      color: @enroll-info-block-meta-color;
    }

    &.uk-active {
      .time,
      .location,
      .price-with-vat {
        color: inherit;
      }

      .seats {
        color: inherit !important;
      }
    }
  }

  .uk-dropdown {
    .options-item .table > div {
      vertical-align: top;
    }
  }

  // Modules.
  .module {
    word-break: break-word;

    & + .module {
      margin-top: @global-margin-medium;
    }

    & > .md-icon {
      min-width: @md-icon-size;
      color: @enroll-info-block-icon-color;
    }

    .location {
      font-size: @enroll-info-block-meta-font-size;
      line-height: @enroll-info-block-meta-line-height;
      color: @enroll-info-block-meta-color;
    }
  }

  // Total price.
  .total-price {
    .price-raw {
      line-height: 1.3;
      font-size: 1.5rem;
      font-weight: @global-font-weight-bold;

      s {
        font-weight: @global-font-weight-normal;
      }
    }

    .price-with-vat {
      font-size: @global-font-size-small;
    }

    s {
      display: inline-block;
    }
  }

  // Location icon.
  .mtx-location-map {
    padding: 0 @global-margin-mini / 2;

    .md-icon {
      font-size: 1rem;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    margin-top: @global-margin;
  }
}

.enroll-info-languages-block {
  padding: @global-margin @mtx-widget-padding @global-margin-large;

  .md-icon {
    color: @enroll-info-block-icon-color;
  }
}

.enroll-info-registration-deadline-block {
  .md-icon {
    margin-top: -0.25rem !important;
    color: @enroll-info-block-icon-color;
  }
}
