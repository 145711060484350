.button-widget-content {
  .uk-button {
    &.custom-size {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      min-height: @global-size-mini;
    }

    &.custom-color {
      &:hover {
        opacity: .86;
      }
    }
  }
}
