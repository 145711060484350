.mtx-h5p {
  // Filters
  .filters {
    .search {
      .input-search-icon();

      input {
        width: 100%;
      }
    }
  }

  .filter-badges {
    margin-top: @global-margin;
  }

  // Listing
  .h5p-item {
    margin-bottom: @global-margin-medium * 2;

    .mtx-action-item {
      position: relative;

      &:hover {
        .thumb-image-hover {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .api-item {
      display: block;
      text-decoration: none;
      border-radius: @thumb-border-radius;
      .hook-api-item();

      .title {
        font-size: @global-font-size;
        text-decoration: @base-link-text-decoration;
      }

      &:hover,
      &:focus {
        .title {
          text-decoration: @base-link-hover-text-decoration;
        }
      }

      &:focus {
        .thumb-image-hover {
          visibility: visible;
          opacity: 1;
        }

        & ~ .mtx-actions-wrapper .mtx-actions {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .thumb {
      border: none !important;

      &-image-hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border-radius: @thumb-border-radius;
        background-color: @overlay-color-darken;
        visibility: hidden;
        opacity: 0;
        transition: @global-hover-transition;
      }
    }

    .info {
      .title {
        margin: @global-margin-mini 0 @global-margin-small;
        font-size: @global-font-size-small;
        line-height: @global-line-height;
      }

      .author,
      .title {
        .text-ellipsis-box(2, 1.3);
      }

      .author,
      .date {
        font-size: @global-font-size-xsmall;
      }

      .author ~ * {
        margin-top: @global-margin-mini;
      }
    }
  }

  .uk-touch & {
    .mtx-actions > a.mtx-handler {
      .md-icon {
        color: @global-muted-color;
      }
    }

    .mtx-action-item {
      &:hover,
      .api-item:focus ~ .mtx-actions-wrapper {
        .mtx-actions .md-icon {
          color: @_white_color;
        }
      }

      &:not(:hover) {
        .mtx-handler:focus {
          .md-icon {
            color: @global-muted-color;
          }
        }
      }
    }
  }

  .used-in-course {
    position: absolute;
    top: @global-margin-small;
    .circle(@md-icon-size);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .md-icon {
      font-size: 1.125rem;
      min-width: 1.125rem;
    }

    [dir=ltr] & {
      left: @global-margin-small;
    }

    [dir=rtl] & {
      right: @global-margin-small;
    }
  }

  .pager {
    line-height: 2;

    .fa {
      color: @global-muted-color !important;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .filters .search {
      float: none;
      overflow: hidden;
    }
  }

  @media all and (max-width: 375px) {
    .h5p-item {
      width: 50%;
    }
  }
}

.node-h5p_content-form input[type="file"] {
  line-height: 1;
}

.node-type-h5p-content {
  .submitted {
    margin-bottom: @global-margin;
  }
}
