.marketplace-promo-banner {
  padding: @global-margin-medium;
  background-size: cover;
  background-position: center center;

  &-preview {
    margin: unit(1.875 - @global-margin, rem) -@content-section-padding -@content-section-padding;
    padding-top: @global-margin;

    @media all and (max-width: @breakpoint-small-max) {
      display: none;
    }
  }

  .banner-button {
    &:hover,
    &:focus {
      opacity: .9;
    }
  }
}
