// Name:            Placeholder
// Description:     Defines styles for placeholder boxes
//
// Component:       `uk-placeholder`
//
// Modifiers:       `uk-placeholder-large`
//
// ========================================================================


// Variables
// ========================================================================

@placeholder-margin-vertical:                   15px;
@placeholder-padding:                           20px;
@placeholder-border:                            #ddd;
@placeholder-background:                        #fafafa;
@placeholder-color:                             #444;

@placeholder-large-padding-vertical:            80px;


/* ========================================================================
   Component: Placeholder
 ========================================================================== */

.uk-placeholder {
    margin-bottom: @placeholder-margin-vertical;
    padding: @placeholder-padding;
    border: 1px dashed @placeholder-border;
    background: @placeholder-background;
    color: @placeholder-color;
    .hook-placeholder;
}

/*
 * Add margin if adjacent element
 */

* + .uk-placeholder { margin-top: @placeholder-margin-vertical; }

/*
 * Remove margin from the last-child
 */

.uk-placeholder > :last-child { margin-bottom: 0; }


/* Modifier: `uk-placeholder-large`
 ========================================================================== */

.uk-placeholder-large {
    padding-top: @placeholder-large-padding-vertical;
    padding-bottom: @placeholder-large-padding-vertical;
    .hook-placeholder-large;
}


// Hooks
// ========================================================================

.hook-placeholder-misc;

.hook-placeholder() {}
.hook-placeholder-large() {}
.hook-placeholder-misc() {}