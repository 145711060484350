@import "editor/variables";
@import "editor/animations";
@import "editor/components/anchor-preview";
@import "editor/components/file-dragging";
@import "editor/components/placeholder";
@import "editor/components/toolbar";
@import "editor/components/toolbar-form";

// contenteditable rules
.editor-element {
  min-height: 30px;

  img {
    max-width: 100%;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }
}

.editor-hidden {
  display: none;
}
