@mtx-course-review-shadow-size: 4px;

// Add comment button.
#mtx-course-review-add-comment {
  position: fixed;
  bottom: 0;
  margin-bottom: @global-margin-large;
  transition: bottom .15s ease;
  z-index: @mtx-api-page-footer-nav-z-index;

  .mtx-api-page-footer-nav-fixed + & {
    bottom: 2 * @mtx-api-page-footer-nav-padding + @mtx-api-page-footer-nav-title-font-size * @mtx-api-page-footer-nav-title-line-height;
  }

  [dir=ltr] & {
    right: @global-margin-large;
  }

  [dir=rtl] & {
    left: @global-margin-large;
  }
}

// Comments list
.mtx-course-review-comments {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: @global-margin @global-margin-large;

  // Scrollbar
  .mtx-custom-scrollbar {
    padding-bottom: @global-margin;
    margin-top: -@mtx-course-review-shadow-size !important;
    margin-bottom: -@mtx-course-review-shadow-size !important;
    margin-inline-end: -@mtx-scrollbar-padding;

    &.os-host-scrollbar-vertical-hidden .scroll-content {
      padding: 0 @mtx-scrollbar-padding;
    }
  }

  .mtx-course-review-sidebar-header {
    margin-bottom: @global-margin;
  }

  .mtx-course-review-sidebar-title {
    color: @global-muted-color;
    font-size: @global-font-size-small;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .mtx-course-review-sidebar-actions {
    & > * {
      margin-left: @global-margin-medium;
    }
  }

  .mtx-course-review-sidebar-content {
    flex: 1;

    & > .mtx-custom-scrollbar {
      height: 100%;
    }
  }
}

.mtx-course-review-comments-form {
  margin-top: @global-margin-medium;

  .form-item-severity,
  .form-item-widget-id {
    float: left;
    margin-top: 0;
    margin-bottom: 0;

    .field-suffix {
      margin-top: @global-margin;
    }

    [dir=ltr] & {
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      margin-left: @global-margin;
    }
  }

  .form-actions {
    margin-top: 0;

    .form-submit {
      margin-top: 0;
    }
  }

  .cke_contents {
    max-height: unit(5 * @global-line-height, rem); // Max 5 lines of text.
  }
}

// Comments list
.mtx-course-review-comment {
  background-color: @global-page-background-color;
  border-radius: @global-border-radius;
  padding: @global-margin @global-margin-large;

  .mtx-list & {
    margin-top: @mtx-course-review-shadow-size;
    margin-bottom: @mtx-course-review-shadow-size;

    & + .mtx-course-review-comment {
      margin-top: @global-margin;
    }
  }

  .mtx-dark-theme & {
    background-color: @overlay-color-lighten;
  }

  .mtx-course-review-comment-resolve-button {
    &.unresolve {
      display: none;
    }
  }

  &.resolved {
    .description {
      text-decoration: line-through;
    }

    .mtx-course-review-comment-resolve-button {
      &.resolve {
        display: none;
      }

      &.unresolve {
        display: inline-block;
      }
    }
  }

  .mtx-course-review-pin {
    color: @_grey_dark_color;
  }
}

.mtx-course-review-severity-dropdown {
  .uk-button {
    padding-left: @button-padding-horizontal / 2;
    padding-right: @button-padding-horizontal / 2;
  }
}

.severity-marker {
  display: flex;
  align-items: center;

  .mtx-course-review-comment & {
    font-size: @global-font-size-xsmall;
  }

  &:before {
    content: '';
    width: .75rem;
    height: .75rem;
    border-radius: @global-border-radius-circle;

    [dir=ltr] & {
      margin-right: @global-margin;
    }

    [dir=rtl] & {
      margin-left: @global-margin;
    }
  }

  &-1:before {
    background: #f5ec03; // low
  }

  &-2:before {
    background: #ffba00; // medium
  }

  &-3:before {
    background: #ff0000; // high
  }
}

// Pin widget in review mode.
#mtx-course-review-pin-done,
#mtx-course-review-pin-cancel {
  display: none;
}

.nei-mode-review {
  #mtx-course-review-add-comment,
  #mtx-course-review-complete-review {
    display: none;
  }

  #mtx-course-review-pin-done,
  #mtx-course-review-pin-cancel {
    display: inline-block;
  }
}

// Completion message
.mtx-course-review-completion-message {
  padding: 2 * @global-margin-xlarge 0 @global-margin-xlarge;

  h1 {
    font-size: 2rem;
  }

  .message {
    margin: 2 * @global-margin-medium;
  }
}

// Reviewers list
#mtx-course-review-reviewers {
  padding: 0 @button-height / 3;
  margin: 0 @global-margin;

  & > .user-avatar {
    width: @button-height; // NEI actions height
    margin: 0 -(@button-height / 6);
  }
}
