@nei-templates-items-width: 144px;
@nei-revisions-items-width: 280px;
@nei-active-item-border-width: 4px; // See colors.less .nei-actions-wrapper styles

.nei-actions-wrapper {
  position: absolute;
  left: 100%;
  top: 0;
  min-width: @nei-templates-items-width;
  margin: 0 @global-margin-large;
  z-index: 0;

  &.nei-revisions-wrapper {
    min-width: @nei-revisions-items-width;
  }

  .nei-actions {
    margin: 0 auto @global-margin;
    text-align: center;
    width: 100%;

    .uk-button {
      display: block;
      margin: 0 0 @global-margin;
      width: 100%;
    }
  }

  .templates-list,
  .revisions-list.os-host-scrollbar-vertical-hidden {
    margin: 0 0 @global-margin;
  }

  .templates-list,
  .revisions-list {
    @media (max-width: @breakpoint-small-max) {
      display: none;
    }
  }

  .template-item,
  .revision-item {
    display: block;
    border-radius: @global-border-radius;
    margin: @nei-active-item-border-width @nei-active-item-border-width @global-margin;
    position: relative;
    z-index: 1;

    &.active {
      cursor: default;
    }
  }

  .template-item {
    padding-top: 80%;

    .layout-icon {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .set-nei-layout-icon(6);

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      transform: rotateY(0deg);
    }

    [dir=rtl] & {
      transform: rotateY(180deg);
    }
  }

  .revision-item {
    color: inherit;
    background-color: @global-base-color;

    .mtx-dark-theme & {
      background-color: @mtx-section-even-bg-color-dark;
    }

    .icon {
      margin: 0;
      margin-inline-end: @global-margin-medium;

      & + .item-content {
        margin-inline-start: @list-icon-size-small + @global-margin-medium;
      }
    }

    .info {
      min-height: 55px;
      padding: @global-margin-medium;
      user-select: none;
    }
  }

  html[dir=rtl] & {
    left: auto;
    right: 100%;
  }

  @media (max-width: @breakpoint-small-max) {
    bottom: 100%;
    left: auto;
    margin: 0 0 @global-margin;
    right: 0;
    padding: @global-margin;
    top: auto;
    width: 100%;
    background-color: fade(#ffffff, 20%);
    border-radius: @global-border-radius;

    .nei-actions {
      width: 100%;
      margin: 0;
      .clearfix();

      .uk-button {
        float: right;
        margin: 0;
        width: 48%;

        & + .uk-button {
          margin-right: 4%;
        }

        [dir=rtl] & {
          float: left;

          & + .uk-button {
            margin-right: auto;
            margin-left: 4%;
          }
        }
      }
    }
  }
}

.nei-content-actions {
  margin-bottom: @global-margin-small;

  & > a,
  & > span.mtx-flag-wrapper > a {
    transition: all .15s ease-in-out;
    display: block;
    width: @button-height;
    height: @button-height;
    line-height: @button-height;
    text-align: center;
    border-radius: @button-border-radius;
  }

  @media (max-width: @breakpoint-small-max) {
    &.nei-edit-link,
    a[href="#revisions"] {
      display: none;
    }
  }

  #mtx-course-actions[dir=ltr] & {
    margin-left: @global-margin-mini;
  }

  #mtx-course-actions[dir=rtl] & {
    margin-right: @global-margin-mini;
  }
}

.set-nei-layout-icon(@quantity) {
  .style(@i: 1) when (@i < (@quantity + 1)) {
    &.template-v@{i} .layout-icon {
      background-image: url('@{global-images-path}nei/tpls/v@{i}.svg');
    }

    .mtx-dark-theme & {
      &.template-v@{i} .layout-icon {
        background-image: url('@{global-images-path}nei/tpls/dark/v@{i}.svg');
      }
    }

    .style(@i + 1);
  }

  .style();
}
