.nei-widgets-toolbar {
  display: none;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;

  &.active {
    display: block;
    overflow: auto;
    background-color: fade(@_black_color, 80%);
  }

  .toolbar-wrapper {
    display: table;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .toolbar-body {
    display: table-cell;
    vertical-align: middle;
  }

  .toolbar-items {
    width: 90%;
    max-width: 960px;
  }

  .toolbar-item {
    display: block;
    color: @global-text-color;
    background-color: @_white_color;
    border-radius: @thumb-border-radius;
    text-align: center;
    padding: @global-margin-large @global-margin-small;
    margin-bottom: @global-margin-medium;
    transition: all .2s linear;

    &:active,
    &:focus,
    &:hover {
      opacity: .9;
    }

    .md-icon {
      font-size: 3rem;
    }
  }

  .title {
    .text-ellipsis();
    margin: @global-margin-small 0 0;
  }
}
