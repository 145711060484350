//
// Component: Slidenav
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-slidenav() {
  text-decoration: none;
}

.hook-slidenav-hover() {}

.hook-slidenav-active() {}

.hook-slidenav-contrast() {}

.hook-slidenav-contrast-hover() {}

.hook-slidenav-contrast-active() {}


// Miscellaneous
// ========================================================================

.hook-slidenav-misc() {
  .uk-lightbox-content {
    &, img {
      border-radius: inherit; // modal border-radius
    }
  }
}
